import { StyleSheet, Text, View } from "react-native"
import React, { useState, useEffect } from "react"
import { Sheader } from "../../components/header/Sheader"
import { translate } from "../../i18n"
import { FULL, fitSize } from "../../theme"
import { useStores } from "../../models"
import { ThemeScreen } from "../../components/screen/ThemeScreen"
import { themeData } from "../../navigators"
import { useTheme } from "styled-components/native"
import { useFocusEffect } from "@react-navigation/native"

type ServiceInfo = {}

const RelationService: React.FC = (route) => {
  const { accountStore } = useStores()
  const [text, setText] = useState("")
  const theme = useTheme() as themeData

  const getServiceInfo = async () => {
    const res = await accountStore.environment.api.normalRequest("/misc/customerServiceInfo", {})
    if (res.kind === "ok") {
      if (res.data) {
        setText(res.data?.message)
      }
    }
  }

  useEffect(() => {
    getServiceInfo()
  }, [])

  return (
    <View style={styles.container}>
      <Sheader rightImage={""} title={"联系客服"} leftImage={""} />
      <View style={styles.content}>
        <Text style={styles.body}>{text}</Text>
      </View>
    </View>
  )
}

export default RelationService

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#202530",
  },
  content: {
    flex: 1,
    backgroundColor: "#F5F5F5",
  },
  body: {
    backgroundColor: "#fff",
    width: "90%",
    height: "70%",
    alignSelf: "center",
    marginTop: fitSize(20),
    borderRadius: fitSize(7),
    padding: fitSize(20),
    color: "#333333",
    fontSize: fitSize(15),
  },
})
