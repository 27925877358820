import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"
import { observer } from "mobx-react-lite"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
}
export interface saleItem {
  id: number
  orderId?: string
  goodsName: string
  price: number
  tokenURI: string
  buyerAddress: string
  phonenumber: string
  nftAsset?: nftAsset
}
export type nftAsset = {
  assetId: number
  nftAddress: string
  num: number
}
export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserTradeMarketOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 2,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return <SaleItem item={item} />
        }}
      />
    </View>
  )
}

const SaleItem = ({ item }: { item: saleItem }) => {
  const [assetData] = useUriInfo(item?.tokenURI, [item])
  const navigation = useTypeNavigation()
  return (
    <Touchable
      onPress={() => {
        navigation.push("TransactionScreen", { id: item.id, isMine: true })
      }}
      style={{
        width: fitSize(351),
        height: fitSize(114),
        alignSelf: "center",
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: fitSize(15),
        paddingTop: fitSize(12),
      }}
    >
      <SizeImage
        source={{ uri: assetData?.Image }}
        style={{ width: fitSize(90), height: fitSize(90) }}
        resizeMode={"contain"}
        isPreview
      />
      <View style={{}}>
        <Text
          numberOfLines={2}
          style={{ fontSize: fitSize(14), color: "#333333", fontWeight: "500", width: "100%" }}
        >
          {item?.goodsName}
        </Text>
        <Text
          style={{
            fontSize: fitSize(12),
            color: "#888888",
            marginTop: fitSize(5),
          }}
        >
          {assetData?.Series}
        </Text>
      </View>
      <View style={{ marginLeft: "auto", flexDirection: "row", alignItems: "center" }}>
        {!!item?.price && (
          <Text style={{ color: "#FF2E11", fontSize: fitSize(16), fontWeight: "500" }}>
            ￥{(item?.price / 100).toFixed(2)}
          </Text>
        )}
        {!!item?.nftAsset?.num && <PriceItemForSearch item={item} />}
      </View>
    </Touchable>
  )
}
export const PriceItemForSearch = observer(({ item }: { item: saleItem }) => {
  const { collectStore } = useStores()
  const name = collectStore.nftForExchange.get(item?.nftAsset?.nftAddress + item?.nftAsset?.assetId)
    ?.name
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text
        style={{
          color: color.price,
          fontSize: fitSize(14),
        }}
      >
        {`${item?.price ? " | " : ""}${name}x${item?.nftAsset?.num}`}
      </Text>
    </View>
  )
})

export const SaleList = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
