import { useState } from 'react'
import { HookFlatList, Text, Touchable } from "../../components"
import { Images, color, fitSize } from "../../theme"
import { Linking, StyleSheet, View } from "react-native"

import { FlexView } from "../../components/screen/FlexView"
import React from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"

interface NftHistoryProps extends RootStackNavProps<"NftHistory"> {}
const PL = 10
export interface nftHistoryInfo {
  from: string
  to: string
  transactionHash: string
  blockTime: string
}
export const NftHistory: React.FC<NftHistoryProps> = ({ navigation, route }) => {
  const { accountStore, collectStore } = useStores()
  const [explorerUrl, setExplorerUrl] = useState<string>('')

  const fetchUserAssetBySeriesId = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/sapi/card/getNftHistoryByPage",
      {
        nftId: route?.params?.nftId,
        contractAddress: route?.params?.contractAddress,
        page,
        pageSize: PL,
      },
      "post",
    )
    if (res.kind === "ok") {
      setExplorerUrl(res?.data?.blockExplorerUrl)
      return res?.data?.list
    } else {
      throw res.msg
    }
  }

  const [assetList] = useAutoList<nftHistoryInfo>(
    {
      fetchData: fetchUserAssetBySeriesId,
      pageLimit: PL,
    },
    [],
  )

  return (
    <FlexView>
      <Sheader title="历史轨迹" />
      <HookFlatList
        style={{ marginTop: fitSize(5), backgroundColor: "#fff" }}
        {...{ ...assetList }}
        renderItem={({ item, index }) => {
          return (
            <Touchable
              onPress={() =>
                Linking.openURL(`${explorerUrl}tx/${item?.transactionHash}`)
              }
              style={{
                width: fitSize(345),
                alignSelf: "center",
                height: fitSize(94),
                borderBottomColor: "#E8E8E8",
                borderBottomWidth: StyleSheet.hairlineWidth,
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: fitSize(14),
                  color: color.primary,
                  fontWeight: "600",
                  marginBottom: fitSize(6),
                }}
              >
                {dayjs(item?.blockTime).format("YYYY/MM/DD HH:mm:ss")}
              </Text>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        color: color.primary,
                        fontSize: fitSize(13),
                        fontWeight: "500",
                        width: fitSize(44),
                      }}
                    >
                      from
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{ fontSize: fitSize(13), color: color.dim, width: fitSize(200) }}
                    >
                      {item?.from}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: fitSize(2) }}>
                    <Text
                      style={{
                        color: color.primary,
                        fontSize: fitSize(13),
                        fontWeight: "500",
                        width: fitSize(44),
                      }}
                    >
                      to
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{ fontSize: fitSize(13), color: color.dim, width: fitSize(200) }}
                    >
                      {item?.to}
                    </Text>
                  </View>
                </View>
                <SizeImage
                  source={Images.market.more_right}
                  style={{ width: fitSize(22), height: fitSize(22) }}
                />
              </View>
            </Touchable>
          )
        }}
      />
    </FlexView>
  )
}

const styles = StyleSheet.create({})
