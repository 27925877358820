const env = {
  prod: {
    BASE_URL: "https://marketplace-api.maascn.cn",
    appid: 1000012,
    OAUTH_URL: "https://oauth.maascn.cn",
    pushy: {
      ios: {
        appId: 25535,
        appKey: "_vFqbS7QaZ0S2gU1WCWGcTGy",
      },
      android: {
        appId: 25536,
        appKey: "IyqVT3PyKumz2U4NmdCB7qTY",
      },
    },
  },
  staging: {
    BASE_URL: "https://ppe-apijiji.tianhe32.cn",
    appid: 1000012,
    OAUTH_URL: "http://maas.test.tianhe32.cn",
    pushy: {
      ios: {
        appId: 25535,
        appKey: "_vFqbS7QaZ0S2gU1WCWGcTGy",
      },
      android: {
        appId: 25885,
        appKey: "oQEKvrTRb0lEo3dhDPssp66h",
      },
    },
  },
  test: {
    BASE_URL: "http://app-api-test.market-test.tianhe32.cn",
    // BASE_URL: "http://192.168.11.2:17400",
    appid: 1000012,
    OAUTH_URL: "http://maas-test.maas-test.tianhe32.cn",
    pushy: {
      ios: {
        appId: 25535,
        appKey: "_vFqbS7QaZ0S2gU1WCWGcTGy",
      },
      android: {
        appId: 25885,
        appKey: "oQEKvrTRb0lEo3dhDPssp66h",
      },
    },
  },
}
module.exports.env = env
