import React from "react"
import { View, StyleSheet, Platform, useWindowDimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { color } from "../../theme"

interface FlexViewProps {
  bgColor?: string
}

export const FlexView: React.FC<FlexViewProps> = ({ children, bgColor }) => {
  const windowHeight = useWindowDimensions().height
  const { top } = useSafeAreaInsets()
  return (
    <View
      style={[
        {
          backgroundColor: bgColor || color.background,
          flexGrow: 1,
          height: Platform.OS === "web" ? windowHeight : undefined,
          paddingTop: top,
        },
      ]}
    >
      {children}
    </View>
  )
}

const styles = StyleSheet.create({})
