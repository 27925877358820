import * as InAppPurchases from "expo-in-app-purchases"

import {
  Image,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  useWindowDimensions,
} from "react-native"
import { Images, color, fitSize, gStyle } from "../../theme"
import React, { useEffect, useRef, useState } from "react"
import { Screen, Text, Touchable } from "../../components"
import { hideLoad, showError, showInfo, showLoad } from "../../utils/showUtil"
import { loadString, saveString } from "../../utils/storage"

import Clipboard from "@react-native-community/clipboard"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { MyErrorCode } from "../../utils/errorCode"
import { PAY_RES_URL } from "../WebView/MassWebView"
import { PayModal } from "./components/PayModal"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { TrackPayError } from "../../utils/sendAnalysisData"
import { TradeOrderStatusEnum } from "../OrderCenter/define"
import { collectCardItemStoreSnapshot } from "../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { delay } from "../../utils/delay"
import { marketItem } from "../TabHome/Components/MarketList"
import { observer } from "mobx-react-lite"
import { regFenToYuan } from "../Transaction/TransactionScreen"
import { translate } from "../../i18n"
import { useApi } from "../../hooks/useApi"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useTokenApi } from "../../hooks/useTokenApi"
import { useUriInfo } from "../../hooks/useUriInfo"

interface ItemConfirmProps extends RootStackNavProps<"ShowOrder"> {}
type orderDetailInfoOld = {
  status?: number
  payExpireTime?: number
  payTime?: number
  hash?: string
  outTradeNo: string
  amount?: number
  expireTime?: number
  isSend?: boolean
  payWay?: number
  count?: number
  activityId?: string
  assetInfo?: collectCardItemStoreSnapshot
  sendTime?: number
  createTime?: number
}
interface orderDetailInfo {
  orderStatus: number
  tokenURI: string
  price: number
  tradeOrderId: string
  createdAt: number
  payTime?: string
  payWay?: number
  transactionHash?: string
  sellerUserAddress: string
  sellerUserPhonenumber: string
  expiredTime: number
  id: number
  goodsName: string
  sendTime: string
  marketOrderId?: string
  nftAsset?: {
    assetId: number
    nftAddress: string
    num: number
  }
}

export const PayWayDesc = {
  1: "汇付钱包",
  2: "杉德钱包",
  3: "数玩券",
}

function getDayfromHour(time: number) {
  if (time <= 24) {
    return time + "小时"
  }
  const days = ~~(time / 24)
  const rest = time % 24
  const DD = days + "天"
  const HH = rest ? rest + "小时" : ""
  return DD + HH
}
export const getRestTimeDays = (time: number) => {
  if (time <= 0) return "0秒"

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)
  const HH = (hours < 10 ? "0" + hours : hours) + "时"
  const MM = (minutes < 10 ? "0" + minutes : minutes) + "分"
  const SS = (seconds < 10 ? "0" + seconds : seconds) + "秒"

  const timeString = (!!hours ? HH : "") + (!!minutes ? MM : "") + (!!seconds ? SS : "")

  if (days >= 1) {
    return `${days}天` + timeString
  }

  return timeString
}
type paylist = { paywayList: { name: string; payWay: number; type: string; icon: string }[] }

export const ShowOrder: React.FC<ItemConfirmProps> = ({ navigation, route }) => {
  const [number, setNumber] = useState(1)
  const { bottom, top } = useSafeAreaInsets()
  const orderid = route.params.id
  const { accountStore, collectStore } = useStores()
  const [showPay, setShowPay] = useState(false)
  const [msgId, setMsgId] = useState("")
  const [isAsyncFnc, setIsAsyncFnc] = useState(false)
  const [isAuthorize, setIsAuthorize] = useState(false)
  const { doRequest, result, setResult } = useApi<orderDetailInfo>({
    api: { path: "/market/getTradeOrderDetail", params: { tradeOrderId: orderid }, way: "post" },
    // config: { condFunc: () => isShow },
  })
  const loadCount = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (Platform.OS === "web") {
        window.document.title = "订单详情"
      }
      loadCount.current++
      if (loadCount.current >= 2) doRequest()
      // if (route.params?.isPayback && accountStore.isOK) {
      //   checkOrder(route?.params?.id, 0)
      // }
    }, [route, accountStore]),
  )

  const [assetData] = useUriInfo(result?.result?.tokenURI, [result])

  const cancelItem = async (canBack: boolean) => {
    GToast.current.showLoading("取消中")
    const r = await accountStore.environment.api.normalRequest(
      "/market/cancleTradeOrder",
      {
        tradeOrderId: result?.result?.tradeOrderId,
      },
      "post",
    )
    if (r.kind === "ok") {
      GToast.current.showToast("取消成功", "TOAST", 2000)
      if (canBack) {
        navigation.goBack()
      }
      if (typeof route.params?.setChange === "function") {
        route.params?.setChange((c) => c + 1)
      }
    } else {
      if (r.kind === "error") {
        if (r.code === MyErrorCode.ThirdPartyServiceError) {
          GToast.current.showToast("第三方服务器错误", "CANCEL", 1500)
          return
        }
      }
      showError(r)

      // if (r.code === 1008) {
      //   GToast.current.showToast("原订单不存在", "CANCEL", 2000)
      // } else {
      //   showError(r)
      // }
    }
  }
  const showAlert = (canBack: boolean = false) => {
    GToast.current.showAlert(
      "是否要取消订单",
      () => {},
      () => {
        cancelItem(canBack)
      },
    )
  }

  const doCopy = (copy: any) => {
    Clipboard.setString(String(copy))
    GToast.current.showToast("复制成功", "TOAST", 2000)
  }
  const doPay = async (payWay = choose.payWay, type = choose.type) => {
    let url = null
    if (Platform.OS === "web") {
      url = new window.URL(window.location.href)
      url.searchParams.append("isPayback", "true")
    } else {
      url = new window.URL(PAY_RES_URL)
      url.searchParams.append("isPayback", "true")
    }
    console.log("sendURl", url.toString())

    GToast.current.showLoading("支付中")
    const payInfo = await accountStore.environment.api.normalRequest(
      "/user/pay/getPayInfo",
      {
        payWay,
        outTradeNo: result?.result?.tradeOrderId,
        redirectUrl: url.toString(),
      },
      "post",
    )
    GToast.current.hideToast()
    if (payInfo.kind === "ok") {
      //其他支付类型
      if (type === "LINKING") {
        //TODO: 以后可能需要用到
        if (Platform.OS === "web") {
          //web临时离开加上标记
          localStorage.setItem("tempLeave", "leave")
          window.location.href = payInfo.data.payInfo
        } else {
          navigation.navigate("MassWebView", {
            uri: payInfo.data.payInfo,
            title: "银行卡支付",
            ext: orderid,
          })
        }
      }
    } else {
      showError(payInfo)
    }
  }

  const checkOrder = async (tradeNo: string, retryTime: number = 1, isShowToast = true) => {
    GToast.current.showLoading("验证中")
    await delay(3000)
    let check = await accountStore.environment.api.normalRequest(
      "/user/pay/getPayResult/" + tradeNo,
    )
    if (check.kind === "ok") {
      if (check.data?.status == 2) {
        GToast.current.showToast("购买成功", "OK", 2000)
        doRequest()
        // console.log(check.data.nftInfo)
        // const nftInfo = await collectStore.addCollects(check.data.nftInfo)
        // if (nftInfo) {
        //   GToast.current.showAlert(
        //     "获得新物品，是否立即前往查看",
        //     () => {},
        //     () => {
        //       // navigation.replace("KnapsackScreen")
        //     },
        //   )
        // }
      } else if (check.data?.status == 1) {
        if (retryTime >= 5) {
          showInfo("发货中", "OK", 2000)
          return
        }
        await delay(1000)
        await checkOrder(tradeNo, retryTime + 1)
      } else if (check.data?.status == 3) {
        showInfo("补发中", "OK", 2000)
      } else {
        showInfo("未知错误status" + check.data?.status, "CANCEL", 2000)
      }
    } else {
      if (isShowToast) showError(check)
    }
  }

  const renderPayModal = () => {
    return (
      <PayModal
        nftAsset={item?.nftAsset}
        closeModal={() => setShowPay(false)}
        isShow={showPay}
        onConfirm={(payWay: number, type: string) => {
          if (payWay === 79999) {
            const availableNfts = Array.from(collectStore.collects.values()).filter(
              (e) =>
                e.item?.asset?.assetId === item.nftAsset?.assetId &&
                e.item?.asset?.contractAddress === item.nftAsset?.nftAddress,
            )
            if (availableNfts?.length >= item?.nftAsset?.num) {
              setShowPay(false)
              const nfts = availableNfts?.slice(0, item?.nftAsset?.num)?.map((e) => ({
                nftAddress: e.item?.asset?.contractAddress,
                nftId: String(e.NftId),
              }))
              approveBuy(nfts)
              // tradeUseTicket(
              //  nfts
              // )
            } else {
              setShowPay(false)
              showError("券不足")
            }
          } else {
            setShowPay(false)
            mockPayNFT(payWay, type)
          }
          // setChoose({ payWay, type })
        }}
        id={result?.result?.tradeOrderId}
        price={result?.result?.price}
      />
    )
  }
  const approveBuy = async (data: { nftId: string; nftAddress: string }[]) => {
    const res = await accountStore.environment.api.normalRequest(
      "/card/isApproveOperateCard",
      {
        // contractName: item?.contractName,1
        // cardContractAddress: item?.contractAddress,
        contractName: "GShouMarket",
        cardContractAddress: item?.nftAsset?.nftAddress,
      },
      "post",
    )
    GToast.current.showLoading("寄售上链中")
    if (res.kind === "ok") {
      if (res.data.isApprove) {
        // setIsAuthorize(true)
        tradeUseTicket(data)
      } else {
        // setIsAuthorize(false)
        const content = await collectStore.environment.api.userPrepareApprove(
          "GShouMarket",
          item?.nftAsset?.nftAddress,
        )
        const msgRes = await collectStore.environment.api.doMessageIdSearch(content.data.msgId)
        if (msgRes.kind === "ok") {
          tradeUseTicket(data, true)
        } else {
          showError(msgRes)
        }

        // setMsgId(content.data.msgId)
        // setIsAsyncFnc(true)
      }
    } else {
      hideLoad()
      throw res?.msg
    }
  }

  const item = result?.result
  const [currentTime, setCurrentTime] = useState(() => new Date().getTime())
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime()
      setCurrentTime(now)

      if (
        item?.orderStatus === TradeOrderStatusEnum.Paying ||
        item?.orderStatus === TradeOrderStatusEnum.Payed ||
        item?.orderStatus === TradeOrderStatusEnum.Sending ||
        item?.orderStatus === TradeOrderStatusEnum.SendFaild
      ) {
        doRequest()
      } else if (
        item?.orderStatus === TradeOrderStatusEnum.Unpay &&
        Math.trunc(now / 1000) >= item?.expiredTime
      ) {
        doRequest()
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [item])
  // const { result: PayResult } = useApi<paylist>({
  //   api: {
  //     path: "/market/getAvailablePaylist",
  //     params: { platform: Platform.OS },
  //     way: "post",
  //   },
  // })
  const [choose, setChoose] = useState<{ payWay: number; type: string } | null>(null)

  // useEffect(() => {
  //   const list = PayResult?.result?.paywayList
  //   if (list) {
  //     setChoose({ payWay: list?.[0]?.payWay, type: list?.[0]?.type })
  //   }
  // }, [PayResult.result])
  const tradeUseTicket = async (
    data: { nftId: string; nftAddress: string }[],
    needWait?: boolean,
  ) => {
    let signature = ""
    if (needWait) {
      const alertRes = await GToast.current.showAlert(
        "需要签名",
        () => {},
        () => {},
        undefined,
        true,
      )
      // console.log(alertRes)
    }

    try {
      signature = await collectStore.environment.api.signData({
        contractName: "GShouMarket",
        domain: "GShouMarket",
        version: "0.0.1",
        operationName: "GShouMarketPutOn",
        data: {
          nftAddresses: data?.map((e) => e.nftAddress),
          nftIds: data?.map((e) => String(e.nftId)),
          orderId: item?.marketOrderId,
        },
      })
    } catch (err) {
      showError(err)
      return
    }
    if (!signature) {
      showError("签名错误")
      return
    }
    showLoad()
    // debugger
    const tradeRes = await collectStore.environment.api.normalRequest(
      "/market/tradeByExchange",
      {
        tradeOrderId: orderid,
        signature: signature,
        nftInfos: data?.map((e) => ({
          nftId: String(e.nftId),
          nftAddress: e.nftAddress,
        })),
      },
      "post",
    )
    if (tradeRes.kind !== "ok") {
      showError(tradeRes)
      return
    }
    const msgRes = await collectStore.environment.api.doMessageIdSearch(tradeRes.data.msgId)
    if (msgRes.kind === "ok") {
      showInfo("交易成功")
      doRequest()
    } else {
      showError(msgRes)
    }
  }

  const mockPayNFT = async (payWay: number, type: string) => {
    let url = null
    if (Platform.OS === "web") {
      url = new window.URL(window.location.href)
      url.searchParams.append("isPayback", "true")
    } else {
      url = new window.URL(PAY_RES_URL)
      url.searchParams.append("isPayback", "true")
    }
    console.log("sendURl", url.toString())

    const content = {
      devType: Platform.OS === "web" ? "1" : "2",
      ipAddr: "127.0.0.1",
      MAC: "F0E1D2C3B4A5",
    }
    const res = await accountStore.environment.api.normalRequest(
      "/market/createThirdPartyPayOrder",
      {
        tradeOrderId: item.tradeOrderId,
        payWay: payWay,
        devInfoJson: JSON.stringify(content),
        redirectUrl: Platform.OS === "web" ? url.toString() : undefined,
      },
      "post",
    )
    GToast.current.showLoading("购买上链中")
    if (res.kind === "ok") {
      // navigation.navigate("HomeScreen")
      // GToast.current.showToast("购买成功", "OK", 1500)
      if (Platform.OS === "web") {
        //web临时离开加上标记
        localStorage.setItem("tempLeave", "leave")
        window.location.href = res.data.payUrl
      } else {
        navigation.navigate("MassWebView", {
          uri: res.data.payUrl,
          title: "银行卡支付",
          ext: orderid,
        })
      }
    } else {
      if (res.kind === "error") {
        if (res.code === MyErrorCode.ThirdPartyServiceError) {
          GToast.current.showToast("第三方服务器错误", "CANCEL", 1500)
          return
        }
      }
      GToast.current.showToast(res.msg || "购买失败", "CANCEL", 1500)
    }
  }

  const getAsyncResult = async (value: string) => {
    const res = await accountStore.environment.api.normalRequest(
      "/user/getAsyncResult",
      {
        msgId: value,
      },
      "post",
    )
    if (res.kind === "ok") {
      if (res.data) {
        setMsgId("")
        setIsAsyncFnc(false)
      }
      if (JSON.parse(res.data).status === 2) {
        hideLoad()
        //@ts-ignore
        navigation.pop(2)

        GToast.current.showToast("购买成功", "OK", 1500)
      } else {
        GToast.current.showToast("购买失败", "CANCEL", 1500)
      }
    } else {
      hideLoad()
      throw res?.msg
    }
  }

  useEffect(() => {
    let time = null
    if (isAsyncFnc) {
      time = setInterval(() => {
        getAsyncResult(msgId)
      }, 1000)
    }
    return () => {
      clearInterval(time)
    }
  }, [isAsyncFnc])

  // useEffect(() => {
  //   if (getRestTimeDays(dayjs(Number(item?.expiredTime) * 1000).diff(currentTime)) === "0秒") {
  //     GToast.current.showToast("购买时间过期", "CANCEL", 1500)
  //     cancelItem(true)
  //   }
  // }, [getRestTimeDays(dayjs(Number(item?.expiredTime) * 1000).diff(currentTime))])
  useEffect(() => {
    if (
      item?.orderStatus === TradeOrderStatusEnum.SendSuccess &&
      item?.payWay === 3 &&
      item?.price
    ) {
      setResult((c) => ({ ...c, result: { ...c.result, price: 0 } }))
    }
  }, [item])

  return (
    <FlexView>
      <Sheader title={"订单详情"} rightImage={""} />
      {result?.result && (
        <Screen
          preset="scroll"
          statusBar={"dark-content"}
          unsafe={true}
          backgroundColor={"#F5F5F5"}
        >
          <View
            style={{
              flexGrow: 1,
              backgroundColor: "#F5F5F5",
              paddingTop: fitSize(5),
            }}
          >
            <View
              style={{
                width: "100%",
                minHeight: fitSize(85),
                backgroundColor: "#fff",
                padding: fitSize(16),
              }}
            >
              <Text
                style={{
                  color: "#333333",
                  fontSize: fitSize(17),
                  fontWeight: "600",
                }}
              >
                {result?.result?.orderStatus === TradeOrderStatusEnum.SendSuccess
                  ? "订单已完成"
                  : result?.result?.orderStatus === TradeOrderStatusEnum.Unpay ||
                    result?.result?.orderStatus === TradeOrderStatusEnum.Paying
                  ? "订单待付款"
                  : result?.result?.orderStatus === TradeOrderStatusEnum.Cancel
                  ? "订单已取消"
                  : "藏品发送中"}
              </Text>
              <Text
                style={{
                  width: fitSize(266),
                  marginTop: fitSize(5),
                  fontSize: fitSize(12),
                  color: "#888888",
                }}
              >
                {result?.result?.orderStatus === TradeOrderStatusEnum.Cancel
                  ? "订单已取消或过期，期待您再次交易"
                  : result?.result?.orderStatus === TradeOrderStatusEnum.Unpay ||
                    result?.result?.orderStatus === TradeOrderStatusEnum.Paying
                  ? getRestTimeDays(dayjs(item?.expiredTime * 1000).diff(currentTime)) + "后过期"
                  : "感谢您购买商品，期待您再次选择"}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                height: fitSize(114),
                backgroundColor: "#fff",
                marginTop: fitSize(1),
                paddingVertical: fitSize(12),
                paddingHorizontal: fitSize(15),
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <SizeImage
                  style={{
                    width: fitSize(90),
                    height: fitSize(90),
                    borderRadius: fitSize(10),
                    overflow: "hidden",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  resizeMode={"cover"}
                  source={
                    assetData?.MiniBackgroundImage
                      ? { uri: assetData?.MiniBackgroundImage }
                      : Images.components.mini_default_background
                  }
                >
                  <SizeImage
                    resizeMode={"contain"}
                    source={{ uri: assetData?.Image }}
                    style={{
                      width: fitSize(90),
                      height: fitSize(90),
                      borderRadius: fitSize(10),
                    }}
                  />
                </SizeImage>

                <View style={{ flex: 1, paddingLeft: fitSize(10) }}>
                  <Text style={{ fontSize: fitSize(16), color: "#333333", fontWeight: "600" }}>
                    {assetData?.Name}
                  </Text>
                  <Text
                    style={{
                      fontSize: fitSize(12),
                      color: "#888888",
                      marginTop: fitSize(4),
                    }}
                  >
                    {assetData?.Series}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: fitSize(11),
                      color: "#888888",
                      marginTop: fitSize(4),
                    }}
                  >
                    持有者 {assetData?.assetId}
                  </Text> */}

                  <View style={{ flexDirection: "row", alignItems: "center", marginTop: "auto" }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      {!!item?.price && (
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <Text
                            style={{
                              color: "#FF2E11",
                              fontSize: fitSize(16),
                              fontWeight: "600",
                            }}
                          >
                            ￥:
                          </Text>
                          <Text
                            preset={"numner"}
                            style={{
                              fontSize: fitSize(16),
                              color: "#FF2E11",
                              fontWeight: "600",
                              marginLeft: fitSize(5),
                            }}
                          >
                            {regFenToYuan(item?.price)}
                          </Text>
                        </View>
                      )}
                      {!!item?.nftAsset?.num && <PriceItemForSearch item={item} />}
                    </View>
                    <Text
                      style={{
                        fontSize: fitSize(16),
                        color: "#333333",
                        marginLeft: fitSize(4),
                        flex: 1,
                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      x1
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                height: fitSize(54),
                width: "100%",
                backgroundColor: "#fff",
                marginTop: fitSize(1),
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: fitSize(16),
              }}
            >
              <Text
                style={{
                  fontSize: fitSize(15),
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                {item?.orderStatus === TradeOrderStatusEnum.SendSuccess ||
                item?.orderStatus === TradeOrderStatusEnum.Cancel
                  ? "总计"
                  : item?.orderStatus === TradeOrderStatusEnum.Unpay ||
                    item?.orderStatus === TradeOrderStatusEnum.Paying
                  ? "待支付"
                  : "已支付"}
              </Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {!!item?.price && (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ color: "#000", fontSize: fitSize(15), fontWeight: "600" }}>
                      ￥
                    </Text>
                    <Text
                      style={{
                        fontSize: fitSize(15),
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      {regFenToYuan(item?.price)}
                    </Text>
                  </View>
                )}
                {!!item?.nftAsset?.num && (
                  <PriceItemForSearch
                    style={{ fontSize: fitSize(15), color: "#000", fontWeight: "600" }}
                    item={item}
                  />
                )}
              </View>
            </View>

            <View
              style={{
                flex: 1,
                backgroundColor: "#fff",
                paddingTop: fitSize(16),
                paddingHorizontal: fitSize(16),
                paddingBottom: fitSize(16),
                marginTop: fitSize(1),
              }}
            >
              <Text
                style={{
                  fontSize: fitSize(15),
                  color: "#333333",
                  fontWeight: "600",
                  marginBottom: fitSize(7.5),
                }}
              >
                订单信息
              </Text>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>订单编号：</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text numberOfLines={1} style={styles.contentRight}>
                    {item?.tradeOrderId}
                  </Text>
                  {Platform.OS !== "web" ? (
                    <Touchable onPress={() => doCopy(item?.tradeOrderId)}>
                      <Image source={Images.components.copy} style={styles.copyImg} />
                    </Touchable>
                  ) : (
                    <CopyToClipboard
                      text={String(item?.tradeOrderId)}
                      onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                    >
                      <Image source={Images.components.copy} style={styles.copyImg} />
                    </CopyToClipboard>
                  )}
                </View>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>创建时间：</Text>
                <Text style={styles.contentRight}>
                  {item?.createdAt && dayjs(item?.createdAt * 1000).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>卖家昵称：</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text numberOfLines={1} style={styles.contentRight}>
                    {item?.sellerUserPhonenumber || "——"}
                  </Text>
                  {item?.sellerUserPhonenumber &&
                    (Platform.OS !== "web" ? (
                      <Touchable onPress={() => doCopy(item?.sellerUserPhonenumber)}>
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </Touchable>
                    ) : (
                      <CopyToClipboard
                        text={String(item?.sellerUserPhonenumber)}
                        onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                      >
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </CopyToClipboard>
                    ))}
                </View>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>卖家地址：</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text ellipsizeMode="middle" numberOfLines={1} style={styles.contentRight}>
                    {item?.sellerUserAddress || "——"}
                  </Text>
                  {item?.sellerUserAddress &&
                    (Platform.OS !== "web" ? (
                      <Touchable onPress={() => doCopy(item?.sellerUserPhonenumber)}>
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </Touchable>
                    ) : (
                      <CopyToClipboard
                        text={String(item?.sellerUserAddress)}
                        onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                      >
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </CopyToClipboard>
                    ))}
                </View>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>获取时间：</Text>
                <Text numberOfLines={1} style={styles.contentRight}>
                  {item?.sendTime ? dayjs(item?.sendTime).format("YYYY/MM/DD HH:mm:ss") : "——"}
                </Text>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>付款方式：</Text>
                <Text style={styles.contentRight}>{PayWayDesc[item?.payWay] ?? "-"}</Text>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>区块链交易ID：</Text>
                <View style={styles.conterRightCopy}>
                  <Text numberOfLines={1} style={styles.contentRight}>
                    {item?.transactionHash || "——"}
                  </Text>
                  {item?.transactionHash &&
                    (Platform.OS !== "web" ? (
                      <Touchable onPress={() => doCopy(item?.transactionHash)}>
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </Touchable>
                    ) : (
                      <CopyToClipboard
                        text={String(item?.transactionHash)}
                        onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                      >
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </CopyToClipboard>
                    ))}
                </View>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>状态：</Text>
                <Text style={styles.contentRight}>
                  {item?.orderStatus === TradeOrderStatusEnum.Unpay ||
                  item?.orderStatus === TradeOrderStatusEnum.Paying
                    ? `待付款`
                    : item?.orderStatus === TradeOrderStatusEnum.Cancel
                    ? "已取消"
                    : "已完成"}
                </Text>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>付款时间：</Text>
                <Text style={styles.contentRight}>
                  {item?.payTime ? dayjs(item?.payTime).format("YYYY-MM-DD HH:mm:ss") : "——"}
                </Text>
              </View>
            </View>
            {renderPayModal()}
            {/* {PayResult?.result?.paywayList?.map((t, i) => {
              return (
                <Touchable
                  onPress={() => {
                    setChoose({ payWay: t.payWay, type: t.type })
                  }}
                  key={i}
                  style={[styles.warpView, { width: "100%", backgroundColor: "#fff" }]}
                >
                  <View style={gStyle.horizontalView}>
                    {t?.icon && <SizeImage source={{ uri: t?.icon }} style={styles.commPic} />}
                    <Text style={styles.title}>{t.name}</Text>
                  </View>
                  <SizeImage
                    source={
                      choose?.payWay == t.payWay
                        ? Images.components.choose
                        : Images.components.not_choose
                    }
                    style={styles.chooseImg}
                  />
                </Touchable>
              )
            })} */}
          </View>
        </Screen>
      )}
      {item?.orderStatus &&
        (item?.orderStatus === TradeOrderStatusEnum.Unpay ||
          item?.orderStatus === TradeOrderStatusEnum.Paying) && (
          <View
            style={{
              width: "100%",
              height: fitSize(56),
              backgroundColor: "#fff",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingHorizontal: fitSize(16),
              marginBottom: bottom,
              marginTop: fitSize(7),
            }}
          >
            {item?.orderStatus === TradeOrderStatusEnum.Unpay ||
            item?.orderStatus === TradeOrderStatusEnum.Paying ? (
              <>
                {/* <Touchable
                  onPress={() => {
                    showAlert(true)
                  }}
                  style={{
                    width: fitSize(166),
                    height: fitSize(42),
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: fitSize(22),
                    borderColor: "#00AFB5",
                    borderWidth: fitSize(1),
                  }}
                >
                  <Text style={{ color: "#00AFB5", fontWeight: "500", fontSize: fitSize(16) }}>
                    取消订单
                  </Text>
                </Touchable> */}
                <Touchable
                  onPress={() => {
                    setShowPay(true)
                    // mockPayNFT()
                    // mockPayNFT()
                  }}
                  style={{
                    width: fitSize(166),
                    height: fitSize(42),
                    backgroundColor: "#00AFB5",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: fitSize(22),
                    marginLeft: fitSize(10),
                  }}
                >
                  <Text style={{ color: "#fff", fontWeight: "500", fontSize: fitSize(16) }}>
                    确认支付
                  </Text>
                </Touchable>
              </>
            ) : null}
          </View>
        )}
    </FlexView>
  )
}
const PriceItemForSearch = observer(
  ({ item, style }: { item: marketItem; style?: StyleProp<TextStyle> }) => {
    const { collectStore } = useStores()
    const name = collectStore.nftForExchange.get(
      item?.nftAsset?.nftAddress + item?.nftAsset?.assetId,
    )?.name
    return (
      <Text
        style={[
          {
            fontSize: fitSize(16),
            color: "#FF2E11",
            fontWeight: "600",
            marginLeft: fitSize(5),
          },
          style,
        ]}
      >
        {item?.price ? "|" : ""}
        {`${name}x${item?.nftAsset?.num}`}
      </Text>
    )
  },
)

const styles = StyleSheet.create({
  copyImg: {
    width: fitSize(15),
    height: fitSize(15),
    marginLeft: fitSize(8),
    // tintColor: "#777777",
  },
  itemWrap: {
    width: fitSize(370),
    height: fitSize(270),
    borderRadius: fitSize(10),
    backgroundColor: "#fff",
    alignSelf: "center",
    marginTop: fitSize(10),
    padding: fitSize(20),
    paddingBottom: 0,
  },
  horizonalTop: {
    height: fitSize(101),
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  itemImg: {
    width: fitSize(96),
    height: fitSize(101),
    resizeMode: "contain",
  },
  rightWrap: {
    flexGrow: 1,
    marginLeft: fitSize(20),
  },
  innHorView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemName: {
    width: fitSize(154),
    height: fitSize(51),
    textAlign: "left",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
  timesText: {
    color: "#4F4F4F",
    fontSize: fitSize(18),
  },
  unitText: {
    color: "#BDBDBD",
    fontSize: fitSize(14),
  },
  dateWrap: {
    width: fitSize(80),
    height: fitSize(30),
    borderRadius: fitSize(2),
    backgroundColor: "#EDEDED",
    justifyContent: "center",
    alignItems: "center",
  },
  dateText: {
    color: "#4F4F4F",
    fontSize: fitSize(16),
  },
  storeWrap: {
    alignSelf: "flex-end",
    marginLeft: fitSize(15),
  },
  storeText: {
    color: "#BDBDBD",
    fontSize: fitSize(12),
  },
  midWarp: {
    height: fitSize(70),
    marginTop: fitSize(30),
    borderBottomColor: "#EDEDED",
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
  },
  buyText: {
    fontSize: fitSize(16),
  },
  buyWrap: {
    flexGrow: 1,
    paddingTop: fitSize(4),
    paddingLeft: fitSize(31),
  },
  noEnough: {
    color: "#FF8788",
    fontSize: fitSize(12),
    marginTop: fitSize(5),
  },
  botWrap: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  botLeftText: {
    color: "#929292",
    fontSize: fitSize(14),
  },
  price: {
    fontWeight: "bold",
    fontSize: fitSize(20),
  },
  bottomWrap: {
    width: fitSize(414),
    position: "absolute",
    backgroundColor: "#fff",
    bottom: 0,
    borderTopColor: "#EDEDED",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  innerBottomWrap: {
    flexDirection: "row",
    alignItems: "center",
    height: fitSize(60),
    paddingHorizontal: fitSize(16),
    justifyContent: "flex-end",
  },
  totalPrice: {
    color: "#FF8788",
    fontSize: fitSize(18),
    fontWeight: "bold",
    flex: 1,
  },
  payhint: {
    fontSize: fitSize(16),
  },
  totalUnit: {
    color: "#EB5757",
    fontSize: fitSize(14),
  },
  warpView: {
    width: fitSize(414),
    height: fitSize(54),
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#EDEDED",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(22),
  },
  choosePayText: {
    color: "#929292",
    fontSize: fitSize(16),
  },
  commPic: {
    width: fitSize(24),
    height: fitSize(24),
    resizeMode: "contain",
    marginRight: fitSize(13),
  },
  title: {
    fontSize: fitSize(16),
    fontWeight: "bold",
  },
  chooseImg: {
    width: fitSize(20),
    height: fitSize(20),
    resizeMode: "contain",
  },
  contentView: {
    flexShrink: 1,
    // height: fitSize(35),
    marginTop: fitSize(10),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleLeft: {
    fontSize: fitSize(12),
    color: "#333333",
  },
  contentRight: {
    fontSize: fitSize(12),
    color: "#888888",
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
})
