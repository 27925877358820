import { BottomTabParamList, RootStackParamList } from "../type/RouteType"
import { NavigationContainerRef, RouteProp, StackActions, useRoute } from "@react-navigation/native"

import { StackNavigationProp } from "@react-navigation/stack"
import { createRef } from "react"
import { useNavigation } from "@react-navigation/core"

export function useTypeNavigation<T extends keyof RootStackParamList>() {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList, T>>()
  return navigation
}

export function useTypeRoute<T extends keyof RootStackParamList>() {
  const route = useRoute<RouteProp<RootStackParamList, T>>()
  return route
}
