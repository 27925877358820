/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */

import "./global"
import "./i18n"
import "./utils/ignore-warnings"

import * as storage from "./utils/storage"

import { Platform, View } from "react-native"
import React, { useEffect, useRef, useState } from "react"
import {
  RootNavigator,
  canExit,
  setRootNavigation,
  useBackButtonHandler,
  useNavigationPersistence,
} from "./navigators"
import { RootStore, RootStoreProvider, setupRootStore, useStores } from "./models"
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context"
import { enableFreeze, enableScreens } from "react-native-screens"
import { fitSize, maxWebWidth } from "./theme"

import { AppInfo } from "./config/AppInfo"
import { ErrorBoundary } from "./screens/error/error-boundary"
import JPush from "jpush-react-native"
import { NavigationContainerRef } from "@react-navigation/native"
import { initFonts } from "./theme/fonts" // expo

enableScreens()
// enableFreeze(true)
export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"
const linking = {
  prefixes: AppInfo.perfix,
  config: {
    screens: {
      mainStack: {
        initialRouteName: "Root",
        screens: {
          // path: "activity/:id",
          // ActivityNew: "activity/:id",

          LoginFromMessage: "LoginFromMessage",
          ShowOrder: "ShowOrder",
          TransactionScreen: "TransactionScreen",
          Mywallet: "Mywallet",
        },
      },
    },
  },
}
const WebWrapper: React.FC = ({ children }) => {
  return (
    <View
      style={{ height: "100%", width: "100%", maxWidth: maxWebWidth, alignSelf: "center", flex: 1 }}
    >
      {children}
    </View>
  )
}
const Wrapper = Platform.OS === "web" ? WebWrapper : React.Fragment
/**
 * This is the root component of our app.
 */
function App() {
  const navigationRef = useRef<NavigationContainerRef<{}>>()
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  setRootNavigation(navigationRef)
  useBackButtonHandler(navigationRef, canExit)
  const { initialNavigationState, onNavigationStateChange } = useNavigationPersistence(
    storage,
    NAVIGATION_PERSISTENCE_KEY,
  )

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      await initFonts() // expo
      setupRootStore().then(setRootStore)
    })()
    if (Platform.OS !== "web") setPush()
  }, [])
  const setPush = () => {
    JPush.init({
      appKey: AppInfo.jpush,
      production: !__DEV__,
      titchannelle: "developer-default",
    })
    JPush.setBadge({ appBadge: 0, badge: 0 })
    JPush.resumePush()
  }

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color. You can replace
  // with your own loading component if you wish.
  if (!rootStore) return null

  // otherwise, we're ready to render the app
  return (
    <RootStoreProvider value={rootStore}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <Wrapper>
          <ErrorBoundary catchErrors="always">
            <RootNavigator
              initialState={initialNavigationState}
              onStateChange={onNavigationStateChange}
              linking={linking}
              ref={navigationRef}
            />
          </ErrorBoundary>
        </Wrapper>
      </SafeAreaProvider>
    </RootStoreProvider>
  )
}

// export const Myapp = SlienceUpdate(App)
export const Myapp = App
