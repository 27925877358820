import { ImageSourcePropType, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import React, { useEffect, useRef, useState } from "react"
import { HookFlatList } from "../../../components"
import { useStores } from "../../../models"
import { useAutoList } from "../../../hooks/useAutoList"
import { fitSize } from "../../../theme"
import { ApplyAssetInfoCard } from "./ApplyAssetInfoCard"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { itemType } from "../AssetDetails"

type Props = {
  type: string
}

const PL = 9
export const ApplyAssetContent: React.FC<Props> = ({ type }) => {
  const navigation = useTypeNavigation()
  const { accountStore } = useStores()
  const [data, setData] = useState([])
  const [nextData, setNextData] = useState([])

  const fetchUserAssetBySeriesId = async (page: number, pageLimit = PL) => {
    const res = await accountStore.environment.api.normalRequest(
      `/card/getNftListByPage/${type}/${page}/${PL}`,
    )
    if (res.kind === "ok") {
      setNextData(res?.data?.list)
      return res?.data?.list
    } else {
      return []
    }
  }

  const onAssetDetails = (id: string, address: string, tokenURL: string) => {
    navigation.push("AssetDetails", { address: address, id: id })
  }

  const [assetList] = useAutoList(
    {
      fetchData: fetchUserAssetBySeriesId,
      pageLimit: PL,
    },
    [],
  )

  useEffect(() => {
    if (nextData.length > 0) {
      nextData.forEach(async (element: { nftId: string; tokenURI: string }) => {
        const content = await fetch(element.tokenURI).then((r) => r.json())
        setData((data) => [...data, content.data])
      })
    } else {
      setNextData([])
    }
  }, [assetList.data])

  return (
    <HookFlatList
      {...assetList}
      data={data}
      notShowEmpty={true}
      contentContainerStyle={styles.contentContainerStyle}
      numColumns={3}
      horizontal={false}
      showsVerticalScrollIndicator={false}
      renderItem={({ item }: ListRenderItemInfo<itemType>) => {
        return (
          <ApplyAssetInfoCard
            assetImage={{ uri: item?.Image }}
            assetName={item.contractAddress}
            assetId={item.NftId}
            // tokenURI={item.}
            onNavigation={onAssetDetails}
          />
        )
      }}
    />
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: fitSize(20),
  },
  emptyStype: {
    position: "relative",
    bottom: fitSize(150),
    top: undefined,
    flex: undefined,
  },
})
