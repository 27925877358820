export enum MyErrorCode {
  Success,
  LoginErrorStart = 1000,
  RecordsNotFound,
  InvalidVerifyCode,
  InvalidParameter,
  InvalidAuthorizationCode,
  RefreshTokenInvalid,
  RefreshTokenExpire,
  RefreshTokenTooEarly,
  ThirdPartyServiceError = 1008,
  ThirdPartyServiceUnknownError = 1009,
  UserNotRealName = 1100,
  UserNotAdapayWallet = 1101,
  UserWalletException = 1102,
  LoginErrorEnd = 1999,
  KeyErrorStart = 2000,
  KeyErrorNotKeygen,
  KeyErrorAlreadyKeygen,
  KeyErrorAlreadyBackup,
  KeyErrorInvalidEncryptKey,
  KeyErrorMasterKeyNotFound,
  KeyErrorNotBackup,
  KeyErrorEnd = 2999,
  OAuthErrorStart = 3000,
  OAuthErrorCodeNotGenerate,
  OAuthErrorCodeNotFound,
  OAuthErrorCodeNotMatch,
  OAuthErrorRefreshTokenAppNotMatch,
  OAuthErrorRefreshTokenInvalid,
  OAuthErrorRefreshTokenExpired,
  OAuthErrorRefreshTokenTooEarly,
  OAuthErrorEnd = 3999,
  DIDBackendErrorStart = 4000,
  ProjectNotFind,
  ProjectLimit,
  TokenTimeout,
  AuthSigntureFail,
  NoPublicKeyFile,
  NoContract,
  ContractIsPublic,
  MinterDuplicated,
  MinterAddressIsNotEthereumAddress,
  AddressAlreadyExist,
  AddressInvalidate,
  NoWallet,
  WalletTokenFetchTooOften,
  WalletTokenFetchFailed,
  WalletGetTokenState,
  JiJiDeployAssetDataError,
  ContractAddressError,
  InvalidateParams,
  HaveCommitID,
  NoUser,
  DIDBackendErrorEnd = 4999,
  ChainErrorStart = 5000,
  ChainErrorUserNoAddress,
  ChainErrorUserNotOwn = 5002,
  ChainErrorMethodFailed = 5003,
  ChainErrorMethodWriteNotFound = 5004,
  ChainErrorNFTLocked = 5005,
  ChainErrorEnd = 5999,
  MarketOrderInfoExpired = 6000,
  MarketOrderStatusError = 6001,
  MarketOrderUnsellError = 6002,
  MarketOrderBuyerIsSellerError = 6003,
  MarketCreateAdapayOrderError = 6004,
  MarketCloseAdapayOrderError = 6005,
  MarketAdapayCreateWalletError = 6006,
  MarketAdapayWalletManagementError = 6007,
  MarketAdapayCreateWalletStatusError = 6008,
  MarketAdapayQueryOrderStatusError = 6009,
}