import { Images, color, fitSize } from "../../theme"
import { Linking, ScrollView, StyleSheet, View } from "react-native"
import { Text, Touchable } from "../../components"

import { FlexView } from "../../components/screen/FlexView"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import React from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { doCopy } from "../../utils/doCopy"
import { nftHistoryInfo } from "../Transaction/NftHistory"
import { regFenToYuan } from "../Transaction/TransactionScreen"
import { transInfo } from "../Transaction/TradeHistory"
import { useAsync } from "../../hooks/useAsync"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"

interface CardDetailProps extends RootStackNavProps<"CardDetail"> {}
interface getIssueAndMint {
  totalIssueCount: number
  currentCirculation: number
}
export const CardDetail: React.FC<CardDetailProps> = ({ route, navigation }) => {
  const item = route.params?.nftItem
  const tokenUri = route?.params?.tokenURI
  const { accountStore } = useStores()
  const { top } = useSafeAreaInsets()
  //@ts-ignore
  const { doRequest, result } = useAsync<Awaited<ReturnType<typeof getInfos>>>({
    func: () => getInfos(),
  })
  const info = result?.result
  const getInfos = async () => {
    const res = await Promise.all([getIssueAndMint(), getNftTradeHistory(), getNftHistoryByPage()])
    return { ...res[0], nftHistory: res[2], nftTradeHistory: res[1] }
  }
  const getIssueAndMint = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/card/getNftIssueAndMintCount",
      {
        seriesId: item?.SeriesId,
        tokenURI: tokenUri,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res.data as getIssueAndMint
    } else {
      throw res.msg
    }
  }
  const getNftTradeHistory = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getNftTradeHistory",
      {
        page: 1,
        pageSize: 3,
        nftId: Number(item?.NftId),
        contractAddress: item?.contractAddress,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res.data?.data as transInfo[]
    } else {
      throw res.msg
    }
  }
  const getNftHistoryByPage = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/sapi/card/getNftHistoryByPage",
      {
        page: 1,
        pageSize: 3,
        nftId: Number(item?.NftId),
        contractAddress: item?.contractAddress,
      },
      "post",
    )
    if (res.kind === "ok") {
      return { explorerUrl: res.data?.blockExplorerUrl, list: res.data?.list as nftHistoryInfo[] }
    } else {
      throw res.msg
    }
  }

  return (
    <FlexView bgColor={color.secBackground}>
      <Sheader
        notShowLeft={false}
        title={""}
        leftImage={"white_back"}
        rightImage={""}
        style={{ backgroundColor: "transparent", position: "absolute", top: top }}
      />
      <LoadingComp result={result} doRequest={doRequest}>
        <ScrollView
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: fitSize(50) }}
        >
          <SizeImage
            resizeMode={"contain"}
            source={{ uri: item?.DefaultFormat === "gif" ? item?.GifImage : item?.Image }}
            style={styles.img}
          />
          <View
            style={{
              height: fitSize(38),
              borderBottomColor: "#F2F2F2",
              borderBottomWidth: StyleSheet.hairlineWidth,
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SizeImage
              source={Images.market.series}
              style={{
                width: fitSize(22),
                height: fitSize(22),
                marginLeft: fitSize(10),
                marginRight: fitSize(5),
              }}
            />
            <Text style={{ fontSize: fitSize(13), fontWeight: "600", color: color.primary }}>
              {item?.Series}
            </Text>
          </View>
          <View
            style={{
              paddingHorizontal: fitSize(15),
              width: "100%",
              shadowColor: "#000",
              shadowOffset: { width: fitSize(0), height: fitSize(2) },
              shadowRadius: fitSize(15),
              shadowOpacity: 0.1,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center", marginTop: fitSize(10) }}>
              <Text style={{ color: color.text, fontWeight: "600", fontSize: fitSize(20) }}>
                {item?.Name}
              </Text>
              <View style={{ top: -fitSize(3) }}>
                <Text
                  style={{
                    color: color.text,
                    fontWeight: "600",
                    fontSize: fitSize(16),
                    marginLeft: fitSize(4),
                  }}
                >
                  {item?.CardShowId}
                </Text>
              </View>
            </View>
            <Text style={{ fontSize: fitSize(11), color: color.primary }}>
              持有者{` `}
              {accountStore?.user?.account}
            </Text>
            <View style={{ flexDirection: "row", marginTop: fitSize(10) }}>
              <View style={styles.rowCenter}>
                <Text style={styles.leftRow}>发行</Text>
                <Text style={styles.rightRow}>
                  {info?.totalIssueCount}
                  {` `}份
                </Text>
              </View>
              <View style={[styles.rowCenter, { marginLeft: fitSize(5) }]}>
                <Text style={styles.leftRow}>流通</Text>
                <Text style={styles.rightRow}>
                  {info?.currentCirculation}
                  {` `}份
                </Text>
              </View>
            </View>
            <Touchable
              onPress={() => navigation.push("TransactionInfo", { nftItem: item })}
              style={{
                width: fitSize(345),
                height: fitSize(44),
                borderRadius: fitSize(22),
                backgroundColor: color.primary,
                justifyContent: "center",
                alignItems: "center",
                marginTop: fitSize(13),
                marginBottom: fitSize(15),
              }}
            >
              <Text
                style={{
                  color: color.secBackground,
                  fontWeight: "600",
                  fontSize: fitSize(16),
                }}
              >
                确认寄售
              </Text>
            </Touchable>
          </View>
          <View
            style={{
              paddingHorizontal: fitSize(15),
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: fitSize(20),
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: fitSize(15), color: color.text, fontWeight: "600" }}>
              历史轨迹
            </Text>
            <Touchable
              onPress={() => {
                navigation.push("NftHistory", {
                  nftId: Number(item?.NftId),
                  contractAddress: item?.contractAddress,
                })
              }}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Text style={{ fontSize: fitSize(14), fontWeight: "500", color: color.primary }}>
                查看全部
              </Text>
              <SizeImage
                source={Images.market.more_right}
                style={{ width: fitSize(16), height: fitSize(16) }}
              />
            </Touchable>
          </View>
          <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(2) }}>
            {info?.nftHistory?.list?.map((e, i) => (
              <View
                key={i}
                style={{ flexDirection: "row", width: "100%", marginVertical: fitSize(4) }}
              >
                <Text style={{ fontSize: fitSize(12), color: color.dim }}>
                  {dayjs(e?.blockTime).format("YYYY/MM/DD HH:mm:ss")}
                </Text>
                <View style={{ marginLeft: "auto" }}>
                  <Text
                    numberOfLines={1}
                    style={{ fontSize: fitSize(12), color: color.dim, width: fitSize(150) }}
                  >
                    from{` `}
                    {e?.from}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={{ fontSize: fitSize(12), color: color.dim, width: fitSize(150) }}
                  >
                    to{` `}
                    {e?.to}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              paddingHorizontal: fitSize(15),
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: fitSize(11),
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: fitSize(15), color: color.text, fontWeight: "600" }}>
              成交记录
            </Text>
            <Touchable
              onPress={() => {
                navigation.push("TradeHistory", {
                  nftId: Number(item?.NftId),
                  contractAddress: item?.contractAddress,
                })
              }}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Text style={{ fontSize: fitSize(14), fontWeight: "500", color: color.primary }}>
                查看全部
              </Text>
              <SizeImage
                source={Images.market.more_right}
                style={{ width: fitSize(16), height: fitSize(16) }}
              />
            </Touchable>
          </View>
          <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(2) }}>
            {info?.nftTradeHistory?.map((e, i) => (
              <View
                key={i}
                style={{ flexDirection: "row", width: "100%", marginVertical: fitSize(4) }}
              >
                <Text style={{ fontSize: fitSize(12), color: color.dim }}>
                  {dayjs(e?.payTime).format("YYYY/MM/DD HH:mm:ss")}
                </Text>
                <View style={{ marginLeft: "auto" }}>
                  <Text
                    numberOfLines={1}
                    style={{ fontSize: fitSize(12), color: color.text, fontWeight: "600" }}
                  >
                    ￥{regFenToYuan(e?.price)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text
            style={{
              fontSize: fitSize(15),
              color: color.text,
              fontWeight: "600",
              marginLeft: fitSize(15),
            }}
          >
            链信息
          </Text>
          <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(6) }}>
            <View style={{ width: "100%", flexDirection: "row", marginVertical: fitSize(4) }}>
              <Text style={{ fontSize: fitSize(12), color: color.dim }}>合约名称</Text>
              <Text style={{ fontSize: fitSize(12), color: color.text, marginLeft: "auto" }}>
                {item?.contractName}
              </Text>
            </View>
            <View style={{ width: "100%", flexDirection: "row", marginVertical: fitSize(4) }}>
              <Text style={{ fontSize: fitSize(12), color: color.dim }}>合约地址</Text>
              <Text
                numberOfLines={1}
                style={{
                  fontSize: fitSize(12),
                  color: color.text,
                  marginLeft: "auto",
                  width: fitSize(150),
                }}
              >
                {item?.contractAddress}
              </Text>
              <Touchable onPress={() => doCopy(item?.contractAddress)}>
                <SizeImage
                  source={Images.components.copy}
                  style={{ width: fitSize(15), height: fitSize(15), marginLeft: fitSize(5) }}
                />
              </Touchable>
            </View>
          </View>
        </ScrollView>
      </LoadingComp>
    </FlexView>
  )
}

const styles = StyleSheet.create({
  img: {
    width: fitSize(375),
    height: fitSize(375),
    backgroundColor: "#000",
  },
  leftRow: {
    color: color.secBackground,
    backgroundColor: color.primary,
    width: fitSize(32),
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopLeftRadius: fitSize(2),
    borderBottomLeftRadius: fitSize(2),
    fontSize: fitSize(11),
  },
  rightRow: {
    color: "#666",
    backgroundColor: "rgb(240,	240	,240	)",
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopRightRadius: fitSize(2),
    borderBottomRightRadius: fitSize(2),
    paddingHorizontal: fitSize(5),
    fontSize: fitSize(11),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
})
