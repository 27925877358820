import React from "react"
import { TouchableOpacity } from "react-native"
import debounce from "lodash.debounce" // 4.0.8
import { TouchableWithoutFeedback } from "react-native-gesture-handler"
const withPreventDoubleClick = (WrappedComponent: React.ComponentType<any | string>) => {
  class PreventDoubleClick extends React.PureComponent {
    debouncedOnPress = () => {
      //@ts-ignore
      this.props.onPress && this.props.onPress()
    }

    onPress = debounce(this.debouncedOnPress, 300, {
      leading: true,
      trailing: false,
    })

    render() {
      return <WrappedComponent {...this.props} onPress={this.onPress} />
    }
  }
  //@ts-ignore
  PreventDoubleClick.displayName = `withPreventDoubleClick(${
    WrappedComponent.displayName || WrappedComponent.name
  })`
  return PreventDoubleClick
}

export const TouchableOpacityEx = withPreventDoubleClick(TouchableOpacity)
export const TouchableWithoutFeedbackThrottle = withPreventDoubleClick(TouchableWithoutFeedback)
