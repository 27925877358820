import { Animated, StyleSheet, Text, View } from "react-native"
import { NavigationState, Route, SceneRendererProps, TabBar } from "react-native-tab-view"
import { color, fitSize } from "../../../theme"

import React from "react"
import { themeData } from "../../../navigators"

type MyTabBarProps<T extends Route> = SceneRendererProps & {
  navigationState: NavigationState<T>
  split?: number
  indicatorLeft?: number
  bgColor?: string
  textTintColor?: string
  textColor?: string
  leftSpace?: number
  width?: number
}

export function MyTabBar<T extends Route>(props: MyTabBarProps<T>) {
  return (
    <TabBar
      {...props}
      activeColor={"#333"}
      inactiveColor={"#999999"}
      style={{
        backgroundColor: props.bgColor || null,
        width: "100%",
        alignSelf: "center",
        justifyContent: "center",
      }}
      scrollEnabled={false}
      renderLabel={({ route, focused }) => {
        return (
          <Text
            style={{
              color: focused ? props.textTintColor || "#06FDE8" : props.textColor || "#06FDE888",
              fontSize: focused ? fitSize(12) : fitSize(12),
              fontWeight: focused ? "bold" : "normal",
              width: props.width ? props.width : fitSize(100),
              height: fitSize(29),
              textAlign: "center",
            }}
          >
            {route.title}
          </Text>
        )
      }}
    />
  )
}

const styles = StyleSheet.create({})
