import {
  IAPResponseCode,
  InAppPurchase,
  connectAsync,
  finishTransactionAsync,
  setPurchaseListener,
} from "expo-in-app-purchases"
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native"
import { Platform, View } from "react-native"
import React, { createRef, useEffect, useState } from "react"
import { hideLoad, showError, showInfo } from "../utils/showUtil"
import { loadString, remove } from "../utils/storage"

import { GlobalModal } from "../modal/GlobalModal"
import { MainNavigator } from "./main-navigator"
import { RootNavigation } from "./navigation-utilities"
import { createStackNavigator } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import { seriesStoreSnapshot } from "../models/collects/series"
import { useInit } from "../hooks/useInit"
import { useStores } from "../models"

// import { GlobalModal } from "../modal/GlobalModal"
// import { LightUnityModal } from "../modal/LightUnityModal"

// import { UnityModal } from "../modal/UnityModal"

/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your MainNavigator) which the user
 * will use once logged in.
 */

// import { createNativeStackNavigator } from "react-native-screens/native-stack"

// import { createStackNavigator } from "@react-navigation/stack"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * We recommend using MobX-State-Tree store(s) to handle state rather than navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type RootParamList = {
  mainStack: undefined
  blank: undefined
}

export interface themeData {
  BGUrl: string
  Select: {
    Fold: string
    Home: string
    Person: string
  }
  Unselect: {
    Fold: string
    Home: string
    Person: string
  }
  themeColor: string
}

// const Stack = createNativeStackNavigator<RootParamList>()
const Stack = createStackNavigator<RootParamList>()

const RootStack = observer(() => {
  const { isNetSettle } = useInit()
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="mainStack"
        component={MainNavigator}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  )
})
export const GToast = createRef<React.ElementRef<typeof GlobalModal>>()
export const RootNavigator = React.forwardRef<
  NavigationContainerRef<{}>,
  Partial<React.ComponentProps<typeof NavigationContainer>>
>((props, ref) => {
  const { accountStore } = useStores()

  return (
    <NavigationContainer
      {...props}
      ref={ref}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? ""}`,
      }}
    >
      <RootStack />
      <GlobalModal ref={GToast} />
    </NavigationContainer>
  )
})

RootNavigator.displayName = "RootNavigator"
