import { Image, Platform, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../theme"
import React, { useEffect, useState } from "react"
import { hideLoad, showInfo } from "../../utils/showUtil"
import { useFocusEffect, useNavigation } from "@react-navigation/native"

import Clipboard from "@react-native-community/clipboard"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { MyErrorCode } from "../../utils/errorCode"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { Text } from "../../components"
import { Touchable } from "../../components"
import { copyToClipboard } from "../../utils/copy"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { userInfoType } from "../Transaction/TransactionScreen"

interface MineProps extends RootStackNavProps<"Mine"> {}

export const Mine: React.FC<MineProps> = ({ navigation, route }) => {
  const { accountStore } = useStores()
  const { push } = useTypeNavigation()
  const { top } = useSafeAreaInsets()
  const [userInfo, setUserInfo] = useState<userInfoType>()
  const user = accountStore.user
  const doCopy = () => {
    if (Platform.OS === "web") {
      copyToClipboard(accountStore?.user?.account || "1")
    } else {
      Clipboard.setString("0x" + accountStore?.user?.account || "1")
    }
    showInfo("复制成功")
  }

  const fetchUserInfo = async () => {
    const userRes = await accountStore.getUserInfo()
    if (userRes.kind === "ok") {
      setUserInfo(userRes.data)
    }
  }

  // useFocusEffect(
  //   React.useCallback(() => {
  //     if (route.params?.isWallet) {
  //       pushWalletScreen()
  //     }
  //   }, [route]),
  // )

  useEffect(() => {
    fetchUserInfo()
  }, [])

  const pushWalletScreen = async () => {
    if (userInfo?.userCustId) {
      const createAdapayWallet = await accountStore.environment.api.normalRequest(
        "user/walletManagement",
        {
          returnUrl: Platform.OS === "web" ? `${window.location.origin}/Mine` : undefined,
          payWay: 2,
        },
        "post",
      )
      if (createAdapayWallet.kind === "ok") {
        if (Platform.OS === "web") {
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          navigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        if (createAdapayWallet.kind === "error") {
          if (createAdapayWallet.code === MyErrorCode.ThirdPartyServiceError) {
            GToast.current.showToast("钱包服务错误", "CANCEL", 1500)
            return
          }
        }
        GToast.current.showToast("钱包生成失败", "TOAST", 2000)
      }
    } else {
      GToast.current.showLoading("正在创建支付钱包")
      const createAdapayWallet = await accountStore.environment.api.normalRequest(
        "user/createWallet",
        {
          returnUrl: Platform.OS === "web" ? `${window.location.origin}/Mine` : undefined,
          payWay: 2,
        },
        "post",
      )
      if (createAdapayWallet.kind === "ok") {
        hideLoad()
        if (Platform.OS === "web") {
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          navigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        if (createAdapayWallet.kind === "error") {
          if (createAdapayWallet.code === MyErrorCode.ThirdPartyServiceError) {
            GToast.current.showToast("钱包服务错误", "CANCEL", 1500)
            return
          }
        }
        GToast.current.showToast("创建钱包失败", "TOAST", 2000)
      }
    }
  }

  return (
    <FlexView>
      <Sheader isNotSafe rightImage={""} title={"个人中心"} />
      <View style={[styles.topContent, { top: top + fitSize(60) }]}>
        <View style={[styles.settingBar, { marginTop: top + fitSize(8) }]}>
          <View style={styles.settingBox}>
            <Touchable
              onPress={() => {
                push("Setting")
              }}
              style={{ marginRight: fitSize(5) }}
            >
              <Image source={Images.mine.setting} style={styles.settingIcon} />
            </Touchable>
            <Touchable
              onPress={() => {
                push("MessageList")
              }}
            >
              <SizeImage
                source={Images.mine.mail}
                style={{ width: fitSize(32), height: fitSize(32) }}
              ></SizeImage>
              {accountStore.user?.unReadMessages && (
                <View
                  style={{
                    width: fitSize(16),
                    height: fitSize(16),
                    borderRadius: fitSize(8),
                    backgroundColor: "#FF4747",
                    borderColor: "#17181A",
                    borderWidth: fitSize(4),
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                />
              )}
            </Touchable>
          </View>
        </View>
      </View>
      <View style={[styles.topInfoContent, { marginTop: top + fitSize(40) }]}>
        <View
          style={[
            {
              flexDirection: "row",
              minHeight: fitSize(50),
              flexShrink: 1,
              alignItems: "center",
            },
          ]}
        >
          <Image
            source={Images.test.m_owner1}
            style={{
              width: fitSize(75),
              height: fitSize(75),
              marginRight: fitSize(12),
            }}
          />
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text
              numberOfLines={1}
              style={{ fontSize: fitSize(17), fontWeight: "bold", color: "#06FDE8" }}
            >
              {user?.phonenumber}
            </Text>
          </View>
        </View>
        <View style={styles.boundaryLine} />
        <View style={styles.addressContent}>
          <View style={styles.addressBox}>
            <View style={[styles.rowCenter, { width: fitSize(214) }]}>
              <View>
                <Text style={styles.addressText}>
                  区块链地址
                  {` ¦ `}
                </Text>
              </View>
              <Text
                style={[styles.addressText, { width: fitSize(150) }]}
                numberOfLines={1}
                ellipsizeMode={"middle"}
              >
                0x{accountStore.user?.account}
              </Text>
            </View>
            <Touchable
              onPress={() => {
                doCopy()
              }}
              style={styles.rowCenter}
            >
              <Text style={{ color: color.primary, fontSize: fitSize(12) }}>复制</Text>
            </Touchable>
          </View>
        </View>
      </View>
      <View style={styles.typeContent}>
        <View
          style={{
            width: fitSize(340),
            height: fitSize(130),
            borderWidth: fitSize(1),
            borderColor: color.primary,
            borderRadius: fitSize(5),
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: color.secBackground,
          }}
        >
          <Text
            style={{
              color: color.lighterGrey,
              position: "absolute",
              top: fitSize(10),
              left: fitSize(10),
            }}
          >
            我的交易
          </Text>

          <Touchable
            onPress={() => {
              push("SaleCenter")
            }}
            style={styles.warpItem}
          >
            <Image source={Images.tab.market} style={{ width: fitSize(40), height: fitSize(40) }} />
            <Text style={{ color: color.primary, marginTop: fitSize(10) }}>寄售管理</Text>
          </Touchable>
          <Touchable
            onPress={() => {
              push("OrderBuy")
            }}
            style={styles.warpItem}
          >
            <Image source={Images.tab.market} style={{ width: fitSize(40), height: fitSize(40) }} />
            <Text style={{ color: color.primary, marginTop: fitSize(10) }}>购买记录</Text>
          </Touchable>

          <Touchable style={styles.warpItem} onPress={pushWalletScreen}>
            <Image source={Images.tab.market} style={{ width: fitSize(40), height: fitSize(40) }} />
            <Text style={{ color: color.primary, marginTop: fitSize(10) }}>我的钱包</Text>
          </Touchable>
        </View>
      </View>
    </FlexView>
  )
}
const styles = StyleSheet.create({
  boundaryLine: {
    width: fitSize(325),
    height: 1,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#696969",
    alignSelf: "center",
    marginTop: fitSize(25),
    marginBottom: fitSize(15),
  },
  container: {
    backgroundColor: "#17181A",
    // alignItems: "center",
  },
  topContent: {
    width: fitSize(375),
    height: fitSize(192),
    justifyContent: "space-between",
    paddingHorizontal: fitSize(15),
    backgroundColor: "#202530",
    position: "absolute",
  },
  settingBar: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  settingBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  settingIcon: {
    width: fitSize(24),
    height: fitSize(24),
    tintColor: "#06FDE8",
  },
  topInfoContent: {
    width: fitSize(343),
    minHeight: fitSize(50),
    borderRadius: fitSize(6),
    alignSelf: "center",
    paddingTop: fitSize(0),
  },
  addressContent: {
    width: fitSize(343),
    borderRadius: fitSize(6),
    alignSelf: "center",
    justifyContent: "center",
    paddingHorizontal: fitSize(12),
    // backgroundColor: "red",
  },
  addressBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  copyText: {
    fontSize: fitSize(12),
    fontWeight: "600",
    lineHeight: fitSize(17),
    color: "#06FDE8",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  addressLabel: {
    color: "#06FDE899",
    fontSize: fitSize(12),
  },
  addressText: {
    // width: fitSize(214),
    color: "#06FDE899",
    fontSize: fitSize(12),
  },
  typeContent: {
    marginTop: fitSize(40),
    marginBottom: fitSize(25),
    alignSelf: "center",
    // paddingLeft: fitSize(18),
  },
  warpItem: {
    justifyContent: "center",
    alignItems: "center",
  },
})
