import {
  ActivityIndicator,
  Animated,
  FlatList,
  FlatListProps,
  Platform,
  ScrollViewProps,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native"
import React, { useMemo } from "react"
import { color, fitSize } from "../../theme"

import { EmptyItem } from "./EmptyItem"
import { NoInternet } from "../empty/NoInternet"
import { RefreshControl } from "react-native-web-refresh-control"
import RefreshNormalHeader from "./RefreshControl/RefreshNormalHeader"
import { Text } from ".."
import { Touchable } from "../button/button"

// import { FlatList } from "react-native-gesture-handler"

interface HookFlatListProps<T> extends FlatListProps<T> {
  error?: string
  isLoading?: boolean
  bottomRefreshing?: boolean
  topRefreshing?: boolean
  tintColor?: string
  notShowEmpty?: boolean
  emptyStype?: StyleProp<ViewStyle>
  errorTop?: number
}

export function HookFlatList<T>(props: HookFlatListProps<T>) {
  const {
    error,
    isLoading,
    bottomRefreshing,
    topRefreshing,
    data,
    tintColor,
    notShowEmpty = true,
    emptyStype,
    errorTop,
  } = props
  if (error) {
    return (
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "transparent",
          flex: 1,
        }}
      >
        <NoInternet
          onPress={() => {
            props.onRefresh()
          }}
          error={error}
          top={errorTop}
          isLoading={isLoading}
        />
      </View>
    )
  }
  if (isLoading) {
    return (
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: fitSize(20),
          backgroundColor: "transparent",
          flex: 1,
        }}
      >
        <ActivityIndicator color={"#999"} />
      </View>
    )
  }

  const ListFooterComponent = useMemo(() => {
    if (data?.length === 0) {
      return null
    }
    return (
      <View style={styles.loadMoreView}>
        {bottomRefreshing ? (
          <ActivityIndicator />
        ) : (
          <Text style={{ color: color.dim, fontSize: fitSize(14) }}>这是我的底线</Text>
        )}
      </View>
    )
  }, [data, bottomRefreshing])
  const { onRefresh, refreshing, ...rest } = props
  const windowHeight = useWindowDimensions().height

  return (
    <View
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? windowHeight - fitSize(65) : undefined,
      }}
    >
      <FlatList<T>
        showsVerticalScrollIndicator={false}
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={topRefreshing} />}
        keyExtractor={(item, index) => index + ""}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={() => {
          return (
            <View
              style={[
                {
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  // marginTop: fitSize(50),
                },
                emptyStype,
              ]}
            >
              <Touchable
                onPress={() => {
                  props.onRefresh()
                }}
              >
                <EmptyItem tintColor={tintColor || "#CBCEDD"} />
              </Touchable>
            </View>
          )
        }}
        onEndReachedThreshold={0.1}
        {...rest}
      />
      {/* {data?.length === 0 && notShowEmpty && (
        <View
          style={[
            {
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              alignSelf: "center",
              top: 150,
              // ...StyleSheet.absoluteFillObject,
            },
            emptyStype,
          ]}
        >
          <Touchable
            onPress={() => {
              props.onRefresh()
            }}
          >
            <EmptyItem tintColor={tintColor || "#CBCEDD"} />
          </Touchable>
        </View>
      )} */}
    </View>
  )
}

const styles = StyleSheet.create({
  loadMoreView: {
    minHeight: fitSize(200),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: fitSize(10),
  },
})
export const EmptyList = () => {
  return (
    <View
      style={[
        {
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          // marginTop: fitSize(50),
        },
      ]}
    >
      <EmptyItem tintColor={"#CBCEDD"} />
    </View>
  )
}
