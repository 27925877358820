// import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import { AppRegistry } from "react-native"
import { Myapp } from "../app/app"
if (process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

AppRegistry.registerComponent("jiji", () => Myapp)

AppRegistry.runApplication("jiji", {
  rootTag: document.getElementById("root"),
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()
