import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"
import { showError, showInfo } from "../../../utils/showUtil"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
  orderStatus: number
}
export interface saleItem {
  order: Order[]
  relationOrder?: Order
  exchangeTime: number
}

interface Order {
  id: number
  orderType: number
  goodsName: string
  tokenURI: string
  sellerName: string
  sellerUserId: number
  barterRequestMsg: string
  createdAt: number
  orderId: string
}
export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router, orderStatus }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserExchangeOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 2,
        orderType: 1,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return (
            <SaleItem
              refresh={() => {
                fetchList.doFirstload()
              }}
              item={item}
            />
          )
        }}
      />
    </View>
  )
}

const SaleItem = ({ item, refresh }: { item: saleItem; refresh: () => void }) => {
  const [assetData] = useUriInfo(item?.order?.[0]?.tokenURI, [item])
  const navigation = useTypeNavigation()
  const { collectStore } = useStores()
  const doTakeDown = async () => {
    GToast.current.showLoading("正在下架中")
    const content = await collectStore.environment.api.getSignature(
      "PayAbleMarket",
      {
        nftAddress: assetData.contractAddress,
        nftId: assetData.NftId,
        orderId: item?.order?.[0]?.orderId,
      },
      false,
    )
    const res = await collectStore.environment.api.messageIdRequest(
      "/market/unSellNft",
      { orderId: item?.order?.[0]?.orderId, signature: content.data },
      "post",
    )
    if (res.kind === "ok") {
      showInfo("下架成功")
      refresh()
    } else {
      showError(res)
    }
  }

  return (
    <Touchable
      onPress={() => {
        navigation.push("TransactionScreenTradeToItemScreen", {
          id: item?.order?.[0]?.id,
          isMine: true,
        })
      }}
      style={{
        width: fitSize(351),
        height: fitSize(213),
        alignSelf: "center",
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
        // flexDirection: "row",
        // justifyContent: "space-between",
        paddingHorizontal: fitSize(15),
        paddingTop: fitSize(12),
      }}
    >
      <Text style={{ fontSize: fitSize(14), color: color.primary, fontWeight: "500" }}>
        发布时间{dayjs(item?.order?.[0]?.createdAt * 1000).format("YYYY/MM/DD HH:mm:ss")}
      </Text>
      <Text
        numberOfLines={2}
        style={{
          fontSize: fitSize(13),
          color: color?.dim,
          width: fitSize(312),
          height: fitSize(38),
          alignSelf: "center",
          marginTop: fitSize(8),
        }}
      >
        {item?.order?.[0]?.barterRequestMsg}
      </Text>
      <SizeImage
        source={{ uri: assetData?.Image }}
        style={{ width: fitSize(57), height: fitSize(57), marginVertical: fitSize(15) }}
        resizeMode={"contain"}
        isPreview
      />
      <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
        <Touchable
          onPress={() => {
            navigation.push("TransactionScreenTradeToItemScreen", {
              id: item?.order?.[0]?.id,
              isMine: true,
            })
          }}
          style={{
            width: fitSize(80),
            height: fitSize(32),
            borderRadius: fitSize(16),
            borderWidth: fitSize(1),
            justifyContent: "center",
            alignItems: "center",
            borderColor: color.primary,
            marginRight: fitSize(10),
          }}
        >
          <Text style={{ color: color.primary, fontSize: fitSize(14), fontWeight: "500" }}>
            查看
          </Text>
        </Touchable>
        <Touchable
          onPress={doTakeDown}
          style={{
            width: fitSize(80),
            height: fitSize(32),
            borderRadius: fitSize(16),
            borderWidth: fitSize(1),
            justifyContent: "center",
            alignItems: "center",
            borderColor: color.primary,
            backgroundColor: color.primary,
          }}
        >
          <Text style={{ color: "#fff", fontSize: fitSize(14), fontWeight: "500" }}>下架</Text>
        </Touchable>
      </View>
    </Touchable>
  )
}
export const ChangeList = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
