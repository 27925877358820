import * as storage from "../../utils/storage"

import { RootStore, RootStoreModel } from "./root-store"

import { AccountStore } from "./../account/account"
import { Environment } from "../environment"
import I18n from "i18n-js"
import { onSnapshot } from "mobx-state-tree"

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = "root"

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment()
  await env.setup()
  return env
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore
  let data: any

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment()
  try {
    // load data from storage
    // 设置初始化的store
    data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || {}
    // data = {}
    // console.log("data", data)
    rootStore = RootStoreModel.create(data, env)
    //设置语言
    I18n.locale = rootStore.accountStore.setting.language
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env)

    // but please inform us what happened
    __DEV__ && console.tron.error(e.message, null)
  }

  // reactotron logging
  if (__DEV__) {
    env.reactotron.setRootStore(rootStore, data)
  }

  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) => {
    const { accountStore, collectStore } = snapshot
    const { isOK, ...rest } = accountStore
    // const { allCardsItemMapAddress, ...simpleCollectStore } = collectStore
    storage.save(ROOT_STATE_STORAGE_KEY, { accountStore: rest, collectStore })
  })

  return rootStore
}
