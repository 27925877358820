import { Image, Platform, ScrollView, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../theme"
import React, { useEffect, useState } from "react"
import { Text, Touchable, TouchableDeep } from "../../components"
import { hideLoad, showError, showInfo } from "../../utils/showUtil"

import Clipboard from "@react-native-community/clipboard"
import CopyToClipboard from "react-copy-to-clipboard"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators/root-navigator"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { infoType } from "../Transaction/TransactionScreen"
import { useApi } from "../../hooks/useApi"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useUriInfo } from "../../hooks/useUriInfo"

interface SaleDetailProps extends RootStackNavProps<"SaleDetail"> {}

export const SaleDetail: React.FC<SaleDetailProps> = ({ route, navigation }) => {
  const { doRequest, result, setResult } = useApi<infoType>({
    api: {
      path: "/market/getTradeMarketorderDetail",
      params: {
        id: route.params.id,
        platform: Platform.OS,
      },
      way: "post",
    },
  })
  const [msgId, setMsgId] = useState("")
  const [isAsyncFnc, setIsAsyncFnc] = useState(false)
  const [isAuthorize, setIsAuthorize] = useState(false)
  const { accountStore, collectStore } = useStores()
  const { bottom } = useSafeAreaInsets()
  const info = result?.result
  const [assetData] = useUriInfo(result?.result?.tokenURI, [result])
  const doCopy = (copy: string) => {
    Clipboard.setString(copy)
    GToast.current.showToast("复制成功", "TOAST", 2000)
  }

  const lookRecord = () => {
    navigation.push("TransactionRecords", { status: 1 })
  }

  const goEdit = () => {
    navigation.push("TransactionInfo", {
      orderId: info?.orderId,
      isEdit: true,
      price: info.price,
      nftItem: assetData,
    })
  }
  const goTakeDown = () => {
    GToast.current.showAlert(
      `藏品下架后回到「资产」查看，若想再次出售需重新发售。确认下架吗？`,
      () => {},
      () => {
        doTakeDown()
      },
    )
  }
  const doTakeDown = async () => {
    GToast.current.showLoading("正在下架中")
    const content = await collectStore.environment.api.getSignature(
      "PayAbleMarket",
      {
        nftAddress: assetData.contractAddress,
        nftId: assetData.NftId,
        orderId: info.orderId,
      },
      false,
    )
    const res = await accountStore.environment.api.updateMarketTradeOrder(
      info.orderId,
      2,
      content.data,
    )
    if (res.status !== 6002) {
      if (res.kind === "ok") {
        setMsgId(res.data.msgId)
        setIsAsyncFnc(true)
      }
    } else {
      hideLoad()
      showInfo("订单已锁定")
    }
  }

  const getAsyncResult = async (value: string) => {
    const res = await accountStore.environment.api.normalRequest(
      "/user/getAsyncResult",
      {
        msgId: value,
      },
      "post",
    )
    if (res.kind === "ok") {
      if (JSON.parse(res.data).status === 2) {
        hideLoad()

        collectStore.addOneCollect({
          nftAddress: JSON.parse(res.data).nftAddress,
          NftId: JSON.parse(res.data).nftIds,
        })
        GToast.current.showToast("下架成功", "IMG", 2000, true, 1, 1, {
          image: assetData?.Image,
          name: JSON.parse(res.data).nftAddress,
          id: JSON.parse(res.data).nftIds,
        })
        navigation.goBack()
      } else if (JSON.parse(res.data).status === 1) {
        return
      } else {
        setMsgId("")
        setIsAsyncFnc(false)
        showError(res)
      }
    } else {
      hideLoad()
      showInfo(res?.msg)
    }
  }

  useEffect(() => {
    let time = null
    if (isAsyncFnc) {
      time = setInterval(() => {
        getAsyncResult(msgId)
      }, 1000)
    }
    return () => {
      clearInterval(time)
    }
  }, [isAsyncFnc])

  return (
    <FlexView>
      <Sheader isNotSafe rightImage="" />
      <LoadingComp doRequest={doRequest} result={result}>
        <>
          <ScrollView
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: fitSize(50) }}
          >
            <SizeImage
              isGif={assetData?.DefaultFormat === "gif"}
              resizeMode={"contain"}
              source={{
                uri: assetData?.DefaultFormat === "gif" ? assetData?.GifImage : assetData?.Image,
              }}
              style={styles.img}
            />
            <View style={styles.nameBox}>
              <Text style={styles.nameText}>{assetData?.Name}</Text>
              <Text style={styles.priceText}>¥{(info?.price / 100).toFixed(2)}</Text>
            </View>
            <View style={styles.content}>
              <View style={styles.contentTopInfo}>
                <View style={styles.rowCenter}>
                  <View style={styles.spaceLine} />
                  <Text style={styles.seriesName}>{assetData?.Series}</Text>
                </View>
                <View style={styles.rowCenter}>
                  <Text style={[styles.totalNumber, { backgroundColor: "#333" }]}>发行</Text>
                  <Text style={[styles.totalNumber, { backgroundColor: "#999" }]}>
                    {assetData?.Circulation}份
                  </Text>
                </View>
              </View>
              <View style={styles.contentBottomInfo}>
                <Text numberOfLines={4} ellipsizeMode="tail" style={styles.seriseContent}>
                  {assetData?.Description}
                </Text>
              </View>
            </View>
            <View style={styles.addressInfo}>
              <View style={styles.contentTopInfo}>
                <View style={styles.rowCenter}>
                  <View style={styles.spaceLine} />
                  <Text style={styles.seriesName}>链上信息</Text>
                </View>
              </View>
              <View style={styles.addressInfoContent}>
                <View style={styles.contentView}>
                  <Text style={styles.titleLeft}>地址:</Text>
                  <View style={styles.conterRightCopy}>
                    <Text numberOfLines={1} style={styles.contentRight}>
                      {assetData?.contractAddress.replace(
                        /(\+?\d{6})(\d+)(\d{4})/g,
                        (start, middle, end) => {
                          return start + ".".repeat(middle.length) + end
                        },
                      ) || "——"}
                    </Text>
                    {Platform.OS !== "web" ? (
                      <Touchable onPress={() => doCopy(assetData?.contractAddress)}>
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </Touchable>
                    ) : (
                      <CopyToClipboard
                        text={assetData?.contractAddress}
                        onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                      >
                        <Image source={Images.components.copy} style={styles.copyImg} />
                      </CopyToClipboard>
                    )}
                  </View>
                </View>
                <View style={styles.contentView}>
                  <Text style={styles.titleLeft}>链上标识:</Text>
                  <View style={styles.conterRightCopy}>
                    <Text numberOfLines={1} style={styles.contentRight}>
                      {assetData?.NftId || "——"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.addressInfo}>
              <View style={styles.contentTopInfo}>
                <View style={styles.rowCenter}>
                  <View style={styles.spaceLine} />
                  <Text style={styles.seriesName}>拥有者</Text>
                </View>
              </View>
              <View style={styles.addressInfoContent}>
                <View style={styles.contentView}>
                  <Text style={styles.titleLeft}>{info?.phonenumber || "——"}</Text>
                  <View style={styles.conterRightCopy}>
                    <Text numberOfLines={1} style={styles.contentRight}>
                      {assetData?.contractAddress.replace(
                        /(\+?\d{6})(\d+)(\d{4})/g,
                        (start, middle, end) => {
                          return start + ".".repeat(middle.length) + end
                        },
                      ) || "——"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.addressInfo}>
              <View style={styles.contentTopInfo}>
                <View style={styles.rowCenter}>
                  <View style={styles.spaceLine} />
                  <Text style={styles.seriesName}>交易记录</Text>
                </View>
              </View>
              <View style={styles.addressInfoContent}>
                <View style={styles.contentView}>
                  <Text style={styles.titleLeft}>交易记录</Text>
                  <Touchable style={styles.conterRightCopy} onPress={() => lookRecord()}>
                    <Text numberOfLines={1} style={styles.contentRight}>
                      {`查看交易记录 >`}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
          </ScrollView>
          <View style={[styles.infoBar, { minHeight: fitSize(55) + bottom }]}>
            <View style={styles.infoContent}>
              <View style={styles.rowCenter}>
                <TouchableDeep
                  style={[
                    styles.button,
                    {
                      backgroundColor: "transparent",
                      borderWidth: fitSize(1),
                      borderColor: color.primary,
                    },
                  ]}
                  onPress={goTakeDown}
                >
                  <Text style={[styles.buttonLabel, { color: color.primary }]}>下架</Text>
                </TouchableDeep>
              </View>
              <TouchableDeep
                style={[styles.button, { backgroundColor: "#06FDE8" }]}
                onPress={goEdit}
              >
                <Text style={[styles.buttonLabel, { color: color.background }]}>编辑</Text>
              </TouchableDeep>
            </View>
          </View>
        </>
      </LoadingComp>
    </FlexView>
  )
}

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  sheaderTitle: {
    color: "#fafafa",
  },
  rightWrapStyle: {
    width: fitSize(50),
  },
  nameBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: fitSize(10),
    marginBottom: fitSize(15),
  },
  nameText: {
    fontSize: fitSize(17),
    color: "#06FDE8",
    fontWeight: "500",
  },
  priceText: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  content: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(150),
    marginBottom: fitSize(10),
  },
  contentTopInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(30),
    paddingHorizontal: fitSize(5),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  spaceLine: {
    width: fitSize(2),
    height: fitSize(14),
    backgroundColor: "#06FDE8",
    marginRight: fitSize(5),
  },
  seriesName: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  totalNumber: {
    fontSize: fitSize(13),
    color: "#ffffff",
    padding: fitSize(3),
  },
  contentBottomInfo: {
    flexGrow: 1,
    paddingLeft: fitSize(10),
    paddingTop: fitSize(3),
    borderBottomWidth: 1,
    borderColor: "#99999944",
    marginTop: fitSize(7),
  },
  seriseContent: {
    fontSize: fitSize(15),
    color: "#9a9a9a",
  },
  addressInfo: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(60),
    marginBottom: fitSize(10),
    borderBottomWidth: 1,
    borderColor: "#99999944",
  },
  addressInfoContent: {
    flexGrow: 1,
  },
  infoBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentView: {
    flexShrink: 1,
    height: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  titleLeft: {
    fontSize: fitSize(14),
    color: "#808080",
  },
  contentRight: {
    fontSize: fitSize(12),
    color: "#999999",
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
  copyImg: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
    tintColor: "#06FDE8",
  },
  img: {
    width: fitSize(375),
    height: fitSize(230),
  },
  infoBar: {
    width: "100%",
    backgroundColor: "#1A1A24",
    position: "absolute",
    bottom: 0,
  },
  infoContent: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: fitSize(16),
    paddingTop: fitSize(12),
    justifyContent: "space-between",
  },
  button: {
    minWidth: fitSize(100),
    height: fitSize(30),

    borderRadius: fitSize(5),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    fontSize: fitSize(14),
    fontWeight: "600",
  },
  shareIcon: {
    width: fitSize(20),
    height: fitSize(20),
    tintColor: "#06FDE8",
  },
})
