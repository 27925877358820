import { Image, ImageSourcePropType, StyleSheet, Text, View, TouchableOpacity } from "react-native"
import React, { ReactElement } from "react"
import { fitSize } from "../../../theme"
import { useStores } from "../../../models"

type Props = {
  assetImage?: ImageSourcePropType
  assetName?: string
  assetId?: string
  onNavigation?: (id?: string, address?: string, tokenURI?: string) => void
  tokenURI?: string
}

export const ApplyAssetInfoCard: React.FC<Props> = React.memo(
  ({ assetImage, assetName, assetId, onNavigation = () => {}, tokenURI }): ReactElement => {
    const { collectStore } = useStores()
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={() => onNavigation(assetId, assetName, tokenURI)}
      >
        <Image source={assetImage} style={styles.assetImage} resizeMode="cover" />
        <View style={styles.content}>
          <Text numberOfLines={1} ellipsizeMode="tail" style={styles.assetName}>
            {assetId}
          </Text>
          <Text numberOfLines={1} ellipsizeMode="tail" style={styles.assetId}>
            {assetName}
          </Text>
        </View>
      </TouchableOpacity>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: fitSize(110),
    height: fitSize(250),
    margin: fitSize(8),
  },
  content: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    height: fitSize(47),
    width: "100%",
  },
  assetImage: {
    width: fitSize(110),
    height: fitSize(200),
  },
  assetName: {
    width: "100%",
    height: fitSize(20),
    fontSize: fitSize(12),
    color: "#06FDE8",
    backgroundColor: "#161823",
    textAlign: "center",
    paddingTop: fitSize(1),
  },
  assetId: {
    fontSize: fitSize(12),
    height: fitSize(20),
    color: "#06FDE8",
    width: "100%",
    backgroundColor: "#50616d",
    textAlign: "center",
    paddingTop: fitSize(1),
  },
})
