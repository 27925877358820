import { FULL, Images, color, deviceWidth, fitSize } from "../../theme"
import { HookFlatList, Screen, Text, Touchable } from "../../components"
import {
  Image,
  ImageBackground,
  StyleSheet,
  View,
  useWindowDimensions,
  Platform,
} from "react-native"
import React, { useEffect, useState } from "react"

import Clipboard from "@react-native-community/clipboard"
import { GToast, themeData } from "../../navigators/root-navigator"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { collectBagItemStoreSnapshot } from "../../models/collects/collectBagItem"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import { useAutoList } from "../../hooks/useAutoList"
import { useAutoListMarkId } from "../../hooks/useAutoListMarkId"
import { useStores } from "../../models"
import { ThemeScreen } from "../../components/screen/ThemeScreen"
import { useTheme } from "styled-components/native"
import { useFocusEffect } from "@react-navigation/native"
import { FlexView } from "../../components/screen/FlexView"

interface MessageListProps extends RootStackNavProps<"MessageList"> {}

type levels = {
  id: number
  title: string
  description: string
  link: string
  pushTime: number
  messagType: string
}

export const MessageList: React.FC<MessageListProps> = observer(({ navigation }) => {
  const { accountStore } = useStores()
  const user = accountStore.user
  const theme = useTheme() as themeData

  const [maxId, setMaxId] = useState<number>(0)
  const windowHeight = useWindowDimensions().height

  const fetchData = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(`/misc/notice/list/${page}`)
    if (res.kind === "ok") {
      const list = res?.data.list
      if (list.length != 0) {
        if (list[0].id >= maxId) {
          setMaxId(list[0].id)
        }
      }
      return list
    } else {
      throw res.msg
    }
  }
  const [list] = useAutoListMarkId<levels>({ fetchData, anchorName: "id" }, [])

  const publishTimeDesc = (postTime) => {
    postTime = String(postTime).length >= 10 ? postTime * 1000 : postTime
    let curTime = new Date().getTime()
    let timeDiff = curTime - postTime

    let min = 60 * 1000
    let hour = min * 60
    let day = hour * 24
    let week = day * 7

    let exceedWeek = Math.floor(timeDiff / week)
    let exceedDay = Math.floor(timeDiff / day)
    let exceedHour = Math.floor(timeDiff / hour)
    let exceedMin = Math.floor(timeDiff / min)

    if (exceedWeek > 0) {
      return dayjs(postTime).format("YYYY-MM-DD HH:mm:ss")
    } else {
      if (exceedDay < 7 && exceedDay > 0) {
        return exceedDay + "天前"
      } else {
        if (exceedHour < 24 && exceedHour > 0) {
          return exceedHour + "小时前"
        } else {
          if (exceedMin < 4) {
            return "刚刚"
          } else {
            return exceedMin + "分种前"
          }
        }
      }
    }
  }

  return (
    <View testID="PackageDetail" style={FULL}>
      <FlexView>
        <Sheader
          isNotSafe
          rightImage={""}
          title={"系统通知"}
          rightWrapStyle={{ width: fitSize(50) }}
        />
        <View
          style={{
            flex: 1,
            marginTop: fitSize(10),
            alignItems: "center",
            height: Platform.OS === "web" ? windowHeight : undefined,
          }}
        >
          <HookFlatList
            renderItem={({ item, index }) => {
              return (
                <Touchable
                  style={{
                    width: "100%",
                    // height: fitSize(37),
                    // borderColor: "#F0EFEF",
                    // borderTopWidth: StyleSheet.hairlineWidth,
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: fitSize(16),
                    // paddingHorizontal: fitSize(10)
                  }}
                >
                  <View style={styles.centerWrap}>
                    <Text
                      // numberOfLines={1}
                      // ellipsizeMode="tail" //head middle tail clip
                      style={{
                        width: fitSize(300),
                        fontSize: fitSize(16),
                        color: color.whiteText,
                        fontWeight: "bold",
                        marginBottom: fitSize(12),
                      }}
                    >
                      {item?.title}
                    </Text>
                    <View>
                      <Text
                        style={{
                          fontSize: fitSize(12),
                          color: color.dim,
                          marginBottom: fitSize(4),
                        }}
                      >
                        {item?.description}
                      </Text>
                      <View>
                        {!!item?.link && (
                          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Touchable
                              style={{ flexDirection: "row", alignItems: "center" }}
                              onPress={() => {
                                navigation.navigate("MassWebView", { uri: item?.link })
                              }}
                            >
                              <Image
                                source={Images.setting.show_link2}
                                style={{ width: fitSize(13), height: fitSize(13) }}
                              />
                              <Text
                                style={{
                                  fontSize: fitSize(14),
                                  color: "#279ACC",
                                  paddingLeft: fitSize(4),
                                }}
                              >
                                网页链接
                              </Text>
                            </Touchable>
                          </View>
                        )}
                        <Text
                          style={{
                            fontSize: fitSize(12),
                            color: "#999",
                            textAlign: "right",
                            marginTop: fitSize(8),
                          }}
                        >
                          {publishTimeDesc(item?.pushTime)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </Touchable>
              )
            }}
            {...{ ...list }}
          />
        </View>
      </FlexView>
    </View>
  )
})

const styles = StyleSheet.create({
  centerWrap: {
    alignSelf: "center",
    width: fitSize(343),
    // marginTop: fitSize(82),
    // minHeight: fitSize(300),
    // backgroundColor: "#fff",
    // borderRadius: fitSize(12),
    // elevation: 1,
    // shadowColor: "#000",
    // shadowOffset: { width: 0, height: fitSize(4) },
    // shadowOpacity: 0.05,
    // shadowRadius: fitSize(12),
  },
})
