import * as React from "react"

import {
  GestureResponderEvent,
  Pressable,
  PressableProps,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native"
import { textPresets, viewPresets } from "./button.presets"
import { useEffect, useRef, useState } from "react"

import { ButtonProps } from "./button.props"
import { Text } from "../text/text"
import { TouchableOpacityEx } from "./withPreventDoubleClick"
import { flatten } from "ramda"
import { fitSize } from "../../theme"

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Button(props: ButtonProps) {
  // grab the props
  const {
    preset = "primary",
    tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    children,
    ...rest
  } = props

  const viewStyle = viewPresets[preset] || viewPresets.primary
  const viewStyles = flatten([viewStyle, styleOverride])
  const textStyle = textPresets[preset] || textPresets.primary
  const textStyles = flatten([textStyle, textStyleOverride])

  const content = children || <Text tx={tx} text={text} style={textStyles} />

  return (
    <TouchableOpacity activeOpacity={0.8} style={viewStyles} {...rest}>
      {content}
    </TouchableOpacity>
  )
}
export const Touchable: React.FC<TouchableOpacityProps> = (props) => {
  return (
    <TouchableOpacityEx activeOpacity={0.8} {...props}>
      {props.children}
    </TouchableOpacityEx>
  )
}
interface TouchableWithTwice extends TouchableOpacityProps {
  onTwicePap?: ((event: GestureResponderEvent) => void) | undefined
  twiceTime?: number
}
//双击组件
export const TouchableWithTwice: React.FC<TouchableWithTwice> = (props) => {
  const { twiceTime = 300 } = props
  const time = useRef(0)
  const triggerPress = (event: GestureResponderEvent) => {
    const now = new Date().getTime()
    if (now - time.current < twiceTime) {
      console.log(now - time.current)
      if (props.onTwicePap) props?.onTwicePap(event)
    } else {
      time.current = now
      if (props?.onPress) props?.onPress(event)
    }
  }

  return (
    <TouchableOpacity activeOpacity={0.8} {...props} onPress={triggerPress}>
      {props.children}
    </TouchableOpacity>
  )
}

type borderR = {
  br?: number
}
export const TouchableDeep: React.FC<PressableProps & borderR> = (props) => {
  const [isActive, setIsActive] = useState(false)
  // const opacity = useSharedValue(0)
  // const btnStyle = useAnimatedStyle(() => {
  //   return {
  //     opacity: opacity.value,
  //   }
  // })

  // useEffect(() => {
  //   if (isActive) {
  //     opacity.value = withTiming(1, { duration: 200 })
  //   } else {
  //     opacity.value = withTiming(0, { duration: 200 })
  //   }
  // }, [isActive])
  const fstyle = StyleSheet.flatten(props.style as StyleProp<ViewStyle>)

  return (
    <Pressable
      onPressIn={() => {
        setIsActive(true)
      }}
      onPressOut={() => {
        setIsActive(false)
      }}
      {...props}
    >
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            // backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: props.br
              ? props.br
              : fstyle?.borderRadius
              ? fstyle?.borderRadius
              : fitSize(3),
            zIndex: 1,
          },
          // btnStyle,
        ]}
      ></View>

      {props.children}
    </Pressable>
  )
}
