import { Api, dealError } from "."
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem"
import { GetLoginResult, GetUserInfo } from "./api.types"

import { ApiResponse } from "apisauce"
import { getChannel } from "../../utils/getMetaInfo"

export class AccountApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async doOneLogin(passToken: string): Promise<GetLoginResult> {
    try {
      const channel = await getChannel()
      const response: ApiResponse<any> = await this.api.apisauce.post("/user/login/oneKeyLogin", {
        LoginSDKAccessToken: passToken,
        channel,
      })
      console.log("loginresponse", response)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return {
        kind: "ok",
        token: response.data.data.Token,
        maasToken: response.data.data.MaasToken,
      }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
  async getInfo(): Promise<GetUserInfo> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post("/user/profile", {})
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      // if (!response.ok) {
      //   const problem = getGeneralApiProblem(response)
      //   if (problem) return problem
      // }
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log("apisourceerror!", e.message)

      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async composeRequest() {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post("/login/isLogined", {})
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          if (problem.kind === "unauthorized") {
          }
          return problem
        }
      }
      return { kind: "ok", token: "" }
    } catch (e) {
      console.log("apisourceerror!", e.message)
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
