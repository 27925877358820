import {
  Alert,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native"
import { Images, color, fitSize } from "../../theme"
import React, { useEffect, useState } from "react"
import { Switch, Text, Touchable } from "../../components"

import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import { MarketFolderList } from "./Components/MarketFolderList"
import { MarketList } from "./Components/MarketList"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import SplashScreen from "react-native-splash-screen"
import { TradeForItemList } from "./Components/TradeForItemList"
import { endBatch } from "mobx/dist/internal"
import { observer } from "mobx-react-lite"
import { useApi } from "../../hooks/useApi"
import { useNoTokenApi } from "../../hooks/useNoTokenApi"
import { useStores } from "../../models"
import { useTouchLoop } from "./Hooks/useTouchLoop"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"

export const time = ["时间排序", "时间升序", "时间降序"]
export const price = ["价格排序", "价格升序", "价格降序"]
export interface tabType {
  seriesInfo: SeriesInfo
  cardContracts: CardContract[]
  boxContracts: CardContract[]
  logicContracts: CardContract[]
}

interface CardContract {
  id: number
  contractName: string
  contractAddress: string
  seriesId: number
  contractType: number
  contractCover: string
  resourceUrl: string
  allCfgUrl: string
}

interface SeriesInfo {
  id: number
  seriesName: string
  seriesCover: string
  seriesHead: string
  issuerName: string
  issuerIcon: string
  communityUrl: string
  downloadUrl: string
  gameIntroduction: string
  onlineTime: number
}
type seriesType = {
  list: tabType[]
}

interface NaviType {
  navType: number
  skuNftAddress?: string
  skuId?: number
  skuName?: string
  seriesId?: number
  seriesName?: string
  navName?: string
  navId?: number
}
interface TabHomeProps extends RootStackNavProps<"HomeScreen"> {}
const test = ["推荐", "阿斯顿", "阿斯顿", "推荐", "阿斯顿", "阿斯顿", "推荐", "阿斯顿", "阿斯顿"]
export const TabHome: React.FC<TabHomeProps> = observer(({ navigation, route }) => {
  const [isShowBuyTrade, setIsShowBugTrade] = useState(false)
  const { accountStore } = useStores()

  return (
    <FlexView>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          height: fitSize(48),
          alignItems: "center",
          paddingHorizontal: fitSize(12),
        }}
      >
        <SizeImage source={Images.home.logo} style={{ width: fitSize(100), height: fitSize(38) }} />
        {!isShowBuyTrade && (
          <Touchable
            onPress={() => {
              if (!accountStore.token) {
                GToast.current.doLogin()
              } else {
                navigation.push("MarketSearchPage")
              }
            }}
            style={{ marginLeft: "auto", marginRight: fitSize(10) }}
          >
            <SizeImage
              source={Images.home.search}
              style={{ width: fitSize(26), height: fitSize(26) }}
            />
          </Touchable>
        )}
        {/* <Touchable
          onPress={() => {
            setIsShowBugTrade((c) => !c)
          }}
        >
          <MySwitch isTrue={isShowBuyTrade} />
        </Touchable> */}
      </View>
      {!isShowBuyTrade ? <Tab1 /> : <Tab2 />}
    </FlexView>
  )
})

const Tab1 = observer(() => {
  const { accountStore } = useStores()
  const [currentChoose, setCurrentChoose] = useState(0)
  const { doRequest, result } = useNoTokenApi<{ list: NaviType[] }>({
    api: { path: "/market/getMarketNav" },
  })
  const tabs = result?.result?.list
  const { touchPrice, touchTime, sortMoney, sortTime } = useTouchLoop()
  const navigation = useTypeNavigation()
  const currentSeriesId = tabs?.[currentChoose]?.seriesId
  const navId = tabs?.[currentChoose]?.navId
  return (
    <LoadingComp result={result} doRequest={doRequest}>
      <View
        style={{
          width: "100%",
          height: fitSize(40),
          alignItems: "center",
          paddingLeft: fitSize(15),
          flexDirection: "row",
        }}
      >
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ paddingRight: fitSize(100) }}
        >
          {!!tabs?.length &&
            tabs?.map((e, i) => {
              const title =
                e.navType === 0
                  ? "全部"
                  : e.navType === 1
                  ? e.seriesName
                  : e.navType === 3
                  ? e.navName
                  : e.skuName
              return (
                <Touchable
                  key={i}
                  onPress={() => {
                    switch (e.navType) {
                      // case 0:
                      //   setCurrentChoose(i)
                      //   break
                      // case 1:
                      //   setCurrentChoose(i)
                      //   break
                      case 2:
                        navigation.push("MarketSearchResultList", {
                          skuInfo: {
                            skuId: e?.skuId,
                            skuNftAddress: e?.skuNftAddress,
                          },
                          searchWord: e?.skuName,
                        })
                        break
                      default:
                        setCurrentChoose(i)
                    }
                  }}
                  style={{ marginRight: fitSize(24), height: fitSize(40) }}
                >
                  <Text
                    style={{
                      fontSize: currentChoose === i ? fitSize(18) : fitSize(16),
                      color: currentChoose === i ? color?.primary : color?.text,
                      fontWeight: currentChoose === i ? "700" : "500",
                      lineHeight: fitSize(40),
                      position: "absolute",
                      alignSelf: "center",
                      zIndex: 100,
                    }}
                  >
                    {title}
                  </Text>
                  <Text
                    style={{
                      fontSize: fitSize(18),
                      fontWeight: "700",
                      color: "transparent",
                      lineHeight: fitSize(40),
                    }}
                  >
                    {title}
                  </Text>
                </Touchable>
              )
            })}
        </ScrollView>
        <Touchable
          onPress={() => {
            if (!accountStore.token) {
              GToast.current.doLogin()
            } else {
              navigation.push("ChooseSeries", { action: "homeFilter" })
            }
          }}
          activeOpacity={1}
          style={{ position: "absolute", right: 0 }}
        >
          <SizeImage
            source={Images.home.more}
            style={{ width: fitSize(100), height: fitSize(40) }}
          />
        </Touchable>
      </View>
      <View
        style={{
          marginTop: fitSize(5),
          marginLeft: fitSize(12),
          flexDirection: "row",
          marginBottom: fitSize(10),
        }}
      >
        <Touchable
          onPress={touchTime}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortTime === 0 ? color?.text : color?.primary,
            }}
          >
            {time[sortTime]}
          </Text>
          <SizeImage
            source={
              sortTime === 0
                ? Images.home.sort_de
                : sortTime === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable>
        <Touchable
          onPress={touchPrice}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortMoney === 0 ? color?.text : color?.primary,
            }}
          >
            {price[sortMoney]}
          </Text>
          <SizeImage
            source={
              sortMoney === 0
                ? Images.home.sort_de
                : sortMoney === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable>
      </View>
      <MarketFolderList
        navId={navId}
        orderPrice={sortMoney}
        orderTime={sortTime}
        seriesId={currentSeriesId}
      />
    </LoadingComp>
  )
})
const Tab2 = observer(() => {
  const [currentChoose, setCurrentChoose] = useState(0)
  const { touchPrice, touchTime, sortMoney, sortTime } = useTouchLoop()
  const navigation = useTypeNavigation()
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          marginTop: fitSize(15),
          marginLeft: fitSize(12),
          flexDirection: "row",
          marginBottom: fitSize(10),
        }}
      >
        <Touchable
          onPress={touchTime}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortTime === 0 ? color?.text : color?.primary,
            }}
          >
            {time[sortTime]}
          </Text>
          <SizeImage
            source={
              sortTime === 0
                ? Images.home.sort_de
                : sortTime === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable>
        {/* <Touchable
          onPress={touchPrice}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortMoney === 0 ? color?.text : color?.primary,
            }}
          >
            {price[sortMoney]}
          </Text>
          <SizeImage
            source={
              sortMoney === 0
                ? Images.home.sort_de
                : sortMoney === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable> */}
      </View>
      <TradeForItemList orderPrice={sortMoney} orderTime={sortTime} />
    </View>
  )
})

const MySwitch = ({ isTrue }: { isTrue: boolean }) => {
  return (
    <View>
      <View
        style={{
          width: fitSize(96),
          height: fitSize(26),
          backgroundColor: color.primary,
          borderRadius: fitSize(13),
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {isTrue ? (
          <Text
            style={{
              fontSize: fitSize(14),
              color: "#fff",
              marginLeft: "auto",
              marginRight: fitSize(8),
            }}
          >
            以物换物
          </Text>
        ) : (
          <Text
            style={{
              fontSize: fitSize(14),
              color: "#fff",
              marginLeft: fitSize(8),
            }}
          >
            寄售市场
          </Text>
        )}
      </View>
      <View
        style={{
          width: fitSize(30),
          height: fitSize(30),
          borderRadius: fitSize(15),
          backgroundColor: "#fff",
          borderColor: "#00AFB5",
          borderWidth: fitSize(1.5),
          position: "absolute",
          left: isTrue ? 0 : fitSize(66),
          top: fitSize(-2),
        }}
      ></View>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
