import { HookFlatList, Text } from "../../components"
import { Images, color, fitSize } from "../../theme"
import { StyleSheet, View } from "react-native"

import { FlexView } from "../../components/screen/FlexView"
import React from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { regFenToYuan } from "./TransactionScreen"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"

interface NftHistoryProps extends RootStackNavProps<"NftHistory"> {}
const PL = 20
export interface transInfo {
  sellerUserAddress: string
  sellerUserPhonenumber: string
  buyerUserAddress: string
  buyerUserPhonenumber: string
  price: number
  payTime: string
}
export const TradeHistory: React.FC<NftHistoryProps> = ({ navigation, route }) => {
  const { accountStore, collectStore } = useStores()

  const fetchUserAssetBySeriesId = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getNftTradeHistory",
      {
        nftId: route?.params?.nftId,
        contractAddress: route?.params?.contractAddress,
        page,
        pageSize: PL,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }

  const [assetList] = useAutoList<transInfo>(
    {
      fetchData: fetchUserAssetBySeriesId,
      pageLimit: PL,
    },
    [],
  )

  return (
    <FlexView>
      <Sheader title="成交记录" />
      <HookFlatList
        style={{ marginTop: fitSize(5), backgroundColor: "#fff" }}
        {...{ ...assetList }}
        renderItem={({ item, index }) => {
          return (
            <View
              style={{
                width: fitSize(345),
                alignSelf: "center",
                height: fitSize(51),
                borderBottomColor: "#E8E8E8",
                borderBottomWidth: StyleSheet.hairlineWidth,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: fitSize(14),
                    color: color.primary,
                    fontWeight: "600",
                    marginBottom: fitSize(6),
                  }}
                >
                  {dayjs(item?.payTime).format("YYYY/MM/DD HH:mm:ss")}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    width: fitSize(120),
                    fontSize: fitSize(12),
                    color: "#000",
                  }}
                >
                  卖家:{item?.sellerUserAddress}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{
                    width: fitSize(120),
                    fontSize: fitSize(12),
                    color: "#000",
                  }}
                >
                  买家:{item?.buyerUserAddress}
                </Text>
                <Text
                  style={{
                    width: fitSize(60),
                    fontSize: fitSize(12),
                    color: "#000",
                    textAlign: "right"
                  }}
                >
                  价格:￥{regFenToYuan(item?.price)}
                </Text>
              </View>
              {/* <SizeImage
                source={Images.market.more_right}
                style={{ width: fitSize(22), height: fitSize(22), marginLeft: fitSize(5) }}
              /> */}
            </View>
          )
        }}
      />
    </FlexView>
  )
}

const styles = StyleSheet.create({})
