import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
}
export interface saleItem {
  id: number
  goodsName: string
  price: number
  tokenURI: string
  buyerAddress: string
  phonenumber: string
  sellDuration: number
  unsellTime: string
  updatedAt: string
  orderId: string
}

export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserTradeMarketOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 4,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return <SaleItem item={item} />
        }}
      />
    </View>
  )
}

const SaleItem = ({ item }: { item: saleItem }) => {
  const [assetData] = useUriInfo(item?.tokenURI, [item])
  const { push } = useTypeNavigation()
  const takeUp = async () => {
    push("TransactionInfo", {
      isEdit: false,
      isReOnSell: true,
      price: item.price,
      nftItem: assetData,
      orderId: item?.orderId,
    })
  }
  return (
    <View
      style={{
        width: fitSize(351),
        height: fitSize(114),
        alignSelf: "center",
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
        flexDirection: "row",
        paddingHorizontal: fitSize(15),
        paddingTop: fitSize(12),
      }}
    >
      <SizeImage
        source={{ uri: assetData?.Image }}
        style={{ width: fitSize(90), height: fitSize(90) }}
        resizeMode={"contain"}
        isPreview
      />
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{ fontSize: fitSize(14), color: "#333333", fontWeight: "500", width: "100%" }}
          >
            {item?.goodsName}
          </Text>
        </View>
        {/* <View
          style={{ flexDirection: "row", marginTop: fitSize(20), justifyContent: "space-between" }}
        >
          <Text style={{ color: color.dim }}>寄售时长:</Text>
          <Text style={{ color: color.dim }}>{item?.sellDuration}天</Text>
        </View> */}
        <View style={{ flexDirection: "row", marginTop: fitSize(5) }}>
          <Text style={{ fontSize: fitSize(12), color: "#888888" }}>下架时间:</Text>
          <Text style={{ fontSize: fitSize(12), color: "#888888", marginLeft: fitSize(5) }}>
            {dayjs(item?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          </Text>
        </View>
      </View>
      <Touchable
        onPress={takeUp}
        style={{
          position: "absolute",
          backgroundColor: "#00AFB5",
          bottom: fitSize(10),
          right: fitSize(10),
          borderRadius: fitSize(15),
          justifyContent: "center",
          alignItems: "center",
          padding: fitSize(5),
          paddingHorizontal: fitSize(15),
        }}
      >
        <Text style={{ color: "#fff", fontSize: fitSize(13), fontWeight: "600" }}>重新上架</Text>
      </Touchable>
    </View>
  )
}
export const TakeDownList = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
