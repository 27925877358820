import { ActivityIndicator, Image, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../theme"
import { Text, Touchable } from ".."

import { GestureResponderEvent } from "react-native"
import React from "react"

interface NoInternetProps {
  onPress: ((event: GestureResponderEvent) => void) | undefined
  isLoading?: boolean
  top?: number
  error?: string
}

export const NoInternet: React.FC<NoInternetProps> = ({ onPress, isLoading, top = 150, error }) => {
  return (
    <View style={{ alignSelf: "center", marginTop: fitSize(top), alignItems: "center" }}>
      <Image
        source={Images.common.no_internet}
        style={{ width: fitSize(183), height: fitSize(183) }}
      />
      <Text style={{ fontSize: fitSize(14), color: color.text, marginTop: fitSize(0) }}>
        {error || "无法连接网络"}
      </Text>
      <Touchable
        onPress={onPress}
        disabled={isLoading}
        style={{
          width: fitSize(81),
          height: fitSize(32),
          borderRadius: fitSize(16),
          backgroundColor: isLoading ? color.dim : color.primary,
          marginTop: fitSize(35),
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Text style={{ fontSize: fitSize(15), color: color.text, fontWeight: "bold" }}>重试</Text>
        {isLoading && (
          <ActivityIndicator
            size={"small"}
            color={"#999"}
            style={{ position: "absolute", alignSelf: "center", right: fitSize(5) }}
          />
        )}
      </Touchable>
    </View>
  )
}

const styles = StyleSheet.create({})
