import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, ScrollView, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { nftItem, useUriInfo } from "../../../hooks/useUriInfo"
import { showError, showInfo } from "../../../utils/showUtil"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
  orderStatus: number
}
export interface saleItem {
  order: Order[]
  relationOrder?: Order
  exchangeTime: number
}

interface Order {
  id: number
  orderType: number
  goodsName: string
  tokenURI: string
  sellerName: string
  sellerUserId: number
  barterRequestMsg: string
  createdAt: number
  orderId: string
}
export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router, orderStatus }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserExchangeOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 2,
        orderType: 2,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return (
            <SaleItem
              refresh={() => {
                fetchList.doFirstload()
              }}
              item={item}
            />
          )
        }}
      />
    </View>
  )
}

const SaleItem = ({ item, refresh }: { item: saleItem; refresh: () => void }) => {
  const [assetData] = useUriInfo(item?.order?.[0]?.tokenURI, [item])
  const navigation = useTypeNavigation()
  const { collectStore } = useStores()
  const doTakeDown = async (orderID: string, item: nftItem) => {
    GToast.current.showLoading("正在下架中")
    const content = await collectStore.environment.api.getSignature(
      "PayAbleMarket",
      {
        nftAddress: item.contractAddress,
        nftId: item.NftId,
        orderId: orderID,
      },
      false,
    )
    const res = await collectStore.environment.api.messageIdRequest(
      "/market/unSellNft",
      { orderId: orderID, signature: content.data },
      "post",
    )
    if (res.kind === "ok") {
      showInfo("下架成功")
      refresh()
    } else {
      showError(res)
    }
  }

  return (
    <Touchable
      onPress={() => {
        navigation.push("TransactionScreenTradeToItemScreen", { id: item?.relationOrder?.id })
      }}
      style={{
        width: fitSize(351),
        height: fitSize(184),
        alignSelf: "center",
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
        // flexDirection: "row",
        // justifyContent: "space-between",
        paddingHorizontal: fitSize(15),
        paddingTop: fitSize(12),
      }}
    >
      <Text style={{ fontSize: fitSize(14), color: color.primary, fontWeight: "500" }}>
        {item?.relationOrder?.sellerName}的以物换物
      </Text>
      <Text
        style={{
          fontSize: fitSize(13),
          color: color?.primary,
          position: "absolute",
          top: fitSize(13),
          right: fitSize(15),
        }}
      >
        进行中
      </Text>
      <ScrollView horizontal>
        {item?.order?.map((e, i) => (
          <ItemFromUri data={e} key={e.orderId} doTakeDown={doTakeDown} />
        ))}
        {/* <SizeImage
          source={{ uri: assetData?.Image }}
          style={{ width: fitSize(57), height: fitSize(57), marginVertical: fitSize(15) }}
          resizeMode={"contain"}
          isPreview
        /> */}
      </ScrollView>
      <View style={{ flexDirection: "row", alignSelf: "flex-end", marginBottom: fitSize(20) }}>
        <Touchable
          onPress={() => {
            navigation.push("TransactionScreenTradeToItemScreen", {
              id: item?.relationOrder?.id,
            })
          }}
          style={{
            width: fitSize(80),
            height: fitSize(32),
            borderRadius: fitSize(16),
            borderWidth: fitSize(1),
            justifyContent: "center",
            alignItems: "center",
            borderColor: color.primary,
            marginRight: fitSize(10),
          }}
        >
          <Text style={{ color: color.primary, fontSize: fitSize(14), fontWeight: "500" }}>
            查看
          </Text>
        </Touchable>
        {/* <Touchable
          onPress={doTakeDown}
          style={{
            width: fitSize(80),
            height: fitSize(32),
            borderRadius: fitSize(16),
            borderWidth: fitSize(1),
            justifyContent: "center",
            alignItems: "center",
            borderColor: color.primary,
            backgroundColor: color.primary,
          }}
        >
          <Text style={{ color: "#fff", fontSize: fitSize(14), fontWeight: "500" }}>撤回</Text>
        </Touchable> */}
      </View>
    </Touchable>
  )
}
const ItemFromUri = ({
  data,
  doTakeDown,
}: {
  data: Order
  doTakeDown: (orderID: string, item: nftItem) => Promise<void>
}) => {
  const [item] = useUriInfo(data?.tokenURI)
  console.log(item?.Image)
  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginRight: fitSize(5),
        marginVertical: fitSize(15),
      }}
    >
      <SizeImage
        source={{ uri: item?.Image }}
        isPreview
        resizeMode={"contain"}
        style={{
          width: fitSize(57),
          height: fitSize(57),
          borderRadius: fitSize(5),
          overflow: "hidden",
          backgroundColor: "#000",
        }}
      />
      <Touchable
        onPress={() => {
          doTakeDown(data?.orderId, item)
        }}
        style={{
          // width: fitSize(80),
          // height: fitSize(32),
          paddingHorizontal: fitSize(10),
          paddingVertical: fitSize(4),
          borderRadius: fitSize(16),
          borderWidth: fitSize(1),
          justifyContent: "center",
          alignItems: "center",
          borderColor: color.primary,
          backgroundColor: color.primary,
          marginTop: fitSize(10),
        }}
      >
        <Text style={{ color: "#fff", fontSize: fitSize(14), fontWeight: "500" }}>撤回</Text>
      </Touchable>
    </View>
  )
}

export const PaticipateList = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
