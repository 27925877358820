import { GToast } from "../navigators"

export const showError = (
  res: any,
  type = "CANCEL" as "CANCEL" | "TOAST" | "OK" | "LOAD",
  time = 2000,
) => {
  requestAnimationFrame(() => {
    GToast.current.showToast(
      `${(res as any)?.msg || (typeof res === "string" ? res : "")}`,
      type,
      time,
    )
  })
}
export const showInfo = (
  res: string,
  type = "TOAST" as "CANCEL" | "TOAST" | "OK" | "LOAD",
  time = 2000,
) => {
  requestAnimationFrame(() => {
    GToast.current.showToast(res, type, time)
  })
}
export const showLoad = (text: string = "请求中", time?: number) => {
  requestAnimationFrame(() => {
    GToast.current.showLoading(text, time)
  })
}
export const hideLoad = () => {
  requestAnimationFrame(() => {
    GToast.current.hideToast()
  })
}
export const manageError = (res: any) => {
  hideLoad()
  showError(res)
}
