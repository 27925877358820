import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const comeInfoModel = types.model("comeInfoStore").props({
  fromUrl: types.maybeNull(types.string),
  appid: types.maybeNull(types.string),
})

type comeInfoStoreType = Instance<typeof comeInfoModel>
export interface comeInfoStore extends comeInfoStoreType {}
type comeInfoStoreSnapshotType = SnapshotOut<typeof comeInfoModel>
export interface comeInfoStoreSnapshot extends comeInfoStoreSnapshotType {}
export const createcomeInfoStoreDefaultModel = () => types.optional(comeInfoModel, {})
