import { Image, ImageSourcePropType, StyleSheet, Text, View } from "react-native"
import React, { ReactElement, ReactNode } from "react"
import { fitSize } from "../../../theme"
import _ from "lodash"

type Props = {
  icon: ImageSourcePropType
  title: string
  info: string
}

interface NullIconProps {
  isIcon: boolean
  icon: ReactNode
}

export const ApplyAssetInfoBar: React.FC<Props> = ({ icon, title, info }): ReactElement => {
  const NullIcon: React.FC<NullIconProps> = ({ isIcon, icon }): ReactElement => {
    return (
      <React.Fragment>
        {!isIcon ? (
          icon
        ) : (
          <View style={styles.nullIcon}>
            <Text style={styles.iconText}>{title}</Text>
          </View>
        )}
      </React.Fragment>
    )
  }
  return (
    <View style={styles.container}>
      <NullIcon
        isIcon={_.isEmpty(icon)}
        icon={<Image source={icon} style={[styles.icon]} resizeMode="cover" />}
      />
      <View style={styles.content}>
        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
          {title}
        </Text>
        <Text numberOfLines={2} ellipsizeMode="tail" style={styles.info}>
          {info}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(100),
    padding: fitSize(10),
    borderRadius: fitSize(10),
    borderWidth: 1,
    borderColor: "#06FDE8",
  },
  icon: {
    height: "90%",
    width: fitSize(90),
    borderRadius: fitSize(10),
  },
  content: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "90%",
    width: "70%",
  },
  title: {
    width: "100%",
    fontSize: fitSize(17),
    color: "#06FDE8",
  },
  info: {
    width: "100%",
    fontSize: fitSize(13),
    color: "#06FDE8",
  },
  nullIcon: {
    height: "90%",
    width: fitSize(90),
    borderRadius: fitSize(10),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#33333322",
  },
  iconText: {
    fontSize: fitSize(20),
    color: "#06FDE8",
  },
})
