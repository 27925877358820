import { ApiResponse } from "apisauce"

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; temporary: true; msg: string }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; temporary: true; msg: string }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: "server"; msg?: string }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized"; msg?: string }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden"; msg?: string }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: "not-found"; msg?: string }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected"; msg?: string }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true; msg?: string }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: "bad-data"; msg?: string }
  | { kind: "error"; msg: string; code?: number; }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: ApiResponse<any>): GeneralApiProblem | void {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true, msg: "网络无法连接" }
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true, msg: "网络错误" }
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true, msg: "连接超时" }
    case "SERVER_ERROR":
      return { kind: "server", msg: "服务器卡壳啦" }
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true, msg: "未知错误" }
    case "CLIENT_ERROR":
      switch (response.status) {
        case 401:
          return { kind: "unauthorized", msg: "未授权" }
        case 402:
          return { kind: "forbidden", msg: "无权限" }
        case 403:
          return { kind: "forbidden", msg: "无权限" }
        case 404:
          return { kind: "not-found", msg: "404 not found" }
        case 405:
          return { kind: "forbidden", msg: "无权限" }
        default:
          return { kind: "rejected", msg: "客户端错误" }
      }
    case "CANCEL_ERROR":
      return null
  }
}
