import { Platform, StyleSheet, View, useWindowDimensions } from "react-native"
import { WebView, WebViewMessageEvent } from "react-native-webview"
import { deviceHeight, deviceWidth, fitSize } from "../../theme"

import Modal from "react-native-modal/dist/modal"
import React from "react"
import { Touchable } from "../../components"
import { WebView as WWebView } from "react-native-web-webview"
import { genereateEnv } from "../../../genereateEnv"

interface SignWebviewModalProps {
  isShow: boolean
  onMessage: (e: WebViewMessageEvent) => void
  onDismiss: () => void
  closeWebView: () => void
  hash: string
}
const MyWebView = Platform.OS === "web" ? WWebView : WebView

export const SignWebviewModal: React.FC<SignWebviewModalProps> = ({
  isShow,
  onDismiss,
  onMessage,
  closeWebView,
  hash,
}) => {
  const windowHeight = useWindowDimensions().height

  const compatibleHeight = Platform.OS === "web" ? windowHeight : deviceHeight

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      style={{ margin: 0, justifyContent: "flex-end" }}
      onBackButtonPress={closeWebView}
      onBackdropPress={closeWebView}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      statusBarTranslucent
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
      onDismiss={onDismiss}
      swipeDirection={"down"}
    >
      <View style={{ height: fitSize(600), width: deviceWidth }}>
        <MyWebView
          id={"massMessage"}
          allowsInlineMediaPlayback
          onMessage={onMessage}
          onShouldStartLoadWithRequest={() => true}
          bounces={false}
          source={{
            uri: `${genereateEnv.OAUTH_URL}/signature?hash=${hash}&appid=${genereateEnv.appid}&redirectUrl=https://jiji.com`,
          }}
        />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({})
