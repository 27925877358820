import React, { DependencyList, useEffect, useRef, useState } from "react"
export function delay(time: number = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

type itemList<T> = {
  //传入上次的锚点
  fetchData: (anchor: number, ...params: any) => Promise<T[]>
  // pageLimit: number
  anchorName: string
  delaTime?: number
}
export type useListReturnType<T> = {
  data: T[]
  setData: React.Dispatch<React.SetStateAction<T[]>>
  bottomRefreshing: boolean
  topRefreshing: boolean
  onRefresh: (param?: any) => void
  onEndReached: () => void
  error: string
  isLoading: boolean
  noMoreData: boolean
  doFirstload: () => void
}
const initAnchor = 0
export function useAutoListMarkId<T>(
  { fetchData, anchorName, delaTime }: itemList<T>,
  deps?: DependencyList,
): [useListReturnType<T>] {
  const [data, setData] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [topRefreshing, setTopRefreshing] = useState(true)
  const [bottomRefreshing, setBottomRefreshing] = useState(false)
  const [noMoreData, setNoMoreData] = useState(false)
  const anchorRef = useRef(initAnchor)
  const [error, setError] = useState("")
  const isMountedRef = useRef(false)
  const bottomRefreshRef = useRef(false)
  const topRefreshingRef = useRef(false)

  const judgeNoMoreData = (newData: T[]) => {
    if (newData) {
      return newData.length === 0
    } else {
      return false
    }
  }

  const setDatas = (mydata: T[]) => {
    if (error) {
      setError("")
    }
    setIsLoading(false)
    setNoMoreData(judgeNoMoreData(mydata))
    setData(mydata)
    setTopRefreshing(false)
    topRefreshingRef.current = false
    setTimeout(() => {
      setBottomRefreshing(false)
      bottomRefreshRef.current = false
    }, 100)
  }

  const loadData = () => {
    if (error) {
      setIsLoading(true)
    }

    fetchData(anchorRef.current)
      .then((item) => {
        if (isMountedRef.current) {
          setDatas(item)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e)
      })
  }
  const onRefresh = (params) => {
    console.log("onRefresh", params)
    anchorRef.current = 0
    setNoMoreData(false)
    setTopRefreshing(true)
    topRefreshingRef.current = true
    loadData()
  }
  const onEndReached = () => {
    console.log(
      "onEndReachedstart",
      data.length === 0,
      topRefreshingRef.current,
      noMoreData,
      bottomRefreshRef.current,
    )
    if (data.length === 0 || topRefreshingRef.current || noMoreData || bottomRefreshRef.current) {
      return
    }
    setBottomRefreshing(true)
    bottomRefreshRef.current = true
    anchorRef.current = data[data.length - 1][anchorName]
    if (error) {
      setIsLoading(true)
    }
    fetchData(anchorRef.current)
      .then((item) => {
        if (isMountedRef.current) {
          if (error) {
            setError("")
          }
          setIsLoading(false)
          setNoMoreData(judgeNoMoreData(item))
          setData([...data, ...item])
          setTimeout(() => {
            setBottomRefreshing(false)
            bottomRefreshRef.current = false
          }, 100)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e)
      })
  }
  const doFirstload = async () => {
    setTopRefreshing(false)
    topRefreshingRef.current = false
    if (delaTime) {
      await delay(delaTime)
    }
    anchorRef.current = 0
    loadData()
  }
  useEffect(() => {
    isMountedRef.current = true
    doFirstload()
    return () => {
      isMountedRef.current = false
    }
  }, deps)

  return [
    {
      data,
      setData,
      bottomRefreshing,
      topRefreshing,
      onRefresh,
      onEndReached,
      error,
      isLoading,
      noMoreData,
      doFirstload,
    },
  ]
}
