import { StyleSheet, View } from "react-native"
import React, { useRef } from "react"
import { ShouldStartLoadRequest } from "react-native-webview/lib/WebViewTypes"
import { InjectJS } from "./Components/JSContent"
import { NoInternet } from "../../components/empty/NoInternet"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { WebView } from "react-native-webview"
import { fitSize } from "../../theme"
import { useState } from "react"

interface WalletWebViewProps extends RootStackNavProps<"WalletWebView"> {}
export const PAY_RES_URL = "http://jijipay.app"
export const LOGIN_URL = "http://jiji.app"
export const WalletWebView: React.FC<WalletWebViewProps> = ({ navigation, route }) => {
  const [title, setTitle] = useState(() => route.params?.title || "开通钱包")
  const [error, setError] = useState("")
  const webviewRef = useRef<WebView>()

  const refresh = () => {
    webviewRef.current?.reload()
  }
  const openExternalLink = (req: ShouldStartLoadRequest) => {
    console.log(req.url)
    if (req.url.startsWith(LOGIN_URL)) {
      const s = new URL(req.url)
      const code = s.searchParams.get("code")
      console.log("code", code)
      navigation.navigate("LoginFromMessage", { code })
      return false
    } else if (req.url.startsWith(PAY_RES_URL)) {
      const s = new URL(req.url)
      const isPayback = s.searchParams.get("isPayback")
      const id = s.searchParams.get("id")
      console.log("id", id, "isPayback", isPayback)
      navigation.navigate("ShowOrder", {
        isPayback: isPayback === "true" ? true : false,
        id: route.params.ext,
      })
      return false
    } else {
      return true
    }
  }

  return (
    <View style={[{ flexGrow: 1 }]}>
      <View style={{ flex: 1 }}>
        <Sheader
          textStyle={{ color: "#06FDE8" }}
          rightImage={""}
          isNotSafe={false}
          title={title}
          leftImage={"black_back"}
          style={{ backgroundColor: "#fff" }}
        />
        <WebView
          ref={webviewRef}
          onError={(err) => setError(err.toString())}
          source={{ uri: route.params.uri }}
          bounces={false}
          onShouldStartLoadWithRequest={(e) => openExternalLink(e)}
          allowsInlineMediaPlayback
          injectedJavaScriptBeforeContentLoaded={InjectJS}
        />
        {!!error && (
          <View style={[StyleSheet.absoluteFill, { backgroundColor: "#fff" }]}>
            <NoInternet
              onPress={() => {
                refresh()
              }}
            />
          </View>
        )}
      </View>
    </View>
  )
}
