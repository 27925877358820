import { StyleSheet, Text, View, Modal, Image } from "react-native"
import React, { ReactElement, ReactNode } from "react"
import { Images, fitSize } from "../theme"
import { Touchable } from "../components"

interface props {
  modalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  children: ReactNode
  title?: string
}

export const TextModal: React.FC<props> = ({
  modalVisible,
  setModalVisible,
  children,
  title = "",
}): ReactElement => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            <Touchable onPress={() => setModalVisible(!modalVisible)}>
              <Image style={styles.clearIcon} source={Images.mine.clear_icon} />
            </Touchable>
          </View>
          {children}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000055",
  },
  modalView: {
    backgroundColor: "white",
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: fitSize(350),
    height: fitSize(500),
  },
  title: {
    fontSize: fitSize(17),
    color: "#010101",
    fontWeight: "bold",
    paddingTop: fitSize(5),
    paddingLeft: fitSize(5),
  },
  clearIcon: {
    width: 25,
    height: 25,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(45),
    borderBottomWidth: 1,
    borderBottomColor: "#01010155",
  },
})
