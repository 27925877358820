import { Image, ImageSourcePropType, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { Images, fitSize } from "../../../theme"
import { Text, Touchable } from "../../../components"

import { GestureResponderEvent } from "react-native"
import React from "react"
import { useStores } from "../../../models"
import { SizeImage } from "../../../components/image-component/SizeImage"

interface NaviItemProps {
  isBottom?: boolean
  onPress?: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
  top?: number
  leftTitle: string
  imageSource?: ImageSourcePropType
  hintText?: string
  notShowArrow?: boolean
  bgColor?: string
  height?: number
}

export const NaviItem: React.FC<NaviItemProps> = ({
  isBottom = false,
  onPress,
  style,
  top = 0,
  leftTitle,
  hintText,
  imageSource,
  notShowArrow = false,
  bgColor = "#fff",
  height = fitSize(56),
}) => {
  const { accountStore } = useStores()
  return (
    <Touchable
      style={[
        styles.container,
        // { borderBottomWidth: isBottom ? 0 : StyleSheet.hairlineWidth },
        { height: height },
        { marginTop: top },
        { backgroundColor: bgColor },
        style,
      ]}
      onPress={onPress}
    >
      <Text style={styles.leftTitle}>{leftTitle}</Text>
      <View style={styles.horView}>
        {!!imageSource && (
          <SizeImage
            //@ts-ignore
            source={imageSource}
            style={styles.imageStyle}
          />
        )}
        {!!hintText && <Text style={styles.hintText}>{hintText}</Text>}
        {!notShowArrow && <Image source={Images.setting.back} style={styles.backImage} />}
      </View>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: fitSize(56),
    paddingHorizontal: fitSize(16),
    backgroundColor: "#202530",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // borderBottomWidth: StyleSheet.hairlineWidth,
    // borderBottomColor: "#06FDE8",
  },
  leftTitle: {
    fontSize: fitSize(15),
    color: "#333333",
  },
  backImage: {
    width: fitSize(12),
    height: fitSize(12),
    tintColor: "#00AFB5",
  },
  horView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  hintText: {
    color: "#999",
    fontSize: fitSize(14),
    marginRight: fitSize(6),
  },
  imageStyle: {
    width: fitSize(60),
    height: fitSize(60),
    resizeMode: "contain",
    marginRight: fitSize(12),
    overflow: "hidden",
    borderRadius: fitSize(33),
  },
})
