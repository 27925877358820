import { StyleSheet, View } from "react-native"
import { Text, Touchable, TouchableDeep } from "../../components"
import { color, deviceHeight, fitSize } from "../../theme"

import Modal from "react-native-modal"
import React from "react"
import { RootNavigation } from "../../navigators"
import { alertText } from "../GlobalModal"
import { hideLoad } from "../../utils/showUtil"

interface PlatteWhiteAlertProps {
  isShow: boolean
  closeModal: () => void
}

export const ChooseOrderModal: React.FC<PlatteWhiteAlertProps> = ({ closeModal, isShow }) => {
  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{ margin: 0 }}
      onBackButtonPress={() => {
        closeModal()
      }}
      onBackdropPress={() => {
        closeModal()
      }}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      deviceHeight={deviceHeight}
      hideModalContentWhileAnimating={true}
    >
      <View style={styles.container}>
        <Touchable
          onPress={() => {
            //@ts-ignore
            RootNavigation.navigate("ChooseSeries", { action: "homeSell" })
            closeModal()
          }}
          style={{
            width: fitSize(245),
            height: fitSize(109),
            backgroundColor: color.primary,
            borderRadius: fitSize(10),
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: fitSize(13), color: "#fff" }}>发布寄售</Text>
        </Touchable>
        <Touchable
          onPress={() => {
            //@ts-ignore
            RootNavigation.navigate("TradeToItemAddPage", { isOffer: false })
            closeModal()
          }}
          style={{
            width: fitSize(245),
            height: fitSize(109),
            backgroundColor: color.primary,
            borderRadius: fitSize(10),
            justifyContent: "center",
            alignItems: "center",
            marginTop: fitSize(15),
          }}
        >
          <Text style={{ fontSize: fitSize(13), color: "#fff" }}>发布以物换物</Text>
        </Touchable>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    alignItems: "center",
    padding: fitSize(20),
    backgroundColor: "#fff",
    borderRadius: fitSize(20),
  },
})
