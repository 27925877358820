import {
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"
import { HookFlatList, Screen, Touchable } from "../../components"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { AssetItem } from "./Components/AssetItem"
import { RootStackNavProps } from "../../type/RouteType"
import { SelectModal } from "./Components/SelectModal"
import SelectText from "./Components/SelectText"
import { Sheader } from "../../components/header/Sheader"
import _ from "lodash"
import { collectBriefItemStore } from "../../models/collects/collectBriefItem"
import { fitSize } from "../../theme"
import { useAutoList } from "../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../models"

interface TabAssetProps extends RootStackNavProps<"AssetScreen"> {}

export type modalType = "game" | "series" | "time"
const PL = 10
export const AssetScreen: React.FC<TabAssetProps> = ({ navigation, route }) => {
  const { accountStore, collectStore } = useStores()
  const { height } = useWindowDimensions()
  const [selectGameValue, setSelectGameValue] = useState("")
  const [selectTypeValue, setSelectTypeValue] = useState("")
  const [selectTimeValue, setSelectTimeValue] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [sheetTitle, setSheetTitle] = useState("")
  const [modalType, setModalType] = useState<modalType>()
  const [selectGame, setSelectGame] = useState("")
  const [selectSeries, setSelectSeries] = useState("")
  const [seriesList, setSeriesList] = useState([])
  const [nextData, setNextData] = useState([])
  const [data, setData] = useState([])
  const [assetList, setAssetList] = useState([])
  const page = useRef(1)
  const [totalNumber, setTotalNumber] = useState()

  const onNavigation = (item) => {
    // console.log("onNavigation", data)
    // navigation.push("TransactionInfo", {
    //   id: Number(item.NftId),
    //   address: item.contractAddress,
    //   contentId: item.id,
    //   nftItem:
    // })
  }

  const onModalVisible = (text: string, type: modalType) => {
    setSheetTitle(text)
    setModalVisible(true)
    setModalType(type)
  }

  const onSelectTime = (text: string, type: modalType) => {
    setSheetTitle(text)
    setSelectTimeValue((v) => !v)
    setModalType(type)
  }

  const fetchAssetList = async (page: number, pageLimit = PL) => {
    const res = await accountStore.environment.api.normalRequest(
      `/card/getAppInfoByPage/${page}/${PL}`,
    )
    if (res.kind === "ok") {
      return res?.data?.list
    } else if (res.kind === "forbidden") {
      navigation.replace("LoginFromMessage")
    } else {
      return []
    }
  }

  const [gameList] = useAutoList(
    {
      fetchData: fetchAssetList,
      pageLimit: PL,
    },
    [],
  )

  const onSetGame = (item) => {
    setSelectGameValue(item?.appName)
    setSelectGame(item?.appId)
    setData([])
    setNextData([])
    setSelectTypeValue("")
    setSelectSeries("")
    setSeriesList([])
    page.current = 1
    getApplyInfo(item?.appId)
  }

  const onSeries = (item) => {
    setSelectTypeValue(item?.contractName)
    setSelectSeries(item?.contractAddress)
  }

  const getApplyInfo = async (value: string) => {
    const res = await accountStore.environment.api.normalRequest(`/card/getAppDetail/${value}`)
    if (res.kind === "ok") {
      setSeriesList(res.data.contractList)
      onModalVisible("选择系列", "series")
    } else if (res.kind === "forbidden") {
      navigation.replace("LoginFromMessage")
    } else {
      throw res?.msg
    }
  }

  const fetchUserAssetBySeriesId = async (pageLimit = PL) => {
    const res = await accountStore.environment.api.normalRequest(
      `/card/getNftListByPage/${selectSeries}/${page.current}/${PL}`,
    )
    if (res.kind === "ok") {
      setNextData(res?.data?.list)
      setAssetList(res?.data?.list)
      setTotalNumber(res?.data.totalNumber)
      setModalVisible(false)
    }
  }

  const onAssetDetails = (id: string, address: string, tokenURL: string) => {
    navigation.push("AssetDetails", { address: address, id: id })
  }

  useEffect(() => {
    if (nextData.length > 0) {
      nextData.forEach(async (element: { nftId: string; tokenURI: string }) => {
        const content = await fetch(element.tokenURI).then((r) => r.json())
        setData((data) => [...data, content.data])
      })
    } else {
      setNextData([])
    }
  }, [assetList])

  useEffect(() => {
    if (selectGame && selectSeries) {
      fetchUserAssetBySeriesId()
    }
  }, [selectGame, selectSeries])

  useFocusEffect(
    useCallback(() => {
      if (!data.length) {
        onModalVisible("选择游戏", "game")
      }
    }, []),
  )

  return (
    <View
      testID="AssetScreen"
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? height : undefined,
      }}
    >
      <Screen statusBar={"dark-content"} preset="fixed" backgroundColor={"#202530"}>
        <Sheader
          rightImage={""}
          title={"选择资产"}
          // textStyle={{ color: "#06FDE8" }}
          leftImage={""}
          style={{ backgroundColor: "#202530" }}
        />
        <View style={styles.filtrateBar}>
          <SelectText
            select={selectGameValue}
            onSelect={onModalVisible}
            selectState={modalVisible}
            sheetTitle="选择游戏"
            defaultValue={"全部游戏"}
            type="game"
          />
          <SelectText
            select={selectTypeValue}
            onSelect={onModalVisible}
            selectState={modalVisible}
            sheetTitle="选择系列"
            defaultValue={"系列名称"}
            type="series"
          />
          <SelectText
            select={""}
            onSelect={onSelectTime}
            selectState={selectTimeValue}
            sheetTitle="选择时间"
            defaultValue={"获得时间"}
            type="time"
          />
        </View>
        <FlatList
          data={data}
          // notShowEmpty={true}
          numColumns={3}
          horizontal={false}
          showsVerticalScrollIndicator={false}
          onEndReached={() => {
            if (totalNumber !== data.length) {
              page.current = page.current + 1
              fetchUserAssetBySeriesId()
            }
          }}
          renderItem={({ item, index }) => {
            return (
              <AssetItem
                assetImage={{ uri: item?.Image }}
                assetName={item?.Name}
                assetId={item?.NftId}
                tokenURI={item?.tokenURI}
                item={item}
                key={index}
                onNavigation={onNavigation}
              />
            )
          }}
        />

        <SelectModal
          modalVisible={modalVisible}
          setModalVisible={() => setModalVisible(false)}
          sheetTitle={sheetTitle}
        >
          {modalType === "game" ? (
            <HookFlatList
              {...gameList}
              data={gameList.data}
              notShowEmpty={true}
              contentContainerStyle={styles.contentContainerStyle}
              numColumns={1}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }: any) => {
                return (
                  <Touchable
                    style={
                      selectGame === item?.appId
                        ? styles.sheetSelectItem
                        : styles.sheetNotSelectItem
                    }
                    key={index}
                    onPress={() => onSetGame(item)}
                  >
                    <Text style={styles.sheetSelectItemText}>{item?.appName}</Text>
                  </Touchable>
                )
              }}
            />
          ) : (
            <ScrollView style={styles.sheetList} showsVerticalScrollIndicator={false}>
              {seriesList?.map((item, index) => {
                return (
                  <Touchable
                    style={selectSeries ? styles.sheetSelectItem : styles.sheetNotSelectItem}
                    key={index}
                    onPress={() => onSeries(item)}
                  >
                    <Text
                      style={
                        selectSeries ? styles.sheetSelectItemText : styles.sheetNotSelectItemText
                      }
                    >
                      {item?.contractName}
                    </Text>
                  </Touchable>
                )
              })}
            </ScrollView>
          )}
        </SelectModal>
      </Screen>
    </View>
  )
}

const styles = StyleSheet.create({
  sheetList: {
    width: "100%",
    height: fitSize(260),
    marginTop: fitSize(10),
  },
  filtrateBar: {
    width: "100%",
    padding: fitSize(7),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: fitSize(6),
  },
  content: {
    paddingLeft: fitSize(10),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    height: fitSize(32),
    width: fitSize(345),
    borderRadius: 18,
    backgroundColor: "#2F3036",
    fontSize: fitSize(14),
    color: "#ffffff",
    padding: 0,
    paddingLeft: fitSize(25),
  },
  searchIcon: {
    width: fitSize(18),
    height: fitSize(18),
    position: "absolute",
    bottom: fitSize(8),
    left: fitSize(25),
    tintColor: "#D9D9D9",
  },
  clearIocnBox: {
    position: "absolute",
    right: fitSize(15),
  },
  clearIcon: {
    width: fitSize(30),
    height: fitSize(30),
    tintColor: "#D9D9D9",
  },
  emptyStype: {
    position: "relative",
    bottom: fitSize(150),
    top: undefined,
    flex: undefined,
  },
  contentContainerStyle: {
    paddingBottom: fitSize(20),
  },
  listWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  sheetNotSelectItem: {
    width: "100%",
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(5),
  },
  sheetSelectItemText: {
    fontSize: fitSize(16),
    fontWeight: "400",
    lineHeight: fitSize(22),
    color: "#06FDE8",
  },
  sheetNotSelectItemText: {
    fontSize: fitSize(16),
    fontWeight: "400",
    lineHeight: fitSize(22),
    color: "#06FDE899",
  },
  sheetSelectItem: {
    width: "100%",
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(5),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#696969",
  },
})
