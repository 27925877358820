import { StyleSheet, View } from "react-native"

import { HookFlatList } from "../../../components"
import { MarketItem } from "./MarketItem"
import React from "react"
import { TradeForItemItem } from "./TradeForItemItem"
import { fitSize } from "../../../theme"
import { showError } from "../../../utils/showUtil"
import { useAutoList } from "../../../hooks/useAutoList"
import { useIsFocused } from "@react-navigation/native"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../../models"

interface MarketListProps {
  searchText?: string
  orderPrice?: number
  orderTime?: number
  onChangeTotal?: (data: number) => void
  minPrice?: string
  maxPrice?: string
  isParity?: boolean
}
const PL = 12
export type marketItem = {
  id: number
  goodsName: string
  price: number
  tokenURI: string
  tagType?: number
}
const getOrderText = (order: number) => {
  switch (order) {
    case 1:
      return "ASC"
    case 2:
      return "DESC"
    default:
      return undefined
  }
}
export const TradeForItemList: React.FC<MarketListProps> = ({
  searchText,
  orderPrice,
  orderTime,
  onChangeTotal,
  maxPrice,
  minPrice,
  isParity,
}) => {
  const { accountStore } = useStores()
  const navigation = useNavigation() as any
  const isFocused = useIsFocused()

  const fetchData = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getTradeMarketOrder",
      {
        page,
        pageSize: PL,
        goodsName: searchText || undefined,
        order:
          orderPrice || orderTime
            ? {
                price: getOrderText(orderPrice),
                createdAt: getOrderText(orderTime),
              }
            : undefined,
        minPrice: Number(minPrice) || undefined,
        maxPrice: Number(maxPrice) || undefined,
        isParity: isParity === true ? true : undefined,
        orderType: 1,
        // seriesId: 10001,
      },
      "post",
    )
    if (res.kind === "ok") {
      if (res.data?.totalCount > 0) {
        if (onChangeTotal) onChangeTotal(res.data?.totalCount)
      }
      return res.data?.data
    } else if (res.kind === "forbidden" || res.kind === "unauthorized") {
      navigation.replace("LoginFromMessage")
    } else {
      showError(res)
    }
  }

  const [list] = useAutoList<marketItem>(
    {
      fetchData: fetchData,
      pageLimit: 12,
    },
    [isFocused, orderPrice, orderTime, maxPrice, minPrice, isParity],
  )

  return (
    <View style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...list }}
        contentContainerStyle={{ paddingLeft: fitSize(7) }}
        renderItem={({ item, index }) => {
          return <TradeForItemItem item={item} />
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
