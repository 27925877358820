import { Images, fitSize } from "../../theme"
import { Platform, StyleSheet, View } from "react-native"
import React, { useEffect } from "react"
import { Text, Touchable } from "../../components"

import { FlexView } from "../../components/screen/FlexView"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { WalletItem } from "./components/WalletItem"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { useTokenApi } from "../../hooks/useTokenApi"

interface MywalletProps {}
export interface walletStatus {
  id: number
  name: string
  type: string
  payWay: number
  platform: string
  icon: string
  isOpen: boolean
}
export const Mywallet: React.FC<MywalletProps> = observer(({}) => {
  const { accountStore } = useStores()
  return (
    <FlexView>
      <Sheader isNotSafe rightImage="" title={"选择钱包"}></Sheader>
      {Array.from(accountStore.walletStatus.values())?.map((e, i) => {
        return (
          <WalletItem
            key={i}
            isOpen={e.isOpen}
            walletName={e.name}
            walletIcon={e.icon}
            wallet={e}
          ></WalletItem>
        )
      })}
    </FlexView>
  )
})

const styles = StyleSheet.create({})
