import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, ScrollView, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"
import { showError, showInfo } from "../../../utils/showUtil"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
  orderStatus: number
}
export interface saleItem {
  order: Order[]
  relationOrder?: Order
  exchangeTime: number
}

interface Order {
  id: number
  orderType: number
  goodsName: string
  tokenURI: string
  sellerName: string
  sellerUserId: number
  barterRequestMsg: string
  createdAt: number
  orderId: string
}
export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router, orderStatus }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserExchangeOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 4,
        orderType: 1,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return (
            <SaleItem
              refresh={() => {
                fetchList.doFirstload()
              }}
              item={item}
              orderStatus={orderStatus}
            />
          )
        }}
      />
    </View>
  )
}

const SaleItem = ({
  item,
  refresh,
  orderStatus,
}: {
  item: saleItem
  refresh: () => void
  orderStatus: number
}) => {
  const [assetData] = useUriInfo(item?.order?.[0]?.tokenURI, [item])
  const navigation = useTypeNavigation()
  const { collectStore } = useStores()

  return (
    <Touchable
      style={{
        width: fitSize(351),
        height: fitSize(144),
        alignSelf: "center",
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
        // flexDirection: "row",
        // justifyContent: "space-between",
        paddingHorizontal: fitSize(15),
        paddingTop: fitSize(12),
      }}
    >
      <Text style={{ fontSize: fitSize(14), color: color.primary, fontWeight: "500" }}>
        {item?.order?.[0]?.sellerName}的以物换物
      </Text>
      <Text style={{ fontSize: fitSize(12), color: color.primary, marginTop: fitSize(2) }}>
        发布时间{dayjs(item?.order?.[0]?.createdAt * 1000).format("YYYY/MM/DD HH:mm:ss")}
      </Text>

      <Text
        style={{
          fontSize: fitSize(13),
          color: orderStatus === 2 ? "#FF2E11" : color.dim,
          position: "absolute",
          top: fitSize(13),
          right: fitSize(15),
        }}
      >
        {orderStatus === 2 ? "已撤回" : "已下架"}
      </Text>
      <ScrollView horizontal>
        {item?.order?.map((e, i) => {
          return <ItemFromUri data={e} key={e.orderId} />
        })}
      </ScrollView>
    </Touchable>
  )
}
const ItemFromUri = ({ data }: { data: Order }) => {
  const [item] = useUriInfo(data?.tokenURI)
  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginRight: fitSize(5),
        marginVertical: fitSize(15),
      }}
    >
      <SizeImage
        source={{ uri: item?.Image }}
        isPreview
        resizeMode={"contain"}
        style={{
          width: fitSize(57),
          height: fitSize(57),
          borderRadius: fitSize(5),
          overflow: "hidden",
          backgroundColor: "#000",
        }}
      />
    </View>
  )
}

export const TakeDownListR = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
