import { Alert, Image, Linking, Platform, StyleSheet, View } from "react-native"
import { FULL, Images, fitSize } from "../../theme"
import React, { useState } from "react"

import { AppInfo } from "../../config/AppInfo"
import { GToast } from "../../navigators"
import { NaviItem } from "./components/NaviItem"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { Text } from "../../components"
import { showError } from "../../utils/showUtil"
import { translate } from "../../i18n"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"

// import _updateConfig from "../../../update.json"

interface PasswordManageProps extends RootStackNavProps<"About"> {}
// const { appKey } = _updateConfig[Platform.OS]

export const About: React.FC<PasswordManageProps> = ({ navigation }) => {
  return (
    <View style={FULL}>
      <Sheader rightImage={""} title={"关于"} leftImage={"black_back"} />
      <View style={{ backgroundColor: "#F5F5F5", flex: 1 }}>
        {/* <Image
         source={Images.login.logo}
          style={{
            width: fitSize(60),
            height: fitSize(60),
            alignSelf: "center",
            marginTop: fitSize(47),
          }}
        /> */}
        <Text
          style={{
            fontSize: fitSize(20),
            fontWeight: "bold",
            color: "#333",
            marginTop: fitSize(10),
            alignSelf: "center",
          }}
        >
          {AppInfo.name}
        </Text>
        <Text
          onPress={() => {
            // Pushy.buildTime
            // checkVersionFromServer()
          }}
          style={{ color: "#333", fontSize: fitSize(16), alignSelf: "center" }}
        >
          Version {AppInfo.verson}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})
