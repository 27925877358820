import { Images, fitSize } from "../../../theme"
import { Platform, StyleSheet, View } from "react-native"
import { Text, Touchable } from "../../../components"
import { hideLoad, showError, showLoad } from "../../../utils/showUtil"

import { FlexView } from "../../../components/screen/FlexView"
import { GToast } from "../../../navigators"
import { MyErrorCode } from "../../../utils/errorCode"
import React from "react"
import { Sheader } from "../../../components/header/Sheader"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { is } from "ramda"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { walletStatus } from "../Mywallet"

interface WalletItemProps {
  isOpen?: boolean
  canWithdraw?: boolean
  walletIcon?: string
  walletName?: string
  text1?: string
  text2?: string
  wallet: walletStatus
}

export const WalletItem: React.FC<WalletItemProps> = ({
  isOpen = false,
  canWithdraw = false,
  walletIcon = Images.test.wallet_icon,
  walletName = "汇付链宝",
  text1 = "余额：",
  text2 = "¥1000",
  wallet,
  ...props
}) => {
  const { accountStore } = useStores()
  const navigation = useTypeNavigation()
  const pushWalletScreen = async () => {
    showLoad()
    // 是否开户
    if (isOpen) {
      const createAdapayWallet = await accountStore.environment.api.normalRequest(
        "user/walletManagement",
        {
          returnUrl: Platform.OS === "web" ? `${window.location.origin}/Mywallet` : undefined,
          payWay: wallet?.payWay,
        },
        "post",
      )
      hideLoad()
      if (createAdapayWallet.kind === "ok") {
        if (Platform.OS === "web") {
          localStorage.setItem("tempLeave", "leave")
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          navigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        showError(createAdapayWallet)
      }
    } else {
      showLoad("正在创建支付钱包")
      const createAdapayWallet = await accountStore.environment.api.normalRequest(
        "user/createWallet",
        {
          returnUrl: Platform.OS === "web" ? `${window.location.origin}/Mywallet` : undefined,
          payWay: wallet.payWay,
        },
        "post",
      )
      if (createAdapayWallet.kind === "ok") {
        hideLoad()
        if (Platform.OS === "web") {
          localStorage.setItem("tempLeave", "leave")
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          navigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        showError(createAdapayWallet)
      }
    }
  }

  return (
    <Touchable onPress={pushWalletScreen} style={styles.container}>
      <SizeImage
        style={{ width: fitSize(40), height: fitSize(40) }}
        source={walletIcon}
      ></SizeImage>
      <View style={{ marginLeft: fitSize(11.5) }}>
        <Text
          style={{
            fontSize: fitSize(16),
            fontWeight: "600",
            color: isOpen ? "#00AFB5" : "#888888",
          }}
        >
          {walletName}
        </Text>
        <View style={{ flexDirection: "row", marginTop: fitSize(5) }}>
          <Text style={{ fontSize: fitSize(12), color: "#888888" }}>{}</Text>
          <Text style={{ fontSize: fitSize(12), color: "#888888", marginLeft: fitSize(5) }}>
            {}
          </Text>
        </View>
      </View>
      {isOpen ? (
        <View style={{ flexDirection: "row", marginLeft: "auto" }}>
          <View style={[styles.button, { backgroundColor: canWithdraw ? "#00AFB5" : "#C4C4C4" }]}>
            <Text style={{ color: "#fff", fontSize: fitSize(13) }}>提现</Text>
          </View>
          <View style={styles.button}>
            <Text style={{ color: "#fff", fontSize: fitSize(13) }}>充值</Text>
          </View>
        </View>
      ) : (
        <View style={{ flexDirection: "row", marginLeft: "auto" }}>
          <View style={styles.button}>
            <Text style={{ color: "#fff", fontSize: fitSize(13) }}>去开通</Text>
          </View>
        </View>
      )}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    width: fitSize(351),
    height: fitSize(80),
    flexDirection: "row",
    backgroundColor: "#fff",
    margin: fitSize(12),
    borderRadius: fitSize(10),
    alignItems: "center",
    paddingHorizontal: fitSize(15),
    paddingVertical: fitSize(20),
  },
  button: {
    backgroundColor: "#00AFB5",
    borderRadius: fitSize(5),
    width: fitSize(60),
    height: fitSize(26),
    alignItems: "center",
    justifyContent: "center",
    marginLeft: fitSize(10),
  },
})
