import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  TextInput,
  View,
} from "react-native"
import { HookFlatList, Text, Touchable } from "../../components"
import { Images, color, deviceHeight, deviceWidth, fitSize } from "../../theme"
import { MarketList, marketItem } from "../TabHome/Components/MarketList"
import React, { useEffect, useMemo, useState } from "react"
import { hideLoad, showError, showLoad } from "../../utils/showUtil"
import { price, time } from "../TabHome/TabHome"

import { DismissKeyboardView } from "../../components/screen/DismissKeyboardView"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import Modal from "react-native-modal/dist/modal"
import { MyErrorCode } from "../../utils/errorCode"
import { RootStackNavProps } from "../../type/RouteType"
import { SizeImage } from "../../components/image-component/SizeImage"
import { delay } from "../../utils/delay"
import { infoType } from "../Transaction/TransactionScreen"
import { translate } from "../../i18n"
import { useAutoList } from "../../hooks/useAutoList"
import { useAutoListMarkId } from "../../hooks/useAutoListMarkId"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useTouchLoop } from "../TabHome/Hooks/useTouchLoop"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { withDecay } from "react-native-reanimated"

interface MarketResultListProps extends RootStackNavProps<"MarketSearchResultList"> {}
export const MarketSearchResultList: React.FC<MarketResultListProps> = ({ navigation, route }) => {
  const { top, bottom } = useSafeAreaInsets()
  const { goBack } = useTypeNavigation()
  const { accountStore } = useStores()
  const [total, setTotal] = useState(0)
  const {
    touchPrice,
    touchTime,
    sortMoney,
    sortTime,
    isOnlyTicket,
    setIsOnlyTicket,
  } = useTouchLoop()
  const [modalShow, setModalShow] = useState(false)
  const [minPrice, setMinPrice] = useState("")
  const [maxPrice, setMaxPrice] = useState("")
  const [isShowParity, setIsShowParity] = useState(false)
  const onChangeTotal = (total: number) => {
    setTotal(total)
  }
  const [inPutErr, setInputError] = useState("")
  const [prices, setPrices] = useState({ minPrice: undefined, maxPrice: undefined })
  const checkPrice = () => {
    const min = Number(minPrice)
    const max = Number(maxPrice)
    if (Number.isNaN(min) || Number.isNaN(max)) {
      setInputError("* 需要输入数字")
      return false
    } else if (min > max) {
      setInputError("* 最高价不得低于最低价")

      return false
    } else {
      setInputError("")
      return true
    }
  }
  const setPrice = () => {
    if (checkPrice()) {
      setPrices({ minPrice: Number(minPrice) * 100, maxPrice: Number(maxPrice) * 100 })
      setModalShow(false)
    }
  }
  const reSetPrice = () => {
    setMaxPrice("")
    setMinPrice("")
    setPrices({ minPrice: undefined, maxPrice: undefined })
    setModalShow(false)
  }
  const [showBatch, setShwBatch] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [batchItems, setBatchItems] = useState<marketItem[]>([])
  const userInfo = accountStore?.user
  const doBatchPay = async () => {
    if (!accountStore.token) {
      GToast.current.doLogin()
      return
    }
    if (!userInfo.isOpenSandPay) {
      GToast.current.showLoading("正在创建支付钱包")
      const walletRes = await accountStore.environment.api.normalRequest(
        "/user/getUseWalletStatus",
        { platform: Platform.OS },
        "post",
      )
      if (walletRes.kind !== "ok") {
        showError(walletRes)
        return
      }

      const createAdapayWallet = await accountStore.environment.api.normalRequest(
        "user/createWallet",
        {
          returnUrl:
            Platform.OS === "web" ? `${window.location.origin}/Mine?isWallet=true` : undefined,
          payWay: walletRes?.data?.list?.[0]?.payWay || 2,
        },
        "post",
      )
      if (createAdapayWallet.kind === "ok") {
        hideLoad()
        if (Platform.OS === "web") {
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          navigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        if (createAdapayWallet.kind === "error") {
          if (createAdapayWallet.code === MyErrorCode.ThirdPartyServiceError) {
            GToast.current.showToast("钱包服务错误", "CANCEL", 1500)
            return
          }
        }
        GToast.current.showToast("创建钱包失败", "TOAST", 2000)
      }
    }
    showLoad()
    const promises = batchItems.map((e) => doSingleBuy(e))
    try {
      await Promise.all(promises)
      hideLoad()
      navigation.push("OrderBuy")
    } catch (err) {
      showError(err)
    }
  }

  const doSingleBuy = async (item: marketItem) => {
    const infoRes = await accountStore.environment.api.normalRequest(
      "/market/getTradeMarketorderDetail",
      {
        id: Number(item.id),
        platform: Platform.OS,
      },
      "post",
    )
    if (infoRes.kind !== "ok") {
      throw infoRes.msg
    }
    const info = infoRes.data as infoType
    if (userInfo.id === info.sellerUserId) {
      throw "不可购买自己的在售商品"
    }
    // if (userInfo.adaPayUserCustId) { // adapay 是否开户
    // 甘文交 是否开户
    const res = await accountStore.environment.api.createTradeOrder(info?.orderId, info.updatedAt)
    if (res.kind === "ok") {
      return res.data.tradeOrderId
      // navigation.push("ShowOrder", { id: res.data.tradeOrderId })
    } else if (res.kind === "forbidden") {
      navigation.replace("LoginFromMessage")
      throw "forbidden"
    } else if (res.kind === "error") {
      if (res.code === MyErrorCode.MarketOrderBuyerIsSellerError) {
        throw "不可购买自己的在售商品"
      } else if (res.code === MyErrorCode.UserNotAdapayWallet) {
        throw "还未创建支付钱包"
      } else if (res.code === MyErrorCode.MarketOrderInfoExpired) {
        throw "订单信息已更新,请重新刷新页面"
      } else if (res.code === MyErrorCode.MarketOrderStatusError) {
        throw "订单状态异常,请重新刷新页面"
      } else if (res.code === MyErrorCode.ChainErrorUserNotOwn) {
        throw "该资产已经上架或已售出,请重新刷新页面"
      } else {
        throw res.msg
      }
    }
  }

  return (
    <FlexView>
      <View style={styles.searchWarp}>
        <Touchable
          onPress={() => goBack()}
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: fitSize(32),
            height: fitSize(32),
            marginLeft: fitSize(8),
          }}
        >
          <Image
            source={Images.common.black_back}
            style={{ width: fitSize(32), height: fitSize(32) }}
          />
        </Touchable>
        <Touchable
          onPress={() => goBack()}
          style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
        >
          <View style={styles.inputWrap}>
            <View
              style={{
                borderRadius: fitSize(12),
                borderWidth: fitSize(1),
                borderColor: color.primary,
                flexDirection: "row",
                paddingLeft: fitSize(10),
                paddingRight: fitSize(5),
                alignItems: "center",
                height: fitSize(24),
              }}
            >
              <Text style={{ fontSize: fitSize(14), color: "#333" }}>
                {route.params.searchWord || route.params?.seriesName}
              </Text>
              <SizeImage
                source={Images.home.delete}
                style={{ width: fitSize(14), height: fitSize(14), marginLeft: fitSize(5) }}
              />
            </View>
            {total > 0 && (
              <Text
                style={{
                  fontSize: fitSize(12),
                  color: color.text,
                  marginLeft: "auto",
                  marginRight: fitSize(12),
                }}
              >
                共{total}个搜索结果
              </Text>
            )}
          </View>
          <Image
            source={Images.home.search_gray}
            style={{
              width: fitSize(18),
              height: fitSize(18),
              position: "absolute",
              left: fitSize(12),
            }}
          />
        </Touchable>
      </View>
      <View
        style={{
          marginTop: fitSize(5),
          marginLeft: fitSize(12),
          flexDirection: "row",
          marginBottom: fitSize(10),
        }}
      >
        <Touchable
          onPress={touchTime}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortTime === 0 ? color?.text : color?.primary,
            }}
          >
            {time[sortTime]}
          </Text>
          <SizeImage
            source={
              sortTime === 0
                ? Images.home.sort_de
                : sortTime === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable>
        <Touchable
          onPress={touchPrice}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: sortMoney === 0 ? color?.text : color?.primary,
            }}
          >
            {price[sortMoney]}
          </Text>
          <SizeImage
            source={
              sortMoney === 0
                ? Images.home.sort_de
                : sortMoney === 1
                ? Images.home.sort_top
                : Images.home.sort_down
            }
            style={{ width: fitSize(9), height: fitSize(10) }}
          />
        </Touchable>
        <Touchable
          onPress={() => {
            setIsOnlyTicket((c) => !c)
          }}
          style={{
            width: fitSize(88),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: "#EDEDED",
            marginRight: fitSize(5),
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(13),
              color: !isOnlyTicket ? color?.text : color?.primary,
            }}
          >
            只看数玩券
          </Text>
        </Touchable>

        <Touchable
          onPress={() => {
            setShwBatch((c) => !c)
          }}
          style={{
            width: fitSize(70),
            height: fitSize(30),
            borderRadius: fitSize(18),
            backgroundColor: showBatch ? "#fff" : color.primary,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            borderColor: color.primary,
            borderWidth: fitSize(1),
          }}
        >
          <Text style={{ fontSize: fitSize(13), color: showBatch ? color.primary : "#fff" }}>
            {showBatch ? "取消" : "批量购买"}
          </Text>
        </Touchable>
        <Touchable
          onPress={() => {
            setModalShow(true)
          }}
          style={{ marginLeft: fitSize(10) }}
        >
          <SizeImage
            source={Images.home.filter}
            style={{ width: fitSize(22), height: fitSize(22), marginRight: fitSize(12) }}
          />
        </Touchable>
      </View>

      <View style={styles.content}>
        <MarketList
          onBachTotalPriceChange={(e, items) => {
            setTotalPrice(e)
            setBatchItems(items)
          }}
          onChangeTotal={onChangeTotal}
          orderPrice={sortMoney}
          orderTime={sortTime}
          isOnlyTicket={isOnlyTicket}
          seriesId={route?.params?.seriesId}
          searchText={route?.params?.skuInfo ? "" : route?.params?.searchWord}
          maxPrice={prices?.maxPrice}
          minPrice={prices?.minPrice}
          isParity={isShowParity}
          skuInfo={route?.params?.skuInfo}
          showBatchChoose={showBatch}
        />
      </View>
      {showBatch && (
        <View
          style={{
            position: "absolute",
            width: fitSize(375),
            height: fitSize(50) + bottom,
            flexDirection: "row",
            backgroundColor: "#fff",
            alignItems: "center",
            bottom: 0,
            paddingHorizontal: fitSize(10),
          }}
        >
          <Text style={{ fontSize: fitSize(13), color: color.price, fontWeight: "bold" }}>
            共计:
          </Text>
          <Text style={{ fontSize: fitSize(16), color: color.price }}>
            {Math.floor((totalPrice / 100) * 100) / 100}
          </Text>
          <Touchable
            onPress={() => {
              doBatchPay()
            }}
            style={{
              width: fitSize(70),
              height: fitSize(30),
              borderRadius: fitSize(18),
              backgroundColor: color.primary,
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <Text style={{ fontSize: fitSize(13), color: "#fff" }}>生成订单</Text>
          </Touchable>
        </View>
      )}
      <Modal
        useNativeDriverForBackdrop
        hasBackdrop={true}
        coverScreen={false}
        isVisible={modalShow}
        animationIn="slideInRight"
        animationOut="slideOutRight"
        style={{ margin: 0 }}
        onBackButtonPress={() => {
          setModalShow(false)
        }}
        onBackdropPress={() => {
          setModalShow(false)
        }}
        backdropTransitionOutTiming={0}
        useNativeDriver
        deviceHeight={deviceHeight}
        hideModalContentWhileAnimating={true}
      >
        <View
          style={{
            width: fitSize(240),
            height: "100%",
            backgroundColor: color.secBackground,
            alignSelf: "flex-end",
          }}
        >
          <Text
            style={{
              fontSize: fitSize(17),
              fontWeight: "500",
              color: color?.text,
              marginTop: fitSize(14) + top,
              marginLeft: fitSize(15),
            }}
          >
            筛选条件
          </Text>
          <Text
            style={{
              fontSize: fitSize(14),
              fontWeight: "500",
              color: color.primary,
              marginLeft: fitSize(15),
              marginTop: fitSize(20),
            }}
          >
            价格区间
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginLeft: fitSize(15),
              alignItems: "center",
              marginTop: fitSize(10),
            }}
          >
            <TextInput
              keyboardType="numeric"
              value={minPrice}
              onChangeText={(e) => {
                setMinPrice(e)
              }}
              onBlur={() => {
                if (minPrice && maxPrice) {
                  checkPrice()
                }
              }}
              style={styles.textStyle}
            />
            <View
              style={{
                width: fitSize(10),
                height: fitSize(1),
                borderRadius: fitSize(1),
                backgroundColor: "#00AFB5",
                marginHorizontal: fitSize(5),
              }}
            />
            <TextInput
              keyboardType="numeric"
              value={maxPrice}
              onChangeText={(e) => {
                setMaxPrice(e)
              }}
              onBlur={() => {
                console.log(minPrice, maxPrice)
                if (minPrice && maxPrice) {
                  checkPrice()
                }
              }}
              style={styles.textStyle}
            />
          </View>
          <View style={{ marginLeft: fitSize(15), marginTop: fitSize(5), flexDirection: "row" }}>
            <Text style={{ fontSize: fitSize(12), color: color.dim }}>最低价</Text>
            <Text style={{ fontSize: fitSize(12), color: color.dim, marginLeft: fitSize(79) }}>
              最高价
            </Text>
          </View>
          {!!inPutErr && (
            <Text
              style={{
                color: color.error,
                fontSize: fitSize(12),
                fontWeight: "500",
                marginTop: fitSize(10),
                marginLeft: fitSize(15),
              }}
            >
              {inPutErr}
            </Text>
          )}
          <View
            style={{
              marginLeft: fitSize(15),
              marginTop: fitSize(15),
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: fitSize(12), color: color.dim, marginRight: fitSize(5) }}>
              是否只显示平价转让
            </Text>
            <Switch onValueChange={setIsShowParity} value={isShowParity} />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginHorizontal: fitSize(12),
              marginTop: "auto",
              marginBottom: fitSize(54) + bottom,
            }}
          >
            <Touchable
              onPress={reSetPrice}
              style={{
                width: fitSize(104),
                height: fitSize(42),
                borderRadius: fitSize(21),
                borderWidth: fitSize(1),
                borderColor: color.primary,
                justifyContent: "center",
                alignItems: "center",
                marginRight: fitSize(8),
              }}
            >
              <Text style={{ fontSize: fitSize(16), fontWeight: "500", color: color.primary }}>
                重置
              </Text>
            </Touchable>
            <Touchable
              onPress={setPrice}
              style={{
                width: fitSize(104),
                height: fitSize(42),
                borderRadius: fitSize(21),

                backgroundColor: color.primary,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{ fontSize: fitSize(16), fontWeight: "500", color: color.secBackground }}
              >
                完成
              </Text>
            </Touchable>
          </View>
        </View>
      </Modal>
    </FlexView>
  )
}

const styles = StyleSheet.create({
  searchWarp: {
    width: deviceWidth,
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    // paddingLeft: fitSize(10),
  },
  inputWrap: {
    width: fitSize(318),
    height: fitSize(34),
    backgroundColor: "#fff",
    borderRadius: fitSize(17),
    paddingLeft: fitSize(38),
    flexDirection: "row",
    alignItems: "center",
  },
  iconStyle: {
    width: fitSize(32),
    height: fitSize(32),
    marginLeft: fitSize(30),
  },
  monsterimg: {
    width: fitSize(90),
    height: fitSize(100),
    resizeMode: "contain",
  },
  fullView: {
    flexGrow: 1,
    marginLeft: fitSize(19),
  },
  content: {
    flexGrow: 1,
    // backgroundColor: "#fff",
    zIndex: -1,
  },
  innerContent: {
    width: fitSize(394),
    flexGrow: 1,
    // backgroundColor: "#333",
    alignSelf: "center",
    marginTop: fitSize(-7),
  },
  modal: {
    margin: 0,
    alignSelf: "flex-end",
  },
  title: {
    fontWeight: "bold",
    fontSize: fitSize(14),
  },
  chooseItem: {
    backgroundColor: "#F5F5F5",
    width: fitSize(76),
    height: fitSize(34),
    borderRadius: fitSize(4),
    justifyContent: "center",
    alignItems: "center",
  },

  chooseItemText: {
    color: "#333",
    fontSize: fitSize(12),
  },
  textStyle: {
    width: fitSize(95),
    height: fitSize(30),
    borderColor: "#00AFB5",
    borderRadius: fitSize(2),
    color: color.primary,
    fontSize: fitSize(14),
    borderWidth: fitSize(1),
    paddingHorizontal: fitSize(5),
    paddingVertical: 0,
    lineHeight: fitSize(30),
  },
})
