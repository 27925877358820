import {
  CardStyleInterpolators,
  HeaderStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack"

import { About } from "../screens/Setting/About"
import { ApplyAsset } from "../screens/TabAsset/ApplyAsset"
import { AssetDetails } from "../screens/TabAsset/AssetDetails"
import { AssetScreen } from "../screens/TabAsset/AssetScreen"
import { BottomTabNavigator } from "./BottomTabNavigator"
import { CardDetail } from "../screens/CardsPage/CardDetail"
import { CardsPage } from "../screens/CardsPage/CardsPage"
import { ChangeCenter } from "../screens/ChangeCenter/ChangeCenter"
import { ChooseSeries } from "../screens/ChooseSeries/ChooseSeries"
import { LoginFromMessage } from "../screens/LoginFlow/LoginFromMessage"
import { MarketSearchPage } from "../screens/SearchMarket/SearchMarket"
import { MarketSearchResultList } from "../screens/SearchMarket/MarketSearchResultList"
import { MassWebView } from "../screens/WebView/MassWebView"
import { MessageList } from "../screens/Message/MessageList"
import { Mine } from "../screens/Mine/Mine"
import { Mywallet } from "../screens/Mywallet/Mywallet"
import { NftHistory } from "../screens/Transaction/NftHistory"
import { OrderBuy } from "../screens/OrderCenter/OrderBuy"
import { Platform } from "react-native"
import React from "react"
import RelationService from "../screens/Setting/RelationService"
import { RootStackParamList } from "../type/RouteType"
import { SaleCenter } from "../screens/SaleCenter/SaleCenter"
import { SaleDetail } from "../screens/SaleCenter/SaleDetail"
import { Setting } from "../screens/Setting/Setting"
import { ShowOrder } from "../screens/PayProcess/ShowOrder"
import { TabHome } from "../screens/TabHome/TabHome"
import { TradeHistory } from "../screens/Transaction/TradeHistory"
import { TradeToItemAddPage } from "../screens/Transaction/TradeToItemAddPage"
import { TransactionInfo } from "../screens/Transaction/TransactionInfo"
import { TransactionRecords } from "../screens/Transaction/TransactionRecords"
import { TransactionScreen } from "../screens/Transaction/TransactionScreen"
import { TransactionScreenTradeToItemScreen } from "../screens/Transaction/TransactionScreenTradeToItemScreen"
import { WalletWebView } from "../screens/WebView/WalletWebView"
import { createNativeStackNavigator } from "@react-navigation/native-stack"

/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
// export type PrimaryParamList = {
//   welcome: undefined
//   demo: undefined
//   demoList: undefined
//   GLTestScreen: undefined
//   DemoUri: undefined
// }

// Documentation: https://reactnavigation.org/docs/stack-navigator/
// const Stack = createNativeStackNavigator<RootStackParamList>()
const Stack =
  Platform.OS === "ios"
    ? createNativeStackNavigator<RootStackParamList>()
    : createStackNavigator<RootStackParamList>()

export function MainNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        //@ts-ignore
        cardStyleInterpolator:
          Platform.OS !== "web" ? CardStyleInterpolators.forHorizontalIOS : undefined,
        // stackAnimation: "slide_from_right",
        // animation: "slide_from_right",
        animation: Platform.OS === "web" ? "none" : "default",
      }}
    >
      <Stack.Screen name="Root" component={BottomTabNavigator} />
      <Stack.Screen name="MassWebView" component={MassWebView} />
      <Stack.Screen name="WalletWebView" component={WalletWebView} />
      <Stack.Screen
        name="LoginFromMessage"
        component={LoginFromMessage}
        options={{ title: "登录" }}
      />
      <Stack.Screen
        name="HomeScreen"
        component={TabHome}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="AssetScreen"
        component={AssetScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ApplyAsset"
        component={ApplyAsset}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MarketSearchPage"
        component={MarketSearchPage}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MarketSearchResultList"
        component={MarketSearchResultList}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="ShowOrder" component={ShowOrder} />
      <Stack.Screen name="AssetDetails" component={AssetDetails} />
      <Stack.Screen name="TransactionRecords" component={TransactionRecords} />
      <Stack.Screen name="TransactionScreen" component={TransactionScreen} />
      <Stack.Screen name="TransactionInfo" component={TransactionInfo} />
      <Stack.Screen name="Mine" component={Mine} />
      <Stack.Screen name="MessageList" component={MessageList} />
      <Stack.Screen name="Setting" component={Setting} />
      <Stack.Screen name="RelationService" component={RelationService} />
      <Stack.Screen name="OrderBuy" component={OrderBuy} />
      <Stack.Screen name="SaleCenter" component={SaleCenter} />
      <Stack.Screen name="SaleDetail" component={SaleDetail} />
      <Stack.Screen name="Mywallet" component={Mywallet} />
      <Stack.Screen name="ChooseSeries" component={ChooseSeries} />
      <Stack.Screen name="CardsPage" component={CardsPage} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="NftHistory" component={NftHistory} />
      <Stack.Screen name="TradeHistory" component={TradeHistory} />
      <Stack.Screen name="CardDetail" component={CardDetail} />
      <Stack.Screen
        name="TransactionScreenTradeToItemScreen"
        component={TransactionScreenTradeToItemScreen}
      />
      <Stack.Screen name="TradeToItemAddPage" component={TradeToItemAddPage} />
      <Stack.Screen name="ChangeCenter" component={ChangeCenter} />
    </Stack.Navigator>
  )
}
/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["Root"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
