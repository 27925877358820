import { price, time } from "../TabHome"

import { useState } from "react"

export const loopSet = (max: number, current: number) => {
  if (current >= max - 1) {
    return 0
  } else {
    return current + 1
  }
}

export function useTouchLoop() {
  const [sortTime, setSortTime] = useState(0)
  const [sortMoney, setSortMoney] = useState(0)
  const [isOnlyTicket, setIsOnlyTicket] = useState(false)
  const touchTime = () => {
    setSortTime(loopSet(time.length, sortTime))
  }
  const touchPrice = () => {
    setSortMoney(loopSet(price.length, sortMoney))
  }
  return { touchTime, touchPrice, sortTime, sortMoney, isOnlyTicket, setIsOnlyTicket }
}
