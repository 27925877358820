import {
  ListRenderItemInfo,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ImageSourcePropType,
} from "react-native"
import React, { ReactElement, useEffect, useLayoutEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { RootStackNavProps } from "../../type/RouteType"
import { fitSize, Images } from "../../theme"
import { Sheader } from "../../components/header/Sheader"
import { HookFlatList } from "../../components"
import I18n from "i18n-js"
import { useStores } from "../../models"
import { useAutoList } from "../../hooks/useAutoList"
import { ApplyAssetInfoBar } from "./Components/ApplyAssetInfoBar"
import { ApplyAssetInfoCard } from "./Components/ApplyAssetInfoCard"
import { SceneRendererProps, TabBar, TabView } from "react-native-tab-view"
import { MyTabBar } from "./Components/MyTabBar"
import { ApplyAssetContent } from "./Components/ApplyAssetContent"

interface ApplyAssetProps extends RootStackNavProps<"ApplyAsset"> {}

type routeProp = {
  key: string
  title: string
}

type contractListType = {
  contractName: string
  contractAddress: string
}

type infoType = {
  appId: number
  appName: string
  iconUrl: string
  description: string
  assetNum: number
  contractList: [contractListType]
}

export const ApplyAsset: React.FC<ApplyAssetProps> = observer(
  ({ navigation, route }): ReactElement => {
    const { accountStore, collectStore } = useStores()
    const { height, width } = useWindowDimensions()
    const [item, setItem] = useState<object>({})
    const [info, setInfo] = useState<infoType>()
    const [navState, setNavState] = useState({
      index: 0,
      routes: [],
    })

    const _renderScene = ({
      route,
    }: SceneRendererProps & {
      route: routeProp
    }) => {
      return <ApplyAssetContent type={route.key} />
    }

    const _handleIndexChange = (index: number) => {
      setNavState({ ...navState, index })
    }

    const getApplyInfo = async () => {
      const res = await accountStore.environment.api.normalRequest(
        `/card/getAppDetail/${route.params.series}`,
      )
      if (res.kind === "ok") {
        setInfo(res.data)
        res.data?.contractList.forEach((element) => {
          setNavState({
            index: navState.index,
            routes: [
              ...navState.routes,
              { key: element?.contractAddress, title: element?.contractName },
            ],
          })
        })
      } else if (res.kind === "forbidden") {
        navigation.replace("LoginFromMessage")
      } else {
        throw res?.msg
      }
    }

    useEffect(() => {
      getApplyInfo()
    }, [])

    return (
      <View
        testID="ApplyAsset"
        style={[
          styles.root,
          {
            height: Platform.OS === "web" ? height : undefined,
            backgroundColor: "#202530",
          },
        ]}
      >
        <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"} />
        <Sheader
          notShowLeft={false}
          title={route.params.name}
          textStyle={{ color: "#06FDE8" }}
          rightWrapStyle={styles.rightWrapStyle}
        />
        <View style={{ padding: fitSize(10) }}>
          <ApplyAssetInfoBar
            icon={{ uri: info?.iconUrl }}
            title={info?.appName}
            info={info?.description}
          />
          <Text style={styles.total}>资产总数:{info?.assetNum}</Text>
          <View style={styles.bottomLine} />
        </View>
        {navState.routes.length > 0 && (
          <TabView
            initialLayout={{ width: width }}
            navigationState={navState}
            renderScene={_renderScene}
            onIndexChange={_handleIndexChange}
            renderTabBar={(props) => <MyTabBar bgColor={"#202530"} {...props} />}
          />
        )}
      </View>
    )
  },
)

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  sheaderTitle: {
    color: "#fafafa",
  },
  rightWrapStyle: {
    width: fitSize(50),
  },
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    padding: fitSize(10),
  },
  bottomLine: {
    width: "100%",
    height: fitSize(2),
    backgroundColor: "#06FDE8",
    marginTop: fitSize(5),
  },
  total: {
    fontSize: fitSize(17),
    fontWeight: "500",
    marginTop: fitSize(20),
    color: "#06FDE8",
  },
  contentContainerStyle: {
    paddingBottom: fitSize(20),
  },
  loadMoreView: {
    height: fitSize(40),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyStype: {
    position: "relative",
    bottom: fitSize(150),
    top: undefined,
    flex: undefined,
  },
})
