import { Platform } from "react-native"

// Use this import if you want to use "env.js" file
// const { API_URL } = require("../../config/env")
// Or just specify it directly like this:
const env = require("../../../genereateEnv.js")
const URL = env?.genereateEnv?.BASE_URL
// const API_URL = "http://47.101.216.210:28000"
// const API_URL = "http://47.101.216.210:3000/mock/11"
// const API_URL = "http://192.168.10.200:16080"
// const API_URL = "http://192.168.10.200:15080"
// const API_URL = "http://192.168.10.200:11080"
// const API_URL = "http://192.168.10.200:12080"
// const API_URL = "http://192.168.10.200:11080"
// const API_URL = "http://192.168.10.200:38000"
// const API_URL = "http://47.101.216.210:50082" //测试
// const API_URL = "http://ppe-api.jiji.tianhe32.cn" //测试
const API_URL = Platform.OS === "web" ? "" : URL || "http://api.jiji.tianhe32.cn"
const PREFIX = "/v1/api"

const ServerListUrl = "http://47.101.216.210:10080"

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * The URL of server list.
   */
  serverListUrl: string

  /**
   * The URL path prefix
   */
  pathPrefix: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL || "",
  pathPrefix: PREFIX,
  serverListUrl: ServerListUrl,
  timeout: 5 * 60 * 1000,
}
