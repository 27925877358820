import * as React from "react"

import { Text as ReactNativeText } from "react-native"
import { TextProps } from "./text.props"
import { flatten } from "ramda"
import { presets } from "./text.presets"
import { translate } from "../../i18n"

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Text(props: TextProps) {
  // grab the props
  const {
    preset = "default",
    tx,
    txOptions,
    text,
    children,
    style: styleOverride,
    s,
    ...rest
  } = props

  // figure out which content to use
  const i18nText = tx && translate(tx, txOptions)
  const content = i18nText || text || children

  const style = presets[preset] || presets.default
  const fontS = s && { fontSize: s }
  const styles = flatten([style, styleOverride, fontS])

  return (
    <ReactNativeText {...rest} style={styles}>
      {content}
    </ReactNativeText>
  )
}
