import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, StyleSheet, View } from "react-native"
import { Images, fitSize } from "../../../theme"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"

import { GToast } from "../../../navigators"
import { SizeImage } from "../../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import { BoughtItem } from "./BoughtItem"
import { nftAsset } from "../../SaleCenter/Components/SaleList"

interface BoughtViewProps {
  type: number
  change: number
  setChange: (data: any) => void
  router: any
}
export type MarketItem = {
  id: number
  tradeOrderId: string
  sellerUserAddress: string
  sellerUserPhonenumber: string
  goodsName: string
  price: number
  payTime?: any
  tokenURI: string
  orderStatus: number
  createdAt: string
  nftAsset?: nftAsset
}
export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ type, change, setChange, router }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getConsumeHistory",
      {
        page,
        pageSize: pageLimit,
        orderStatus: type,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
      // return [
      //   {
      //     id: 1,
      //     tradeOrderId: "e19b2909f0a3401d8441fbef776d58b3",
      //     sellerUserAddress: "0x484bba261f4d477aa0303fa239eaa50128665642",
      //     sellerUserPhonenumber: "180****5502",
      //     goodsName: "时空IN•唐｜花神录",
      //     price: 2,
      //     payTime: null,
      //     tokenURI: "https://nftmeta.kongjiin.com/KJ-HSL/10007",
      //     orderStatus: 2,
      //     createdAt: "2022-09-15T07:48:11.892Z",
      //   },
      // ]
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<MarketItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return <BoughtItem data={item} />
        }}
      />
    </View>
  )
}
export const BoughtView = React.memo(bv)
const styles = StyleSheet.create({
  priceText: {
    color: "#06FDE8",
    fontSize: fitSize(18),
    fontWeight: "bold",
  },
})
