import { HookFlatList, Text, Touchable } from "../../components"
import { StyleSheet, View } from "react-native"
import { color, fitSize } from "../../theme"

import { FlexView } from "../../components/screen/FlexView"
import React from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { tabType } from "../TabHome/TabHome"
import { useApi } from "../../hooks/useApi"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"

interface ChooseSeriesProps extends RootStackNavProps<"ChooseSeries"> {}

const SeriesSize = 20
export const ChooseSeries: React.FC<ChooseSeriesProps> = ({ navigation, route }) => {
  const { accountStore } = useStores()
  const fetchList = async (page: number, pageLimit = SeriesSize) => {
    const res = await accountStore.environment.api.normalRequest(
      `/series/getSeriesInfoByPage/${page}/${pageLimit}`,
    )
    if (res.kind === "ok") {
      return res?.data?.list
    } else {
      throw res.msg
    }
  }
  const [list] = useAutoList<tabType>(
    {
      fetchData: fetchList,
      pageLimit: SeriesSize,
    },
    [],
  )
  const doAction = (item: tabType) => {
    const action = route?.params?.action
    switch (action) {
      case "homeFilter":
        navigation.push("MarketSearchResultList", {
          seriesId: item?.seriesInfo?.id,
          seriesName: item?.seriesInfo?.seriesName,
        })
        break
      case "homeSell":
        navigation.push("CardsPage", {
          name: item?.seriesInfo?.seriesName,
          contractAddress: [
            ...item?.boxContracts.map((e) => e.contractAddress),
            ...item?.cardContracts.map((e) => e.contractAddress),
          ],
        })
        break
      case "addToChooOrder":
        navigation.push("CardsPage", {
          name: item?.seriesInfo?.seriesName,
          contractAddress: [
            ...item?.boxContracts.map((e) => e.contractAddress),
            ...item?.cardContracts.map((e) => e.contractAddress),
          ],
          action: "addToChooOrder",
        })
        break
      default:
        return
    }
  }

  return (
    <FlexView>
      <Sheader notShowLeft={false} title={"IP系列"} isNotSafe />
      <HookFlatList
        {...{ ...list }}
        contentContainerStyle={{ marginTop: fitSize(15), paddingLeft: fitSize(8) }}
        numColumns={3}
        renderItem={({ item, index }) => {
          return (
            <Touchable
              onPress={() => doAction(item)}
              style={{
                width: fitSize(111),
                height: fitSize(111 + 45),
                marginHorizontal: fitSize(4.5),
              }}
            >
              <SizeImage
                source={{ uri: item?.seriesInfo?.seriesCover }}
                resizeMode={"contain"}
                style={{
                  width: fitSize(111),
                  height: fitSize(111),
                  borderRadius: fitSize(2),
                  overflow: "hidden",
                  backgroundColor: "#000",
                }}
              />
              <Text
                style={{
                  width: fitSize(111),
                  textAlign: "center",
                  fontSize: fitSize(14),
                  marginTop: fitSize(10),
                  color: color.text,
                }}
              >
                {item?.seriesInfo?.seriesName}
              </Text>
            </Touchable>
          )
        }}
      />
    </FlexView>
  )
}

const styles = StyleSheet.create({})
