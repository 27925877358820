import * as InAppPurchases from "expo-in-app-purchases"

import { HookFlatList, Text, Touchable, TouchableDeep } from "../../../components"
import { Image, ImageBackground, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import { PriceItemForSearch, nftAsset } from "./SaleList"
import React, { useEffect, useRef } from "react"
import { loadString, saveString } from "../../../utils/storage"

import { GToast } from "../../../navigators"
import { PayWayDesc } from "../../PayProcess/ShowOrder"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { collectBagItemStoreSnapshot } from "../../../models/collects/collectBagItem"
import { collectCardItemStoreSnapshot } from "../../../models/collects/collectCardItem"
import dayjs from "dayjs"
import { useAutoList } from "../../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

interface BoughtViewProps {
  change: number
  setChange: (data: any) => void
  router: any
}
export type saleItem = {
  id: number
  goodsName: string
  price: number
  tokenURI: string
  sellDuration: number
  unsellTime: string
  updatedAt: string
  payTime: string
  buyerAddress: string
  phonenumber: string
  nftAsset?: nftAsset
  payWay?: number
}

export const ORDER_MAX_LIMIT = 10

const bv: React.FC<BoughtViewProps> = ({ change, setChange, router }) => {
  const { accountStore, collectStore } = useStores()
  const navigation = useTypeNavigation()

  const fetchBoughtList = async (page: number, pageLimit = ORDER_MAX_LIMIT) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getUserTradeMarketOrder",
      {
        page,
        pageSize: pageLimit,
        orderStatus: 6,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data?.data
    } else {
      throw res.msg
    }
  }
  const [fetchList] = useAutoList<saleItem>(
    {
      fetchData: fetchBoughtList,
      pageLimit: ORDER_MAX_LIMIT,
    },
    [router],
  )
  const count = useRef(0)
  useFocusEffect(
    React.useCallback(() => {
      if (count.current > 0) {
        fetchList.doFirstload()
      }
      count.current++
    }, []),
  )

  return (
    <View key={change} style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...fetchList }}
        contentContainerStyle={{ marginTop: fitSize(5) }}
        renderItem={({ item, index }) => {
          return <SoldItem item={item} />
        }}
      />
    </View>
  )
}
export const SoldList = React.memo(bv)
const SoldItem = ({ item }: { item: saleItem }) => {
  const [assetData] = useUriInfo(item?.tokenURI, [item])
  const takeUp = async () => {}

  return (
    <View
      style={{
        width: fitSize(351),
        height: fitSize(154),
        alignSelf: "center",
        backgroundColor: "#fff",
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
      }}
    >
      <View
        style={{
          height: fitSize(40),
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingHorizontal: fitSize(15),
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderColor: "rgba(0, 0, 0, 0.06)",
        }}
      >
        <Text
          style={{ color: "#888888", fontSize: fitSize(12), width: fitSize(200), flexShrink: 1 }}
        >
          {dayjs(item?.payTime).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          paddingTop: fitSize(12),
          flex: 1,
          paddingHorizontal: fitSize(12),
        }}
      >
        <SizeImage
          source={{ uri: assetData?.Image }}
          style={{
            width: fitSize(90),
            height: fitSize(90),
          }}
          resizeMode={"contain"}
          isPreview
        />
        <View style={{}}>
          <Text
            style={{ fontSize: fitSize(14), color: "#333333", fontWeight: "500", width: "100%" }}
          >
            {item?.goodsName}
          </Text>
          <Text
            style={{
              fontSize: fitSize(12),
              color: "#888888",
              fontWeight: "400",
              marginTop: fitSize(5),
            }}
          >
            {assetData?.Series}
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            right: fitSize(10),
            top: fitSize(20),
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {!!item?.price && item?.payWay !== 3 && (
              <Text style={styles.priceText}>￥{(item?.price / 100).toFixed(2)}</Text>
            )}
            {!!item?.nftAsset?.num && (
              <PriceItemForSearch item={{ ...item, price: item?.payWay === 3 ? 0 : item.price }} />
            )}
          </View>
          <Text style={{ color: "#333", fontSize: fitSize(13) }}>
            支付方式:{PayWayDesc[item?.payWay]}
          </Text>
        </View>
      </View>
      {/* <View
        style={{
          width: fitSize(335),
          height: fitSize(40),
          borderTopWidth: StyleSheet.hairlineWidth,
          borderColor: color.dim,
          padding: fitSize(5),
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image source={Images.test.m_owner1} style={{ width: fitSize(30), height: fitSize(30) }} />
        <Text style={{ color: color.dim }}>{item?.phonenumber}</Text>
        <Text
          numberOfLines={1}
          ellipsizeMode={"middle"}
          style={{ color: color.dim, width: fitSize(100), marginLeft: "auto" }}
        >
          {item?.buyerAddress}
        </Text>
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  priceText: {
    color: "#FF2E11",
    fontSize: fitSize(16),
    fontWeight: "500",
  },
})
