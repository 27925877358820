import { ActivityIndicator, Image, Platform, StyleSheet, View } from "react-native"
import { Images, deviceHeight, deviceWidth, fitSize, gStyle } from "../../../theme"
import React, { useEffect, useState } from "react"
import { Text, Touchable, TouchableDeep } from "../../../components"

import Modal from "react-native-modal"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { useApi } from "../../../hooks/useApi"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { observer } from "mobx-react-lite"
import { useStores } from "../../../models"

interface ChoosePayProps {
  isShow: boolean
  closeModal: () => void
  onConfirm: (way: number, type: string) => void
  price: number
  id: string
  nftAsset?: {
    assetId: number
    nftAddress: string
    num: number
  }
}
type paylist = { paywayList: { name: string; payWay: number; type: string; icon: string }[] }
export const PayModal: React.FC<ChoosePayProps> = observer(
  ({ closeModal, isShow, onConfirm, price, id, nftAsset }) => {
    const { bottom } = useSafeAreaInsets()
    const { result } = useApi<paylist>({
      api: {
        path: "/market/getAvailablePaylist",
        params: { platform: Platform.OS },
        way: "post",
      },
      // config: { condFunc: () => isShow },
    })
    const [choose, setChoose] = useState<{ payWay: number; type: string } | null>(null)
    const { collectStore } = useStores()

    useEffect(() => {
      const list = result?.result?.paywayList
      if (list) {
        setChoose({ payWay: list?.[0]?.payWay, type: list?.[0]?.type })
      }
    }, [result.result])
    return (
      <Modal
        useNativeDriverForBackdrop
        hasBackdrop={true}
        coverScreen={true}
        isVisible={isShow}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{ margin: 0, justifyContent: "flex-end" }}
        onBackButtonPress={closeModal}
        onBackdropPress={closeModal}
        swipeDirection={"down"}
        backdropTransitionOutTiming={0}
        useNativeDriver
        hideModalContentWhileAnimating={true}
        deviceHeight={deviceHeight}
      >
        <View
          style={{
            width: "100%",
            minHeight: fitSize(154) + bottom,
            backgroundColor: "#fff",
            paddingBottom: bottom,
          }}
        >
          <Touchable
            onPress={closeModal}
            style={{
              width: fitSize(16 + 16),
              height: fitSize(16 + 16),
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 100,
            }}
          >
            <Image
              source={Images.components.cancel_modal}
              style={{ width: fitSize(16), height: fitSize(16), tintColor: "#666" }}
            />
          </Touchable>
          <View style={[styles.warpView, { borderTopWidth: 0 }]}>
            <Text style={styles.choosePayText}>支付方式</Text>
          </View>
          {!!price
            ? result?.result?.paywayList?.map((t, i) => {
                return (
                  <Touchable
                    onPress={() => {
                      setChoose({ payWay: t.payWay, type: t.type })
                    }}
                    key={i}
                    style={styles.warpView}
                  >
                    <View style={gStyle.horizontalView}>
                      {t?.icon && <SizeImage source={{ uri: t?.icon }} style={styles.commPic} />}
                      <Text style={styles.title}>{t.name}</Text>
                      <View style={gStyle.horizontalView}>
                        <Text
                          style={[
                            styles.payAmountUnit,
                            { fontSize: fitSize(12), marginLeft: fitSize(12) },
                          ]}
                        >
                          ￥
                        </Text>
                        <Text style={[styles.payAmount, { fontSize: fitSize(16) }]}>
                          {(price / 100).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                    <SizeImage
                      source={
                        choose?.payWay == t.payWay
                          ? Images.components.choose
                          : Images.components.not_choose
                      }
                      style={styles.chooseImg}
                    />
                  </Touchable>
                )
              })
            : null}
          {!!nftAsset?.num && (
            <TicketWrap setChoose={setChoose} choose={choose} nftAsset={nftAsset} />
            // <Touchable
            //   onPress={() => {
            //     setChoose({ payWay: 79999, type: "ticket" })
            //   }}
            //   style={styles.warpView}
            // >
            //   <MyTickets nftAsset={nftAsset} />
            //   <SizeImage
            //     source={
            //       choose?.payWay == 79999 ? Images.components.choose : Images.components.not_choose
            //     }
            //     style={styles.chooseImg}
            //   />
            // </Touchable>
          )}
          <View style={{ height: fitSize(20) }} />
          <TouchableDeep
            onPress={() => {
              // dispatch(resetTop2AndInsert("PaySuccess", { way: params?.way }))
              if (collectStore.isFetchingCollects) return
              if (choose) {
                onConfirm(choose.payWay, choose.type)
              }
            }}
            style={[
              styles.buttonWrap,
              { marginTop: "auto", marginBottom: fitSize(4), backgroundColor: "#00AFB5" },
            ]}
          >
            <Text style={styles.buttonText}>确认支付</Text>
          </TouchableDeep>
        </View>
      </Modal>
    )
  },
)
const TicketWrap = observer(
  ({
    setChoose,
    choose,
    nftAsset,
  }: {
    setChoose: React.Dispatch<
      React.SetStateAction<{
        payWay: number
        type: string
      }>
    >
    choose: {
      payWay: number
      type: string
    }
    nftAsset?: {
      assetId: number
      nftAddress: string
      num: number
    }
  }) => {
    const { collectStore } = useStores()

    return (
      <Touchable
        onPress={() => {
          setChoose({ payWay: 79999, type: "ticket" })
        }}
        style={styles.warpView}
        disabled={collectStore.isFetchingCollects}
      >
        <MyTickets nftAsset={nftAsset} />
        <SizeImage
          source={choose?.payWay == 79999 ? Images.components.choose : Images.components.not_choose}
          style={styles.chooseImg}
        />
      </Touchable>
    )
  },
)
const MyTickets = observer(
  ({
    nftAsset,
  }: {
    nftAsset?: {
      assetId: number
      nftAddress: string
      num: number
    }
  }) => {
    const { collectStore } = useStores()
    const name = collectStore.nftForExchange.get(nftAsset?.nftAddress + nftAsset?.assetId)?.name
    useEffect(() => {
      collectStore.getAllBagItemsFromAddresses(true, [nftAsset.nftAddress])
    }, [])

    return (
      <View>
        <View style={gStyle.horizontalView}>
          <Text style={styles.title}>{name}</Text>
          <View style={gStyle.horizontalView}>
            <Text
              style={[styles.payAmountUnit, { fontSize: fitSize(12), marginLeft: fitSize(12) }]}
            >
              x {nftAsset?.num}
            </Text>
          </View>
        </View>
        <View style={gStyle.horizontalView}>
          <Text style={{ fontSize: fitSize(12) }}>你当前拥有</Text>
          <CollectNum assetId={nftAsset?.assetId} nftAddress={nftAsset?.nftAddress} />
        </View>
      </View>
    )
  },
)
const CollectNum = observer(({ nftAddress, assetId }: { assetId: number; nftAddress: string }) => {
  const { collectStore } = useStores()
  // console.log(collectStore.collects)
  if (collectStore?.isFetchingCollects) {
    return <ActivityIndicator size={"small"} color={"gray"} />
  }
  return (
    <Text>
      x
      {
        Array.from(collectStore.collects.values()).filter(
          (e) =>
            e.item?.asset?.assetId === assetId && e.item?.asset?.contractAddress === nftAddress,
        ).length
      }
    </Text>
  )
})

const styles = StyleSheet.create({
  startImg: {
    width: fitSize(46),
    height: fitSize(46),
    resizeMode: "contain",
    alignSelf: "center",
    marginTop: fitSize(31),
  },
  content: {
    alignItems: "center",
  },
  payTitle: {
    color: "#4F4F4F",
    fontSize: fitSize(20),
    fontWeight: "bold",
    marginTop: fitSize(5),
  },
  payAmount: { fontSize: fitSize(40), fontWeight: "bold" },
  payAmountUnit: { fontSize: fitSize(24), color: "#333", marginTop: fitSize(5) },
  payRestTime: {
    color: "#929292",
    fontSize: fitSize(14),
  },
  restTime: {
    color: "#4F4F4F",
    fontSize: fitSize(20),
  },
  timeLine1: {
    backgroundColor: "#EDEDED",
    width: fitSize(28),
    height: fitSize(2),
    position: "absolute",
    bottom: 0,
    left: -fitSize(2),
  },
  timeLine2: {
    backgroundColor: "#EDEDED",
    width: fitSize(28),
    height: fitSize(2),
    position: "absolute",
    bottom: 0,
    right: -fitSize(2),
  },
  warpView: {
    width: deviceWidth,
    height: fitSize(54),
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#EDEDED",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(20),
  },
  choosePayText: {
    color: "#333",
    fontSize: fitSize(16),
    fontWeight: "bold",
  },
  commPic: {
    width: fitSize(24),
    height: fitSize(24),
    resizeMode: "contain",
    marginRight: fitSize(9),
  },
  title: {
    fontSize: fitSize(16),
    // fontWeight: "bold",
    color: "#333",
  },
  chooseImg: {
    width: fitSize(20),
    height: fitSize(20),
    resizeMode: "contain",
  },
  buttonWrap: {
    // position: "absolute",
    // bottom: fitSize(4),
    alignSelf: "center",
    width: fitSize(343),
    height: fitSize(40),
    backgroundColor: "#FFBC1D",
    borderRadius: fitSize(6),
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonText: {
    fontSize: fitSize(16),
    fontWeight: "bold",
    color: "#fff",
  },
})
