import { StyleSheet, View, useWindowDimensions, Platform, ListRenderItemInfo } from "react-native"
import React, { useEffect, useState } from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { HookFlatList, Screen } from "../../components"
import _ from "lodash"
import { Sheader } from "../../components/header/Sheader"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"
import { fitSize } from "../../theme/Layout"
import TransactionRecordsBox from "./components/TransactionRecordsBox"
import { itemType } from "./TransactionScreen"

interface transactionRecordsProps extends RootStackNavProps<"TransactionRecords"> {}

export type infoItem = {
  id: number
  tradeOrderId: string
  sellerUserAddress: string
  sellerUserPhonenumber: string
  buyerUserPhonenumber:string
  goodsName: string
  price: number
  payTime: any
  tokenURI: string
  orderStatus: number
  createdAt: string
}

export type modalType = "game" | "series" | "time"
const PL = 10
export const TransactionRecords: React.FC<transactionRecordsProps> = ({ navigation, route }) => {
  const { height } = useWindowDimensions()
  const { accountStore, collectStore } = useStores()

  const fetchUserAssetBySeriesId = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getConsumeHistory",
      {
        page,
        pageSize: PL,
        orderStatus: route.params.status,
      },
      "post",
    )
    if (res.kind === "ok") {
      return res?.data.data
    } else {
      return []
    }
  }

  const [assetList] = useAutoList(
    {
      fetchData: fetchUserAssetBySeriesId,
      pageLimit: PL,
    },
    [],
  )

  return (
    <View
      testID="TransactionRecords"
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? height : undefined,
        backgroundColor: "#202530",
      }}
    >
      <Sheader
        rightImage={""}
        title={"交易记录"}
        textStyle={{ color: "#06FDE8" }}
        leftImage={""}
        style={{ backgroundColor: "#202530" }}
      />
      <HookFlatList
        {...assetList}
        data={assetList.data}
        numColumns={1}
        notShowEmpty={true}
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, index }: ListRenderItemInfo<infoItem>) => {
          return (
            <TransactionRecordsBox
              sellPhone={item?.sellerUserPhonenumber}
              buyPhone={item?.buyerUserPhonenumber}
              time={item?.createdAt}
              price={item?.price}
              key={index}
            />
          )
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: fitSize(20),
  },
  loadMoreView: {
    height: fitSize(40),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyStype: {
    position: "relative",
    bottom: fitSize(150),
    top: undefined,
    flex: undefined,
  },
})
