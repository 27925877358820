import { DependencyList, useCallback, useEffect, useRef, useState } from "react"

import { GToast } from "../navigators"
import { delay } from "../utils/delay"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../models"

type apiType = { path: string; params?: any; way?: string }
interface useApiType<T> {
  api: apiType & {
    mockData?: T
  }
  config?: {
    showLoading?: boolean
    loadingText?: string
    showError?: boolean
    dealFunc?: (data: any) => any
    withOK?: boolean
    isFoucsEffect?: boolean
    condFunc?: (...args: any[]) => boolean
  }
}
export interface resType<T> {
  error: string | null
  result: T
  loading: boolean
  isStart: boolean
}
const CONFIG = {
  showError: true,
  loadingText: "",
  showLoading: false,
  dealFunc: null,
  withOK: true,
  isFoucsEffect: false,
  condFunc: () => true,
}

export function useNoTokenApi<T>(
  { api, config = CONFIG }: useApiType<T>,
  deps: DependencyList = [],
) {
  const { accountStore } = useStores()
  const [result, setResult] = useState<resType<T>>({
    error: null,
    result: null,
    loading: false,
    isStart: false,
  })
  config = { ...CONFIG, ...config }
  const sub = useRef(true)
  const doRequest = async (requestApi?: apiType) => {
    setResult((c) => ({ ...c, loading: true, isStart: true }))
    if (config.showLoading) {
      GToast.current.showLoading(config.loadingText || "加载中")
    }
    if (api?.mockData) {
      await delay(300)
      setResult((c) => ({ ...c, result: api?.mockData, loading: false }))
      return undefined
    }
    const finalpi = requestApi || api
    const res = await accountStore.environment.api.normalRequest(
      finalpi.path,
      finalpi.params,
      finalpi.way,
    )
    if (config.showLoading) {
      GToast.current.hideToast()
    }
    if (sub.current === false) {
      return undefined
    }
    if (res.kind === "ok") {
      if (config?.dealFunc) {
        setResult((c) => ({ ...c, result: config?.dealFunc(res.data), loading: false, error: "" }))
      } else {
        setResult((c) => ({ ...c, result: res.data, loading: false, error: "" }))
      }
      return res
    } else {
      setResult((c) => ({ ...c, loading: false, error: res.msg }))
      if (config.showError) GToast.current.showToast(res.msg, "TOAST", 2000)
      return res
    }
  }
  const doFirstLoad = () => {
    if (!config?.condFunc) {
      doRequest()
      return
    }
    if (config?.condFunc && config?.condFunc()) {
      doRequest()
    }
  }
  useEffect(() => {
    sub.current = true
    if (!config.isFoucsEffect) {
      doFirstLoad()
    }
    return () => {
      sub.current = false
    }
  }, [...deps])
  useFocusEffect(
    useCallback(() => {
      sub.current = true
      if (config.isFoucsEffect) {
        doFirstLoad()
      }
      return () => {
        sub.current = false
      }
    }, [...deps]),
  )

  return { result, setResult, doRequest }
}
