import { Image, StyleSheet, View, ViewStyle } from "react-native"
import { color, Images, fitSize } from "../../theme"

import React from "react"
import { Text } from "../text/text"

interface EmptyItemProps {
  tintColor?: string
  style?: ViewStyle
}

export const EmptyItem: React.FC<EmptyItemProps> = ({ tintColor, style }) => {
  return (
    <View style={{ ...style, ...styles.container }}>
      <Image
        source={Images.common.newEmpty}
        style={{
          width: fitSize(183),
          height: fitSize(183),
        }}
      />
      <Text
        style={{
          color: color.dim,
          fontSize: fitSize(14),
        }}
      >
        此处空空如也
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
})
