import { ActivityIndicator, GestureResponderEvent, StyleSheet, View } from "react-native"

import { NoInternet } from "../empty/NoInternet"
import React from "react"
import { resType } from "../../hooks/useApi"

interface LoadingCompProps {
  doRequest: (...args: any[]) => any
  result: resType<any>
  top?: number
}

export const LoadingComp: React.FC<LoadingCompProps> = ({ children, doRequest, result, top }) => {
  const data = result?.result
  return (
    <>
      {result.error ? (
        <NoInternet error={result.error} onPress={doRequest} top={top} />
      ) : !data ? (
        <ActivityIndicator style={{ alignSelf: "center", top: 100 }} />
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const styles = StyleSheet.create({})
