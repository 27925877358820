import {
  Animated,
  FlatList,
  Image,
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native"
import { FULL, Images, color, deviceWidth, fitSize } from "../../theme"
import { HookFlatList, Screen, Text, TouchableDeep } from "../../components"
import React, { useCallback, useState } from "react"
import { SceneRendererProps, TabBar, TabView } from "react-native-tab-view"

import { BoughtView } from "../OrderCenter/components/BoughtView"
import { ChangeList } from "./Components/ChangeList"
import { MyTabBar } from "../OrderCenter/components/MyTabBar"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SoldList } from "./Components/SoldList"
import { TakeDownList } from "./Components/TakeDownList"
import { ThemeScreen } from "../../components/screen/ThemeScreen"
import { themeData } from "../../navigators"
import { useAutoList } from "../../hooks/useAutoList"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../models"
import { useTheme } from "styled-components/native"
import { PaticipateList } from "./Components/PaticipateList"
import { TakeDownListR } from "./Components/TakeDownListR"

interface OrderMainProps extends RootStackNavProps<"SaleCenter"> {}
export const routes = [
  { key: "first", title: "发布中" },
  { key: "second", title: "参与中" },
  { key: "mid", title: "已撤回" },
  { key: "third", title: "已下架" },
  { key: "forth", title: "已完成" },
]
type routeProp = {
  key: string
  title: string
}

export const ChangeCenter: React.FC<OrderMainProps> = ({ navigation, route }) => {
  const [navState, setNavState] = useState({
    index: 0,
    routes: routes,
  })
  const [change, setChange] = useState(0)
  const theme = useTheme() as themeData
  const _renderScene = ({
    route,
  }: SceneRendererProps & {
    route: routeProp
  }) => {
    switch (route.key) {
      case "first":
        return <ChangeList orderStatus={2} change={change} setChange={setChange} router={route} />
      case "second":
        return (
          <PaticipateList orderStatus={1} change={change} setChange={setChange} router={route} />
        )
      case "mid":
        return <TakeDownList orderStatus={4} change={change} setChange={setChange} router={route} />
      case "third":
        return (
          <TakeDownListR orderStatus={1} change={change} setChange={setChange} router={route} />
        )

      case "forth":
        return <SoldList change={change} setChange={setChange} router={route} />

      default:
        return null
    }
  }
  const _handleIndexChange = (index: number) => {
    setNavState({ ...navState, index })
  }
  const { height, width } = useWindowDimensions()

  return (
    <View
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? height : undefined,
        backgroundColor: color.background,
      }}
    >
      <Sheader
        rightImage={""}
        title={"换物订单"}
        textStyle={{ color: color.text, fontSize: fitSize(17), fontWeight: "600" }}
        leftImage={""}
        style={{ backgroundColor: color.secBackground }}
      />
      <TabView
        initialLayout={{ width: width }}
        lazy={true}
        navigationState={navState}
        renderScene={_renderScene}
        onIndexChange={_handleIndexChange}
        // style={{ justifyContent: "center", alignItems: "center", width: "100%" }}
        renderTabBar={(props) => (
          // indicatorLeft={fitSize(37)
          <MyTabBar
            split={3}
            bgColor={color.secBackground}
            indicatorLeft={fitSize(33)}
            width={fitSize(70)}
            leftSpace={fitSize(13)}
            textColor="#888888"
            textTintColor="#00AFB5"
            textSize={fitSize(15)}
            tintTextSize={fitSize(15)}
            {...props}
          />
        )}
      />
    </View>
  )
}
const styles = StyleSheet.create({})
