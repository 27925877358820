import { ImageStyle, ResizeMode } from "react-native-fast-image"
import React, { useEffect, useRef, useState } from "react"
import { StyleProp, StyleSheet, View } from "react-native"
import { Text, Touchable } from "../../../components"
import { color, fitSize } from "../../../theme"

import { SizeImage } from "../../../components/image-component/SizeImage"
import { marketItem } from "./MarketList"
import { observer } from "mobx-react-lite"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

export type nftItem = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: string[]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
  showBgImage: string
  showSealImage: string
}

export const SealImageComp = (
  data: nftItem & { resizeMode?: ResizeMode; style?: StyleProp<ImageStyle> },
) => {
  const { Image, showSealImage, showBgImage, resizeMode, style } = data
  const { width, height } = StyleSheet.flatten(style)
  return (
    <SizeImage resizeMode={resizeMode} source={{ uri: showBgImage }} style={style}>
      <SizeImage
        resizeMode={resizeMode}
        source={{
          uri: Image,
        }}
        style={{ width, height }}
      >
        <SizeImage
          resizeMode={resizeMode}
          source={{ uri: showSealImage }}
          style={{ width, height }}
        />
      </SizeImage>
    </SizeImage>
  )
}
interface MarketItemProps {
  item: marketItem & { isChoose: boolean }
  showBatchChoose?: boolean
  batchSelectItem?: (item: marketItem) => void
}

export const MarketItem: React.FC<MarketItemProps> = observer(
  ({ item, showBatchChoose, batchSelectItem }) => {
    const navigation = useTypeNavigation()
    const [data] = useUriInfo(item.tokenURI, [item.tokenURI])
    return (
      <Touchable
        onPress={() => {
          if (showBatchChoose) {
            batchSelectItem(item)
          } else {
            navigation.push("TransactionScreen", { id: item.id })
          }
        }}
        style={{
          width: fitSize(170),
          height: fitSize(246),
          margin: fitSize(5.5),
          // padding: fitSize(10),
          backgroundColor: color.secBackground,
          borderRadius: fitSize(10),
          shadowColor: "#000",
          shadowOpacity: 0.08,
          shadowOffset: { width: fitSize(0), height: fitSize(2) },
          shadowRadius: fitSize(5),
        }}
      >
        <View
          style={{
            width: fitSize(170),
            height: fitSize(150),
            backgroundColor: "#000",
            borderTopLeftRadius: fitSize(10),
            borderTopRightRadius: fitSize(10),
          }}
        >
          <SealImageComp
            resizeMode={"contain"}
            style={{ width: fitSize(170), height: fitSize(150), overflow: "hidden" }}
            {...data}
          />
          {/* <SizeImage
          isPreview
          source={{ uri: data?.Image }}
          style={{
            width: fitSize(170),
            height: fitSize(150),
            overflow: "hidden",
          }}
          resizeMode={"contain"}
        /> */}
        </View>
        <View
          style={{
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: fitSize(8),
            marginTop: fitSize(5),
          }}
        >
          <Text
            numberOfLines={1}
            style={{ color: color.text, fontSize: fitSize(14), fontWeight: "500" }}
          >
            {item?.goodsName}
          </Text>
        </View>
        <Text
          numberOfLines={1}
          style={{
            color: color.text,
            fontSize: fitSize(14),
            fontWeight: "400",
            marginLeft: fitSize(8),
          }}
        >
          {data?.CardShowId}
        </Text>

        <Text
          style={{
            fontSize: fitSize(12),
            fontWeight: "400",
            color: color.dim,
            marginVertical: fitSize(2),
            marginLeft: fitSize(8),
          }}
        >
          {data?.Series}
        </Text>

        <View style={{ flexDirection: "row" }}>
          {!!item?.nftAsset?.num && <PriceItemForSearch item={item} />}
          {!!item?.price && !!item?.nftAsset?.num && (
            <Text style={{ color: color.price, fontSize: fitSize(17), fontWeight: "500" }}>|</Text>
          )}
          {!!item?.price && (
            <Text
              style={{
                marginTop: "auto",
                color: color.price,
                fontSize: fitSize(17),
                marginBottom: fitSize(5),
                marginLeft: fitSize(5),
                fontWeight: "500",
              }}
            >
              {`￥${Math.floor((item.price / 100) * 100) / 100}`}
            </Text>
          )}
        </View>

        {item?.tagType === 1 && (
          <View
            style={{
              width: fitSize(64),
              height: fitSize(23),
              borderRadius: fitSize(11.5),
              backgroundColor: "#00AFB5",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: fitSize(8),
              right: fitSize(8),
            }}
          >
            <Text style={{ fontSize: fitSize(12), fontWeight: "500", color: "#fff" }}>
              平价商品
            </Text>
          </View>
        )}
        {showBatchChoose && (
          <View style={{ position: "absolute", bottom: fitSize(10), right: fitSize(10) }}>
            <Touchable
              onPress={() => {
                batchSelectItem(item)
              }}
              style={{
                width: fitSize(70),
                height: fitSize(30),
                borderRadius: fitSize(18),
                backgroundColor: item?.isChoose ? "#fff" : color.primary,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "auto",
                borderColor: color.primary,
                borderWidth: fitSize(1),
              }}
            >
              <Text
                style={{ fontSize: fitSize(13), color: item?.isChoose ? color.primary : "#fff" }}
              >
                {item?.isChoose ? "取消" : "选择"}
              </Text>
            </Touchable>
          </View>
        )}
      </Touchable>
    )
  },
)
const PriceItemForSearch = observer(({ item }: { item: marketItem }) => {
  const { collectStore } = useStores()
  const name = collectStore.nftForExchange.get(item?.nftAsset?.nftAddress + item?.nftAsset?.assetId)
    ?.name
  return (
    <Text
      style={{
        marginTop: "auto",
        color: color.price,
        fontSize: fitSize(17),
        marginBottom: fitSize(5),
        marginLeft: fitSize(5),
        fontWeight: "500",
      }}
    >
      {`${name}x${item?.nftAsset?.num}`}
    </Text>
  )
})
const styles = StyleSheet.create({})
