import { StyleSheet, Text, View } from "react-native"
import React, { ReactElement } from "react"
import { fitSize } from "../../../theme"
import { Touchable } from "../../../components"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { Source } from "react-native-fast-image"
import { collectBriefItemStore } from "../../../models/collects/collectBriefItem"

export type assetItemProps = {
  assetImage?: Source
  assetName?: string
  assetId?: string
  onNavigation?: (item: collectBriefItemStore) => void
  tokenURI?: string
  item: collectBriefItemStore
}

export const AssetItem: React.FC<assetItemProps> = React.memo(
  ({ assetImage, assetName, assetId, onNavigation = () => {}, item }): ReactElement => {
    return (
      <Touchable onPress={() => onNavigation(item)} style={styles.modalListContent}>
        <SizeImage isPreview source={assetImage} style={styles.modalCardImage} resizeMode="cover" />
        <Text numberOfLines={1} style={[styles.modalCardShowId]}>
          {assetId}
        </Text>
        <Text numberOfLines={1} style={[styles.modalCardShowId]}>
          {assetName}
        </Text>
      </Touchable>
    )
  },
)

const styles = StyleSheet.create({
  modalListContent: {
    height: fitSize(200),
    width: fitSize(100),
    margin: fitSize(13),
    alignItems: "center",
    justifyContent: "center",
  },
  modalCardImage: {
    width: fitSize(93),
    height: fitSize(140),
    alignSelf: "center",
    backgroundColor:'#333333'
  },
  modalCardShowId: {
    fontSize: fitSize(8),
    marginTop: fitSize(6),
    width: fitSize(93),
    height: fitSize(16),
    backgroundColor: "#666",
    lineHeight: fitSize(16),
    textAlign: "center",
    borderRadius: fitSize(2),
    color: "#fff",
  },
})
