import { Platform } from "react-native"

const config = {
  production: {},
  dev: {},
  common: {
    verson: "0.0.1",
    name: "SHOU",
    jpush: "6792ae410996a42231194e71",
    perfix: ["maasSHOU://", "https://maasSHOU.cn", "http://maasSHOU.cn"],
    packageName: "com.maasSHOU",
    platform: Platform.OS,
  },
}

export const AppInfo = {
  ...config.production,
  ...config.common,
}
