import {
  Instance,
  SnapshotOut,
  applySnapshot,
  destroy,
  flow,
  getSnapshot,
  types,
} from "mobx-state-tree"
import {
  collectBagItemModel,
  collectBagItemStore,
  collectBagItemStoreSnapshot,
} from "./collectBagItem"
import {
  collectBriefItemModel,
  collectBriefItemStore,
  collectBriefItemStoreSnapshot,
} from "./collectBriefItem"
import { collectCardItemModel, collectCardItemStoreSnapshot } from "./collectCardItem"
import { seriesModel, seriesStoreSnapshot } from "./series"

import { GToast } from "../../navigators/root-navigator"
import { atlasPageInfoModel } from "./atlas/atlasPageInfo"
import { collectAtlasItemStore } from "./collectAtlasItem"
import { collectAtlasListModel } from "./collectAtlasList"
import { collectForExchangeModel } from "./collectForExchange"
import { collectUserAtlasItemModel } from "./collectUserAtlasItem"
import { nftcfgModel } from "./nftcfg"
import { openItems } from "../../services/api"
import { retry } from "../../utils/retry"
import { rowPadEnd } from "../../utils/rowpad"
import { showError } from "../../utils/showUtil"
import { withEnvironment } from "../extensions/with-environment"

const fetchAssetInfo = async (baseUrl: string, nftId: string) => {
  try {
    return await fetch(`${baseUrl}/${nftId}`, {
      headers: { "Cache-control": "no-store" },
    })
      .then((data) => data.json())
      .then((e) => {
        if (e?.code === 0) {
          return e
        } else {
          throw { message: "获取资源出错" + `${baseUrl}/${nftId}` }
        }
      })
  } catch (err) {
    // GToast.current.showToast(err.message, "CANCEL", 2000)
    // console.log(err)
    throw err
  }
}
const fetchAssetInfoFromTokenUri = async (uri) => {
  try {
    return await fetch(`${uri}`, {
      headers: { "Cache-control": "no-store" },
    })
      .then((data) => data.json())
      .then((e) => {
        if (e?.code === 0) {
          return e
        } else {
          throw { message: "获取资源出错" + `${uri}` }
        }
      })
  } catch (err) {
    // GToast.current.showToast(err.message, "CANCEL", 2000)
    // console.log(err)
    throw err
  }
}

export interface foldCards extends collectBriefItemStore {
  foldCounts: number
}
//sort 排序 0默认1卡牌类型 sort1 筛选 0全部1仅卡牌2卡包
const sortFromItems = <T extends collectBriefItemStore>(
  seriesId: number,
  sort: number,
  sort1: number,
  data: Map<string, T>,
) => {
  const t = Array.from(data.values()).filter((ele) => {
    const item = ele?.item
    return sort == 0
      ? (item?.asset?.Type === 1 || item?.asset?.Type === 3) && item?.asset?.SeriesId === seriesId
      : sort == 1
      ? item?.asset?.Type === 1 && item?.asset?.SeriesId === seriesId
      : item?.asset?.Type === 3 && item?.asset?.SeriesId === seriesId
  })
  if (sort1 === 0) {
    t.sort((a, b) => {
      if (a?.item?.asset?.Type !== b?.item?.asset?.Type)
        return b.item?.asset?.Type > a.item?.asset?.Type ? 1 : -1
      if (b?.isNew === true && a?.isNew === true) {
        return Number(b?.item?.asset?.assetId) > Number(a?.item?.asset?.assetId) ? -1 : 1
      } else if (b?.isNew === false && a?.isNew === false) {
        return Number(b?.item?.asset?.assetId) > Number(a?.item?.asset?.assetId) ? -1 : 1
      } else {
        return Number(b?.isNew) - Number(a?.isNew)
      }
    })
  } else {
    t.sort((a, b) => {
      return b?.item?.asset?.assetId - a?.item?.asset?.assetId
    })
  }
  return t
}
export interface nftlike {
  nftAddress: string
  NftId: string
}

export const collectsModel = types
  .model("collectsStore")
  .props({
    series: types.map(seriesModel),
    nftcfg: types.map(nftcfgModel),
    collectsCache: types.map(collectBagItemModel),
    isFetchingSeries: false,
    isFetchingCollects: false,
    //用户持有卡 map: key nftAddress+_+nftId
    collects: types.map(collectBriefItemModel),
    //所有卡牌信息 map: key address+assetId value 卡牌信息
    allCardsItemMapAddress: types.map(collectCardItemModel),
    nftForExchange: types.map(collectForExchangeModel),
  })
  .extend(withEnvironment)
  .views((self) => ({
    //所有卡牌盲盒 sort 排序 0默认1卡牌类型 sort1 筛选 0全部1仅卡牌2卡包
    cardsFromSeries(seriesId: number, sort: number, sort1: number) {
      return sortFromItems(seriesId, sort, sort1, self.collects)
    },
    //所有物品推叠 sort 排序 0默认1卡牌类型 sort1 筛选 0全部1仅卡牌2卡包
    foldCardsFromSeries(seriesId: number, sort: number, sort1: number): foldCards[] {
      let foldMap = new Map<string, foldCards>()
      const collects = Array.from(self.collects.values()).filter(
        (i) => i?.item?.asset?.SeriesId === seriesId,
      )
      for (let ele of collects) {
        const e = ele
        const flag = `${e.item?.asset?.Type}-${e.item?.asset?.assetId}`
        const findItem = foldMap.get(flag)
        if (findItem) {
          foldMap.set(flag, {
            ...findItem,
            foldCounts: findItem.foldCounts + 1,
            isNew: e.isNew || findItem.isNew,
          })
        } else {
          foldMap.set(flag, { ...e, foldCounts: 1 })
        }
      }
      return sortFromItems(seriesId, sort, sort1, foldMap)
    },
  }))
  .actions((self) => {
    return {
      getCollectRemote: flow(function* <T extends nftlike | collectBriefItemStoreSnapshot>(
        item: T,
        tokenUri?: string,
      ) {
        // console.log(item + tokenUri + "---start get---")
        const key = `${item.nftAddress}_${item.NftId}`
        let cache = localStorage.getItem(key)
        if (cache) {
          console.log("cache", cache)
          return JSON.parse(cache)
        }
        // console.log("item.nftAddress", key)
        // const cfg = self.nftcfg.get(String(item.nftAddress))
        try {
          // if (!cfg) {
          //   GToast.current.showToast("找不到配置清单", "CANCEL", 2000)
          //   console.log("找不到配置清单", "address", key)
          //   self.collectsCache.set(key, {})
          //   return undefined
          // }
          const rsp = yield retry(() => fetchAssetInfoFromTokenUri(tokenUri), 5)
          if (rsp?.data) {
            const result = {
              ...rsp.data,
              asset: { key: String(item.nftAddress) + rsp.data.assetId, ...rsp.data },
              temp: { key: String(item.nftAddress) + rsp.data.assetId, ...rsp.data },
              key,
            } as collectBagItemStoreSnapshot
            // //TODO: 如果返回失败处理方式
            // self.collectsCache.set(key, result)
            console.log(item.NftId + "---finish get---", rsp?.data)
            localStorage.setItem(key, JSON.stringify(result))
            return result
          } else {
            // self.collectsCache.set(key, { key })
            return undefined
          }
        } catch (err) {
          // GToast.current.showToast(`获取${key}资源出错`, "CANCEL", 2000)
          // self.collectsCache.set(key, { key })
          return undefined
        }
      }),
    }
  })
  .actions((self) => ({
    getCollectsRemote: flow(function* <T extends nftlike & { tokenUri: string }>(items: T[]) {
      const startTime = new Date().getTime()
      const size = items.length
      if (size <= 0) return []

      const results: collectBagItemStore[] = new Array(size).fill(undefined)
      const MaxRequestNum = 10

      let dealIndex = 0
      const workCoroutine = async function () {
        while (dealIndex < size) {
          let index = dealIndex++
          const info = await self.getCollectRemote(items[index], items[index]?.tokenUri)
          if (info) {
            //@ts-ignore
            results[index] = info
          }
        }
      }

      const promises = new Array(MaxRequestNum).fill(0).map(() => workCoroutine())
      yield Promise.all(promises)
      console.log("------comsuming time:------", new Date().getTime() - startTime)
      return results
    }),
    clearCollectCache: () => {
      self.collects.clear()
      // self.collectsCache.clear()
    },
  }))
  .actions((self) => ({
    saveCollects: (co: collectBriefItemStoreSnapshot[]) => {
      applySnapshot(
        self.collects,
        co.reduce(
          (base, card) => ({
            ...base,
            [card.nftAddress + "_" + card.NftId]: {
              ...card,
              item: card.nftAddress + "_" + card.NftId,
            },
          }),
          {},
        ),
      )
    },
    saveSeries: (co: seriesStoreSnapshot[]) => {
      applySnapshot(
        self.series,
        co.reduce(
          (base, series) => ({
            ...base,
            [series.id]: {
              ...series,
            },
          }),
          {},
        ),
      )
    },
    saveRemoteCollectCaches: (co: collectBagItemStoreSnapshot[]) => {
      const reduced = co.reduce(
        (base, card) => ({
          ...base,
          [card.key]: {
            ...card,
          },
        }),
        {},
      )
      console.log("reduced", reduced)

      self.collectsCache.merge(reduced)
    },

    remove: (item: collectBagItemStoreSnapshot) => {
      destroy(item)
    },

    //@ts-ignore
    addOneCollect: flow(function* (item: collectBriefItemStoreSnapshot | nftlike) {
      const cfg = self.nftcfg.get(String(item.nftAddress))
      let doSeriesQuest = false
      if (cfg) {
        try {
          const info: collectBagItemStore | undefined = yield self.getCollectRemote(item)
          if (info) {
            if (self.collects.size === 0) {
              doSeriesQuest = true
            }
            self.collects.set(item.nftAddress + "_" + item.NftId, {
              ...item,
              isNew: true,
              item: item.nftAddress + "_" + item.NftId,
            })
            //@ts-ignore
            if (doSeriesQuest) yield self.getAllSerie(false)
          } else {
            GToast.current.showToast("查询信息失败", "CANCEL", 2000)
          }
          return info
        } catch (err) {
          // GToast.current.showToast(err.message, "CANCEL", 2000)
          console.log(err)
        }
      } else {
        GToast.current.showToast("找不到配置清单", "CANCEL", 2000)
        console.log("找不到配置清单", "address", item.nftAddress)
      }
    }),
  }))
  .actions((self) => {
    const removeOneItem = (id: string) => {
      self.collects.delete(id)
      // self.collectsCache.delete(id)
    }

    //新增
    return {
      saveNftForExchange: flow(function* () {
        const nftInfos = yield self.environment.api.normalRequest("/market/exchangableNfts")
        // debugger
        if (nftInfos.kind === "ok") {
          const data = nftInfos.data as {
            nftInfos: {
              nftAddress: string
              assetId: number
              name: string
            }[]
          }
          const finalREs = data.nftInfos.reduce(
            (base, next) => ({
              ...base,
              [next.nftAddress + next.assetId]: {
                key: next.nftAddress + next.assetId,
                ...next,
              },
            }),
            {},
          )
          console.log(finalREs)

          applySnapshot(self.nftForExchange, finalREs)

          console.log(self.nftForExchange)
        }
      }),
      addCollects: flow(function* (items: (collectBriefItemStoreSnapshot | nftlike)[]) {
        const result = [] as (collectBagItemStore | undefined)[]
        for (let ele of items) {
          const res: collectBagItemStore | undefined = yield self.addOneCollect(ele)
          if (!res) return undefined
          result.push(res)
        }
        return result
      }),
      //获取所有nft
      getAllBagItemsFromAddresses: flow(function* (showFetching: boolean, address?: string[]) {
        let resArr = []
        let page = 1
        let totalPage = 1
        const BatchLimit = 5
        if (showFetching) self.isFetchingCollects = true
        const result = yield self.environment.api.normalRequest(
          "/series/getUserSeriesNftByPage",
          {
            page: page,
            pageSize: 50,
            contractAddress: address,
          },
          "post",
        )
        if (result.kind === "ok") {
          resArr[page] = result.data.list
          totalPage = result.data.totalPage
          page++
        } else {
          showError(result)
          console.log(result)
          __DEV__ && console.tron.log(result.kind)
          if (showFetching) self.isFetchingCollects = false
          return
        }
        const doCoroutine = async () => {
          while (page <= totalPage) {
            let index = page++
            const res = await self.environment.api.normalRequest(
              "/series/getUserSeriesNftByPage",
              {
                page: index,
                pageSize: 50,
                contractAddress: address,
              },
              "post",
            )
            if (res.kind === "ok") {
              resArr[index] = res.data.list
            } else {
              //@ts-ignore
              throw new Error(res?.msg || "error")
            }
          }
        }
        yield Promise.all(new Array(BatchLimit).fill(0).map(() => doCoroutine()))
        resArr = resArr.flat(1).map((e) => ({
          NftId: e?.nftId,
          nftAddress: e?.nftAddress,
          tokenUri: e?.tokenURI,
        }))
        const collectRemotes = yield self.getCollectsRemote(resArr)
        console.log("collectRemotes", collectRemotes)
        self.saveRemoteCollectCaches(collectRemotes)
        // const newFlagResult = resArr.map((item) => {
        //   const nftInfo = self.collects.get(String(item.nftAddress + "_" + item.NftId))
        //   return { ...item, isNew: nftInfo !== undefined ? !!nftInfo.isNew : false }
        // })
        // console.log("=====isNewFlagResult=====", newFlagResult)
        // let finalResArr = newFlagResult
        // console.log("finalResArr", finalResArr.length, finalResArr)
        self.saveCollects(resArr)

        if (showFetching) self.isFetchingCollects = false
      }),

      //获取所有nft
      getAllBagItems: flow(function* (showFetching: boolean, seriesId?: number) {
        let resArr = []
        let page = 0
        let totalPage = 1
        const BatchLimit = 5
        if (showFetching) self.isFetchingCollects = true
        const result = yield self.environment.api.normalRequest("/user/asset/getNftIdByPage", {
          Page: page,
          seriesId: seriesId,
        })
        if (result.kind === "ok") {
          resArr[page] = result.data.Data
          totalPage = result.data.TotalPage
          page++
        } else {
          showError(result)
          console.log(result)
          __DEV__ && console.tron.log(result.kind)
          if (showFetching) self.isFetchingCollects = false
          return
        }
        const doCoroutine = async () => {
          while (page <= totalPage - 1) {
            let index = page++
            const res = await self.environment.api.normalRequest("/user/asset/getNftIdByPage", {
              Page: index,
              seriesId: seriesId,
            })
            if (res.kind === "ok") {
              resArr[index] = res.data.Data
            } else {
              //@ts-ignore
              throw new Error(res?.msg || "error")
            }
          }
        }
        yield Promise.all(new Array(BatchLimit).fill(0).map(() => doCoroutine()))
        resArr = resArr.flat(1)
        yield self.getCollectsRemote(resArr)
        const newFlagResult = resArr.map((item) => {
          const nftInfo = self.collects.get(String(item.nftAddress + "_" + item.NftId))
          return { ...item, isNew: nftInfo !== undefined ? !!nftInfo.isNew : false }
        })
        console.log("=====isNewFlagResult=====", newFlagResult)
        let finalResArr = newFlagResult
        //只拉取这一个系列
        if (seriesId) {
          const exceptCollectsArray = Array.from(self.collects.values())
            .filter((t) => {
              return t.item?.asset?.SeriesId != seriesId
            })
            .map((t) => ({ NftId: t.NftId, isNew: t.isNew, nftAddress: t.nftAddress }))
          console.log("exceptCollectsArray", exceptCollectsArray.length, exceptCollectsArray)
          finalResArr = [...newFlagResult, ...exceptCollectsArray]
        }
        console.log("finalResArr", finalResArr.length, finalResArr)
        self.saveCollects(finalResArr)

        if (showFetching) self.isFetchingCollects = false
      }),
      sendOneCard: removeOneItem,
      openRandom: flow(function* (newItem: openItems, oldItemID: string) {
        const items = newItem.nftIds.map((data) => {
          return { NftId: data, nftAddress: newItem.nftAddress }
        })
        const quests = items.map((data) => {
          //@ts-ignore
          return self.addOneCollect(data)
        })
        try {
          const res = yield Promise.all(quests)

          console.log("questOpenData", res)
          return res as collectBagItemStore[]
        } catch (err) {
          showError(err)
          return undefined
        }
      }),
      //查询nft所属的系列
      getSeriesDetails: flow(function* (showFetching: boolean) {
        if (showFetching) self.isFetchingSeries = true
        const series = new Set<number>()
        let infos = []
        self.collects.forEach((item) => {
          series.add(item?.item?.asset?.SeriesId)
        })
        // console.log("seriessss", [...series])
        const result = yield self.environment.api.normalRequest("/user/asset/series/batchInfo", {
          seriesIds: 1,
        })
        if (result.kind === "ok") {
          infos = result.data.infos
        } else {
          showError(result)
          if (showFetching) self.isFetchingSeries = false
          __DEV__ && console.tron.log(result.kind)
          // throw new Error(result)
        }
        self.saveSeries(infos)
        if (showFetching) self.isFetchingSeries = false
        return infos
      }),
      //查询所有系列
      getAllSerie: flow(function* (showFetching: boolean) {
        if (showFetching) self.isFetchingSeries = true
        const result = yield self.environment.api.normalRequest("/system/series/allInfo")
        if (showFetching) self.isFetchingSeries = false
        if (result.kind === "ok") {
          self.saveSeries(result.data)
          return result.data
        } else {
          showError(result)
          __DEV__ && console.tron.log(result.kind)
          throw new Error(result)
        }
      }),

      changeNew: function (id: string) {
        const item = self.collects.get(id)
        if (item?.isNew) {
          item.isNew = false
        }
      },
      getAllNFTCfg: flow(function* () {
        //拉取nft配置地图
        const questCfg = yield self.environment.api.normalRequest("/user/asset/nftcfg")
        if (questCfg.kind === "ok") {
          applySnapshot(
            self.nftcfg,
            questCfg.data.list.reduce((base, item) => ({ ...base, [item.nftAddress]: item }), {}),
          )
          const cardsInfoReq = questCfg.data.list
          // .filter((item) => item.type === 1)
          console.log("cardsInfoReq", cardsInfoReq)
          let resourceDataTotal = {}
          for (let i = 0; i < cardsInfoReq.length; i++) {
            //所有卡牌信息
            if (cardsInfoReq[i]?.allCfgUrl) {
              self.environment.api.apisauce.setHeader("Cache-control", "no-store")
              const allCfg = yield self.environment.api.normalRequest(cardsInfoReq[i].allCfgUrl)
              if (allCfg.kind === "ok") {
                const cardData = allCfg.data.reduce(
                  (base, item) => ({
                    ...base,
                    [item.contractAddress + item.assetId]: {
                      ...item,
                      key: item.contractAddress + item.assetId,
                    },
                  }),
                  {},
                )
                resourceDataTotal = { ...resourceDataTotal, ...cardData }
                console.log(cardsInfoReq[i].seriesId, cardsInfoReq[i].nftAddress)
              } else {
                showError(allCfg)
              }
            }
          }
          applySnapshot(self.allCardsItemMapAddress, resourceDataTotal)
        } else {
          showError({ msg: "获取总配置失败" })
        }
      }),
      clearAllNewFlag: function () {
        self.collects.forEach((e) => {
          e.changeToOld()
        })
      },
    }
  })

type collectsStoreType = Instance<typeof collectsModel>
export interface collectsStore extends collectsStoreType {}
type collectsStoreSnapshotType = SnapshotOut<typeof collectsModel>
export interface collectsStoreSnapshot extends collectsStoreSnapshotType {}
export const createcollectsStoreDefaultModel = () => types.optional(collectsModel, {})
