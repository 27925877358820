import { HookFlatList, Text, Touchable } from "../../components"
import React, { useState } from "react"
import { StyleSheet, View } from "react-native"
import { color, fitSize } from "../../theme"

import { FlexView } from "../../components/screen/FlexView"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { useApi } from "../../hooks/useApi"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"
import { useUriInfo } from "../../hooks/useUriInfo"

interface CardsPageProps extends RootStackNavProps<"CardsPage"> {}

interface List {
  nftId: string
  tokenURI: string
}
const SeriesSize = 20
export const CardsPage: React.FC<CardsPageProps> = ({ navigation, route }) => {
  const { accountStore } = useStores()
  const fetchList = async (page: number, pageLimit = SeriesSize) => {
    const res = await accountStore.environment.api.normalRequest(
      `/series/getUserSeriesNftByPage`,
      {
        contractAddress: route?.params?.contractAddress,
        page,
        pageSize: pageLimit,
      },
      "post",
    )
    if (res.kind === "ok") {
      setTotal(res?.data?.totalNum)
      return res?.data?.list
    } else {
      throw res.msg
    }
  }
  const [list] = useAutoList<List>(
    {
      fetchData: fetchList,
      pageLimit: SeriesSize,
    },
    [],
  )
  const doAction = (item: List) => {
    // const action = route?.params?.action
    // switch (action) {
    //   case "homeFilter":
    //   // navigation.push("MarketSearchResultList", {
    //   //   seriesId: item?.id,
    //   //   seriesName: item?.seriesName,
    //   // })
    //   default:
    // }
  }
  const [total, setTotal] = useState(0)

  return (
    <FlexView>
      <Sheader notShowLeft={false} title={route?.params?.name} isNotSafe />
      {!!total && (
        <Text
          style={{
            fontSize: fitSize(13),
            color: color.primary,
            alignSelf: "flex-end",
            marginTop: fitSize(12),
            marginRight: fitSize(12),
            fontWeight: "500",
            // marginBottom: fitSize(11),
          }}
        >
          共{total}件
        </Text>
      )}
      <HookFlatList
        {...{ ...list }}
        contentContainerStyle={{ marginTop: fitSize(11), paddingLeft: fitSize(10) }}
        numColumns={3}
        renderItem={({ item, index }) => {
          return <CardItem action={route?.params?.action} item={item} />
        }}
      />
    </FlexView>
  )
}

const CardItem = ({ item, action }: { item: List; action: string }) => {
  const [data] = useUriInfo(item.tokenURI)
  const { push, navigate, getState } = useTypeNavigation()
  return (
    <Touchable
      onPress={() => {
        if (action === "addToChooOrder") {
          //@ts-ignore
          const routes = getState()?.routes
          const preRoutes = routes[routes.length - 3]
          let nftItem
          if (Array.isArray(preRoutes?.params?.nftItem)) {
            nftItem = [...preRoutes?.params?.nftItem, data]
          } else {
            nftItem = [data]
          }
          console.log("nftItem", nftItem)
          // debugger
          navigate("TradeToItemAddPage", { ...preRoutes.params, nftItem })
        } else {
          push("CardDetail", { nftItem: data, tokenURI: item?.tokenURI })
        }
      }}
      style={{
        width: fitSize(110),
        height: fitSize(148),
        marginHorizontal: fitSize(4.5),
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        shadowColor: "#000",
        shadowOpacity: 0.08,
        shadowOffset: { width: fitSize(0), height: fitSize(2) },
        shadowRadius: fitSize(5),
        marginVertical: fitSize(6),
      }}
    >
      <SizeImage
        source={{ uri: data?.Image }}
        resizeMode={"contain"}
        style={{
          width: fitSize(111),
          height: fitSize(99),
          borderTopLeftRadius: fitSize(10),
          borderTopRightRadius: fitSize(10),
          overflow: "hidden",
          backgroundColor: "#000",
        }}
      />
      <Text
        numberOfLines={1}
        style={{
          width: fitSize(111 - 5),
          fontSize: fitSize(13),
          marginTop: fitSize(5),
          marginLeft: fitSize(6),
          color: color.text,
        }}
      >
        {data?.Name}
      </Text>
      <Text
        numberOfLines={1}
        style={{
          width: fitSize(111 - 5),
          fontSize: fitSize(12),
          marginLeft: fitSize(6),
          color: color.dim,
        }}
      >
        {data?.CardShowId}
      </Text>
    </Touchable>
  )
}
const styles = StyleSheet.create({})
