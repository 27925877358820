import * as React from "react"

import { BottomTabBar, createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import {
  BottomTabParamList,
  TabFiveParamList,
  TabFourParamList,
  TabOneParamList,
  TabThreeParamList,
  TabTwoParamList,
} from "../type/RouteType"
import {
  Image,
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import { Images, color, fitSize } from "../theme"
import { useEffect, useState } from "react"

import { AppInfo } from "../config/AppInfo"
import { AssetScreen } from "../screens/TabAsset/AssetScreen"
import { GToast } from "."
import I18n from "i18n-js"
import { TabHome } from "../screens/TabHome/TabHome"
import { TabMine } from "../screens/TabMine/TabMine"
import { Touchable } from "../components"
import _ from "lodash"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { createStackNavigator } from "@react-navigation/stack"
import { getFocusedRouteNameFromRoute } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { themeData } from "./root-navigator"
import { titleText } from "./index.config"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../models/root-store/root-store-context"

const BottomTab = createBottomTabNavigator<BottomTabParamList>()
//@ts-ignore
export const BottomTabNavigator = observer(({ navigation, route }) => {
  const { collectStore, accountStore } = useStores()
  const insets = useSafeAreaInsets()
  return (
    <BottomTab.Navigator
      initialRouteName="TabOne"
      // detachInactiveScreens={false}
      screenOptions={{
        tabBarStyle: {
          // display: getFocusedRouteNameFromRoute(route) === "TabTwo" ? "none" : "flex",
          height: fitSize(60) + insets.bottom,
          backgroundColor: "#fff",
          elevation: 0,
          padding: 0,
          borderTopWidth: 0,
          shadowColor: "#000",
          shadowOffset: { width: 0, height: fitSize(2) },
          shadowOpacity: 0.1,
          shadowRadius: fitSize(10),
        },
      }}
    >
      <BottomTab.Screen
        name="TabOne"
        component={TabOneNavigator}
        options={{
          headerShown: false,
          // tabBarLabelPosition: "below-icon",
          tabBarIcon: ({ color, focused }) => (
            <TabBarLabel title={"市场"} color={color} focused={focused} />
          ),
          tabBarLabel: ({ color, focused }) => null,
        }}
      />
      <BottomTab.Screen
        name="TabTwo"
        component={TabTwoNavigator}
        options={{
          headerShown: false,
          tabBarButton: (props) => {
            return (
              <View
                style={{
                  top: fitSize(-22),
                  borderRadius: fitSize(65 / 2),
                  width: fitSize(65),
                  height: fitSize(65),
                  backgroundColor: "#fff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Touchable
                  onPress={() => {
                    if (!accountStore.token) {
                      GToast.current.doLogin()
                    } else {
                      navigation.push("ChooseSeries", { action: "homeSell" })
                      // GToast.current.showChooseAdd()
                    }
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: fitSize(57 / 2),
                    width: fitSize(57),
                    height: fitSize(57),
                    backgroundColor: "#00AFB5",
                    shadowColor: "#000",
                    shadowOffset: { width: 0, height: fitSize(2) },
                    shadowOpacity: 0.25,
                    shadowRadius: fitSize(4),
                  }}
                >
                  <Image
                    source={Images.tab.add}
                    style={{ width: fitSize(24), height: fitSize(24) }}
                    resizeMode="cover"
                  />
                </Touchable>
              </View>
            )
          },
        }}
      />
      <BottomTab.Screen
        name="TabFive"
        component={TabFiveNavigator}
        options={{
          headerShown: false,
          lazy: false,
          // tabBarLabelPosition: "below-icon",
          tabBarIcon: ({ color, focused }) => (
            <TabBarLabel title={"资产"} color={color} focused={focused} />
          ),
          tabBarLabel: ({ color, focused }) => null,
        }}
      />
    </BottomTab.Navigator>
  )
})

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { source: number | object; color: string; focused: boolean }) {
  // const { color, focused, source } = props
  return (
    <Image
      source={props.source}
      style={{
        width: fitSize(24),
        height: fitSize(24),
        resizeMode: "contain",
        tintColor: props.focused ? color.primary : color.lighterGrey,
        // top: scaleSize(-2),
      }}
    />
  )
}
function TabBarLabel(props: { title: string; color: string; focused: boolean }) {
  return (
    <View style={{ width: fitSize(60), alignItems: "center" }}>
      <Text
        style={{
          color: props.focused ? "#00AFB5" : "#333",
          fontSize: fitSize(19),
          fontWeight: "600",
        }}
      >
        {props.title}
      </Text>
    </View>
  )
}

const TabOneStack = createNativeStackNavigator<TabOneParamList>()

const TabOneNavigator = ({ navigation, route }: any) => {
  return (
    <TabOneStack.Navigator screenOptions={{ headerShown: false }}>
      <TabOneStack.Screen name="TabOneScreen" component={TabHome} />
    </TabOneStack.Navigator>
  )
}

const TabFiveStack = createNativeStackNavigator<TabFiveParamList>()

const TabFiveNavigator = observer(({ navigation, route }: any) => {
  const { accountStore, collectStore } = useStores()
  React.useLayoutEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      if (!accountStore?.token) {
        e.preventDefault()
        GToast.current.doLogin()
      }
    })
    return unsubscribe
  }, [navigation, accountStore])

  return (
    <TabFiveStack.Navigator screenOptions={{ headerShown: false }}>
      <TabFiveStack.Screen name="TabFiveScreen" component={TabMine} />
    </TabFiveStack.Navigator>
  )
})

const TabTWoStack = createNativeStackNavigator<TabTwoParamList>()

const TabTwoNavigator = ({ navigation, route }: any) => {
  return (
    <TabTWoStack.Navigator screenOptions={{ headerShown: false }}>
      <TabTWoStack.Screen name="TabTwoScreen" component={() => <View></View>} />
    </TabTWoStack.Navigator>
  )
}
