export const palette = {
  black: "#373737",
  white: "#ffffff",
  offWhite: "#F4F4F4",
  orange: "#FBA928",
  orangeDarker: "#EB9918",
  lightGrey: "#888888",
  midLightGrey: "#747474",
  lighterGrey: "#DCDCDC",
  angry: "#FF2E11",
  cyan: "#00AFB5",
  backmain: "#333333",
  ligherBlack: "#202530",
  cyanLigter: "#06FDE899",
  grey: "#A2A3A3",
}
