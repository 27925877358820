import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const walletModel = types.model("walletStore").props({
  icon: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isOpen: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  payWay: types.maybeNull(types.number),
  platform: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
})

type walletStoreType = Instance<typeof walletModel>
export interface walletStore extends walletStoreType {}
type walletStoreSnapshotType = SnapshotOut<typeof walletModel>
export interface walletStoreSnapshot extends walletStoreSnapshotType {}
export const createwalletStoreDefaultModel = () => types.optional(walletModel, {})
