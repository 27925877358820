import { Instance, SnapshotOut, onSnapshot, types } from "mobx-state-tree"

import { AccountModel } from "../account/account"
import { collectsModel } from "../collects/collects"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  accountStore: types.optional(AccountModel, {} as any),
  collectStore: types.optional(collectsModel, {} as any),

}).actions((self) => ({
  removeToken:self.accountStore.removeAuth,
  removeAll(){
    self.accountStore.removeAuth();
    self.accountStore.clearUserInfo()
    self.collectStore.saveCollects([]);
    self.collectStore.saveSeries([])
  },
  afterCreate(){
    // onSnapshot(self,()=>{
    //   console.log('showToken',self.accountStore?.token)
    // })
    
  }
}))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
