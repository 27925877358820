import * as AnalysisData from "../utils/sendAnalysisData"

import { GToast, RootNavigation } from "../navigators"
import { hideLoad, showError, showInfo, showLoad } from "../utils/showUtil"
import { load, loadString } from "../utils/storage"
import { useEffect, useState } from "react"

import { Login } from "../utils/sendAnalysisData"
import { Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../models"

export const isWebView = function () {
  if (Platform.OS !== "web") {
    return false
  }
  //判断是否是微信
  var ua = navigator.userAgent.toLowerCase()
  const apps = ["micromessenger", "weibo", "lark", "weixin"]
  for (let i = 0; i < apps.length; i++) {
    if (ua.includes(apps[i])) {
      return true
    }
  }
  return false
}
export function useInit() {
  const navigation = useNavigation<any>()
  const { accountStore, collectStore, removeToken } = useStores()
  const store = useStores()
  const token = accountStore.token
  const [isNetSettle, setisNetSettle] = useState(false)

  useEffect(() => {
    if (isNetSettle && token) {
      doTokenChangeInit()
    }
  }, [token, isNetSettle])
  useEffect(() => {
    doRoutineInit()
  }, [])

  const renewalLoginState = async () => {
    await accountStore.environment.api
      .normalRequest("user/login/refreshToken", {}, "post")
      .then((res) => {
        if (res.kind === "ok") {
          console.log(res, "续期成功")
        }
      })
      .catch((err) => {
        console.log(err.message, "续期失败")
      })
  }
  const doTokenChangeInit = async () => {
    if (Platform.OS === "web") {
      //web页面临时离开
      if (localStorage.getItem("tempLeave") === "leave") {
        collectStore.environment.api.apisauce.setHeader("Authorization", token)
        accountStore.changeOk(true)
        localStorage.setItem("tempLeave", "stay")
        return
      }
    }
    try {
      collectStore.environment.api.apisauce.setHeader("Authorization", token)
      const userRes = await accountStore.getUserInfo()
      if (userRes.kind === "unauthorized") {
        renewalLoginState()
      }
      await accountStore.getWalletStatus()
      accountStore.changeOk(true)
    } catch (err) {
      showError(err)
      accountStore.clearUserInfo()
    }
  }
  const doRoutineInit = async () => {
    if (accountStore.comeInfo?.appid) {
      collectStore.environment.api.apisauce.setHeader("appid", accountStore.comeInfo?.appid)
    } else {
      const ht = new URL(window.location.href)
      const appid = ht.searchParams.get("appid")
      const redirectUrl = ht.searchParams.get("redirectUrl")
      if (appid) {
        collectStore.environment.api.apisauce.setHeader("appid", appid)
      }
      if (redirectUrl || appid) {
        accountStore.setComeInfo({ appid, fromUrl: redirectUrl })
      }
    }
    try {
      accountStore.changeOk(false)
      const res = getInfoFromUrl()
      collectStore.saveNftForExchange()
      setisNetSettle(true)
    } catch (err) {
      setisNetSettle(false)
      showError(err)
    }
  }
  const getInfoFromUrl = () => {
    const url = new URL(window.location.href)
    const platformId = url.searchParams.get("platformId")
    const fromUrl = url.searchParams.get("fromUrl")
    return { platformId, fromUrl }
  }
  return { isNetSettle }
}
