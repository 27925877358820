import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const seriesModel = types.model("seriesStore").props({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  coverImage: types.maybeNull(types.string),
  backgroundImage: types.maybeNull(types.string),
  totalCardsNumber: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  isReal: types.maybeNull(types.boolean),
  bagTopImage: types.maybeNull(types.string),
  bagBottomImage: types.maybeNull(types.string),
  atlasActiveBtnImage: types.maybeNull(types.string),
  atlasUnactiveBtnImage: types.maybeNull(types.string),
  atlasBackgroundImage: types.maybeNull(types.string),
  atlasPanneImage: types.maybeNull(types.string),
  atlasCardWrapImage: types.maybeNull(types.string),
  atlasCardLockImage: types.maybeNull(types.string),
})

type seriesStoreType = Instance<typeof seriesModel>
export interface seriesStore extends seriesStoreType {}
type seriesStoreSnapshotType = SnapshotOut<typeof seriesModel>
export interface seriesStoreSnapshot extends seriesStoreSnapshotType {}
export const createseriesStoreDefaultModel = () => types.optional(seriesModel, {})
