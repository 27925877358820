import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const collectForExchangeModel = types.model("collectForExchangeStore").props({
  key: types.identifier,
  nftAddress: types.maybeNull(types.string),
  assetId: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
})

type collectForExchangeStoreType = Instance<typeof collectForExchangeModel>
export interface collectForExchangeStore extends collectForExchangeStoreType {}
type collectForExchangeStoreSnapshotType = SnapshotOut<typeof collectForExchangeModel>
export interface collectForExchangeStoreSnapshot extends collectForExchangeStoreSnapshotType {}
export const createcollectForExchangeStoreDefaultModel = () =>
  types.optional(collectForExchangeModel, {})
