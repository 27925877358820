import "react-native-get-random-values"

import * as UUID from "uuid"

import { Instance, SnapshotOut, applySnapshot, flow, getParent, types } from "mobx-state-tree"
import { comeInfoModel, comeInfoStoreSnapshot } from "./comeInfo"
import { hideLoad, showError, showLoad } from "../../utils/showUtil"
import { walletModel, walletStoreSnapshot } from "./walletStatus/wallet"

import { AccountApi } from "../../services/api/account-api"
import { Platform } from "react-native"
import { RootNavigation } from "../../navigators"
import { settingModel } from "./setting"
import { userInfoModel } from "./userInfo"
import { walletStatus } from "./../../screens/Mywallet/Mywallet"
import { withEnvironment } from "../extensions/with-environment"

// import { thresholdApi } from "../../services/thresholdSign"
interface List {
  id: number
  name: string
  type: string
  payWay: number
  platform: string
  icon: string
  isOpen: boolean
}
export const AccountModel = types
  .model("AccountStore")
  .props({
    token: "",
    refreshToken: "",
    user: types.optional(userInfoModel, {}),
    setting: types.optional(settingModel, {}),
    isOK: false,
    showTab: true,
    comeInfo: types.optional(comeInfoModel, {}),
    walletStatus: types.map(walletModel),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get isHaveOneWallet(): boolean {
      const wallets = Array.from(self.walletStatus.values())
      if (wallets.length === 0) return false
      const walletsOpen = wallets.filter((e) => e.isOpen === true)
      if (walletsOpen.length === 0) return false
      return true
    },
  }))
  .actions((self) => {
    const accountApi = new AccountApi(self.environment.api)
    function setAuthToken(data: string) {
      console.log("api setAuthorization", data)
      self.environment.api.apisauce.setHeader("Authorization", data)
      // thresholdApi.apisauce.setHeader("Authorization", maasToken)
      // thresholdApi.apisauce.setHeader("MaasAuthorization", maasToken)

      self.token = data
    }
    function removeAuth() {
      if (self.token !== undefined) {
        self.environment.api.apisauce.deleteHeader("Authorization")
        // thresholdApi.apisauce.deleteHeader("Authorization")
        self.token = undefined
      }
    }
    async function createWallet(wallet: walletStoreSnapshot) {
      showLoad("正在创建支付钱包")
      const createAdapayWallet = await self.environment.api.normalRequest(
        "user/createWallet",
        {
          returnUrl: Platform.OS === "web" ? `${window.location.origin}/Mywallet` : undefined,
          payWay: wallet.payWay,
        },
        "post",
      )
      if (createAdapayWallet.kind === "ok") {
        hideLoad()
        if (Platform.OS === "web") {
          localStorage.setItem("tempLeave", "leave")
          window.location.href = createAdapayWallet.data.redirectUrl
        } else {
          //@ts-ignore
          RootNavigation.navigate("MassWebView", {
            uri: createAdapayWallet.data.redirectUrl,
          })
        }
      } else {
        showError(createAdapayWallet)
      }
    }
    return {
      getWalletStatus: flow(function* (isNeedToCreate = false) {
        const res = yield self.environment.api.normalRequest(
          "/user/getUseWalletStatus",
          {
            platform: Platform.OS,
          },
          "post",
        )
        if (res.kind === "ok") {
          const data = res?.data?.list as List[]
          if (Array.isArray(data)) {
            applySnapshot(
              self.walletStatus,
              data.reduce(
                (base, series) => ({
                  ...base,
                  [series.id]: {
                    ...series,
                  },
                }),
                {},
              ),
            )
            if (isNeedToCreate) {
              if (data?.length > 0 && data.filter((e) => e?.isOpen === true)?.length === 0) {
                yield createWallet(data[0])
              }
            }
          } else {
            throw { msg: "wallet返回数据错误" }
          }
        } else {
          throw res
        }
      }),
      getUserInfo: flow(function* () {
        const result = yield accountApi.getInfo()
        if (result.kind === "ok") {
          self.user = result.data
          return result
        } else if (result.kind === "unauthorized" || result?.code === 2010) {
          removeAuth()
          throw result
        } else {
          __DEV__ && console.tron.log(result.kind)
          throw result
        }
      }),
      doMessageLogin: function (token: string, maasToken?: string) {
        setAuthToken(token)
      },
      removeAuth,
      clearUserInfo: () => {
        self.user = undefined
        self.token = undefined
      },
      changeOk: (isOk: boolean = true) => {
        self.isOK = isOk
      },
      setTab: (data: boolean) => {
        self.showTab = data
      },
      setComeInfo: (data: comeInfoStoreSnapshot) => {
        self.comeInfo = data
      },
    }
  })

type AccountStoreType = Instance<typeof AccountModel>
export interface AccountStore extends AccountStoreType {}
type AccountStoreSnapshotType = SnapshotOut<typeof AccountModel>
export interface AccountStoreSnapshot extends AccountStoreSnapshotType {}
export const createAccountStoreDefaultModel = () => types.optional(AccountModel, {})
