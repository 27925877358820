import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
  useWindowDimensions,
} from "react-native"
import React, { useEffect, useRef, useState } from "react"
import { Touchable, TouchableDeep } from "../../components"
import { hideLoad, showError, showInfo } from "../../utils/showUtil"

import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { Images } from "../../theme/Images"
import { MyErrorCode } from "../../utils/errorCode"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { Toast } from "../../modal/GlobalModal"
import _ from "lodash"
import { array } from "mobx-state-tree/dist/internal"
import { color } from "../../theme"
import dayjs from "dayjs"
import { fitSize } from "../../theme/Layout"
import { useFocusEffect } from "@react-navigation/native"
import { useStores } from "../../models"

interface transactionInfoProps extends RootStackNavProps<"TradeToItemAddPage"> {}

type itemType = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: [string]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}

type consignSaleType = {
  nftId: number
  contractAddress: string
  price: string
  goodsName: string
}
const minPrice = 2
const maxPrice = 20000
const MAX_PUT_R = 2
export const TradeToItemAddPage: React.FC<transactionInfoProps> = ({ navigation, route }) => {
  const isOffer = route?.params?.isOffer

  const MAX_PUT = isOffer ? MAX_PUT_R : 1
  const { accountStore, collectStore } = useStores()

  const item = route.params?.nftItem
  // const [item, setItem] = useState(() => {
  //   return route.params?.nftItem
  // })
  // useEffect(() => {
  //   if (isOffer && item?.length) {
  //     onPurchase()
  //   }
  // }, [item])
  useFocusEffect(
    React.useCallback(() => {
      if (isOffer && item?.length) {
        onPurchase()
      }
    }, [route]),
  )

  const currentItem = item?.[item?.length - 1]
  console.log(currentItem)
  const fetchCreateTradeMarketorder = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/createTradeMarketorder",
      {
        nftId: Number(currentItem?.NftId),
        contractAddress: currentItem?.contractAddress,
        price: 200,
        isParity: false,
        barterRequestMsg: desData ? desData : undefined,
        orderType: isOffer ? 2 : 1,
        parentOrder: isOffer ? route?.params?.orderId : undefined,
      },
      "post",
    )
    GToast.current.showLoading("正在上架中")
    if (res.kind === "ok") {
      const content = await collectStore.environment.api.getSignature(
        "PayAbleMarket",
        {
          nftAddress: currentItem.contractAddress,
          nftId: currentItem.NftId,
          orderId: res.data.orderId,
        },
        true,
      )
      if (content.kind === "ok") {
        const msgRes = await collectStore.environment.api.doMessageIdSearch(content.data.msgId)
        if (msgRes.kind === "ok") {
          showInfo("上架成功")
          if (item?.length === MAX_PUT) {
            //@ts-ignore
            navigation.navigate("TabOneScreen")
          }
        } else {
          showError(msgRes)
          setNftItemsLessOne()
        }
        // setMsgId(content.data.msgId)
        // setIsAsyncFnc(true)
      } else {
        showError(content)
        setNftItemsLessOne()
      }
    } else {
      showError(res)
      setNftItemsLessOne()
      // if (res.kind === "error") {
      //   if (res.code === MyErrorCode.UserNotAdapayWallet) {
      //     requestAnimationFrame(() => GToast.current.showToast("请先开通支付钱包", "TOAST", 1500))
      //   } else if (res.code === MyErrorCode.ThirdPartyServiceError) {
      //     GToast.current.showToast("第三方服务器错误", "CANCEL", 1500)
      //   }
      // } else {
      //   showError(res)
      //   throw res?.msg
      // }
    }
  }

  const onPurchase = async () => {
    if (desData.length === 0) return showInfo("请填写您想要交换所得物品的描述")
    const res = await accountStore.environment.api.normalRequest(
      "/card/isApproveOperateCard",
      {
        // contractName: item?.contractName,1
        // cardContractAddress: item?.contractAddress,
        contractName: "PayAbleMarket",
        cardContractAddress: currentItem?.contractAddress,
      },
      "post",
    )
    GToast.current.showLoading("寄售上链中")
    if (res.kind === "ok") {
      if (res.data.isApprove) {
        fetchCreateTradeMarketorder()
      } else {
        const prePareRes = await collectStore.environment.api.userPrepareApprove(
          "PayAbleMarket",
          currentItem?.contractAddress,
        )
        if (prePareRes.kind === "ok") {
          fetchCreateTradeMarketorder()
        } else {
          showError(prePareRes)
          setNftItemsLessOne()
        }
      }
    } else {
      showError(res)
      setNftItemsLessOne()
    }
  }
  const setNftItemsLessOne = () => {
    if (route?.params?.nftItem?.length) {
      navigation.setParams({
        ...route.params,
        nftItem: route.params.nftItem?.slice(0, route?.params?.nftItem?.length - 1),
      })
    }
  }

  const [desData, setDesData] = useState("")

  const doPublish = () => {
    onPurchase()
  }

  return (
    <FlexView>
      <Sheader rightImage={""} title={"发布商品"} />
      <Text
        style={{
          fontSize: fitSize(15),
          color: color.text,
          marginTop: fitSize(15),
          marginLeft: fitSize(15),
          fontWeight: "600",
        }}
      >
        选择以物换物的物品（{route?.params?.nftItem?.length || 0}/{MAX_PUT}）
      </Text>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {item?.map((item, i) => {
          return (
            <View
              key={i}
              style={{
                width: fitSize(90),
                height: fitSize(129),
                backgroundColor: color.secBackground,
                borderRadius: fitSize(10),
                shadowColor: "#000",
                shadowOpacity: 0.08,
                shadowOffset: { width: fitSize(0), height: fitSize(2) },
                shadowRadius: fitSize(5),
                marginLeft: fitSize(15),
                marginTop: fitSize(12),
              }}
            >
              <SizeImage
                source={{ uri: item?.Image }}
                isPreview
                resizeMode={"contain"}
                style={{
                  width: fitSize(90),
                  height: fitSize(90),
                  borderTopLeftRadius: fitSize(10),
                  borderTopRightRadius: fitSize(10),
                  overflow: "hidden",
                  backgroundColor: "#000",
                }}
              />
              <Text
                numberOfLines={1}
                style={{
                  width: fitSize(90 - 8),
                  fontSize: fitSize(11),
                  marginTop: fitSize(5),
                  marginLeft: fitSize(8),
                  color: color.primary,
                }}
              >
                {item?.Name}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  width: fitSize(90 - 8),
                  fontSize: fitSize(10),
                  marginLeft: fitSize(8),
                  color: color.dim,
                }}
              >
                {item?.CardShowId}
              </Text>
            </View>
          )
        })}
        {item?.length !== MAX_PUT && (
          <Touchable
            onPress={() => {
              navigation.push("ChooseSeries", { action: "addToChooOrder" })
            }}
            style={{
              width: fitSize(90),
              height: fitSize(129),
              backgroundColor: "rgba(0, 175, 181, 0.1)",
              borderColor: "rgba(0, 175, 181, 0.2)",
              borderWidth: fitSize(1),
              borderRadius: fitSize(10),
              justifyContent: "center",
              alignItems: "center",
              marginLeft: fitSize(15),
              marginTop: fitSize(12),
            }}
          >
            <SizeImage
              source={Images.home.add}
              style={{ width: fitSize(24), height: fitSize(24) }}
            />
          </Touchable>
        )}
      </View>

      {!isOffer && (
        <>
          <Text
            style={{
              fontSize: fitSize(15),
              color: color.text,
              marginTop: fitSize(20),
              marginLeft: fitSize(15),

              fontWeight: "600",
            }}
          >
            添加以物换物描述
          </Text>
          <View>
            <TextInput
              maxLength={100}
              onChangeText={setDesData}
              value={desData}
              placeholder="描述的文字内容，后台限制100字"
              placeholderTextColor={color?.dim}
              style={{
                width: fitSize(345),
                height: fitSize(120),
                backgroundColor: "#fff",
                paddingTop: fitSize(12),
                paddingHorizontal: fitSize(15),
                fontSize: fitSize(13),
                color: color.text,
                alignSelf: "center",
                borderRadius: fitSize(10),
                marginTop: fitSize(12),
              }}
              multiline
            ></TextInput>
            <Text
              style={{
                color: "#888888",
                fontSize: fitSize(12),
                position: "absolute",
                bottom: fitSize(5),
                right: fitSize(35),
                zIndex: 100,
              }}
            >
              {desData.length}/100
            </Text>
          </View>
        </>
      )}
      <Text
        style={{
          fontSize: fitSize(15),
          color: color.text,
          marginTop: fitSize(20),
          marginLeft: fitSize(15),
          fontWeight: "600",
          marginBottom: fitSize(15),
        }}
      >
        以物换物规则
      </Text>
      <Text style={styles.role}>1. 主动发起方只可放置1件物品进行发布交换；</Text>
      <Text style={styles.role}>2. 与之交换方可最多选择2个物品进行交换；</Text>
      <Text style={styles.role}>3. 主动发起方有权选择交换物品中的其中任意1个；</Text>
      <Text style={styles.role}>4. 与之交换方需要对未被选中的交换物品进行下架操作。</Text>

      {!isOffer && (
        <View
          style={[
            styles.infoBar,
            { minHeight: fitSize(55), marginTop: "auto", paddingBottom: fitSize(10) },
          ]}
        >
          <View style={[styles.infoContent, { justifyContent: "center" }]}>
            <View style={[styles.rowCenter, { justifyContent: "center" }]}>
              <TouchableDeep
                onPressIn={() => {
                  // if (isOffer) {
                  // } else {
                  //   doPublish()
                  // }
                  doPublish()
                }}
                style={[
                  styles.button,
                  {
                    backgroundColor: color.primary,
                    borderWidth: fitSize(1),
                    borderColor: color.primary,
                  },
                ]}
                onPress={() => {}}
              >
                <Text style={[styles.buttonLabel, { color: color.whiteText }]}>
                  {isOffer ? "以物换物" : "发布"}
                </Text>
              </TouchableDeep>
            </View>
          </View>
        </View>
      )}
    </FlexView>
  )
}

const styles = StyleSheet.create({
  infoBar: {
    width: "100%",
    backgroundColor: "#fff",
    // position: "absolute",
    // bottom: 0,
    shadowColor: "#000",
    shadowOffset: { width: fitSize(0), height: fitSize(2) },
    shadowRadius: fitSize(15),
    shadowOpacity: 0.1,
  },
  infoContent: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: fitSize(16),
    paddingTop: fitSize(12),
    justifyContent: "space-between",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    minWidth: fitSize(345),
    height: fitSize(42),
    alignSelf: "center",

    borderRadius: fitSize(22),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    fontSize: fitSize(14),
    fontWeight: "600",
  },
  role: {
    fontSize: fitSize(13),
    color: color.dim,
    marginTop: fitSize(5),
    width: fitSize(338),
    alignSelf: "center",
  },
})
