import { Image, StyleSheet, View } from "react-native"
import { Images, color, fitSize } from "../../../theme"
import { Text, Touchable } from "../../../components"

import { MarketItem } from "./BoughtView"
import React from "react"
import { SizeImage } from "../../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"
import { TradeOrderStatusEnum } from "../define"
import { observer } from "mobx-react-lite"
import { useStores } from "../../../models"

interface BoughtItemProps {
  data: MarketItem
}
//0: 全部; 1:待支付; 2:已支付(上链中); 3:发货完成; 4:发货失败; 5: 已取消

export const orderStatus = {
  1: "待支付",
  2: "发送中",
  3: "发货完成",
  5: "已取消",
}
export const BoughtItem: React.FC<BoughtItemProps> = ({ data }) => {
  const item = data
  const [assetData] = useUriInfo(item.tokenURI)
  const navigation = useTypeNavigation()

  return (
    <Touchable
      onPress={() => {
        navigation.push("ShowOrder", { id: String(item?.tradeOrderId) })
      }}
      style={{
        width: fitSize(351),
        height: fitSize(154),
        alignSelf: "center",
        backgroundColor: "#fff",
        borderRadius: fitSize(10),
        marginTop: fitSize(12),
        marginHorizontal: fitSize(12),
      }}
    >
      <View
        style={{
          height: fitSize(40),
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingHorizontal: fitSize(15),
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderColor: "rgba(0, 0, 0, 0.06)",
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            color: "#888888",
            fontSize: fitSize(12),
            width: fitSize(200),
            flexShrink: 1,
          }}
        >
          订单编号 : {item.tradeOrderId}
        </Text>
        <View style={{ width: fitSize(50) }}>
          <Text
            numberOfLines={1}
            style={{
              color:
                item?.orderStatus === TradeOrderStatusEnum.Unpay ||
                item?.orderStatus === TradeOrderStatusEnum.Paying
                  ? "#FF2E11"
                  : item?.orderStatus === TradeOrderStatusEnum.SendSuccess
                  ? "#333333"
                  : item?.orderStatus === TradeOrderStatusEnum.Cancel
                  ? "#888888"
                  : "#00AFB5",
              fontSize: fitSize(13),
              width: fitSize(50),
              textAlign: "right",
            }}
          >
            {item?.orderStatus === TradeOrderStatusEnum.Unpay ||
            item?.orderStatus === TradeOrderStatusEnum.Paying
              ? `待付款`
              : item?.orderStatus === TradeOrderStatusEnum.SendSuccess
              ? "已完成"
              : item?.orderStatus === TradeOrderStatusEnum.Cancel
              ? "已取消"
              : "发送中"}
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          paddingTop: fitSize(12),
          flex: 1,
          paddingHorizontal: fitSize(12),
        }}
      >
        <SizeImage
          source={{ uri: assetData?.Image }}
          style={{
            width: fitSize(90),
            height: fitSize(90),
          }}
          resizeMode={"contain"}
        />
        <View style={{ flex: 1, paddingLeft: fitSize(12) }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Text
                numberOfLines={2}
                style={{
                  fontSize: fitSize(14),
                  color: "#333333",
                  fontWeight: "500",
                  width: "100%",
                }}
              >
                {item?.goodsName}
              </Text>
              <Text
                style={{
                  fontSize: fitSize(12),
                  color: "#888888",
                  fontWeight: "400",
                  marginTop: fitSize(5),
                }}
              >
                {assetData?.Series}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ justifyContent: "space-between" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: fitSize(5),
            }}
          >
            {!!item?.price && (
              <>
                <Text style={styles.priceText}>￥</Text>
                <Text preset={"numner"} style={styles.priceText}>
                  {item?.price / 100}
                </Text>
              </>
            )}
            {!!item?.nftAsset?.num && <PriceItemForSearch item={item} />}
          </View>
          {(item.orderStatus === TradeOrderStatusEnum.Unpay ||
            item?.orderStatus === TradeOrderStatusEnum.Paying) && (
            <View
              style={{
                backgroundColor: "#00AFB5",
                borderRadius: fitSize(15),
                width: fitSize(82),
                height: fitSize(30),
                alignItems: "center",
                justifyContent: "center",
                marginBottom: fitSize(12),
              }}
            >
              <Text style={{ color: "#fff", fontSize: fitSize(13), fontWeight: "600" }}>
                去支付
              </Text>
            </View>
          )}
        </View>
      </View>
    </Touchable>
  )
}
export const PriceItemForSearch = observer(({ item }: { item: MarketItem }) => {
  const { collectStore } = useStores()
  const name = collectStore.nftForExchange.get(item?.nftAsset?.nftAddress + item?.nftAsset?.assetId)
    ?.name
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text
        style={{
          color: color.price,
          fontSize: fitSize(14),
        }}
      >
        {`${item?.price ? " | " : ""}${name}x${item?.nftAsset?.num}`}
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  priceText: {
    color: "#FF2E11",
    fontSize: fitSize(16),
    fontWeight: "500",
  },
})
