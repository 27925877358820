import { Images, fitSize } from "../../theme"
import {
  ListRenderItemInfo,
  Platform,
  StatusBar,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native"
import React, { useEffect, useMemo } from "react"
import { Text, Touchable } from "../../components"
import { hideLoad, showError, showInfo, showLoad } from "../../utils/showUtil"

import { AssetCard } from "./Components/AssetCard"
import { HookFlatList } from "../../components"
import { RootStackNavProps } from "../../type/RouteType"
import { SeriesList } from "./Components/SeriesList"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import SplashScreen from "react-native-splash-screen"
import { doCopy } from "../../utils/doCopy"
import { observer } from "mobx-react-lite"
import { red } from "bn.js"
import { useAutoList } from "../../hooks/useAutoList"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useUriInfo } from "../../hooks/useUriInfo"

interface TabHomeProps extends RootStackNavProps<"HomeScreen"> {}

type ItemType = {
  appId: number
  appName: string
  iconUrl: string
  description: string
  assetNum: number
}
const PL = 10
export const TabMine: React.FC<TabHomeProps> = observer(({ navigation, route }) => {
  const { accountStore } = useStores()
  const windowHeight = useWindowDimensions().height
  const { top, bottom } = useSafeAreaInsets()

  //   const fetchAssetList = async (page: number, pageLimit = PL) => {
  //     const res = await accountStore.environment.api.normalRequest(
  //       `/card/getAppInfoByPage/${page}/${PL}`,
  //     )
  //     if (res.kind === "ok") {
  //       console.log(res)
  //       return res?.data?.list
  //     } else if (res.kind === "forbidden") {
  //       navigation.replace("LoginFromMessage")
  //     } else {
  //       return []
  //     }
  //   }
  // const [nftItem] = useUriInfo("http://yuanyi-rsc-test.maas-test.tianhe32.cn/KJ-HSL/10037")

  return (
    <View
      testID="TabHome"
      style={[
        styles.container,
        {
          height: Platform.OS === "web" ? windowHeight : undefined,
          flexGrow: 1,
        },
      ]}
    >
      <View style={[styles.header, { paddingTop: top + fitSize(20), height: top + fitSize(172) }]}>
        <View style={styles.userInfo}>
          <SizeImage style={styles.userAvatar} source={Images.mine.testAvatar}></SizeImage>
          <View style={{ justifyContent: "space-around" }}>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: fitSize(5) }}>
              <Text style={styles.userName}>
                {accountStore?.user?.phonenumber?.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}
              </Text>
              <Text style={styles.certification}>
                {accountStore?.user?.isRealname === 1 ? "已实名" : "未实名"}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text numberOfLines={1} style={styles.userDid}>
                {accountStore?.user?.account}
              </Text>
              <Touchable
                onPress={() => {
                  doCopy(accountStore?.user?.account)
                }}
              >
                <SizeImage
                  source={Images.mine.copyDid}
                  style={{ width: fitSize(15), height: fitSize(15) }}
                ></SizeImage>
              </Touchable>
            </View>
          </View>
          <View style={styles.setting}>
            <Touchable
              onPress={() => {
                navigation.push("Setting")
              }}
            >
              <SizeImage
                source={Images.mine.setting}
                style={{ width: fitSize(24), height: fitSize(24) }}
              ></SizeImage>
            </Touchable>
          </View>
        </View>
        <View style={styles.functionArea}>
          <Touchable
            onPress={() => {
              navigation.push("SaleCenter")
            }}
            style={styles.functionItem}
          >
            <SizeImage style={styles.functionIcon} source={Images.mine.sell}></SizeImage>
            <Text style={styles.functionTitle}>我出售的</Text>
          </Touchable>
          <View
            style={{
              width: fitSize(1),
              height: fitSize(37),
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          ></View>
          <Touchable
            onPress={() => {
              navigation.push("OrderBuy")
            }}
            style={styles.functionItem}
          >
            <SizeImage style={styles.functionIcon} source={Images.mine.buy}></SizeImage>
            <Text style={styles.functionTitle}>我买到的</Text>
          </Touchable>
          <View
            style={{
              width: fitSize(1),
              height: fitSize(37),
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          ></View>
          <Touchable
            onPress={() => {
              navigation.push("ChangeCenter")
            }}
            style={styles.functionItem}
          >
            <SizeImage style={styles.functionIcon} source={Images.home.changeItem}></SizeImage>
            <Text style={styles.functionTitle}>换物订单</Text>
          </Touchable>

          <View
            style={{
              width: fitSize(1),
              height: fitSize(37),
              backgroundColor: "rgba(255, 255, 255, 0.5);",
            }}
          ></View>
          <Touchable
            onPress={() => {
              navigation.push("Mywallet")
            }}
            style={styles.functionItem}
          >
            <SizeImage style={styles.functionIcon} source={Images.mine.wallet}></SizeImage>
            <Text style={styles.functionTitle}>我的钱包</Text>
          </Touchable>
        </View>
      </View>
      <View style={styles.body}>
        <View style={styles.collectionsTitle}>
          <Text style={{ fontSize: fitSize(15), color: "#333333", fontWeight: "600" }}>
            我的藏品
          </Text>
          <Touchable
            onPress={() => {
              navigation.push("ChooseSeries", { action: "homeSell" })
            }}
            style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}
          >
            <Text style={{ fontSize: fitSize(14), color: "#00AFB5", fontWeight: "500" }}>
              查看全部
            </Text>
            <SizeImage
              style={{ height: fitSize(16), width: fitSize(16) }}
              source={Images.mine.up}
            ></SizeImage>
          </Touchable>
        </View>
        {accountStore?.isOK && <SeriesList />}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  header: {
    flexDirection: "column",
    backgroundColor: "#00AFB5",
    width: "100%",
    height: fitSize(216),
    paddingLeft: fitSize(13),
    paddingRight: fitSize(20),
  },

  userInfo: {
    flexDirection: "row",
    alignItems: "center",
  },

  userAvatar: {
    width: fitSize(66),
    height: fitSize(66),
    borderRadius: fitSize(33),
    borderColor: "#fff",
    borderWidth: fitSize(3),
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: fitSize(10),
  },

  userName: {
    fontSize: fitSize(18),
    color: "#fff",
    marginRight: fitSize(5),
  },

  certification: {
    fontSize: fitSize(11),
    color: "#00AFB5",
    backgroundColor: "#fff",
    borderRadius: fitSize(10.5),
    paddingHorizontal: fitSize(6),
    paddingVertical: fitSize(2.5),
    justifyContent: "center",
    alignItems: "center",
  },

  userDid: {
    fontSize: fitSize(12),
    color: "rgba(255, 255, 255, 0.5);",
    marginRight: fitSize(5),
    width: fitSize(200),
  },

  setting: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-end",
    height: "100%",
  },

  functionArea: {
    flexDirection: "row",
    marginTop: fitSize(20),
    justifyContent: "space-around",
    alignItems: "center",
  },

  functionItem: {
    justifyContent: "space-around",
    alignItems: "center",
  },

  functionTitle: {
    fontSize: fitSize(12),
    color: "#fff",
    marginTop: fitSize(9),
  },

  functionIcon: {
    width: fitSize(28),
    height: fitSize(28),
  },

  body: {
    backgroundColor: "#fff",
    width: "100%",
    flex: 1,
    paddingHorizontal: fitSize(12),
    paddingTop: fitSize(20),
  },

  collectionsTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: fitSize(15),
  },

  collectionsList: {},

  collectionItem: {
    flexDirection: "row",
    alignItems: "center",
  },

  collectionName: {
    fontSize: fitSize(15),
    color: "#333333",
    marginBottom: fitSize(5),
    fontWeight: "600",
  },

  colectionNumber: {
    fontSize: fitSize(14),
    color: "#888888",
  },

  moreIcon: {
    height: fitSize(20),
    width: fitSize(20),
    marginLeft: "auto",
  },
})
