export const Images = {
  mine: {
    clear_icon: require("../../assets/images/clear.png"),
    //setting: require("../../assets/images/setting_mine.png"),
    mail: require("../../assets/images/home_mail.png"),
    testAvatar: require("../../assets/images/test_avatar.png"),
    copyDid: require("../../assets/images/copy_did.png"),
    setting: require("../../assets/images/setting.png"),
    sell: require("../../assets/images/sell.png"),
    buy: require("../../assets/images/buy.png"),
    wallet: require("../../assets/images/wallet.png"),
    up: require("../../assets/images/up.png"),
    testCollectionIcon: require("../../assets/images/test_collection_icon.png"),
    more: require("../../assets/images/more.png"),
  },
  common: {
    header_back: require("../../assets/images/header_more.png"),
    newEmpty: require("../../assets/images/no_data.png"),
    no_internet: require("../../assets/images/no_internet.png"),
    back_cyan: require("../../assets/images/default_back.png"),
    circle_layer1: require("../../assets/images/loading_layer1.png"),
    circle_layer2: require("../../assets/images/loading_layer2.png"),
    black_back: require("../../assets/images/black_back_arrow.png"),
    white_back: require("../../assets/images/white_back.png"),
    white_share: require("../../assets/images/white_share.png"),
  },
  components: {
    select_gray_activity: require("../../assets/images/select_gray_activity.png"),
    select_gray_unactivity: require("../../assets/images/select_gray_unactivity.png"),
    select_green_activity: require("../../assets/images/select_green_activity.png"),
    select_green_unactivity: require("../../assets/images/select_green_unactivity.png"),
    filter: require("../../assets/images/filter.png"),
    copy: require("../../assets/images/cpoy_address.png"),
    share: require("../../assets/images/white_share_acti.png"),
    header_back: require("../../assets/images/header_more.png"),
    mini_default_background: require("../../assets/images/default_mini_background.png"),
    cancel_modal: require("../../assets/images/cancel_modal_new.png"),
    choose: require("../../assets/images/pay_choose.png"),
    not_choose: require("../../assets/images/pay_not_choose.png"),
    search_clear: require("../../assets/images/search_clear.png"),
    count: require("../../assets/images/count.png"),
    calendar: require("../../assets/images/calendar.png"),
  },
  setting: {
    back: require("../../assets/images/setting_right_back.png"),
    show_link2: require("../../assets/images/link_activity2.png"),
    testAvatar: require("../../assets/images/test_avatar.png"),
  },
  market: {
    mine: require("../../assets/images/person.png"),
    search: require("../../assets/images/Search.png"),
    delete: require("../../assets/images/delete_his.png"),
    series: require("../../assets/images/title_icon.png"),
    more_right: require("../../assets/images/more_right.png"),
  },
  tab: {
    market: require("../../assets/images/market_tab_a.png"),
    bag: require("../../assets/images/bag_tab.png"),
    ji: require("../../assets/images/ji.png"),
    add: require("../../assets/images/pure_add.png"),
  },
  test: {
    m_owner1: require("../../assets/images/header_test.png"),
    wallet_icon: require("../../assets/images/test_wallet_icon.png"),
  },
  home: {
    search: require("../../assets/images/search_home.png"),
    sort_de: require("../../assets/images/sort_default.png"),
    sort_top: require("../../assets/images/sort_top.png"),
    sort_down: require("../../assets/images/sort_down.png"),
    logo: require("../../assets/images/logo.png"),
    more: require("../../assets/images/more_home.png"),
    search_gray: require("../../assets/images/search_home_gray.png"),
    delete: require("../../assets/images/delete_home_search.png"),
    filter: require("../../assets/images/filter_home.png"),
    people: require("../../assets/images/peoples.png"),
    time: require("../../assets/images/time.png"),
    add: require("../../assets/images/Unionadd.png"),
    changeItem: require("../../assets/images/changeItem.png"),
  },
  share: {
    close: require("../../assets/images/share_close.png"),
    wechat_icon: require("../../assets/images/share_wechat_icon.png"),
    save_local: require("../../assets/images/save_local.png"),
    copy_link: require("../../assets/images/link_activity2.png"),
  },
}
