import {
  Image,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"
import { Images, color, deviceHeight, fitSize } from "../../theme"
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { Touchable, TouchableDeep } from "../../components"
import { hideLoad, showError, showInfo } from "../../utils/showUtil"

import Clipboard from "@react-native-community/clipboard"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { LinerGradient } from "./components/MyLinerGradient"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import Modal from "react-native-modal/dist/modal"
import { MyErrorCode } from "../../utils/errorCode"
import QrSVG from "react-native-qrcode-svg"
import { RootStackNavProps } from "../../type/RouteType"
import { SealImageComp } from "../TabHome/Components/MarketItem"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { doCopy } from "../../utils/doCopy"
import { observer } from "mobx-react-lite"
import { useApi } from "../../hooks/useApi"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useUriInfo } from "../../hooks/useUriInfo"

interface transactionScreenProps extends RootStackNavProps<"TransactionScreen"> {}

export type itemType = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: [string]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}
export interface infoType {
  id: number
  orderId: string
  sellerUserId: number
  goodsName: string
  price: number
  nftId: number
  totalIssueCount: number
  currentCirculation: number
  supportWallet: SupportWallet[]
  contractAddress: string
  orderStatus: MarketOrderStatusEnum
  tokenURI: string
  userAddress: string
  phonenumber: string
  createdAt: number
  updatedAt: number
  nftHistory: NftHistory[]
  nftTradeHistory: NftTradeHistory[]
  blockExplorerUrl: string
  tagType: number
  contractType?: number
  nftAsset?: {
    assetId: number
    nftAddress: string
    num: number
  }
}
export const enum MarketOrderStatusEnum {
  Created = 0,
  OnSelling = 1,
  OnSelled = 2,
  UnSelling = 3,
  Unselled = 4,
  Locked = 5,
  Selled = 6,
  OnSellFailed = 7,
  UnSellFailed = 8,
}
interface SupportWallet {
  id: number
  name: string
  type: string
  payWay: number
  platform: string
  icon: string
  isOpen: boolean
}
interface NftHistory {
  from: string
  to: string
  transactionHash: string
  blockTime: string
}
interface NftTradeHistory {
  sellerUserAddress: string
  sellerUserPhonenumber: string
  buyerUserAddress: string
  buyerUserPhonenumber: string
  price: number
  payTime: string
}
export const regFenToYuan = (fen) => {
  let num = fen
  num = (fen * 0.01).toFixed(2)
  num += ""
  let reg = num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, "$1")
  return num
}

export type userInfoType = {
  account: string
  id: number
  isRealname: number
  phonenumber: string
  userCustId: string | null
}

export const TransactionScreen: React.FC<transactionScreenProps> = observer(
  ({ navigation, route }): ReactElement => {
    const isMine = route?.params?.isMine
    const { accountStore, collectStore } = useStores()
    const { bottom, top } = useSafeAreaInsets()
    const userInfo = accountStore?.user
    const loadCount = useRef(0)
    useFocusEffect(
      useCallback(() => {
        loadCount.current++
        if (loadCount.current >= 2) doRequest()
      }, []),
    )

    const { result, doRequest, setResult } = useApi<infoType>({
      api: {
        path: "/market/getTradeMarketorderDetail",
        params: {
          id: Number(route.params.id),
          platform: Platform.OS,
        },
        way: "post",
      },
    })
    const info = result?.result

    const onPurchase = async () => {
      if (!accountStore.token) {
        GToast.current.doLogin()
        return
      }

      if (userInfo.id === info.sellerUserId) {
        GToast.current.showToast("不可购买自己的在售商品", "TOAST", 2000)
        return
      }
      // debugger
      if (accountStore.isHaveOneWallet && !info.nftAsset?.num) {
        //创建订单
        const res = await accountStore.environment.api.createTradeOrder(
          info?.orderId,
          info.updatedAt,
        )
        if (res.kind === "ok") {
          navigation.push("ShowOrder", { id: res.data.tradeOrderId })
        } else if (res.kind === "forbidden") {
          navigation.replace("LoginFromMessage")
        } else if (res.kind === "error") {
          if (res.code === MyErrorCode.MarketOrderBuyerIsSellerError) {
            GToast.current.showToast("不可购买自己的在售商品", "TOAST", 2000)
          } else if (res.code === MyErrorCode.UserNotAdapayWallet) {
            GToast.current.showToast("还未创建支付钱包", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderInfoExpired) {
            GToast.current.showToast("订单信息已更新,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderStatusError) {
            GToast.current.showToast("订单状态异常,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.ChainErrorUserNotOwn) {
            GToast.current.showToast("该资产已经上架或已售出,请重新刷新页面", "TOAST", 2000)
          } else {
            showError(res)
          }
        }
      } else if (info.nftAsset?.num) {
        const res = await accountStore.environment.api.createTradeOrder(
          info?.orderId,
          info.updatedAt,
        )
        if (res.kind === "ok") {
          navigation.push("ShowOrder", { id: res.data.tradeOrderId })
        } else {
          showError(res)
        }
      } else {
        accountStore.getWalletStatus(true)
      }
    }
    const [item] = useUriInfo(info?.tokenURI, [info?.tokenURI])
    const showShare = () => {
      setIsShowShare(true)
    }
    const [isShowShare, setIsShowShare] = useState(false)
    const shareModal = () => {
      const onPressShareButton = () => {
        navigator.share({
          title: item.Name,
          url: window.location.href,
        })
      }
      return (
        <Modal
          useNativeDriverForBackdrop
          hasBackdrop={true}
          coverScreen={false}
          isVisible={isShowShare}
          animationIn="fadeIn"
          animationOut="fadeOut"
          style={{ margin: 0 }}
          onBackButtonPress={() => {
            setIsShowShare(false)
          }}
          onBackdropPress={() => {
            setIsShowShare(false)
          }}
          swipeDirection={"down"}
          backdropTransitionOutTiming={0}
          useNativeDriver
          deviceHeight={deviceHeight}
          hideModalContentWhileAnimating={true}
        >
          <LinerGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={{
              width: fitSize(280),
              height: fitSize(444),
              alignSelf: "center",
              borderRadius: fitSize(10),
            }}
            colors={["#00AFB5", "#FFFFFF"]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginVertical: fitSize(14),
                marginLeft: fitSize(12),
              }}
            >
              <Text style={{ fontSize: fitSize(17), fontWeight: "600", color: "#fff" }}>分享</Text>
              <Touchable
                onPress={() => {
                  setIsShowShare(false)
                }}
                style={{ position: "absolute", right: fitSize(12) }}
              >
                <SizeImage
                  source={Images.share.close}
                  style={{ width: fitSize(26), height: fitSize(26) }}
                ></SizeImage>
              </Touchable>
            </View>
            <View
              style={{
                width: fitSize(232),
                height: fitSize(312),
                backgroundColor: "#fff",
                borderRadius: fitSize(10),
                marginLeft: fitSize(24),
              }}
            >
              <View
                style={{
                  width: fitSize(200),
                  height: fitSize(200),
                  borderRadius: fitSize(10),
                  backgroundColor: "#1E2228",
                  borderWidth: fitSize(4),
                  borderColor: "#7889A1",
                  margin: fitSize(16),
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SealImageComp
                  resizeMode={"contain"}
                  style={{
                    width: fitSize(184),
                    height: fitSize(184),
                  }}
                  {...item}
                />
                {/* <SizeImage
                  source={{ uri: item?.Image }}
                  resizeMode={"contain"}
                  style={{
                    width: fitSize(184),
                    height: fitSize(184),
                  }}
                ></SizeImage> */}
              </View>

              <View //   分割线
                style={{
                  width: fitSize(184),
                  marginLeft: fitSize(24),
                  borderColor: "rgba(44, 48, 59, 0.15);",
                  borderWidth: fitSize(1),
                  borderStyle: "dashed",
                }}
              ></View>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{ marginLeft: fitSize(24), marginTop: fitSize(12), width: fitSize(140) }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      numberOfLines={1}
                      style={{
                        // flexShrink: 0,
                        fontSize: fitSize(16),
                        fontWeight: "600",
                        color: "#333333",
                      }}
                    >
                      {item?.Name}
                    </Text>
                    {/* <Text
                      numberOfLines={1}
                      style={{
                        marginLeft: fitSize(6),
                        fontSize: fitSize(12),
                        fontWeight: "600",
                        marginTop: fitSize(5),
                      }}
                    >
                      {item?.CardShowId}
                    </Text> */}
                  </View>
                  <Text style={{ fontSize: fitSize(10), color: "#888888" }}>{item?.Series}</Text>
                  {/* <Text style={{ color: "#FF2E11", fontSize: fitSize(14), fontWeight: "600" }}>
                    价格：{true ? "一箱可乐券x10" : `￥${info?.price / 100}`}
                  </Text> */}
                </View>
                <View // 二维码
                  style={{
                    position: "absolute",
                    right: fitSize(24),
                    top: fitSize(20),
                  }}
                >
                  <QrSVG size={fitSize(40)} value={window.location.href} />
                </View>
              </View>
            </View>
            <View // 底部两个按钮
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: fitSize(21),
              }}
            >
              {Platform.OS === "web" ? (
                <>
                  <Touchable
                    onPress={() => doCopy(window.location.href)}
                    style={{
                      width: fitSize(110),
                      height: fitSize(38),
                      backgroundColor: "#00AFB5",
                      borderRadius: fitSize(55),
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: "rgba(0, 0, 0, 0.5)",
                      borderBottomWidth: fitSize(2),
                    }}
                  >
                    <SizeImage
                      source={Images.share.copy_link}
                      style={{ width: fitSize(18), height: fitSize(18) }}
                    ></SizeImage>
                    <Text style={{ fontSize: fitSize(12), color: "#fff", marginLeft: fitSize(2) }}>
                      复制链接
                    </Text>
                  </Touchable>
                </>
              ) : (
                <>
                  <Touchable
                    onPress={onPressShareButton}
                    style={{
                      width: fitSize(110),
                      height: fitSize(38),
                      backgroundColor: "#00AFB5",
                      borderRadius: fitSize(55),
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: "rgba(0, 0, 0, 0.5)",
                      borderBottomWidth: fitSize(2),
                    }}
                  >
                    <SizeImage
                      source={Images.share.wechat_icon}
                      style={{ width: fitSize(24), height: fitSize(24) }}
                    ></SizeImage>
                    <Text style={{ fontSize: fitSize(12), color: "#fff", marginLeft: fitSize(2) }}>
                      分享到微信
                    </Text>
                  </Touchable>
                  <Touchable
                    onPress={onPressShareButton}
                    style={{
                      width: fitSize(110),
                      height: fitSize(38),
                      backgroundColor: "#00AFB5",
                      borderRadius: fitSize(55),
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: "rgba(0, 0, 0, 0.5)",
                      borderBottomWidth: fitSize(2),
                    }}
                  >
                    <SizeImage
                      source={Images.share.save_local}
                      style={{ width: fitSize(24), height: fitSize(24) }}
                    ></SizeImage>
                    <Text style={{ fontSize: fitSize(12), color: "#fff", marginLeft: fitSize(2) }}>
                      保存到本地
                    </Text>
                  </Touchable>
                </>
              )}
            </View>
          </LinerGradient>
        </Modal>
      )
    }
    const [msgId, setMsgId] = useState("")
    const [isAsyncFnc, setIsAsyncFnc] = useState(false)
    const goEdit = () => {
      navigation.push("TransactionInfo", {
        orderId: info?.orderId,
        isEdit: true,
        price: info.price,
        nftItem: item,
      })
    }
    const goTakeDown = () => {
      GToast.current.showAlert(
        `藏品下架后回到「资产」查看，若想再次出售需重新发售。确认下架吗？`,
        () => {},
        () => {
          doTakeDown()
        },
      )
    }
    const doTakeDown = async () => {
      GToast.current.showLoading("正在下架中")
      const content = await collectStore.environment.api.getSignature(
        "PayAbleMarket",
        {
          nftAddress: item.contractAddress,
          nftId: item.NftId,
          orderId: info.orderId,
        },
        false,
        info?.contractType === 0
          ? undefined
          : {
              contractName: "GShouMarket",
              domain: "GShouMarket",
              version: "0.0.1",
              operationName: "GShouMarketPutOn",
              data: {
                nftAddresses: [item.contractAddress],
                nftIds: [item.NftId],
                orderId: info.orderId,
              },
            },
      )
      const res = await accountStore.environment.api.normalRequest(
        "/market/unSellNft",
        { orderId: info.orderId, signature: content.data },
        "post",
      )
      if (res.kind === "ok") {
        setMsgId(res.data.msgId)
        setIsAsyncFnc(true)
      } else {
        hideLoad()
        showInfo("订单已锁定")
      }
    }

    const getAsyncResult = async (value: string) => {
      const res = await accountStore.environment.api.normalRequest(
        "/user/getAsyncResult",
        {
          msgId: value,
        },
        "post",
      )
      if (res.kind === "ok") {
        if (JSON.parse(res.data).status === 2) {
          hideLoad()

          collectStore.addOneCollect({
            nftAddress: JSON.parse(res.data).nftAddress,
            NftId: JSON.parse(res.data).nftIds,
          })
          GToast.current.showToast("下架成功", "IMG", 2000, true, 1, 1, {
            image: item?.Image,
            name: JSON.parse(res.data).nftAddress,
            id: JSON.parse(res.data).nftIds,
          })
          navigation.goBack()
        } else if (JSON.parse(res.data).status === 1) {
          return
        } else {
          setMsgId("")
          setIsAsyncFnc(false)
          showError(res)
        }
      } else {
        hideLoad()
        showInfo(res?.msg)
      }
    }

    useEffect(() => {
      let time = null
      if (isAsyncFnc) {
        time = setInterval(() => {
          getAsyncResult(msgId)
        }, 1000)
      }
      return () => {
        clearInterval(time)
      }
    }, [isAsyncFnc])
    const isShowPrice = false

    return (
      <FlexView bgColor={color.secBackground}>
        <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"} />
        <Sheader
          notShowLeft={false}
          title={""}
          leftImage={"white_back"}
          rightImage={!!info ? "white_share" : ""}
          rightAction={() => {
            showShare()
          }}
          style={{ backgroundColor: "transparent", position: "absolute", top: top }}
        />
        <LoadingComp result={result} doRequest={doRequest}>
          <ScrollView
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: fitSize(50) }}
          >
            <SealImageComp resizeMode={"contain"} style={styles.img} {...item} />
            {/* <SizeImage
              isGif={item?.DefaultFormat === "gif"}
              resizeMode={"contain"}
              source={{ uri: item?.DefaultFormat === "gif" ? item?.GifImage : item?.Image }}
              style={styles.img}
            /> */}
            <View
              style={{
                height: fitSize(38),
                borderBottomColor: "#F2F2F2",
                borderBottomWidth: StyleSheet.hairlineWidth,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SizeImage
                source={Images.market.series}
                style={{
                  width: fitSize(22),
                  height: fitSize(22),
                  marginLeft: fitSize(10),
                  marginRight: fitSize(5),
                }}
              />
              <Text style={{ fontSize: fitSize(13), fontWeight: "600", color: color.primary }}>
                {item?.Series}
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: fitSize(15),
                width: "100%",
                shadowColor: "#000",
                shadowOffset: { width: fitSize(0), height: fitSize(2) },
                shadowRadius: fitSize(15),
                shadowOpacity: 0.1,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center", marginTop: fitSize(10) }}>
                <Text style={{ color: color.text, fontWeight: "600", fontSize: fitSize(20) }}>
                  {info?.goodsName}
                </Text>
                <View style={{ top: -fitSize(3), marginLeft: "auto" }}>
                  <Text
                    style={{
                      color: color.text,
                      fontWeight: "600",
                      fontSize: fitSize(16),
                    }}
                  >
                    {item?.CardShowId}
                  </Text>
                </View>
              </View>
              <Text style={{ fontSize: fitSize(11), color: color.primary }}>
                持有者{` `}
                {info?.userAddress}
              </Text>
              <View style={{}}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {!!info?.price && (
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ top: fitSize(11) }}>
                        <Text
                          style={{
                            fontSize: fitSize(16),
                            fontWeight: "900",
                            fontFamily: "Noto Sans SC",
                            color: color.price,
                          }}
                        >
                          ￥
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: fitSize(26),
                          color: color.price,
                          fontWeight: "700",
                          marginTop: fitSize(2),
                          marginBottom: fitSize(10),
                          fontFamily: "Noto Sans SC",
                        }}
                      >
                        {regFenToYuan(info?.price ?? "-")}
                      </Text>
                    </View>
                  )}
                  {!!info?.nftAsset?.num && <PriceItemForSearch item={info} />}
                </View>

                {info?.tagType === 1 && (
                  <View
                    style={{
                      width: fitSize(64),
                      height: fitSize(23),
                      borderRadius: fitSize(11.5),
                      backgroundColor: "#00AFB5",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      bottom: fitSize(12),
                      right: fitSize(0),
                    }}
                  >
                    <Text style={{ fontSize: fitSize(12), fontWeight: "500", color: "#fff" }}>
                      平价商品
                    </Text>
                  </View>
                )}
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.rowCenter}>
                  <Text style={styles.leftRow}>发行</Text>
                  <Text style={styles.rightRow}>
                    {info?.totalIssueCount}
                    {` `}份
                  </Text>
                </View>
                <View style={[styles.rowCenter, { marginLeft: fitSize(5) }]}>
                  <Text style={styles.leftRow}>流通</Text>
                  <Text style={styles.rightRow}>
                    {info?.currentCirculation}
                    {` `}份
                  </Text>
                </View>
              </View>
              {!isMine && (
                <Touchable
                  onPress={onPurchase}
                  disabled={
                    info?.orderStatus !== MarketOrderStatusEnum.OnSelled ||
                    userInfo.id === info?.sellerUserId
                  }
                  style={{
                    width: fitSize(345),
                    height: fitSize(44),
                    borderRadius: fitSize(22),
                    backgroundColor:
                      info?.orderStatus === MarketOrderStatusEnum.OnSelled &&
                      userInfo.id !== info?.sellerUserId
                        ? color.primary
                        : color.dim,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: fitSize(13),
                  }}
                >
                  <Text
                    style={{
                      color: color.secBackground,
                      fontWeight: "600",
                      fontSize: fitSize(16),
                    }}
                  >
                    {userInfo.id === info?.sellerUserId
                      ? "不可购买自己的在售商品"
                      : info?.orderStatus === MarketOrderStatusEnum.OnSelled
                      ? "立即购买"
                      : info?.orderStatus === MarketOrderStatusEnum.Locked
                      ? "商品待支付锁定中"
                      : info?.orderStatus === MarketOrderStatusEnum.Selled
                      ? "已售出"
                      : ""}
                  </Text>
                </Touchable>
              )}
              <Text
                style={{
                  color: color.text,
                  fontSize: fitSize(12),
                  fontWeight: "500",
                  marginTop: fitSize(8),
                }}
              >
                支持支付方式
              </Text>
              <View
                style={{ marginTop: fitSize(6), flexDirection: "row", marginBottom: fitSize(15) }}
              >
                {info?.supportWallet?.map((e) => {
                  return (
                    <View
                      key={e.id}
                      style={{
                        paddingHorizontal: fitSize(8),
                        paddingVertical: fitSize(2.5),
                        backgroundColor: "rgba(0, 175, 181, 0.2)",
                        borderRadius: fitSize(10),
                        marginRight: fitSize(5),
                      }}
                    >
                      <Text style={{ fontSize: fitSize(11), color: color.primary }}>{e.name}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              style={{
                paddingHorizontal: fitSize(15),
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: fitSize(20),
                flexDirection: "row",
              }}
            >
              <Text style={{ fontSize: fitSize(15), color: color.text, fontWeight: "600" }}>
                历史轨迹
              </Text>
              <Touchable
                onPress={() => {
                  navigation.push("NftHistory", {
                    nftId: info?.nftId,
                    contractAddress: info?.contractAddress,
                  })
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Text style={{ fontSize: fitSize(14), fontWeight: "500", color: color.primary }}>
                  查看全部
                </Text>
                <SizeImage
                  source={Images.market.more_right}
                  style={{ width: fitSize(16), height: fitSize(16) }}
                />
              </Touchable>
            </View>
            <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(2) }}>
              {info?.nftHistory?.map((e, i) => (
                <View
                  key={i}
                  style={{ flexDirection: "row", width: "100%", marginVertical: fitSize(4) }}
                >
                  <Text style={{ fontSize: fitSize(12), color: color.dim }}>
                    {dayjs(e?.blockTime).format("YYYY/MM/DD HH:mm:ss")}
                  </Text>
                  <View style={{ marginLeft: "auto" }}>
                    <Text
                      numberOfLines={1}
                      style={{ fontSize: fitSize(12), color: color.dim, width: fitSize(150) }}
                    >
                      from{` `}
                      {e?.from}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{ fontSize: fitSize(12), color: color.dim, width: fitSize(150) }}
                    >
                      to{` `}
                      {e?.to}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={{
                paddingHorizontal: fitSize(15),
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: fitSize(11),
                flexDirection: "row",
              }}
            >
              <Text style={{ fontSize: fitSize(15), color: color.text, fontWeight: "600" }}>
                成交记录
              </Text>
              <Touchable
                onPress={() => {
                  navigation.push("TradeHistory", {
                    nftId: info?.nftId,
                    contractAddress: info?.contractAddress,
                  })
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Text style={{ fontSize: fitSize(14), fontWeight: "500", color: color.primary }}>
                  查看全部
                </Text>
                <SizeImage
                  source={Images.market.more_right}
                  style={{ width: fitSize(16), height: fitSize(16) }}
                />
              </Touchable>
            </View>
            <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(2) }}>
              {info?.nftTradeHistory?.map((e, i) => (
                <View
                  key={i}
                  style={{ flexDirection: "row", width: "100%", marginVertical: fitSize(4) }}
                >
                  <Text style={{ fontSize: fitSize(12), color: color.dim }}>
                    {dayjs(e?.payTime).format("YYYY/MM/DD HH:mm:ss")}
                  </Text>
                  <View style={{ marginLeft: "auto" }}>
                    <Text
                      numberOfLines={1}
                      style={{ fontSize: fitSize(12), color: color.text, fontWeight: "600" }}
                    >
                      ￥{regFenToYuan(e?.price)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <Text
              style={{
                fontSize: fitSize(15),
                color: color.text,
                fontWeight: "600",
                marginLeft: fitSize(15),
              }}
            >
              链信息
            </Text>
            <View style={{ paddingHorizontal: fitSize(15), marginTop: fitSize(6) }}>
              <View style={{ width: "100%", flexDirection: "row", marginVertical: fitSize(4) }}>
                <Text style={{ fontSize: fitSize(12), color: color.dim }}>合约名称</Text>
                <Text style={{ fontSize: fitSize(12), color: color.text, marginLeft: "auto" }}>
                  {item?.contractName}
                </Text>
              </View>
              <View style={{ width: "100%", flexDirection: "row", marginVertical: fitSize(4) }}>
                <Text style={{ fontSize: fitSize(12), color: color.dim }}>合约地址</Text>
                <Text
                  numberOfLines={1}
                  style={{
                    fontSize: fitSize(12),
                    color: color.text,
                    marginLeft: "auto",
                    width: fitSize(150),
                  }}
                >
                  {item?.contractAddress}
                </Text>
                <Touchable onPress={() => doCopy(item?.contractAddress)}>
                  <Image
                    source={Images.components.copy}
                    style={{ width: fitSize(15), height: fitSize(15), marginLeft: fitSize(5) }}
                  />
                </Touchable>
              </View>
            </View>
          </ScrollView>
          {isMine && (
            <View
              style={[
                styles.infoBar,
                { minHeight: fitSize(55), marginTop: "auto", paddingBottom: fitSize(10) },
              ]}
            >
              <View style={[styles.infoContent, { justifyContent: "center" }]}>
                <View style={[styles.rowCenter, { justifyContent: "center" }]}>
                  <TouchableDeep
                    style={[
                      styles.button,
                      {
                        backgroundColor: "transparent",
                        borderWidth: fitSize(1),
                        borderColor: color.primary,
                      },
                    ]}
                    onPress={goTakeDown}
                  >
                    <Text style={[styles.buttonLabel, { color: color.primary }]}>下架</Text>
                  </TouchableDeep>
                </View>
                {/* <TouchableDeep
                  style={[styles.button, { backgroundColor: color.primary }]}
                  onPress={goEdit}
                >
                  <Text style={[styles.buttonLabel, { color: color.background }]}>编辑</Text>
                </TouchableDeep> */}
              </View>
            </View>
          )}
        </LoadingComp>
        {shareModal()}
      </FlexView>
    )
  },
)
const PriceItemForSearch = observer(({ item }: { item: infoType }) => {
  const { collectStore } = useStores()
  const name = collectStore.nftForExchange.get(item?.nftAsset?.nftAddress + item?.nftAsset?.assetId)
    ?.name
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: fitSize(6) }}>
      <View
        style={{
          paddingHorizontal: fitSize(8),
          paddingVertical: fitSize(2.5),
          backgroundColor: "rgba(0, 175, 181, 0.2)",
          borderRadius: fitSize(10),
          marginRight: fitSize(5),
        }}
      >
        <Text style={{ fontSize: fitSize(11), color: color.primary }}>数玩券</Text>
      </View>

      <Text
        style={{
          color: color.price,
          fontSize: fitSize(14),
        }}
      >
        {`${name}x${item?.nftAsset?.num}`}
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  sheaderTitle: {
    color: "#fafafa",
  },
  rightWrapStyle: {
    width: fitSize(50),
  },
  nameBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: fitSize(10),
    marginBottom: fitSize(15),
  },
  nameText: {
    fontSize: fitSize(17),
    color: "#06FDE8",
    fontWeight: "500",
  },
  priceText: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  content: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(150),
    marginBottom: fitSize(10),
  },
  contentTopInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(30),
    paddingHorizontal: fitSize(5),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  spaceLine: {
    width: fitSize(2),
    height: fitSize(14),
    backgroundColor: "#06FDE8",
    marginRight: fitSize(5),
  },
  seriesName: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  totalNumber: {
    fontSize: fitSize(13),
    color: "#ffffff",
    padding: fitSize(3),
  },
  contentBottomInfo: {
    flexGrow: 1,
    paddingLeft: fitSize(10),
    paddingTop: fitSize(3),
    borderBottomWidth: 1,
    borderColor: "#99999944",
    marginTop: fitSize(7),
  },
  seriseContent: {
    fontSize: fitSize(15),
    color: "#9a9a9a",
  },
  addressInfo: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(60),
    marginBottom: fitSize(10),
    borderBottomWidth: 1,
    borderColor: "#99999944",
  },
  addressInfoContent: {
    flexGrow: 1,
  },
  infoBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentView: {
    flexShrink: 1,
    height: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  titleLeft: {
    fontSize: fitSize(14),
    color: "#808080",
  },
  contentRight: {
    fontSize: fitSize(12),
    color: "#999999",
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
  copyImg: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
    tintColor: "#06FDE8",
  },
  img: {
    width: fitSize(375),
    height: fitSize(375),
    backgroundColor: "#000",
  },
  infoBar: {
    width: "100%",
    backgroundColor: "#fff",
    // position: "absolute",
    // bottom: 0,
    shadowColor: "#000",
    shadowOffset: { width: fitSize(0), height: fitSize(2) },
    shadowRadius: fitSize(15),
    shadowOpacity: 0.1,
  },
  infoContent: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: fitSize(16),
    paddingTop: fitSize(12),
    justifyContent: "space-between",
  },
  button: {
    minWidth: fitSize(266),
    height: fitSize(42),
    alignSelf: "center",

    borderRadius: fitSize(22),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    fontSize: fitSize(14),
    fontWeight: "600",
  },
  shareIcon: {
    width: fitSize(20),
    height: fitSize(20),
    tintColor: "#06FDE8",
  },
  leftRow: {
    color: color.secBackground,
    backgroundColor: color.primary,
    width: fitSize(32),
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopLeftRadius: fitSize(2),
    borderBottomLeftRadius: fitSize(2),
    fontSize: fitSize(11),
  },
  rightRow: {
    color: "#666",
    backgroundColor: "rgb(240,	240	,240	)",
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopRightRadius: fitSize(2),
    borderBottomRightRadius: fitSize(2),
    paddingHorizontal: fitSize(5),
    fontSize: fitSize(11),
  },
})
function userRef(arg0: number) {
  throw new Error("Function not implemented.")
}
