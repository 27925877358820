import { HookFlatList, Text, Touchable } from "../../../components"
import { StyleSheet, View } from "react-native"
import { color, fitSize } from "../../../theme"

import { MarketItem } from "./MarketItem"
import React from "react"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { showError } from "../../../utils/showUtil"
import { useAutoList } from "../../../hooks/useAutoList"
import { useIsFocused } from "@react-navigation/native"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

interface MarketListProps {
  searchText?: string
  seriesId?: number
  orderPrice?: number
  orderTime?: number
  onChangeTotal?: (data: number) => void
  navId?:number
}
const PL = 12
export type marketItem = {
  // id: number
  // goodsName: string
  // price: number
  // tokenURI: string
  // tagType?: number
  // barterRequestMsg?: string
  // sellerName?: string
  nftAddress: string
  // skuId: number
  skuIds: number[]
  groupName: string
  skuGroupImage: string
  minPrice: number
  latestPrice: number
  onSellNum: number
}
const getOrderText = (order: number) => {
  switch (order) {
    case 1:
      return "ASC"
    case 2:
      return "DESC"
    default:
      return undefined
  }
}
export const MarketFolderList: React.FC<MarketListProps> = ({
  searchText,
  orderPrice,
  orderTime,
  seriesId,
  navId,
  onChangeTotal,
}) => {
  const { accountStore } = useStores()
  const navigation = useNavigation() as any

  // 首页的列表
  const fetchData = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getTradeMarketSkuGroup",
      {
        page,
        pageSize: PL,
        goodsName: searchText || undefined,
        seriesId,
        navId,
        order:
          orderPrice || orderTime
            ? {
                price: getOrderText(orderPrice),
                createdAt: getOrderText(orderTime),
              }
            : undefined,
      },
      "post",
    )
    if (res.kind === "ok") {
      if (res.data?.totalCount > 0) {
        if (onChangeTotal) onChangeTotal(res.data?.totalCount)
      }
      return res.data?.data
    } else {
      throw { msg: res.msg }
    }
  }

  const [list] = useAutoList<marketItem>(
    {
      fetchData: fetchData,
      pageLimit: 12,
      isFocusRefresh: false,
    },
    [orderPrice, orderTime, seriesId,navId],
  )

  return (
    <View style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...list }}
        numColumns={2}
        contentContainerStyle={{ paddingLeft: fitSize(7) }}
        renderItem={({ item, index }) => {
          return <MarketFolderItem item={item} />
        }}
      />
    </View>
  )
}
const dealPrice = (price: number) => {
  if (typeof price === "number") {
    return Math.floor((price / 100) * 100) / 100
  } else {
    return 0
  }
}
export const MarketFolderItem: React.FC<MarketItemProps> = ({ item }) => {
  const navigation = useTypeNavigation()
  // const [data] = useUriInfo(item.tokenURI, [item.tokenURI])
  return (
    <Touchable
      onPress={() => {
        navigation.push("MarketSearchResultList", {
          skuInfo: {
            skuIds: item?.skuIds,
            skuNftAddress: item?.nftAddress,
          },
          searchWord: item?.groupName,
        })
      }}
      style={{
        width: fitSize(170),
        height: fitSize(208),
        margin: fitSize(5.5),
        // padding: fitSize(10),
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        shadowColor: "#000",
        shadowOpacity: 0.08,
        shadowOffset: { width: fitSize(0), height: fitSize(2) },
        shadowRadius: fitSize(5),
      }}
    >
      <View style={{
          width: fitSize(170),
          height: fitSize(150),
          backgroundColor: "#000",
          borderTopLeftRadius: fitSize(10),
          borderTopRightRadius: fitSize(10),      
          overflow: "hidden",
      }}>
        <SizeImage
          source={{ uri: item?.skuGroupImage }}
          style={{
            width: fitSize(170),
            height: fitSize(150),
        
          }}
          resizeMode={"contain"}
        >
          <View
            style={{
              position: "absolute",
              bottom: 0,
              width: fitSize(170),
              height: fitSize(22),
              backgroundColor: "rgba(0,0,0,0.7)",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: fitSize(4),
            }}
          >
            <Text style={{ fontSize: fitSize(12), color: "#fff" }}>{item?.onSellNum}个寄售中</Text>
          </View>
        </SizeImage>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: fitSize(8),
          marginTop: fitSize(5),
        }}
      >
        <Text
          numberOfLines={1}
          style={{ color: color.text, fontSize: fitSize(15), fontWeight: "500" }}
        >
          {item?.groupName || ""}
        </Text>
      </View>

      <Text
        style={{
          marginTop: "auto",
          color: color.price,
          fontSize: fitSize(12),
          marginBottom: fitSize(8),
          marginLeft: fitSize(8),
        }}
      >
        <Text style={{ color: "#888", fontSize: fitSize(12) }}>最近成交:</Text>￥
        {dealPrice(item?.latestPrice)}
      </Text>
    </Touchable>
  )
}
export type nftItem = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: string[]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}

interface MarketItemProps {
  item: marketItem
}

const styles = StyleSheet.create({})
