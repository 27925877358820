import {
  Instance,
  SnapshotOut,
  flow,
  getParent,
  getRoot,
  resolveIdentifier,
  types,
} from "mobx-state-tree"
import { collectBagItemModel, collectBagItemStore } from "./collectBagItem"

import { nftcfgModel } from "./nftcfg"

export const collectBriefItemModel = types
  .model("collectBriefItemStore")
  .props({
    NftId: types.maybe(types.string),
    nftAddress: types.maybe(types.string),
    item: types.maybeNull(types.safeReference(collectBagItemModel)),
    isNew: types.maybe(types.boolean),
  })
  .actions((self) => ({
    changeToOld: () => {
      self.isNew = false
    },
    remove() {
      //@ts-ignore
      getParent(self, 2).remove(self)
    },
  }))

type collectBriefItemStoreType = Instance<typeof collectBriefItemModel>
export interface collectBriefItemStore extends collectBriefItemStoreType {}
type collectBriefItemStoreSnapshotType = SnapshotOut<typeof collectBriefItemModel>
export interface collectBriefItemStoreSnapshot extends collectBriefItemStoreSnapshotType {}
export const createcollectBriefItemStoreDefaultModel = () =>
  types.optional(collectBriefItemModel, {})
