import { StyleSheet, Text, View, Platform, useWindowDimensions, ScrollView } from "react-native"
import React, { Children, ReactNode, useState } from "react"
import { Touchable } from "../../../components"
import Modal from "react-native-modal/dist/modal"
import { deviceHeight, deviceWidth, fitSize } from "../../../theme"

type Props = {
  modalVisible: boolean
  setModalVisible: () => void
  sheetTitle: string
  children?: ReactNode
}

export const SelectModal: React.FC<Props> = ({
  modalVisible = false,
  setModalVisible = () => {},
  sheetTitle = "",
  children,
}) => {
  const { height } = useWindowDimensions()
  const compatibleHeight = Platform.OS === "web" ? height : deviceHeight

  const clearModal = () => {
    setModalVisible()
  }

  const submitModal = () => {
    setModalVisible()
  }

  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={false}
      isVisible={modalVisible}
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      style={{ margin: 0, justifyContent: "flex-end" }}
      onBackButtonPress={clearModal}
      onBackdropPress={clearModal}
      backdropTransitionOutTiming={0}
      useNativeDriver
      hideModalContentWhileAnimating={true}
      statusBarTranslucent
      deviceHeight={compatibleHeight}
      propagateSwipe={true}
      swipeDirection={"down"}
    >
      <View style={styles.modalContainer}>
        <View style={styles.sheetBar}>
          <Touchable onPress={clearModal}>
            <Text style={[styles.clearText, { color: "#FFFFFF80" }]}>取消</Text>
          </Touchable>
          <Touchable>
            <Text style={styles.sheetTitle}>{sheetTitle}</Text>
          </Touchable>
          <Touchable onPress={submitModal}>
            <Text style={[styles.clearText, { color: "#06FDE8" }]}>确定</Text>
          </Touchable>
        </View>
        {children}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    height: fitSize(300),
    width: deviceWidth,
    borderTopLeftRadius: fitSize(15),
    borderTopRightRadius: fitSize(15),
    padding: fitSize(10),
    backgroundColor: "#202530",
  },
  sheetSelectItem: {
    width: "100%",
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(5),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#696969",
  },
  sheetNotSelectItem: {
    width: "100%",
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(5),
  },
  sheetSelectItemText: {
    fontSize: fitSize(16),
    fontWeight: "400",
    lineHeight: fitSize(22),
    color: "#06FDE8",
  },
  sheetNotSelectItemText: {
    fontSize: fitSize(16),
    fontWeight: "400",
    lineHeight: fitSize(22),
    color: "#06FDE899",
  },
  sheetBar: {
    width: "100%",
    height: fitSize(30),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sheetContainer: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingHorizontal: 12,
    backgroundColor: "#202530",
  },
  sheetScrollView: {},
  clearText: {
    fontSize: fitSize(14),
    fontWeight: "400",
    lineHeight: fitSize(20),
  },
  sheetTitle: {
    fontSize: fitSize(15),
    fontWeight: "400",
    lineHeight: fitSize(21),
    color: "#FFFFFF",
  },
  sheetList: {
    width: "100%",
    height: fitSize(260),
    marginTop: fitSize(10),
  },
})
