import { Instance, SnapshotOut, flow, getParent, types } from "mobx-state-tree"

export const collectCardItemModel = types.model("collectCardItemStore").props({
  assetId: types.maybeNull(types.number),
  Type: types.maybeNull(types.number),
  Name: types.maybeNull(types.string),
  Series: types.maybeNull(types.string),
  SeriesId: types.maybeNull(types.number),
  Issuer: types.maybeNull(types.string),
  Description: types.maybeNull(types.string),
  TypeId: types.maybeNull(types.number),
  Rarity: types.maybeNull(types.number),
  Circulation: types.maybeNull(types.number),
  Image: types.maybeNull(types.string),
  UnityViewCode: types.maybeNull(types.string),
  BackgroundImage: types.maybeNull(types.string),
  MiniBackgroundImage: types.maybeNull(types.string),
  copyright: types.maybeNull(types.string),
  copyrightImage: types.maybeNull(types.string),
  issueTime: types.maybeNull(types.number),
  issuerImage: types.maybeNull(types.string),
  contractName: types.maybeNull(types.string),
  contractAddress: types.maybeNull(types.string),
  androidBundle: types.maybeNull(types.string),
  iOSBundle: types.maybeNull(types.string),
  key: types.identifier,
  Fileformat: types.optional(types.array(types.string), []),
  DefaultFormat: types.maybeNull(types.string),
  bundleBaseUrl: types.maybeNull(types.string),
  packageName: types.maybeNull(types.string),
  versionCode: types.maybeNull(types.number),
  GifImage: types.maybeNull(types.string),
})

type collectCardItemStoreType = Instance<typeof collectCardItemModel>
export interface collectCardItemStore extends collectCardItemStoreType {}
type collectCardItemStoreSnapshotType = SnapshotOut<typeof collectCardItemModel>
export interface collectCardItemStoreSnapshot extends collectCardItemStoreSnapshotType {}
export const createcollectCardItemStoreDefaultModel = () => types.optional(collectCardItemModel, {})
