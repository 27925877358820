import * as Animatable from "react-native-animatable"

import { ImageBackground, StyleSheet, View } from "react-native"
import { Images, fitSize } from "../../theme"
import React, { useEffect } from "react"

import { Text } from "../../components/text/text"

export const AnimteCircule = ({ msg }) => {
  return (
    <View style={styles.centerWrap}>
      <ImageBackground source={Images.common.circle_layer2} style={[styles.circleImg]}>
        <Animatable.Image
          animation={"rotate"}
          easing={"linear"}
          iterationCount={Infinity}
          useNativeDriver={true}
          source={Images.common.circle_layer1}
          style={[styles.circleImg]}
        ></Animatable.Image>
      </ImageBackground>
      <Text style={styles.msgText}>{msg || "Loading"}</Text>
    </View>
  )
}
const styles = StyleSheet.create({
  centerWrap: {
    width: fitSize(162),
    minHeight: fitSize(146),
    borderRadius: fitSize(10),
    backgroundColor: "#4D4D4D",
    alignSelf: "center",
    alignItems: "center",
    paddingTop: fitSize(35),
    paddingBottom: fitSize(30),
  },
  circleImg: {
    width: fitSize(36),
    height: fitSize(36),
  },
  msgText: {
    color: "#fff",
    fontSize: fitSize(18),
    marginTop: fitSize(20),
    width: fitSize(96),
    textAlign: "center",
  },
})
