import { StyleSheet, Text, View } from "react-native"
import React from "react"
import { infoItem } from "../TransactionRecords"
import { fitSize } from "../../../theme/Layout"
import dayjs from "dayjs"

const TransactionRecordsBox = ({ sellPhone, buyPhone, time, price }) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentView}>
        <Text style={[styles.titleLeft, { color: "#ffffff" }]}>{sellPhone}</Text>
        <View style={styles.conterRightCopy}>
          <Text numberOfLines={1} style={[styles.contentRight, { color: "#999999" }]}>
            寄售
          </Text>
        </View>
      </View>
      <View style={styles.contentView}>
        <Text style={[styles.titleLeft, { color: "#ffffff" }]}>{buyPhone}</Text>
        <View style={styles.conterRightCopy}>
          <Text numberOfLines={1} style={[styles.contentRight, { color: "#999999" }]}>
            买入
          </Text>
        </View>
      </View>
      <View style={styles.contentView}>
        <Text style={[styles.titleLeft, { color: "#999999" }]}>
          {dayjs(time).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
        <View style={styles.conterRightCopy}>
          <Text numberOfLines={1} style={[styles.contentRight, { color: "#06FDE8" }]}>
            ¥{price || "-"}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default TransactionRecordsBox

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: fitSize(100),
    borderBottomWidth: 1,
    borderColor: "#99999955",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: fitSize(20),
  },
  contentView: {
    flexShrink: 1,
    height: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
    width: "100%",
  },
  titleLeft: {
    fontSize: fitSize(14),
  },
  contentRight: {
    fontSize: fitSize(15),
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
  copyImg: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
    tintColor: "#06FDE8",
  },
})
