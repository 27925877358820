import {
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  Image,
} from "react-native"
import React, { ReactElement, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { RootStackNavProps } from "../../type/RouteType"
import { fitSize, Images } from "../../theme"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { Touchable } from "../../components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { GToast } from "../../navigators"
import Clipboard from "@react-native-community/clipboard"
import { useStores } from "../../models"

interface assetDetailsProps extends RootStackNavProps<"AssetDetails"> {}

export type itemType = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: [string]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}

export const AssetDetails: React.FC<assetDetailsProps> = observer(
  ({ navigation, route }): ReactElement => {
    const { height, width } = useWindowDimensions()
    const [item, setItem] = useState<itemType>()
    const { accountStore } = useStores()

    const doCopy = (copy: any) => {
      Clipboard.setString(String(copy))
      GToast.current.showToast("复制成功", "TOAST", 2000)
    }

    const fetchTransactionInfo = async () => {
      const res = await accountStore.environment.api.normalRequest(
        `/card/getNftDetail/${route.params.address}/${route.params.id}`,
      )
      if (res.kind === "ok") {
        const content = await fetch(res.data.tokenURI).then((r) => r.json())
        setItem(content.data)
      } else if (res.kind === "forbidden") {
        navigation.replace("LoginFromMessage")
      } else {
        throw res?.msg
      }
    }

    useEffect(() => {
      fetchTransactionInfo()
    }, [])

    return (
      <View
        testID="AssetDetails"
        style={[
          styles.root,
          {
            height: Platform.OS === "web" ? height : undefined,
            backgroundColor: "#202530",
          },
        ]}
      >
        <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"} />
        <Sheader
          notShowLeft={false}
          title={"资产详情"}
          textStyle={{ color: "#06FDE8" }}
          rightWrapStyle={styles.rightWrapStyle}
        />
        <ScrollView
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: fitSize(80) }}
        >
          <SizeImage
            isGif={item?.DefaultFormat === "gif"}
            resizeMode={"contain"}
            source={{ uri: item?.DefaultFormat === "gif" ? item?.GifImage : item?.Image }}
            style={styles.img}
          />
          <View style={styles.nameBox}>
            <Text style={styles.nameText}>{item?.Name}</Text>
          </View>
          <View style={styles.content}>
            <View style={styles.contentTopInfo}>
              <View style={styles.rowCenter}>
                <View style={styles.spaceLine} />
                <Text style={styles.seriesName}>{item?.Series}</Text>
              </View>
              <View style={styles.rowCenter}>
                <Text style={[styles.totalNumber, { backgroundColor: "#333" }]}>发行</Text>
                <Text style={[styles.totalNumber, { backgroundColor: "#999" }]}>
                  {item?.Circulation}份
                </Text>
              </View>
            </View>
            <View style={styles.contentBottomInfo}>
              <Text numberOfLines={4} ellipsizeMode="tail" style={styles.seriseContent}>
                {item?.Description}
              </Text>
            </View>
          </View>
          <View style={styles.addressInfo}>
            <View style={styles.contentTopInfo}>
              <View style={styles.rowCenter}>
                <View style={styles.spaceLine} />
                <Text style={styles.seriesName}>链上信息</Text>
              </View>
            </View>
            <View style={styles.addressInfoContent}>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>地址:</Text>
                <View style={styles.conterRightCopy}>
                  <Text numberOfLines={1} style={styles.contentRight}>
                    {item?.contractAddress.replace(
                      /(\+?\d{6})(\d+)(\d{4})/g,
                      (start, middle, end) => {
                        return start + ".".repeat(middle.length) + end
                      },
                    ) || "——"}
                  </Text>
                  {Platform.OS !== "web" ? (
                    <Touchable onPress={() => doCopy(item?.contractAddress)}>
                      <Image source={Images.components.copy} style={styles.copyImg} />
                    </Touchable>
                  ) : (
                    <CopyToClipboard
                      text={item?.contractAddress}
                      onCopy={() => GToast.current.showToast("复制成功", "TOAST", 2000)}
                    >
                      <Image source={Images.components.copy} style={styles.copyImg} />
                    </CopyToClipboard>
                  )}
                </View>
              </View>
              <View style={styles.contentView}>
                <Text style={styles.titleLeft}>链上标识:</Text>
                <View style={styles.conterRightCopy}>
                  <Text numberOfLines={1} style={styles.contentRight}>
                    {item?.NftId || "——"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    )
  },
)

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  sheaderTitle: {
    color: "#fafafa",
  },
  rightWrapStyle: {
    width: fitSize(50),
  },
  nameBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: fitSize(10),
    marginBottom: fitSize(15),
  },
  nameText: {
    fontSize: fitSize(17),
    color: "#06FDE8",
    fontWeight: "500",
  },
  content: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(150),
    marginBottom: fitSize(10),
  },
  contentTopInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(30),
    paddingHorizontal: fitSize(5),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  spaceLine: {
    width: fitSize(2),
    height: fitSize(14),
    backgroundColor: "#06FDE8",
    marginRight: fitSize(5),
  },
  seriesName: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  totalNumber: {
    fontSize: fitSize(13),
    color: "#ffffff",
    padding: fitSize(3),
  },
  contentBottomInfo: {
    flexGrow: 1,
    paddingLeft: fitSize(10),
    paddingTop: fitSize(3),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#99999944",
    marginTop: fitSize(7),
  },
  seriseContent: {
    fontSize: fitSize(15),
    color: "#9a9a9a",
  },
  addressInfo: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(80),
    marginBottom: fitSize(10),
    borderBottomWidth: 1,
    borderColor: "#99999944",
  },
  addressInfoContent: {
    flexGrow: 1,
  },
  infoBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentView: {
    flexShrink: 1,
    height: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  titleLeft: {
    fontSize: fitSize(14),
    color: "#808080",
  },
  contentRight: {
    fontSize: fitSize(12),
    color: "#999999",
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
  copyImg: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
    tintColor: "#06FDE8",
  },
  img: {
    width: fitSize(375),
    height: fitSize(230),
  },
})
