import { DependencyList, useEffect, useRef, useState } from "react"
import { load, save } from "../utils/storage"

import { useStores } from "../models"

export type nftItem = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: string[]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
  showBgImage: string
  showSealImage: string
}
export function useUriInfo(uri?: string, deps?: DependencyList) {
  const [data, setData] = useState<nftItem | null>(null)
  const mount = useRef(true)
  useEffect(() => {
    mount.current = true
    if (uri) getData()
    return () => {
      mount.current = false
    }
  }, deps || [])
  const { accountStore } = useStores()
  const getData = async () => {
    const cache = await load(uri)
    if (cache) {
      //@ts-ignore
      setData(cache)
      return
    }
    const res = await accountStore.environment.api.normalRequest(uri)
    if (res.kind === "ok") {
      if (mount.current) setData(res.data)
      save(uri, res.data)
    }
  }
  return [data]
}
