import { delay } from "./delay"

export const retry = async (func: () => Promise<any>, time = 5, delayNum = 2000) => {
  let current = 1
  const doRry = async () => {
    try {
      const res = await func()
      return res
    } catch (err) {
      current = current + 1
      throw err
    }
  }
  while (current <= time) {
    console.log("trying", current)
    try {
      if (current >= 2) {
        console.log("delaying", current)
        await delay(delayNum)
      }
      const res = await doRry()
      return res
    } catch (err) {
      console.log(err)
      continue
    }
  }
  throw new Error("链上拥堵,多次尝试失败")
}
