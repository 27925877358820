import {
  Image,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"
import { Images, color, deviceHeight, fitSize } from "../../theme"
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { Touchable, TouchableDeep } from "../../components"
import { hideLoad, showError, showInfo } from "../../utils/showUtil"

import Clipboard from "@react-native-community/clipboard"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { LinerGradient } from "./components/MyLinerGradient"
import { LoadingComp } from "../../components/loadingComponet/LoadingComp"
import Modal from "react-native-modal/dist/modal"
import { MyErrorCode } from "../../utils/errorCode"
import QrSVG from "react-native-qrcode-svg"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import dayjs from "dayjs"
import { doCopy } from "../../utils/doCopy"
import { observer } from "mobx-react-lite"
import { useApi } from "../../hooks/useApi"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useUriInfo } from "../../hooks/useUriInfo"

interface transactionScreenProps extends RootStackNavProps<"TransactionScreen"> {}

export type itemType = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: [string]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}
export interface infoType {
  id: number
  orderId: string
  sellerUserId: number
  goodsName: string
  price: number
  nftId: number
  totalIssueCount: number
  currentCirculation: number
  supportWallet: SupportWallet[]
  contractAddress: string
  orderStatus: MarketOrderStatusEnum
  tokenURI: string
  userAddress: string
  phonenumber: string
  createdAt: number
  updatedAt: number
  nftHistory: NftHistory[]
  nftTradeHistory: NftTradeHistory[]
  blockExplorerUrl: string
  tagType: number
  barterRequestMsg: string
  sellerName: string
  barterResponderList: { list: BarterItem[] }[]
}
type BarterItem = {
  id: number
  orderId: string
  goodsName: string
  contractAddress: string
  nftId: string
  SKUId: number
  tokenURI: string
  sellerName: string
  sellerUserId: number
}
export const enum MarketOrderStatusEnum {
  Created = 0,
  OnSelling = 1,
  OnSelled = 2,
  UnSelling = 3,
  Unselled = 4,
  Locked = 5,
  Selled = 6,
  OnSellFailed = 7,
  UnSellFailed = 8,
}
export const statusWord = (data: MarketOrderStatusEnum) => {
  switch (data) {
    case MarketOrderStatusEnum.OnSelled:
      return "进行中"
    case MarketOrderStatusEnum.Locked:
      return "已下架"
    case MarketOrderStatusEnum.Selled:
      return "已完成"
    default:
      return ""
  }

  // return ""
}

interface SupportWallet {
  id: number
  name: string
  type: string
  payWay: number
  platform: string
  icon: string
  isOpen: boolean
}
interface NftHistory {
  from: string
  to: string
  transactionHash: string
  blockTime: string
}
interface NftTradeHistory {
  sellerUserAddress: string
  sellerUserPhonenumber: string
  buyerUserAddress: string
  buyerUserPhonenumber: string
  price: number
  payTime: string
}
export const regFenToYuan = (fen) => {
  let num = fen
  num = (fen * 0.01).toFixed(2)
  num += ""
  let reg = num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, "$1")
  return num
}

export type userInfoType = {
  account: string
  id: number
  isRealname: number
  phonenumber: string
  userCustId: string | null
}

export const TransactionScreenTradeToItemScreen: React.FC<transactionScreenProps> = observer(
  ({ navigation, route }): ReactElement => {
    const isMine = route?.params?.isMine
    const { accountStore, collectStore } = useStores()
    const { bottom, top } = useSafeAreaInsets()
    const userInfo = accountStore?.user
    const loadCount = useRef(0)
    useFocusEffect(
      useCallback(() => {
        loadCount.current++
        if (loadCount.current >= 2) doRequest()
      }, []),
    )

    const { result, doRequest, setResult } = useApi<infoType>({
      api: {
        path: "/market/getTradeMarketorderDetail",
        params: {
          id: Number(route.params.id),
          platform: Platform.OS,
        },
        way: "post",
      },
    })
    const info = result?.result

    const onPurchase = async () => {
      if (!accountStore.token) {
        GToast.current.doLogin()
        return
      }

      if (userInfo.id === info.sellerUserId) {
        GToast.current.showToast("不可购买自己的在售商品", "TOAST", 2000)
        return
      }

      // if (userInfo.adaPayUserCustId) { // adapay 是否开户
      if (userInfo.isOpenSandPay) {
        // 甘文交 是否开户
        const res = await accountStore.environment.api.createTradeOrder(
          info?.orderId,
          info.updatedAt,
        )
        if (res.kind === "ok") {
          navigation.push("ShowOrder", { id: res.data.tradeOrderId })
          console.info("text", accountStore.getUserInfo())
        } else if (res.kind === "forbidden") {
          navigation.replace("LoginFromMessage")
        } else if (res.kind === "error") {
          if (res.code === MyErrorCode.MarketOrderBuyerIsSellerError) {
            GToast.current.showToast("不可购买自己的在售商品", "TOAST", 2000)
          } else if (res.code === MyErrorCode.UserNotAdapayWallet) {
            GToast.current.showToast("还未创建支付钱包", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderInfoExpired) {
            GToast.current.showToast("订单信息已更新,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderStatusError) {
            GToast.current.showToast("订单状态异常,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.ChainErrorUserNotOwn) {
            GToast.current.showToast("该资产已经上架或已售出,请重新刷新页面", "TOAST", 2000)
          } else {
            showError(res)
          }
        }
      } else {
        GToast.current.showLoading("正在创建支付钱包")
        const walletRes = await accountStore.environment.api.normalRequest(
          "/user/getUseWalletStatus",
          { platform: Platform.OS },
          "post",
        )
        if (walletRes.kind !== "ok") {
          showError(walletRes)
          return
        }

        const createAdapayWallet = await accountStore.environment.api.normalRequest(
          "user/createWallet",
          {
            returnUrl:
              Platform.OS === "web" ? `${window.location.origin}/Mine?isWallet=true` : undefined,
            payWay: walletRes?.data?.list?.[0]?.payWay || 2,
          },
          "post",
        )
        if (createAdapayWallet.kind === "ok") {
          hideLoad()
          if (Platform.OS === "web") {
            window.location.href = createAdapayWallet.data.redirectUrl
          } else {
            navigation.navigate("MassWebView", {
              uri: createAdapayWallet.data.redirectUrl,
            })
          }
        } else {
          if (createAdapayWallet.kind === "error") {
            if (createAdapayWallet.code === MyErrorCode.ThirdPartyServiceError) {
              GToast.current.showToast("钱包服务错误", "CANCEL", 1500)
              return
            }
          }
          GToast.current.showToast("创建钱包失败", "TOAST", 2000)
        }
      }
    }
    const [item] = useUriInfo(info?.tokenURI, [info?.tokenURI])
    const [msgId, setMsgId] = useState("")
    const [isAsyncFnc, setIsAsyncFnc] = useState(false)
    const goEdit = () => {
      navigation.push("TransactionInfo", {
        orderId: info?.orderId,
        isEdit: true,
        price: info.price,
        nftItem: item,
      })
    }
    const goTakeDown = () => {
      GToast.current.showAlert(
        `藏品下架后回到「资产」查看，若想再次出售需重新发售。确认下架吗？`,
        () => {},
        () => {
          doTakeDown()
        },
      )
    }
    const doTakeDown = async () => {
      GToast.current.showLoading("正在下架中")
      const content = await collectStore.environment.api.getSignature(
        "PayAbleMarket",
        {
          nftAddress: item.contractAddress,
          nftId: item.NftId,
          orderId: info.orderId,
        },
        false,
      )
      const res = await accountStore.environment.api.normalRequest(
        "/market/unSellNft",
        { orderId: info.orderId, signature: content.data },
        "post",
      )
      if (res.kind === "ok") {
        setMsgId(res.data.msgId)
        setIsAsyncFnc(true)
      } else {
        hideLoad()
        showInfo("订单已锁定")
      }
    }

    const getAsyncResult = async (value: string) => {
      const res = await accountStore.environment.api.normalRequest(
        "/user/getAsyncResult",
        {
          msgId: value,
        },
        "post",
      )
      if (res.kind === "ok") {
        if (JSON.parse(res.data).status === 2) {
          hideLoad()

          // collectStore.addOneCollect({
          //   nftAddress: JSON.parse(res.data).nftAddress,
          //   NftId: JSON.parse(res.data).nftIds,
          // })
          showInfo("操作成功")
          // GToast.current.showToast("操作成功", "IMG", 2000, true, 1, 1, {
          //   image: item?.Image,
          //   name: JSON.parse(res.data).nftAddress,
          //   id: JSON.parse(res.data).nftIds,
          // })
          navigation.goBack()
        } else if (JSON.parse(res.data).status === 1) {
          return
        } else {
          setMsgId("")
          setIsAsyncFnc(false)
          showError(res)
        }
      } else {
        hideLoad()
        showInfo(res?.msg)
      }
    }

    useEffect(() => {
      let time = null
      if (isAsyncFnc) {
        time = setInterval(() => {
          getAsyncResult(msgId)
        }, 1000)
      }
      return () => {
        clearInterval(time)
      }
    }, [isAsyncFnc])
    const doExchange = async (id: number[]) => {
      GToast.current.showLoading("交换中")
      const res = await accountStore.environment.api.messageIdRequest(
        "/market/barterExchange",
        { requestorOrderId: info?.id, responderOrderIds: id },
        "post",
      )
      if (res.kind === "ok") {
        // setMsgId(res.data.msgId)
        // setIsAsyncFnc(true)
        showInfo("交换成功")
        navigation.goBack()
      } else {
        showError(res)
      }
    }
    const isOwnerHave = info?.barterResponderList?.find((e) => {
      return e.list?.[0]?.sellerUserId == accountStore?.user?.id
    })
    // console.log(accountStore?.user?.id)
    // console.log(
    //   info?.barterResponderList?.find((e) => {
    //     return e?.list?.[0]?.sellerUserId == accountStore?.user?.id
    //   }),
    // )

    return (
      <FlexView bgColor={"#EDEDED"}>
        <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"} />
        <Sheader
          rightImage={""}
          title={"以物换物详情"}
          textStyle={{ color: color.text, fontSize: fitSize(17), fontWeight: "600" }}
          leftImage={""}
          style={{ backgroundColor: color.secBackground }}
        />
        <LoadingComp result={result} doRequest={doRequest}>
          <ScrollView
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: fitSize(50) }}
          >
            <View
              style={{
                width: fitSize(351),
                height: fitSize(264),
                margin: fitSize(6),
                // padding: fitSize(10),
                backgroundColor: color.secBackground,
                borderRadius: fitSize(10),
                shadowColor: "#000",
                shadowOpacity: 0.08,
                shadowOffset: { width: fitSize(0), height: fitSize(2) },
                shadowRadius: fitSize(5),
                padding: fitSize(15),
                paddingTop: fitSize(12),
                paddingBottom: 0,
                alignSelf: "center",
                marginTop: fitSize(17),
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  position: "absolute",
                  top: fitSize(15),
                  right: fitSize(15),
                }}
              >
                <SizeImage
                  source={Images.home.time}
                  style={{ width: fitSize(16), height: fitSize(16), marginRight: fitSize(5) }}
                />
                <Text style={{ fontSize: fitSize(13), color: color.primary }}>
                  {statusWord(info?.orderStatus)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: fitSize(18), fontWeight: "600", color: color.primary }}>
                  {info?.phonenumber || " "}
                </Text>
              </View>
              <View
                style={{
                  width: fitSize(321),
                  alignSelf: "center",
                  marginTop: fitSize(10),
                  height: fitSize(63),
                  paddingTop: fitSize(10),
                  borderBottomColor: "rgba(0, 175, 181, 0.1)",
                  borderBottomWidth: fitSize(1),
                }}
              >
                <Text numberOfLines={2} style={{ fontSize: fitSize(13), color: "#888" }}>
                  {info?.barterRequestMsg}
                </Text>
              </View>
              <View style={{ flex: 1, paddingTop: fitSize(5) }}>
                <ScrollView horizontal>
                  <View
                    style={{
                      width: fitSize(90),
                      height: fitSize(129),
                      marginHorizontal: fitSize(4.5),
                      backgroundColor: color.secBackground,
                      borderRadius: fitSize(10),
                      shadowColor: "#000",
                      shadowOpacity: 0.08,
                      shadowOffset: { width: fitSize(0), height: fitSize(2) },
                      shadowRadius: fitSize(5),
                      marginVertical: fitSize(6),
                    }}
                  >
                    <SizeImage
                      source={{ uri: item?.Image }}
                      isPreview
                      resizeMode={"contain"}
                      style={{
                        width: fitSize(90),
                        height: fitSize(90),
                        borderTopLeftRadius: fitSize(10),
                        borderTopRightRadius: fitSize(10),
                        overflow: "hidden",
                        backgroundColor: "#000",
                      }}
                    />
                    <Text
                      numberOfLines={1}
                      style={{
                        width: fitSize(90 - 8),
                        fontSize: fitSize(11),
                        marginTop: fitSize(5),
                        marginLeft: fitSize(8),
                        color: color.primary,
                      }}
                    >
                      {item?.Name}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        width: fitSize(90 - 8),
                        fontSize: fitSize(10),
                        marginLeft: fitSize(8),
                        color: color.dim,
                      }}
                    >
                      {item?.CardShowId}
                    </Text>
                  </View>
                </ScrollView>
              </View>
            </View>
            <Text
              style={{
                fontSize: fitSize(15),
                fontWeight: "600",
                color: color.text,
                alignSelf: "center",
                marginVertical: fitSize(15),
              }}
            >
              参与中的用户
            </Text>
            {info?.barterResponderList?.map((e, i) => {
              return (
                <BatterderItem
                  isMine={isMine}
                  e={e}
                  i={i}
                  doExchange={(e) => {
                    doExchange(e?.list?.map((ele) => ele.id))
                  }}
                  info={info}
                />
              )
            })}
          </ScrollView>

          <View
            style={[
              styles.infoBar,
              { minHeight: fitSize(55), marginTop: "auto", paddingBottom: fitSize(10) },
            ]}
          >
            <View style={[styles.infoContent, { justifyContent: "center" }]}>
              <View style={[styles.rowCenter, { justifyContent: "center" }]}>
                <TouchableDeep
                  onPressIn={() => {
                    if (isMine) {
                      goTakeDown()
                    } else {
                      if (info?.sellerUserId === accountStore?.user?.id) {
                        goTakeDown()
                      } else {
                        navigation.push("TradeToItemAddPage", {
                          isOffer: true,
                          orderId: info?.orderId,
                        })
                      }
                    }
                  }}
                  style={[
                    styles.button,
                    {
                      backgroundColor: "transparent",
                      borderWidth: fitSize(1),
                      borderColor: color.primary,
                    },
                  ]}
                  onPress={goTakeDown}
                >
                  <Text style={[styles.buttonLabel, { color: color.primary }]}>
                    {isMine || info?.sellerUserId === accountStore?.user?.id
                      ? "下架"
                      : isOwnerHave
                      ? "追加"
                      : "以物换物"}
                  </Text>
                </TouchableDeep>
              </View>
            </View>
          </View>
        </LoadingComp>
      </FlexView>
    )
  },
)
const BatterderItem = ({
  e,
  i,
  isMine,
  doExchange,
  info,
}: {
  e: { list: BarterItem[] }
  i: number
  isMine: boolean
  doExchange?: (e: { list: BarterItem[] }) => void
  info: infoType
}) => {
  const ele = e?.list[0]
  const { accountStore } = useStores()
  return (
    <View
      key={i}
      style={{
        width: fitSize(351),
        height: fitSize(133),
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        shadowColor: "#000",
        shadowOpacity: 0.08,
        shadowOffset: { width: fitSize(0), height: fitSize(2) },
        shadowRadius: fitSize(5),
        padding: fitSize(15),
        paddingTop: fitSize(15),
        paddingBottom: 0,
        marginVertical: fitSize(7.5),
        alignSelf: "center",
      }}
    >
      <Text style={{ fontSize: fitSize(18), fontWeight: "600", color: color.primary }}>
        {ele?.sellerName}
      </Text>
      <View style={{ flex: 1, paddingTop: fitSize(14) }}>
        <ScrollView horizontal>
          {e.list?.map((element) => (
            <ItemFromUri tokenUri={element.tokenURI} />
          ))}
          {/* <SizeImage
            isPreview
            source={{ uri: item?.Image }}
            style={{
              width: fitSize(57),
              height: fitSize(57),
              borderRadius: fitSize(5),
              overflow: "hidden",
            }}
          /> */}
        </ScrollView>
      </View>
      {accountStore?.user?.id === ele?.sellerUserId && (
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: fitSize(85),
            height: fitSize(28),
            backgroundColor: "rgba(0, 175, 181, 0.1)",
            borderTopRightRadius: fitSize(10),
            borderBottomLeftRadius: fitSize(14),
          }}
        >
          <Text
            style={{
              marginLeft: fitSize(18),
              marginTop: fitSize(4),
              fontSize: fitSize(13),
              color: "#00AFB5",
            }}
          >
            我的换单
          </Text>
        </View>
      )}
      {(isMine || accountStore?.user?.id === info?.sellerUserId) && (
        <Touchable
          onPress={() => {
            doExchange(e)
          }}
          style={{
            width: fitSize(82),
            height: fitSize(28),
            backgroundColor: color.primary,
            borderRadius: fitSize(14),
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: fitSize(14),
            right: fitSize(12),
          }}
        >
          <Text style={{ fontSize: fitSize(13), color: "#fff" }}>与他交换</Text>
        </Touchable>
      )}
    </View>
  )
}
const ItemFromUri = ({ tokenUri }: { tokenUri: string }) => {
  const [item] = useUriInfo(tokenUri)
  return (
    <SizeImage
      isPreview
      source={{ uri: item?.Image }}
      style={{
        width: fitSize(57),
        height: fitSize(57),
        borderRadius: fitSize(5),
        overflow: "hidden",
      }}
    />
  )
}

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  sheaderTitle: {
    color: "#fafafa",
  },
  rightWrapStyle: {
    width: fitSize(50),
  },
  nameBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: fitSize(10),
    marginBottom: fitSize(15),
  },
  nameText: {
    fontSize: fitSize(17),
    color: "#06FDE8",
    fontWeight: "500",
  },
  priceText: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  content: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(150),
    marginBottom: fitSize(10),
  },
  contentTopInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: fitSize(30),
    paddingHorizontal: fitSize(5),
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  spaceLine: {
    width: fitSize(2),
    height: fitSize(14),
    backgroundColor: "#06FDE8",
    marginRight: fitSize(5),
  },
  seriesName: {
    fontSize: fitSize(16),
    color: "#06FDE8",
    fontWeight: "500",
  },
  totalNumber: {
    fontSize: fitSize(13),
    color: "#ffffff",
    padding: fitSize(3),
  },
  contentBottomInfo: {
    flexGrow: 1,
    paddingLeft: fitSize(10),
    paddingTop: fitSize(3),
    borderBottomWidth: 1,
    borderColor: "#99999944",
    marginTop: fitSize(7),
  },
  seriseContent: {
    fontSize: fitSize(15),
    color: "#9a9a9a",
  },
  addressInfo: {
    flexDirection: "column",
    width: "100%",
    minHeight: fitSize(60),
    marginBottom: fitSize(10),
    borderBottomWidth: 1,
    borderColor: "#99999944",
  },
  addressInfoContent: {
    flexGrow: 1,
  },
  infoBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentView: {
    flexShrink: 1,
    height: fitSize(35),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  titleLeft: {
    fontSize: fitSize(14),
    color: "#808080",
  },
  contentRight: {
    fontSize: fitSize(12),
    color: "#999999",
    width: fitSize(200),
    textAlign: "right",
  },
  conterRightCopy: {
    flexDirection: "row",
    alignItems: "center",
  },
  copyImg: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(8),
    tintColor: "#06FDE8",
  },
  img: {
    width: fitSize(375),
    height: fitSize(375),
    backgroundColor: "#000",
  },
  infoBar: {
    width: "100%",
    backgroundColor: "#fff",
    // position: "absolute",
    // bottom: 0,
    shadowColor: "#000",
    shadowOffset: { width: fitSize(0), height: fitSize(2) },
    shadowRadius: fitSize(15),
    shadowOpacity: 0.1,
  },
  infoContent: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: fitSize(16),
    paddingTop: fitSize(12),
    justifyContent: "space-between",
  },
  button: {
    minWidth: fitSize(266),
    height: fitSize(42),
    alignSelf: "center",

    borderRadius: fitSize(22),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonLabel: {
    fontSize: fitSize(14),
    fontWeight: "600",
  },
  shareIcon: {
    width: fitSize(20),
    height: fitSize(20),
    tintColor: "#06FDE8",
  },
  leftRow: {
    color: color.secBackground,
    backgroundColor: color.primary,
    width: fitSize(32),
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopLeftRadius: fitSize(2),
    borderBottomLeftRadius: fitSize(2),
    fontSize: fitSize(11),
  },
  rightRow: {
    color: "#666",
    backgroundColor: "rgb(240,	240	,240	)",
    height: fitSize(18),
    lineHeight: fitSize(18),
    textAlign: "center",
    borderTopRightRadius: fitSize(2),
    borderBottomRightRadius: fitSize(2),
    paddingHorizontal: fitSize(5),
    fontSize: fitSize(11),
  },
})
function userRef(arg0: number) {
  throw new Error("Function not implemented.")
}
