import React, { DependencyList, useCallback, useEffect, useRef, useState } from "react"

import { useFocusEffect } from "@react-navigation/native"

export function delay(time: number = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

type itemList<T> = {
  fetchData: (page: number, ...params: any) => Promise<T[]>
  pageLimit: number
  delaTime?: number
  isFocusRefresh?: boolean
}
export type useListReturnType<T> = {
  data: T[]
  setData: React.Dispatch<React.SetStateAction<T[]>>
  bottomRefreshing: boolean
  topRefreshing: boolean
  onRefresh: (param?: any) => void
  onEndReached: () => void
  error: string
  isLoading: boolean
  noMoreData: boolean
  doFirstload: () => void
}
const initPage = 1
export function useAutoList<T>(
  { fetchData, pageLimit, delaTime, isFocusRefresh = false }: itemList<T>,
  deps?: DependencyList,
): [useListReturnType<T>] {
  const [data, setData] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [topRefreshing, setTopRefreshing] = useState(true)
  const [bottomRefreshing, setBottomRefreshing] = useState(false)
  const [noMoreData, setNoMoreData] = useState(false)
  const pageRef = useRef(initPage)
  const [error, setError] = useState("")
  const isMountedRef = useRef(false)
  const bottomRefreshRef = useRef(false)
  const topRefreshingRef = useRef(false)

  const judgeNoMoreData = (newData: T[]) => {
    if (newData) {
      return newData.length < pageLimit
    } else {
      return false
    }
  }

  const setDatas = (mydata: T[]) => {
    if (error) {
      setError("")
    }
    setIsLoading(false)
    setNoMoreData(judgeNoMoreData(mydata))
    setData(mydata)
    setTopRefreshing(false)
    topRefreshingRef.current = false
    setTimeout(() => {
      setBottomRefreshing(false)
      bottomRefreshRef.current = false
    }, 100)
  }

  const loadData = () => {
    if (error) {
      setIsLoading(true)
    }

    fetchData(pageRef.current)
      .then((item) => {
        if (isMountedRef.current) {
          setDatas(item)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setError(String(e))
      })
  }
  const onRefresh = (params) => {
    pageRef.current = 1
    setNoMoreData(false)
    setTopRefreshing(true)
    topRefreshingRef.current = true
    loadData()
  }
  const onEndReached = () => {
    if (data.length === 0 || topRefreshingRef.current || noMoreData || bottomRefreshRef.current) {
      return
    }
    setBottomRefreshing(true)
    bottomRefreshRef.current = true
    pageRef.current = pageRef.current + 1
    // loadData();
    if (error) {
      setIsLoading(true)
    }
    fetchData(pageRef.current)
      .then((item) => {
        if (isMountedRef.current) {
          if (error) {
            setError("")
          }
          setIsLoading(false)
          setNoMoreData(judgeNoMoreData(item))
          setData([...data, ...item])
          setTimeout(() => {
            setBottomRefreshing(false)
            bottomRefreshRef.current = false
          }, 100)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e)
      })
  }
  const doFirstload = async () => {
    setTopRefreshing(false)
    topRefreshingRef.current = false
    if (delaTime) {
      await delay(delaTime)
    }
    pageRef.current = initPage
    loadData()
  }
  useFocusEffect(
    useCallback(() => {
      isMountedRef.current = true
      if (isFocusRefresh) doFirstload()
      return () => {
        isMountedRef.current = false
      }
    }, deps || []),
  )
  useEffect(() => {
    isMountedRef.current = true
    if (!isFocusRefresh) doFirstload()
    return () => {
      isMountedRef.current = false
    }
  }, deps || [])

  return [
    {
      data,
      setData,
      bottomRefreshing,
      topRefreshing,
      onRefresh,
      onEndReached,
      error,
      isLoading,
      noMoreData,
      doFirstload,
    },
  ]
}
