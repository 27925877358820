import FastImage, { FastImageProps } from "react-native-fast-image"
import { ImageBackground, PixelRatio, Platform, StyleSheet, View } from "react-native"
import React, { Fragment, useEffect, useRef, useState } from "react"

import { number } from "mobx-state-tree/dist/internal"

interface SizeImageProps extends FastImageProps {
  isPreview?: boolean
  isGif?: boolean
  skipSize?: boolean
  // grayScale?: boolean
}
const normalImgSuffix = {
  1: "?x-oss-process=style/x1",
  2: "?x-oss-process=style/x2",
  3: "?x-oss-process=style/x3",
}
const previewImgSuffix = {
  1: "?x-oss-process=style/thumb_x1",
  2: "?x-oss-process=style/thumb_x2",
  3: "?x-oss-process=style/thumb_x3",
}
const pixRatio = PixelRatio.get()
export const getFinalSuffix = (
  isPreview: boolean,
  uri: string,
  width: string | number,
  height: string | number,
  skipSize: boolean,
) => {
  if (uri.endsWith(".gif")) {
    return uri
  }
  if (!skipSize) {
    const isWidthNumber = typeof width === "number"
    const isHeightNumber = typeof height === "number"
    if (isWidthNumber && isHeightNumber) {
      return (
        uri +
        `?x-oss-process=image/resize,${"w_" + ~~(Number(width) * pixRatio)},${
          "h_" + ~~(Number(height) * pixRatio)
        }`
      )
    }
    if (isWidthNumber) {
      return uri + `?x-oss-process=image/resize,${"w_" + ~~(Number(width) * pixRatio)}`
    }
    if (isHeightNumber) {
      return uri + `?x-oss-process=image/resize,${"h_" + ~~(Number(height) * pixRatio)}`
    }
  }
  // 默认样式
  const suffixObject = isPreview ? previewImgSuffix : normalImgSuffix
  if (pixRatio >= 3) {
    return uri + suffixObject[3]
  } else if (pixRatio >= 2) {
    return uri + suffixObject[2]
  } else {
    return uri + suffixObject[1]
  }
}
export const getFinalUrl = (uri: string) => {
  const suffixObject = normalImgSuffix
  if (pixRatio >= 3) {
    return uri + suffixObject[3]
  } else if (pixRatio >= 2) {
    return uri + suffixObject[2]
  } else {
    return uri + suffixObject[1]
  }
}

// 增加图片重试
const maxRetries = 3
export const SizeImage: React.FC<SizeImageProps> = ({
  isPreview = false,
  isGif = false,
  source,
  skipSize,
  // grayScale = false,
  ...rest
}) => {
  const [errorCount, setErrorCount] = useState(0)
  const [visible, setVisible] = useState(true)
  const isMount = useRef(false)
  useEffect(() => {
    isMount.current = true
    return () => {
      isMount.current = false
    }
  }, [])
  const { width, height } = StyleSheet.flatten(rest.style)

  // @ts-ignore
  const data = source?.uri
    ? // @ts-ignore
      { ...source, uri: getFinalSuffix(false, source.uri, width, height, skipSize) }
    : source

  if (Platform.OS === "web") {
    // @ts-ignore
    return <ImageBackground source={data} {...rest}></ImageBackground>
  }

  // if (grayScale) {
  //   return (
  //     <Fragment>
  //       <FastImage
  //         {...rest}
  //         key={`${visible}`}
  //         source={data}
  //         onError={() => {
  //           if (isMount.current && errorCount < maxRetries) {
  //             setErrorCount(errorCount + 1)
  //             setVisible(false)
  //             setTimeout(() => {
  //               if (isMount.current) {
  //                 setVisible(true)
  //               }
  //             }, 500)
  //           }
  //         }}
  //         tintColor={"gray"}
  //       ></FastImage>
  //       <FastImage
  //         key={`${visible}`}
  //         source={data}
  //         style={[rest.style, { position: "absolute", opacity: 0.3 }]}
  //         {...rest}
  //       ></FastImage>
  //     </Fragment>
  //   )
  // }
  return (
    <FastImage
      {...rest}
      key={`${visible}`}
      source={data}
      onError={() => {
        if (isMount.current && errorCount < maxRetries) {
          setErrorCount(errorCount + 1)
          setVisible(false)
          setTimeout(() => {
            if (isMount.current) {
              setVisible(true)
            }
          }, 500)
        }
      }}
    ></FastImage>
  )
}

const styles = StyleSheet.create({})
