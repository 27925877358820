import * as Animatable from "react-native-animatable"
import * as Progress from "react-native-progress"

import {
  BackHandler,
  Image,
  ImageBackground,
  Linking,
  Platform,
  StyleSheet,
  View,
} from "react-native"
import { Images, deviceHeight, fitSize } from "../theme"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Text, Touchable } from "../components"
import { collectBagItemStore, collectBagItemStoreSnapshot } from "../models/collects/collectBagItem"

import { AnimteCircule } from "./Components/AnimteCircule"
import { ChooseOrderModal } from "./Components/ChooseOrderModal"
import { PlatteWhiteAlert } from "./Components/PlatteWhiteAlert"
import { RootNavigation } from "../navigators"
import { SignWebviewModal } from "./Components/SignWebviewModal"
import { SizeImage } from "../components/image-component/SizeImage"
import { WebViewMessageEvent } from "react-native-webview"
import { collectCardItemStore } from "../models/collects/collectCardItem"
import { genereateEnv } from "../../genereateEnv"
import { promiseReturn } from "../type/RequestType"
import { useStores } from "../models"

interface GlobalModalProps {}
let AlertInfo = { msg: "", onClose: () => {}, onSure: () => {}, info: "" }
export type alertText = {
  closeText: string
  sureText: string
  info?: string
  isShowOne?: boolean
}
const DEFAULT_ALERT_TEXT = {
  closeText: "取消",
  sureText: "确认",
  info: "",
}
type msg = {
  msg: string
  type: string
  needCover: boolean
  cprogress?: number
  tprogress?: number
  image?: object
}
export type card3dData = {
  CardShowId?: string
  Name: string
  Image: string
  UnityViewCode: string
  androidBundle: string
  iOSBundle: string
  DefaultFormat: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  GifImage: string
}
export type ModalCardType = card3dData | card3dData[]
export interface GlobalModalHandle {
  showToast: (
    msg: string,
    type: "CANCEL" | "TOAST" | "OK" | "LOAD" | "IMG",
    duration: number,
    needCover?: boolean,
    cprogress?: number,
    tprogress?: number,
    image?: Object,
  ) => NodeJS.Timeout
  hideToast: (time?: NodeJS.Timeout) => void
  showAlert: (
    title: string,
    onClose: () => void,
    onSure: () => void,
    info?: alertText,
    isPromise?: boolean,
  ) => Promise<any>
  showLoading: (msg: string, time?: number) => void
  doLogin: () => void
  showProgress: (msg: string, cprogress?: number, tprogress?: number) => void
  startSign: (hash: string) => Promise<any>
  showChooseAdd: () => void
}
const durationDefault = 5 * 60 * 1000
const GlobalModalref: React.ForwardRefRenderFunction<GlobalModalHandle, GlobalModalProps> = (
  {},
  ref,
) => {
  const time = useRef<NodeJS.Timeout>()

  const [isShow, setIsShow] = useState(false)
  const [info, setInfo] = useState<msg>({
    msg: "",
    type: "",
    needCover: true,
    cprogress: 1,
    tprogress: 1,
    image: { image: "", name: "", id: "" },
  })
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState<alertText>(DEFAULT_ALERT_TEXT as alertText)
  const needGoBack = useRef(false)
  const signRef = useRef<promiseReturn | null>(null)
  const [showChooseOrder, setShowChooseOrder] = useState(false)

  const chooseModal = () => {
    return (
      <ChooseOrderModal closeModal={() => setShowChooseOrder(false)} isShow={showChooseOrder} />
    )
  }

  useEffect(() => {
    const backAction = () => {
      if (!isShow) {
        return false
      } else {
        return true
      }
    }
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler.remove()
  }, [isShow])

  useImperativeHandle(ref, () => ({
    showToast,
    hideToast,
    showAlert,
    showLoading,
    doLogin,
    showProgress,
    startSign,
    showChooseAdd,
  }))
  const doLogin = () => {
    RootNavigation.navigate("LoginFromMessage")
  }
  const showChooseAdd = () => {
    setShowChooseOrder(true)
  }

  const showToast = (
    msg: string,
    type: string,
    duration: number,
    needCover = true,
    cprogress = 1,
    tprogress = 1,
    image: object,
  ) => {
    clearTimeout(time.current)
    setInfo({ msg, type, needCover, cprogress, tprogress, image })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault,
    )
    return time.current
  }
  const showProgress = (msg: string, cprogress = 1, tprogress = 1) => {
    setInfo({ msg, type: "Progress", cprogress, tprogress, needCover: true })
    setIsShow(true)
  }

  const showLoading = (msg: string, duration?: number) => {
    clearTimeout(time.current)
    setInfo({ msg, type: "LOAD", needCover: true })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault,
    )

    return time.current
  }
  const alertRef = useRef<promiseReturn | null>(null)

  const hideToast = (t?: NodeJS.Timeout) => {
    console.log("started", "=========")
    setIsShow(false)
    clearTimeout(t ? t : time.current)
  }
  const showAlert = (
    title: string,
    onClose: () => void,
    onSure: () => void,
    info: alertText = DEFAULT_ALERT_TEXT as alertText,
    isPromise?: boolean,
  ) => {
    AlertInfo = { msg: title, onClose, onSure, info: info.info }

    setAlertInfo(info)
    setIsShowAlert(true)
    if (isPromise) {
      if (alertRef.current) alertRef.current = null
      const alertPromise = new Promise((resolve, reject) => {
        alertRef.current = { resolve, reject }
      })

      return alertPromise
    }
    return null
  }
  const AlertComponent = () => {
    return (
      <PlatteWhiteAlert
        closeModal={() => {
          setIsShowAlert(false)
        }}
        isShow={isShowAlert}
        msg={AlertInfo.msg}
        onClose={() => {
          AlertInfo.onClose()
          if (alertRef.current) {
            alertRef.current.reject({ message: "取消操作" })
          }
        }}
        onConfirm={() => {
          AlertInfo.onSure()
          if (alertRef.current) {
            alertRef.current.resolve("授权成功")
          }
        }}
        alertInfo={alertInfo}
      />
    )
  }

  const getComponent = () => {
    switch (info.type) {
      case "LOAD":
        return <AnimteCircule msg={info.msg} />
      case "CANCEL":
        return <CancelView msg={info.msg} />
      case "OK":
        return <OKView msg={info.msg} />
      case "TOAST":
        return <Toast msg={info.msg} />
      case "Progress":
        return (
          <ProgressCircle msg={info.msg} cprogress={info.cprogress} tprogress={info.tprogress} />
        )
      case "IMG":
        return <ImgView msg={info.msg} image={info.image} />
      default:
        return null
    }
  }
  const [isWebViewShow, setIsWebViewShow] = useState(false)
  const [webViewHash, setWebViewHash] = useState("")
  const closeWebView = () => {
    setIsWebViewShow(false)
    if (signRef.current) {
      signRef.current.reject({ message: "取消签名" })
    }
  }
  const startSign = async (hash: string) => {
    if (signRef.current) signRef.current = null
    const signPromise = new Promise((resolve, reject) => {
      signRef.current = { resolve, reject }
    })
    console.log("start sign")
    //start SignModal
    if (Platform.OS === "web") {
      const url =
        `${genereateEnv.OAUTH_URL}/signature?hash=${hash}&appid=${genereateEnv.appid}&redirectUrl=` +
        window.location.href
      setTimeout(() => {
        window.open(url, "_blank")
      })
    } else {
      setWebViewHash(hash)
      setIsWebViewShow(true)
    }
    return signPromise
  }
  const onWebViewDismiss = () => {}
  const onWebViewMessage = (event: WebViewMessageEvent) => {
    const data = event.nativeEvent.data
    console.log("================WEBVIEW MESSAGE==============", data)
    dealPostData(data)
  }
  useEffect(() => {
    if (Platform.OS === "web") {
      window.addEventListener(
        "message",
        (e) => {
          if (e.origin === genereateEnv.OAUTH_URL || window?.["JIJI"]) {
            console.log("reveiced message", e)
            const data = e.data
            dealPostData(data)
          }
        },
        false,
      )
    }
  }, [])
  const dealPostData = (data: string) => {
    try {
      if (data && typeof data === "string") {
        const parseData = JSON.parse(data)
        console.log("parseData", parseData)
        setIsWebViewShow(false)
        if (signRef.current) signRef.current.resolve(parseData)
      }
    } catch (err) {
      if (signRef.current) signRef.current.reject({ message: err.message })
    }
  }

  return (
    <>
      {isShow && (
        <View pointerEvents={info.needCover ? "auto" : "none"} style={[styles.modal]}>
          {getComponent()}
        </View>
      )}
      {AlertComponent()}
      <SignWebviewModal
        hash={webViewHash}
        isShow={isWebViewShow}
        onMessage={onWebViewMessage}
        onDismiss={onWebViewDismiss}
        closeWebView={closeWebView}
      />
      {chooseModal()}
    </>
  )
}

export const ProgressCircle = ({ msg, cprogress, tprogress }) => {
  return (
    <View style={styles.centerWrap}>
      <Progress.Bar
        color={"#EDEDED"}
        progress={(cprogress || 0) / (tprogress || 1)}
        width={fitSize(66)}
        useNativeDriver
        style={{
          marginBottom: fitSize(19),
          marginTop: fitSize(10),
        }}
      />
      <Text style={styles.msgText}>{msg || "Loading"}</Text>
    </View>
  )
}

const OKView = ({ msg }) => {
  return (
    <View style={styles.centerWrap}>
      {/* <ImageBackground
        source={Images.common.copy_success}
        style={[styles.circleImg]}
      ></ImageBackground> */}
      <Text style={styles.msgText}>{msg || "请求成功"}</Text>
    </View>
  )
}
const CancelView = ({ msg }) => {
  return (
    <View style={styles.centerWrap}>
      {/* <ImageBackground
        source={Images.card.alert_modal}
        style={[styles.circleImg]}
      ></ImageBackground> */}
      <Text style={[styles.msgText]}>{msg || "服务器卡壳啦"}</Text>
    </View>
  )
}

const ImgView = ({ msg, image }) => {
  return (
    <View style={styles.imgView}>
      <View style={styles.imgViewRow}>
        {/* <ImageBackground source={Images.common.complete} style={styles.imgViewComplete} /> */}
        <Text style={styles.imgViewText}>{msg || "请求成功"}</Text>
      </View>
      <SizeImage
        isPreview
        source={{ uri: image?.image }}
        style={styles.imgViewContent}
        resizeMode={"contain"}
      />
      <Text style={styles.imgViewContentName} numberOfLines={1} ellipsizeMode={"tail"}>
        {image?.name}
      </Text>
      <Text style={styles.imgViewContentId} numberOfLines={1} ellipsizeMode={"tail"}>
        {image?.id}
      </Text>
    </View>
  )
}

export const Toast = ({ msg }) => {
  return (
    <View
      style={{
        borderRadius: fitSize(5),
        backgroundColor: "#4E4C4E",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: fitSize(20),
        paddingVertical: fitSize(15),
        alignSelf: "center",
      }}
    >
      <Text style={{ color: "#fff", fontSize: fitSize(18) }}>{msg || "服务器卡壳啦"}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10000,
    justifyContent: "center",
    alignItems: "center",
  },
  centerWrap: {
    width: fitSize(162),
    minHeight: fitSize(146),
    borderRadius: fitSize(10),
    backgroundColor: "#4D4D4D",
    alignSelf: "center",
    alignItems: "center",
    paddingTop: fitSize(35),
    paddingBottom: fitSize(30),
  },
  circleImg: {
    width: fitSize(36),
    height: fitSize(36),
  },
  msgText: {
    color: "#fff",
    fontSize: fitSize(18),
    marginTop: fitSize(20),
    width: fitSize(96),
    textAlign: "center",
  },
  imgView: {
    width: fitSize(200),
    minHeight: fitSize(146),
    borderRadius: fitSize(10),
    backgroundColor: "#fafafafa",
    alignSelf: "center",
    alignItems: "center",
    paddingTop: fitSize(10),
    paddingBottom: fitSize(10),
  },
  imgViewRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: fitSize(10),
  },
  imgViewText: {
    color: "#010101",
    fontSize: fitSize(18),
    textAlign: "center",
    marginLeft: fitSize(5),
  },
  imgViewComplete: {
    width: fitSize(26),
    height: fitSize(26),
  },
  imgViewContent: {
    width: fitSize(190),
    height: fitSize(190),
  },
  imgViewContentName: {
    color: "#9a9a9a",
    fontSize: fitSize(15),
    marginTop: fitSize(10),
  },
  imgViewContentId: {
    color: "#010101",
    fontSize: fitSize(8),
    marginTop: fitSize(5),
  },
})
export const GlobalModal = React.forwardRef(GlobalModalref)
