import {
  Instance,
  SnapshotOut,
  flow,
  getParent,
  getRoot,
  resolveIdentifier,
  types,
} from "mobx-state-tree"
import {
  collectCardItemModel,
  collectCardItemStore,
  collectCardItemStoreSnapshot,
} from "./collectCardItem"

import { RootStore } from "../root-store/root-store"

export const collectBagItemModel = types
  .model("collectBagItemStore")
  .props({
    CardShowId: types.maybe(types.string),
    temp: types.maybeNull(collectCardItemModel),
    key: types.identifier,
  })
  .views((self) => ({
    // get assetObject(): undefined | collectCardItemStore {
    //   // get the user manually from the tree
    //   const root = getRoot<RootStore>(self)
    //   //  return undefined;
    //   return resolveIdentifier(
    //     collectCardItemModel,
    //     root.collectStore.allCardsItemMapAddress,
    //     self.key,
    //   )
    // },
    get asset(): collectCardItemStoreSnapshot | null {
      const res = self.temp
      // || null
      // if (!res) {
      //   //TODO:比较hack的方式 来源 https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/__tests__/core/reference-custom.test.ts#L162
      //   setTimeout(() => self.loadAsset(), 0)
      // }
      return res
    },
  }))

type collectBagItemStoreType = Instance<typeof collectBagItemModel>
export interface collectBagItemStore extends collectBagItemStoreType {}
type collectBagItemStoreSnapshotType = SnapshotOut<typeof collectBagItemModel>
export interface collectBagItemStoreSnapshot extends collectBagItemStoreSnapshotType {}
export const createcollectBagItemStoreDefaultModel = () => types.optional(collectBagItemModel, {})
