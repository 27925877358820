import React, { useEffect } from "react"
import { StyleSheet, View } from "react-native"
import { showError, showInfo } from "../../../utils/showUtil"

import { HookFlatList } from "../../../components"
import { MarketItem } from "./MarketItem"
import { fitSize } from "../../../theme"
import { useAutoList } from "../../../hooks/useAutoList"
import { useIsFocused } from "@react-navigation/native"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../../models"

const MaxChoose = 5

interface MarketListProps {
  searchText?: string
  seriesId?: number
  orderPrice?: number
  orderTime?: number
  onChangeTotal?: (data: number) => void
  minPrice?: string
  maxPrice?: string
  isParity?: boolean
  skuInfo?: {
    skuNftAddress?: string
    skuId?: number
    skuIds?: number[]
  }
  showBatchChoose?: boolean
  onBachTotalPriceChange?: (data: number, items: marketItem[]) => void
  isOnlyTicket?: boolean
}
const PL = 12
export type marketItem = {
  id: number
  goodsName: string
  price: number
  tokenURI: string
  tagType?: number
  barterRequestMsg?: string
  sellerName?: string
  nftAsset?: {
    assetId: number
    nftAddress: string
    num: number
  }
}
const getOrderText = (order: number) => {
  switch (order) {
    case 1:
      return "ASC"
    case 2:
      return "DESC"
    default:
      return undefined
  }
}
export const MarketList: React.FC<MarketListProps> = ({
  searchText,
  orderPrice,
  orderTime,
  seriesId,
  onChangeTotal,
  maxPrice,
  minPrice,
  isParity,
  skuInfo,
  showBatchChoose,
  onBachTotalPriceChange,
  isOnlyTicket,
}) => {
  const { accountStore } = useStores()
  const navigation = useNavigation() as any

  // 就这儿 点列表的item
  const fetchData = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/getTradeMarketOrder",
      {
        page,
        pageSize: PL,
        goodsName: searchText || undefined,
        seriesId,
        order:
          orderPrice || orderTime
            ? {
                price: getOrderText(orderPrice),
                createdAt: getOrderText(orderTime),
              }
            : undefined,
        minPrice: Number(minPrice) || undefined,
        maxPrice: Number(maxPrice) || undefined,
        isParity: isParity === true ? true : undefined,
        skuNftAddress: skuInfo?.skuNftAddress,
        skuId: skuInfo?.skuId,
        skuIds: skuInfo?.skuIds,
        priceType: isOnlyTicket ? 2 : 0,
      },
      "post",
    )
    if (res.kind === "ok") {
      if (res.data?.totalCount > 0) {
        if (onChangeTotal) onChangeTotal(res.data?.totalCount)
      }
      const findata = res.data?.data as marketItem[]
      return findata.map((e) => ({ ...e, isChoose: false }))
    } else {
      throw res.msg
    }
  }

  const [list] = useAutoList<marketItem & { isChoose: boolean }>(
    {
      fetchData: fetchData,
      pageLimit: 12,
      isFocusRefresh: false,
    },
    [orderPrice, orderTime, seriesId, maxPrice, minPrice, isParity, skuInfo, isOnlyTicket],
  )
  const batchSelectItem = (item: marketItem & { isChoose: boolean }) => {
    const { setData, data } = list
    if (data.filter((e) => e.isChoose).length >= MaxChoose && item.isChoose === false) {
      showInfo("最多选" + MaxChoose + "个")
      return
    }
    setData((c) => c.map((e) => (e.id === item?.id ? { ...e, isChoose: !e.isChoose } : { ...e })))
  }
  useEffect(() => {
    if (list?.data?.length) {
      const filterItem = list.data.filter((e) => e.isChoose)
      const sum = filterItem.reduce((pre, cur) => pre + cur.price, 0)
      onBachTotalPriceChange(sum, filterItem)
    }
  }, [list.data])

  return (
    <View style={{ flex: 1 }}>
      <HookFlatList
        {...{ ...list }}
        numColumns={2}
        contentContainerStyle={{ paddingLeft: fitSize(7) }}
        renderItem={({ item, index }) => {
          return (
            <MarketItem
              showBatchChoose={showBatchChoose}
              item={item}
              batchSelectItem={batchSelectItem}
            />
          )
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
