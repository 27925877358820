import { Images, color, fitSize } from "../../../theme"
import React, { useEffect, useRef, useState } from "react"
import { ScrollView, StyleSheet, View } from "react-native"
import { Text, Touchable } from "../../../components"

import { SizeImage } from "../../../components/image-component/SizeImage"
import { marketItem } from "./MarketList"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"
import { useUriInfo } from "../../../hooks/useUriInfo"

export type nftItem = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: string[]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}

interface MarketItemProps {
  item: marketItem
}

export const TradeForItemItem: React.FC<MarketItemProps> = ({ item }) => {
  const navigation = useTypeNavigation()
  const [data] = useUriInfo(item.tokenURI, [item.tokenURI])
  return (
    <Touchable
      onPress={() => {
        navigation.push("TransactionScreenTradeToItemScreen", { id: item.id })
      }}
      style={{
        width: fitSize(351),
        height: fitSize(264),
        margin: fitSize(6),
        // padding: fitSize(10),
        backgroundColor: color.secBackground,
        borderRadius: fitSize(10),
        shadowColor: "#000",
        shadowOpacity: 0.08,
        shadowOffset: { width: fitSize(0), height: fitSize(2) },
        shadowRadius: fitSize(5),
        padding: fitSize(15),
        paddingTop: fitSize(12),
        paddingBottom: 0,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Text style={{ fontSize: fitSize(18), fontWeight: "600", color: color.primary }}>
          {item?.sellerName}
        </Text>
        {/* <View style={{ flexDirection: "row", alignItems: "center" }}>
          <SizeImage
            source={Images.home.people}
            style={{ width: fitSize(16), height: fitSize(16), marginRight: fitSize(5) }}
          />
          <Text style={{ fontSize: fitSize(13), color: color.primary }}>123123</Text>
        </View> */}
      </View>
      <View
        style={{
          width: fitSize(321),
          alignSelf: "center",
          marginTop: fitSize(10),
          height: fitSize(63),
          paddingTop: fitSize(10),
          borderBottomColor: "rgba(0, 175, 181, 0.1)",
          borderBottomWidth: fitSize(1),
        }}
      >
        <Text numberOfLines={2} style={{ fontSize: fitSize(13), color: "#888" }}>
          {item?.barterRequestMsg}
        </Text>
      </View>
      <View style={{ flex: 1, paddingTop: fitSize(5) }}>
        <ScrollView horizontal>
          <View
            style={{
              width: fitSize(90),
              height: fitSize(129),
              marginHorizontal: fitSize(4.5),
              backgroundColor: color.secBackground,
              borderRadius: fitSize(10),
              shadowColor: "#000",
              shadowOpacity: 0.08,
              shadowOffset: { width: fitSize(0), height: fitSize(2) },
              shadowRadius: fitSize(5),
              marginVertical: fitSize(6),
            }}
          >
            <SizeImage
              source={{ uri: data?.Image }}
              isPreview
              resizeMode={"contain"}
              style={{
                width: fitSize(90),
                height: fitSize(90),
                borderTopLeftRadius: fitSize(10),
                borderTopRightRadius: fitSize(10),
                overflow: "hidden",
                backgroundColor: "#000",
              }}
            />
            <Text
              numberOfLines={1}
              style={{
                width: fitSize(90 - 8),
                fontSize: fitSize(11),
                marginTop: fitSize(5),
                marginLeft: fitSize(8),
                color: color.primary,
              }}
            >
              {data?.Name}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                width: fitSize(90 - 8),
                fontSize: fitSize(10),
                marginLeft: fitSize(8),
                color: color.dim,
              }}
            >
              {data?.CardShowId}
            </Text>
          </View>
        </ScrollView>
      </View>
    </Touchable>
  )
}

const styles = StyleSheet.create({})
