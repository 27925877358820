import { HookFlatList, Text, Touchable } from "../../../components"
import { Images, fitSize } from "../../../theme"
import { StyleSheet, View } from "react-native"

import React from "react"
import { SizeImage } from "../../../components/image-component/SizeImage"
import { observer } from "mobx-react-lite"
import { useAuthAutoList } from "../../../hooks/useAuthAutoList"
import { useAutoList } from "../../../hooks/useAutoList"
import { useStores } from "../../../models"
import { useTypeNavigation } from "../../../navigators/useTypeNavigation"

interface SeriesListProps {}
interface seriesData {
  seriesId: number
  seriesName: string
  seriesCover: string
  description: string
  assetNum: number
  cardContractList: CardContractList[]
  boxContractList: CardContractList[]
}

interface CardContractList {
  contractName: string
  contractAddress: string
}
const PL = 10
export const SeriesList: React.FC<SeriesListProps> = observer(({}) => {
  const { accountStore, collectStore } = useStores()
  const { push } = useTypeNavigation()

  const fetchUserAssetBySeriesId = async (page: number) => {
    const res = await accountStore.environment.api.normalRequest(
      `card/getUserSeriesNftInfo/${page}/${PL}`,
      "get",
    )
    if (res.kind === "ok") {
      return res?.data?.list
    } else {
      throw res.msg
    }
  }

  const [list] = useAuthAutoList<seriesData>({
    fetchData: fetchUserAssetBySeriesId,
    pageLimit: PL,
  })

  return (
    <View key={accountStore.isOK ? 1 : 0} style={{ flex: 1 }}>
      <HookFlatList
        errorTop={fitSize(50)}
        {...{ ...list }}
        renderItem={({ item, index }) => {
          return (
            <Touchable
              onPress={() => {
                push("CardsPage", {
                  contractAddress: [
                    ...item?.boxContractList.map((e) => e.contractAddress),
                    ...item?.cardContractList.map((e) => e.contractAddress),
                  ],
                  name: item?.seriesName,
                })
              }}
              style={styles.collectionItem}
            >
              <SizeImage
                style={{
                  height: fitSize(80),
                  width: fitSize(80),
                  borderRadius: fitSize(10),
                  overflow: "hidden",
                  backgroundColor: "#000",
                }}
                resizeMode={"contain"}
                source={{ uri: item?.seriesCover }}
              ></SizeImage>
              <View style={{ justifyContent: "center", marginLeft: fitSize(10) }}>
                <Text style={styles.collectionName}>{item?.seriesName}</Text>
                <Text style={styles.colectionNumber}>{item?.assetNum}件</Text>
              </View>
              <SizeImage style={styles.moreIcon} source={Images.mine.more}></SizeImage>
            </Touchable>
          )
        }}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  collectionItem: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: fitSize(6),
  },

  collectionName: {
    fontSize: fitSize(15),
    color: "#333333",
    marginBottom: fitSize(5),
    fontWeight: "600",
  },

  colectionNumber: {
    fontSize: fitSize(14),
    color: "#888888",
  },

  moreIcon: {
    height: fitSize(20),
    width: fitSize(20),
    marginLeft: "auto",
  },
})
