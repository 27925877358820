import { Animated, StyleSheet, View } from "react-native"
import { NavigationState, Route, SceneRendererProps, TabBar } from "react-native-tab-view"
import { color, fitSize } from "../../../theme"

import React from "react"
import { useTheme } from "styled-components/native"
import { themeData } from "../../../navigators"

type MyTabBarProps<T extends Route> = SceneRendererProps & {
  navigationState: NavigationState<T>
  split?: number
  indicatorLeft?: number
  bgColor?: string
  textTintColor?: string
  textColor?: string
  leftSpace?: number
  width?: number
  textSize?: number
  tintTextSize?: number
}

export function MyTabBar<T extends Route>(props: MyTabBarProps<T>) {
  const theme = useTheme() as themeData
  return (
    <TabBar
      {...props}
      activeColor={"#333"}
      inactiveColor={"#999999"}
      tabStyle={{
        width: props.width ? props.width : fitSize(100),
        height: fitSize(49),
      }}
      contentContainerStyle={{
        paddingLeft: props.leftSpace
          ? props.leftSpace
          : fitSize(375 - 100 * (props?.split || 3)) / 2,
      }}
      indicatorStyle={{
        // backgroundColor: theme.themeColor || color.primary,
        backgroundColor: color.primary,
        width: fitSize(32),
        height: fitSize(2),
        left: props?.indicatorLeft || fitSize(92),
        bottom: fitSize(8),
      }}
      style={{
        backgroundColor: props.bgColor || null,
        shadowRadius: 0,
        elevation: 0,
        borderWidth: 0,
        shadowOpacity: 0,
        width: "100%",
        alignSelf: "center",
      }}
      scrollEnabled={false}
      renderLabel={({ route, focused }) => {
        return (
          <Animated.Text
            style={{
              color: focused
                ? props.textTintColor || color.primary
                : props.textColor || color.primaryLigher,
              fontSize: focused ? props.tintTextSize || fitSize(18) : props.textSize || fitSize(16),
              fontWeight: focused ? "bold" : "normal",
              width: props.width ? props.width : fitSize(100),
              height: fitSize(49),
              textAlign: "center",
              lineHeight: fitSize(49),
            }}
          >
            {route.title}
          </Animated.Text>
        )
      }}
    />
  )
}

const styles = StyleSheet.create({})
