import { Images, fitSize } from "../../theme"
import { StyleSheet, View } from "react-native"

import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { NaviItem } from "./components/NaviItem"
import React from "react"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { Text } from "../../components"
import { Touchable } from "../../components"
import { showInfo } from "../../utils/showUtil"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"

interface SettingProps extends RootStackNavProps<"Setting"> {}

export const Setting: React.FC<SettingProps> = ({ navigation }) => {
  const store = useStores()
  const exitQuery = () => {
    GToast.current.showAlert(
      "退出登录",
      () => {},
      () => {
        exit()
      },
      { closeText: "再想想", sureText: "确认", info: "你是否确认退出当前登录账号？" },
    )
  }
  const exit = () => {
    store.removeAll()
    //@ts-ignore
    navigation.navigate("TabOneScreen")
    GToast.current.showToast("退出成功", "TOAST", 2000)
  }
  const { bottom } = useSafeAreaInsets()

  return (
    <FlexView>
      <Sheader isNotSafe rightImage="" title={"设置"} />

      {/* <NaviItem
        onPress={() => navigation.push("RelationService")}
        leftTitle={"头像"}
        imageSource={Images.setting.testAvatar}
        height={fitSize(80)}
        top={fitSize(5)}
      /> */}
      <NaviItem onPress={() => navigation.push("About")} leftTitle={"关于"} top={fitSize(5)} />
      <NaviItem
        onPress={() => navigation.push("RelationService")}
        leftTitle={"联系客服"}
        top={fitSize(5)}
      />
      <Touchable
        onPress={exitQuery}
        style={{
          position: "absolute",
          bottom: bottom,
          width: "100%",
          height: fitSize(56),
          backgroundColor: "#fff",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: fitSize(15), color: "#00AFB5" }}>退出当前账号</Text>
      </Touchable>
    </FlexView>
  )
}

const styles = StyleSheet.create({})
