import { StyleSheet, View } from "react-native"
import { Text, Touchable, TouchableDeep } from "../../components"
import { color, deviceHeight, fitSize } from "../../theme"

import Modal from "react-native-modal"
import React from "react"
import { alertText } from "../GlobalModal"

interface PlatteWhiteAlertProps {
  msg: string
  isShow: boolean
  closeModal: () => void
  onConfirm: () => void
  onClose?: () => void
  alertInfo: alertText
}

export const PlatteWhiteAlert: React.FC<PlatteWhiteAlertProps> = ({
  msg,
  closeModal,
  isShow,
  onConfirm,
  onClose,
  alertInfo,
}) => {
  return (
    <Modal
      useNativeDriverForBackdrop
      hasBackdrop={true}
      coverScreen={true}
      isVisible={isShow}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={{ margin: 0 }}
      onBackButtonPress={() => {}}
      onBackdropPress={() => {}}
      swipeDirection={"down"}
      backdropTransitionOutTiming={0}
      useNativeDriver
      deviceHeight={deviceHeight}
      hideModalContentWhileAnimating={true}
    >
      <View style={styles.container}>
        <Text style={styles.msg}>{msg}</Text>
        {!!alertInfo.info && (
          <Text
            style={{
              color: "#333",
              fontSize: fitSize(16),
              marginVertical: fitSize(15),
              paddingHorizontal: fitSize(10),
              marginBottom: fitSize(5),
            }}
          >
            {alertInfo.info}
          </Text>
        )}
        <View style={{ flex: 1 }} />
        <View style={styles.bottomWrap}>
          {!alertInfo?.isShowOne && (
            <TouchableDeep
              onPress={() => {
                closeModal()
                if (typeof onClose === "function") onClose()
              }}
              style={styles.leftBtn}
            >
              <Text style={[styles.content, { color: color.primary }]}>{alertInfo.closeText}</Text>
            </TouchableDeep>
          )}
          <TouchableDeep
            onPress={() => {
              closeModal()
              onConfirm()
            }}
            style={[styles.leftBtn, { backgroundColor: color.primary }]}
          >
            <Text style={[styles.content, { color: color.secBackground }]}>
              {alertInfo.sureText}
            </Text>
          </TouchableDeep>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: fitSize(285),
    backgroundColor: color.secBackground,
    borderRadius: fitSize(20),
    padding: fitSize(20),
    alignSelf: "center",
    alignItems: "center",
  },
  msg: {
    fontSize: fitSize(18),
    color: color.text,
    fontWeight: "600",
  },
  bottomWrap: {
    // position: "absolute",
    // bottom: 0,
    width: "100%",
    flexDirection: "row",
    // height: scaleSize(55),
    justifyContent: "space-around",
    marginTop: fitSize(20),
  },
  leftBtn: {
    // flex: 1,
    width: fitSize(116),
    height: fitSize(42),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: fitSize(21),
    borderWidth: fitSize(1),
    borderColor: color.primary,
  },
  rightBtn: {
    width: fitSize(116),
    height: fitSize(42),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: fitSize(21),
    borderWidth: fitSize(1),
    borderColor: color.primary,
    backgroundColor: color.primary,
  },

  content: {
    color: "#333",
    fontSize: fitSize(18),
  },
  modalInfoText: {
    color: "#06FDE8",
    fontSize: fitSize(15),
    // fontWeight: "600",
    marginTop: fitSize(15),
  },
  clearModalText: {
    color: "#06FDE8",
    fontSize: fitSize(13),
    fontWeight: "500",
  },
  modalSubmitText: {
    color: "#17181A",
    fontSize: fitSize(16),
    fontWeight: "600",
  },
})
