import * as Types from "./api.types"
import * as storage from "../../utils/storage"

import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import { ApiResponse, ApisauceInstance, create } from "apisauce"
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem"
import { GetAssetList, GetNormalRequest } from "./api.types"

import { GToast } from "../../navigators"
import { getChannel } from "../../utils/getMetaInfo"
import { retry } from "../../utils/retry"

export const enum TransactionType {
  TransactionTypeNone,
  TransactionTypeActivityBuy,
  TransactionTypeOpenBox,
}
export const enum TransactionStatus {
  None,
  Pending,
  Success,
  Failed,
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  async setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url + this.config.pathPrefix,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })
  }

  getServer(): string {
    return this.apisauce.getBaseURL()
  }

  async prepare(
    id: number,
    path: string,
    data: Record<string, unknown>,
    isMessageHash?: boolean,
  ): Promise<[GeneralApiProblem | undefined, string, string]> {
    const prepareResponse: ApiResponse<any> = await this.apisauce.post(path, data)
    console.log(prepareResponse, "prepareResponse")
    const err = dealError(prepareResponse)
    if (err) return [err as GeneralApiProblem, "", ""]
    const uuid = prepareResponse?.data.data.uuid as string
    let messageHash = ""
    if (isMessageHash) {
      messageHash = prepareResponse?.data.data.messageHash
    } else {
      messageHash = prepareResponse?.data.data
    }
    const res = await GToast.current.startSign(messageHash)
    if (res.hash === messageHash) {
      if (res.signature === "") {
        throw { message: "取消签名" }
      }
      return [undefined, uuid, res.signature]
    } else {
      throw { message: "返回hash错误" }
    }
    // const signature = await sign(id, messageHash)
  }

  async getSignature(
    contractName: string,
    data: { nftAddress: string; nftId: string; orderId: string },
    isSell: boolean,
    outInfo?: any,
  ): Promise<any> {
    try {
      const info = {
        contractName,
        domain: "Web2PayableMarket",
        version: "0.0.1",
        operationName: "sellNft",
        data,
      }
      const [prepareErr, uuid, signature] = await this.prepare(
        0,
        "/user/getSignatureData",
        outInfo || info,
      )
      if (prepareErr) return prepareErr
      if (isSell) {
        const response: ApiResponse<any> = await this.apisauce.post("/market/sellNft", {
          orderId: data.orderId,
          signature,
        })
        const err = dealError(response)
        if (err) return err as GeneralApiProblem
        return { kind: "ok", data: response?.data?.data }
      } else {
        return { kind: "ok", data: signature }
      }
    } catch (e) {
      console.log("userPrepareApprove", e)
      __DEV__ && console.tron.log(e.message)
      throw { kind: "error", msg: e.message }
      // return { kind: "error", msg: e.message }
    }
  }

  async onlyGetSignature(
    contractName: string,
    data: { nftAddress: string; nftId: string; orderId: string },
  ): Promise<string> {
    try {
      const info = {
        contractName,
        domain: "Web2PayableMarket",
        version: "0.0.1",
        operationName: "sellNft",
        data,
      }
      const [prepareErr, uuid, signature] = await this.prepare(0, "/user/getSignatureData", info)
      if (prepareErr) throw prepareErr
      return signature
    } catch (e) {
      console.log("userPrepareApprove", e)
      __DEV__ && console.tron.log(e.message)
      throw { kind: "error", msg: e.message }
      // return { kind: "error", msg: e.message }
    }
  }
  async signData(data: any): Promise<string> {
    try {
      const [prepareErr, uuid, signature] = await this.prepare(0, "/user/getSignatureData", data)
      if (prepareErr) throw prepareErr
      return signature
    } catch (e) {
      console.log("userPrepareApprove", e)
      __DEV__ && console.tron.log(e.message)
      throw { kind: "error", msg: e.message }
      // return { kind: "error", msg: e.message }
    }
  }

  //用户获取质押物品
  async userPrepareApprove(contractName: string, cardContractAddress: string): Promise<any> {
    try {
      const data = {
        contractName,
        cardContractAddress,
      }
      const [prepareErr, uuid, signature] = await this.prepare(
        0,
        "/card/prepareApproveOperateCard",
        data,
        true,
      )
      if (prepareErr) return prepareErr

      const response: ApiResponse<any> = await this.apisauce.post("/card/approveOperateCard", {
        uuid,
        signature,
        ...data,
      })
      console.log("stakeReward", response)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem

      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log("userPrepareApprove", e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
      // return { kind: "error", msg: e.message }
    }
  }

  async userOpenCase(
    userid: number,
    openBlindBox: string,
    nftAddress: string,
  ): Promise<Types.GetTransResponse> {
    try {
      const data = {
        blindBoxId: openBlindBox,
        nftAddress: nftAddress,
      }
      // const [prepareErr, uuid, signature] = await this.prepare(
      //   userid,
      //   "/user/asset/prepareOpenBlindBox",
      //   data,
      // )
      // if (prepareErr) return prepareErr

      // const response: ApiResponse<any> = await this.apisauce.post("/user/asset/openBlindBox", {
      //   uuid,
      //   Signature: signature,
      //   ...data,
      // })
      const response: ApiResponse<any> = await this.apisauce.post(
        "/user/asset/openBlindBoxNew",
        data,
      )
      console.log("response", response)

      const err = dealError(response)
      if (err) return err as GeneralApiProblem

      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  //授权
  async userPrepareThings(
    userid: number,
    data: any,
    urlPrepare: string,
    urlDo: string,
  ): Promise<any> {
    try {
      const [prepareErr, uuid, signature] = await this.prepare(userid, urlPrepare, data)
      if (prepareErr) return prepareErr

      const response: ApiResponse<any> = await this.apisauce.post(urlDo, {
        uuid,
        Signature: signature,
        ...data,
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem

      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  async doTransaction(
    transactionType: TransactionType,
    transactionId: string,
  ): Promise<Types.GetTransResultRequest> {
    try {
      const ErrorQuest = () =>
        dealQueayTrans(() =>
          this.apisauce.post("/user/queryTransaction", {
            transactionId,
          }),
        )
      //只有异常才会重复执行
      const response: ApiResponse<any> = await retry(ErrorQuest, 10, 5000)
      console.log("response", response)
      if (response?.data?.status === TransactionStatus.Failed) {
        throw { kind: "error", msg: "查询交易失败" }
      }
      const err = dealError(response)
      if (err) return err as GeneralApiProblem

      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }
  async doMessageIdSearch(messageId: string): Promise<Types.GetTransResultRequest> {
    try {
      const ErrorQuest = () =>
        dealQueayTrans(() =>
          this.apisauce.post("user/getAsyncResult", {
            msgId: messageId,
          }),
        )
      //只有异常才会重复执行
      const response: ApiResponse<any> = await retry(ErrorQuest, 10, 5000)
      const resJSON = JSON.parse(response?.data?.data)
      const status = resJSON?.status
      if (status === TransactionStatus.Failed) {
        return { kind: "error", msg: "查询交易失败" }
      }
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: resJSON }
    } catch (e) {
      console.log(e)
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }
  //通用请求
  async messageIdRequest(
    path: string,
    params?: any,
    way: string = "get",
  ): Promise<GetNormalRequest> {
    try {
      const response: ApiResponse<any> = await this.apisauce[way](path, params ?? {})
      console.log(path, params, response, "doTakeDown")
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      if (!response?.data?.data?.msgId) {
        return { kind: "error", msg: "返回格式错误" }
      }
      const msgRes = await this.doMessageIdSearch(response?.data?.data?.msgId)
      if (msgRes.kind !== "ok") {
        return msgRes
      }
      return { kind: "ok", data: msgRes.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  //通用请求
  async normalRequest<T = any>(
    path: string,
    params?: any,
    way: string = "get",
  ): Promise<GetNormalRequest<T>> {
    try {
      const response: ApiResponse<any> = await this.apisauce[way](path, params ?? {})
      console.log(path, params, response, "doTakeDown")
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response?.data?.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  async getAssetList(page: number, pageSize: number): Promise<GetAssetList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get("/user/activity/list", {
        page,
        pageSize,
      })
      console.log("user/activity/list", response)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  async updateMarketTradeOrder(
    orderId: string,
    orderStatus: number,
    signature: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post(
        "/market/updateMarketTradeOrder",
        {
          orderId,
          orderStatus,
          signature,
        },
      )
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data, status: response.status }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }

  async createTradeOrder(orderId: string, lastestTime: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post("/market/createTradeOrder", {
        orderId,
        lastestTime,
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: "ok", data: response.data.data, status: response.status }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "error", msg: e.message }
    }
  }
}

export function dealError(response: ApiResponse<any>) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) return problem
  }
  if (response.data.code !== 0) {
    if (response.data.code === 123456) {
      return { kind: "error", msg: "登录验证失败" }
    } else {
      return {
        kind: "error",
        msg: response.data?.error ? String(response.data?.error) : response.data?.message,
        code: response.data.code,
      }
    }
  }
  return null
}
export function dealErrortest(response: ApiResponse<any>) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) return problem
  }
  return null
}
export const dealQueayTrans = async (fn: () => Promise<ApiResponse<any>>) => {
  const res = await fn()
  const status = JSON.parse(res.data?.data)?.status
  if (res.ok && status == 1) {
    throw { kind: "error", msg: "TransctionRetry" }
  } else {
    return res
  }
}
