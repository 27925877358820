import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const nftcfgModel = types.model("nftcfgStore").props({
  baseUrl: types.maybeNull(types.string),
  nftAddress: types.identifier,
  type: types.maybeNull(types.number),
  seriesId: types.maybeNull(types.number),
  allCfgUrl: types.maybeNull(types.string),
  seriesAtlasUrl: types.maybeNull(types.string),
})

type nftcfgStoreType = Instance<typeof nftcfgModel>
export interface nftcfgStore extends nftcfgStoreType {}
type nftcfgStoreSnapshotType = SnapshotOut<typeof nftcfgModel>
export interface nftcfgStoreSnapshot extends nftcfgStoreSnapshotType {}
export const createnftcfgStoreDefaultModel = () => types.optional(nftcfgModel, {})
