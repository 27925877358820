import {
  Platform,
  StyleSheet,
  TextInput,
  ScrollView,
  View,
  useWindowDimensions,
} from "react-native"
import { fitSize } from "../../theme"
import React, { useRef, useState } from "react"
import { Text, Touchable } from "../../components"
import { showError } from "../../utils/showUtil"
import { DismissKeyboardView } from "../../components/screen/DismissKeyboardView"
import { GToast } from "../../navigators"
import { RootStackNavProps } from "../../type/RouteType"
import { genereateEnv } from "../../../genereateEnv"
import { getChannel } from "../../utils/getMetaInfo"
import { translate } from "../../i18n"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { TextModal } from "../../modal/TextModal"
import RenderHtml from "react-native-render-html"
import { isWebView } from "../../hooks/useInit"

interface LoginFromMessageProps extends RootStackNavProps<"LoginFromMessage"> {}

enum textModal {
  agreement = "agreement",
  privacy = "privacy",
}

export const LoginFromMessage: React.FC<LoginFromMessageProps> = ({ navigation, route }) => {
  const { top, bottom } = useSafeAreaInsets()
  const { accountStore } = useStores()
  const [isCheckbox, setIsCheckbox] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("")
  const [modalContent, setModalContent] = useState<string>("")
  const { width } = useWindowDimensions()

  useFocusEffect(
    React.useCallback(() => {
      if (route.params?.code) {
        doLogin(route.params?.code)
      }
      if (isWebView()) {
        document.getElementById("wxtip").style.display = "block"
      }
    }, [route]),
  )

  const doLogin = async (code: string, inviteCode?: string) => {
    GToast.current.showLoading("加载中")
    const channel = await getChannel()
    const res = await accountStore.environment.api.normalRequest(
      "/login/loginOAuth",
      { code, channel: channel },
      "post",
    )
    GToast.current.hideToast()
    if (res.kind === "ok") {
      //TODO:saveCode
      console.log("res.data", res.data)
      accountStore.doMessageLogin(res.data.accessToken)
      navigation.goBack()
    } else {
      showError(res)
    }
  }

  const goToMassOauth = () => {
    if (isCheckbox) {
      if (Platform.OS === "web") {
        const oldUrl = new URL(window.location.href)
        window.location.href = `${genereateEnv.OAUTH_URL}/authorize?redirectUrl=${
          oldUrl.origin + `/LoginFromMessage`
        }&appid=${genereateEnv.appid}`
      } else {
        const jijiurl = "http://jiji.app"
        navigation.navigate("MassWebView", {
          uri: `${genereateEnv.OAUTH_URL}/authorize?redirectUrl=${jijiurl}&appid=${genereateEnv.appid}`,
        })
      }
    } else {
      GToast.current.showToast("请阅读用户协议和隐私政策", "TOAST", 2000)
    }
  }
  const textRef = useRef<TextInput>()
  useFocusEffect(
    React.useCallback(() => {
      return () => textRef?.current?.blur()
    }, []),
  )

  const onCheckbox = (): void => {
    setIsCheckbox(!isCheckbox)
  }

  const getAgreementAPI = async (type: 0 | 1) => {
    const res = await accountStore.environment.api.normalRequest(
      `user/home/agreement/query/${type}`,
      "get",
    )
    if (res.kind === "ok") {
      if (res.data) {
        setModalContent(res?.data?.agreement)
      }
    } else {
      setModalContent("")
    }
  }

  const openTextModal = (type: textModal): void => {
    setModalVisible(true)
    if (type === textModal.agreement) {
      getAgreementAPI(0)
      setModalTitle("用户协议")
    } else if (type === textModal.privacy) {
      getAgreementAPI(1)
      setModalTitle("隐私政策")
    }
  }

  return (
    <DismissKeyboardView style={styles.container}>
      <TextModal modalVisible={modalVisible} setModalVisible={setModalVisible} title={modalTitle}>
        <ScrollView
          style={styles.modalScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          {Platform.OS === "web" ? (
            <div style={styles.modalText} dangerouslySetInnerHTML={{ __html: modalContent }} />
          ) : (
            <RenderHtml
              contentWidth={width}
              source={{
                html: modalContent,
              }}
            />
          )}
        </ScrollView>
      </TextModal>
      <Text style={[styles.loginTitle, { marginTop: fitSize(128) + top }]}>登录后继续操作</Text>
      <View style={styles.userAgreement}>
        <Touchable
          style={[styles.checkbox, { backgroundColor: isCheckbox ? "#FABF33" : "#c2ccd0" }]}
          onPress={onCheckbox}
        />
        <Text>我已阅读并同意</Text>
        <Touchable onPress={() => openTextModal(textModal.agreement)}>
          <Text style={styles.userAgreementText}>《用户协议》</Text>
        </Touchable>
        <Text>和</Text>
        <Touchable onPress={() => openTextModal(textModal.privacy)}>
          <Text style={styles.userAgreementText}>《隐私政策》</Text>
        </Touchable>
      </View>
      <Touchable style={styles.loginButton} onPress={goToMassOauth}>
        <Text style={styles.loginButtonText}>DID登录</Text>
      </Touchable>
    </DismissKeyboardView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
  },
  inputWrap: {
    width: fitSize(330),
    // backgroundColor: "red",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#E6E6E6",
    paddingVertical: fitSize(5),
    marginTop: fitSize(35),
    flexDirection: "row",
  },
  inputNumber: {
    flexGrow: 1,
    color: "#333",
    fontSize: fitSize(16),
    // backgroundColor: "red",
    // backgroundColor: "red",
    paddingVertical: fitSize(0),
  },
  cirle: {
    width: fitSize(14),
    height: fitSize(14),
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: fitSize(7),
    borderColor: "#2697FF",
    marginTop: fitSize(2),
    marginRight: fitSize(5),
  },
  content: {
    color: "#B3B3B3",
    fontSize: fitSize(12),
  },
  contentBlack: {
    color: "#333",
    fontSize: fitSize(12),
  },
  modalcontainer: {
    backgroundColor: "#fff",
    width: fitSize(286),
    minHeight: fitSize(145),
    alignSelf: "center",
    borderRadius: fitSize(6),
  },
  msg: {
    fontSize: fitSize(16),
    color: "#333",
    alignSelf: "center",
    marginTop: fitSize(20),
    marginBottom: fitSize(20),
    // fontWeight: "bold",
    paddingHorizontal: fitSize(10),
  },
  bottomWrap: {
    // position: "absolute",
    // bottom: 0,
    width: fitSize(286),
    flexDirection: "row",
    height: fitSize(55),
    borderTopColor: "#B3B3B3",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  leftBtn: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: "#B3B3B3",
  },
  modalContent: {
    color: "#333",
    fontSize: fitSize(18),
  },
  userAgreement: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: fitSize(330),
    height: fitSize(30),
    marginTop: fitSize(80),
    fontSize: fitSize(12),
    color: "#010101",
  },
  checkbox: {
    width: fitSize(15),
    height: fitSize(15),
    borderRadius: fitSize(30),
    marginRight: fitSize(5),
  },
  userAgreementText: {
    fontSize: fitSize(12),
    color: "#010101",
    fontWeight: "bold",
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  modalScrollView: {
    flex: 1,
    padding: fitSize(5),
  },
  modalText: {
    width: "100%",
    flexWrap: "wrap",
  },
  loginButton: {
    width: fitSize(330),
    height: fitSize(50),
    borderRadius: fitSize(5),
    backgroundColor: "#010101",
    alignSelf: "center",
    marginTop: fitSize(10),
    justifyContent: "center",
    alignItems: "center",
  },
  loginButtonText: {
    color: "#fff",
    fontSize: fitSize(18),
  },
  loginTitle: {
    color: "#333",
    fontSize: fitSize(28),
    alignSelf: "center",
  },
  goBackIcon: {
    width: fitSize(24),
    height: fitSize(24),
  },
  goBackIconBox: {
    padding: fitSize(15),
    position: "absolute",
    left: fitSize(5),
  },
})
