import { useEffect, useRef, useState } from "react"

import { GToast } from "../navigators"
import { useStores } from "../models"

interface useApiType {
  func: Function
  config?: {
    showLoading?: boolean
    loadingText?: string
    showError?: boolean
    dealFunc?: (data: any) => any
    directReq?: boolean
  }
}
export interface resType<T> {
  error: string | null
  result: T
  loading: boolean
  isStart: boolean
}

export function useAsync<T>({
  func,
  config = {
    showError: true,
    loadingText: "",
    showLoading: false,
    dealFunc: null,
    directReq: false,
  },
}: useApiType) {
  const [result, setResult] = useState<resType<T>>({
    error: null,
    result: null,
    loading: false,
    isStart: false,
  })
  const sub = useRef(true)
  const doRequest = async () => {
    setResult({ ...result, loading: true, isStart: true })
    if (config.showLoading) {
      GToast.current.showLoading(config.loadingText || "加载中")
    }
    try {
      const res = await func()
      if (config.showLoading) {
        GToast.current.hideToast()
      }
      if (sub.current === false) {
        return
      }
      if (config?.dealFunc) {
        setResult({ ...result, result: config?.dealFunc(res), loading: false })
      } else {
        setResult({ ...result, result: res, loading: false })
      }
    } catch (err) {
      console.log("error", err)
      const error = typeof err === "string" ? err : err?.message
      setResult({ ...result, loading: false, error: error })
      if (config.showError) GToast.current.showToast(error, "TOAST", 2000)
    }
  }
  useEffect(() => {
    sub.current = true
    doRequest()
    return () => {
      sub.current = false
    }
  }, [])
  return { result, setResult, doRequest }
}
