import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
  useWindowDimensions,
} from "react-native"
import React, { useEffect, useRef, useState } from "react"
import { hideLoad, showError, showInfo } from "../../utils/showUtil"

import DropDownPicker from "react-native-dropdown-picker"
import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { Images } from "../../theme/Images"
import { MyErrorCode } from "../../utils/errorCode"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { SizeImage } from "../../components/image-component/SizeImage"
import { Toast } from "../../modal/GlobalModal"
import { Touchable } from "../../components"
import _ from "lodash"
import { color } from "../../theme"
import dayjs from "dayjs"
import { fitSize } from "../../theme/Layout"
import { useStores } from "../../models"

interface transactionInfoProps extends RootStackNavProps<"TransactionInfo"> {}

type itemType = {
  NftId: string
  CardShowId: string
  issueTime: number
  Issuer: string
  issuerImage: string
  Series: string
  SeriesId: number
  copyright: string
  copyrightImage: string
  BackgroundImage: string
  MiniBackgroundImage: string
  Type: number
  contractName: string
  contractAddress: string
  androidBundle: string
  iOSBundle: string
  bundleBaseUrl: string
  packageName: string
  versionCode: number
  Fileformat: [string]
  DefaultFormat: string
  UnityViewCode: string
  assetId: number
  TypeId: number
  Rarity: number
  Name: string
  Description: string
  Image: string
  GifImage: string
  Circulation: number
}

type consignSaleType = {
  nftId: number
  contractAddress: string
  price: string
  goodsName: string
  nftAsset: {
    nftAddress?: string
    assetId?: number
    num: number
  }
}
const minPrice = 1
const maxPrice = 20000

export const TransactionInfo: React.FC<transactionInfoProps> = ({ navigation, route }) => {
  const isEdit = route?.params?.isEdit
  const isReOnSell = route?.params?.isReOnSell
  const { accountStore, collectStore } = useStores()

  const item = route.params?.nftItem
  const [consignSale, setConsignSale] = useState<consignSaleType>(() => ({
    nftId: Number(item?.NftId),
    contractAddress: item?.contractAddress,
    price: route.params?.price ? String(route.params?.price / 100) : minPrice.toString(),
    goodsName: item?.Name,
    nftAsset: {
      num: 0,
    },
  }))
  const [msgId, setMsgId] = useState("")
  const [isAsyncFnc, setIsAsyncFnc] = useState(false)
  const [isAuthorize, setIsAuthorize] = useState(false)
  const [platePrice, setPlatePrice] = useState("0")
  const [isUseTicket, setIsUseTicket] = useState(false)
  const [isUseRmb, setisUseRmb] = useState(true)
  useEffect(() => {
    if (!isUseRmb) {
      setConsignSale((c) => ({ ...c, price: "0" }))
    }
  }, [isUseRmb])

  const [shuwanTicketNum, setShuwanTicketNum] = useState("")
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [items, setItems] = useState(() =>
    Array.from(collectStore.nftForExchange.values()).map((e) => ({
      label: e.name,
      value: e.key,
    })),
  )

  useEffect(() => {
    getPlatePrice()
  }, [])

  const getPlatePrice = async () => {
    const res = await accountStore.environment.api.normalRequest(
      `/card/getSkuInfo/${item?.contractAddress}/${item?.assetId}`,
      { contractAddress: item?.contractAddress, skuId: item?.assetId },
    )
    if (res.kind === "ok") {
      setPlatePrice(String(res.data?.parityPrice / 100))
      // setPlatePrice("123")
    }
  }
  const fetchCreateTradeMarketorder = async (needWait?: boolean) => {
    if (consignSale.price) {
      const res = await accountStore.environment.api.normalRequest(
        "/market/createTradeMarketorder",
        {
          nftId: consignSale.nftId,
          contractAddress: consignSale.contractAddress,
          price: Math.round(Number(consignSale.price) * 100),
          // goodsName: consignSale.goodsName,
          // nftNumber: item.CardShowId,
          isParity: saleType === 0,
          nftAsset: isUseTicket ? consignSale.nftAsset : undefined,
        },
        "post",
      )
      GToast.current.showLoading("正在上架中")
      if (res.kind === "ok") {
        const passData = {
          nftAddresses: [item.contractAddress],
          nftIds: [item.NftId],
          orderId: res.data.orderId,
        }
        if (needWait) {
          const alertRes = await GToast.current.showAlert(
            "需要签名",
            () => {},
            () => {},
            undefined,
            true,
          )
          // console.log(alertRes)
        }
        const content = await collectStore.environment.api.getSignature(
          "PayAbleMarket",
          {
            nftAddress: item.contractAddress,
            nftId: item.NftId,
            orderId: res.data.orderId,
          },
          true,
          {
            contractName: "GShouMarket",
            domain: "GShouMarket",
            version: "0.0.1",
            operationName: "GShouMarketPutOn",
            data: passData,
          },
        )
        if (content.kind === "ok") {
          const msgRes = await collectStore.environment.api.doMessageIdSearch(content.data.msgId)
          if (msgRes.kind === "ok") {
            showInfo("上架成功")
            //@ts-ignore
            navigation.navigate("TabOneScreen")
          } else {
            showError(msgRes)
          }
        } else {
          showError(content)
        }
      } else {
        showError(res)
        // if (res.kind === "error") {
        //   if (res.code === MyErrorCode.UserNotAdapayWallet) {
        //     requestAnimationFrame(() => GToast.current.showToast("请先开通支付钱包", "TOAST", 1500))
        //   } else if (res.code === MyErrorCode.ThirdPartyServiceError) {
        //     GToast.current.showToast("第三方服务器错误", "CANCEL", 1500)
        //   }
        // } else {
        //   showError(res)
        //   throw res?.msg
        // }
      }
    } else {
      showInfo("请输入价格")
    }
  }

  const fetchReonsellTradeMarketOrder = async () => {
    if (consignSale.price) {
      try {
        const signature = await collectStore.environment.api.signData({
          contractName: "GShouMarket",
          domain: "GShouMarket",
          version: "0.0.1",
          operationName: "GShouMarketPutOn",
          data: {
            nftAddress: [item.contractAddress],
            nftId: [item.NftId],
            orderId: route?.params?.orderId,
          },
        })

        const res = await accountStore.environment.api.normalRequest(
          "/market/reOnSellNft",
          {
            orderId: route?.params?.orderId,
            signature,
            price: Math.floor(Number(consignSale.price) * 100),
          },
          "post",
        )

        if (res.kind === "ok") {
          setMsgId(res.data.msgId)
          setIsAsyncFnc(true)
        } else if (res.kind === "forbidden") {
          navigation.replace("LoginFromMessage")
        } else if (res.kind === "error") {
          if (res.code === MyErrorCode.MarketOrderBuyerIsSellerError) {
            GToast.current.showToast("不可购买自己的在售商品", "TOAST", 2000)
          } else if (res.code === MyErrorCode.UserNotAdapayWallet) {
            GToast.current.showToast("还未创建支付钱包", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderInfoExpired) {
            GToast.current.showToast("订单信息已更新,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.MarketOrderStatusError) {
            GToast.current.showToast("订单状态异常,请重新刷新页面", "TOAST", 2000)
          } else if (res.code === MyErrorCode.ChainErrorUserNotOwn) {
            GToast.current.showToast("该资产已经上架或已售出,请重新刷新页面", "TOAST", 2000)
          }
        } else {
          GToast.current.showToast(res.msg || "上架失败", "CANCEL", 1500)
        }
      } catch (e) {
        if (typeof e.kind === "string" && typeof e.msg === "string") {
          GToast.current.showToast(e.msg, "CANCEL", 1500)
        } else {
          GToast.current.showToast("上架失败", "CANCEL", 1500)
          throw e
        }
      }
    } else {
      showInfo("请输入价格")
    }
  }

  const goSaveEdit = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/market/updateMarketTradeOrder",
      {
        orderId: route?.params?.orderId,
        price: Number(consignSale.price) * 100,
        orderStatus: 1,
      },
      "post",
    )
    if (res.kind === "ok") {
      navigation.goBack()
      showInfo("修改成功")
    } else {
      showError(res)
    }
  }

  const getAsyncResult = async (value: string) => {
    const res = await accountStore.environment.api.normalRequest(
      "/user/getAsyncResult",
      {
        msgId: value,
      },
      "post",
    )
    if (res.kind === "ok") {
      const status = JSON.parse(res?.data)?.status
      if (status == 2) {
        if (isAuthorize) {
          GToast.current.showToast("上架成功", "OK", 1500)
          //@ts-ignore
          navigation.navigate("TabOneScreen")
        } else {
          fetchCreateTradeMarketorder()
        }
      } else if (status == 1) {
        return
      } else {
        if (res.data) {
          setMsgId("")
          setIsAsyncFnc(false)
        }
        GToast.current.showToast("寄售失败", "CANCEL", 1500)
      }
    } else {
      hideLoad()
      throw res?.msg
    }
  }

  const onPurchase = async () => {
    if (isUseRmb) {
      const price = Number(consignSale.price)
      if (price < minPrice) {
        showInfo(`挂单价格无法低于￥${minPrice}.00`)
        return
      }
      if (price > maxPrice) {
        showInfo(`挂单价格无法高于￥${maxPrice}.00`)
        return
      }

      // if (!accountStore?.user?.adaPayUserCustId) { // adapay 是否开户
      if (!accountStore.isHaveOneWallet) {
        // 甘文交 是否开户
        GToast.current.showToast("请先开通支付钱包", "TOAST", 1500)
        return
      }
    }
    if (isUseTicket) {
      if (!consignSale?.nftAsset?.num) {
        showInfo(`必须输入数玩券数量`)
        return
      }

      if (!consignSale?.nftAsset?.nftAddress) {
        showInfo(`必须选择数玩券`)
        return
      }
    }
    const res = await accountStore.environment.api.normalRequest(
      "/card/isApproveOperateCard",
      {
        // contractName: item?.contractName,1
        // cardContractAddress: item?.contractAddress,
        contractName: "GShouMarket",
        cardContractAddress: item?.contractAddress,
      },
      "post",
    )
    GToast.current.showLoading("寄售上链中")
    if (res.kind === "ok") {
      if (res.data.isApprove) {
        // setIsAuthorize(true)
        fetchCreateTradeMarketorder()
      } else {
        // setIsAuthorize(false)
        const content = await collectStore.environment.api.userPrepareApprove(
          "GShouMarket",
          item?.contractAddress,
        )
        const msgRes = await collectStore.environment.api.doMessageIdSearch(content.data.msgId)
        if (msgRes.kind === "ok") {
          fetchCreateTradeMarketorder(true)
        } else {
          showError(msgRes)
        }

        // setMsgId(content.data.msgId)
        // setIsAsyncFnc(true)
      }
    } else {
      hideLoad()
      throw res?.msg
    }
  }

  const onChangePrice = (moneyVal: string) => {
    let str = moneyVal.split(".")

    // 控制不能输入两个小数点

    if (str.length - 1 > 1) {
      if (moneyVal == "0..") {
        moneyVal = "0."
      }
      moneyVal = moneyVal
    }

    // 控制不能输入00.xx

    if (str[0].length > 1) {
      //@ts-ignore
      if (str[0].indexOf(0) == "0") {
        // console.log('数字格式不正确！');
        moneyVal = ""
      }
    }

    if (moneyVal == ".") {
      moneyVal = moneyVal.replace(/[.]/g, "0.")
    }

    if (str[1]) {
      if (str[1].length <= 2) {
        moneyVal = str[0] + "." + str[1]
      } else {
        moneyVal = str[0] + "." + str[1].slice(0, 2)
      }
    }

    // return moneyVal
    const validator = /^[+-]?\d*(?:[.,]\d*)?$/
    let text = moneyVal
    if (validator.test(text)) {
      text = text.replace(",", ".")
      const price = Number(text)
      // if (minPrice <= price && price <= maxPrice)
      setConsignSale({ ...consignSale, price: text })
    } else {
      const price = Number(text)
      // if (minPrice <= price && price <= maxPrice)
      setConsignSale({ ...consignSale, price: text.substring(0, text.length - 1) })
    }
  }

  const onReOnSell = async () => {
    const res = await accountStore.environment.api.normalRequest(
      "/card/isApproveOperateCard",
      {
        // contractName: item?.contractName,1
        // cardContractAddress: item?.contractAddress,
        contractName: "PayAbleMarket",
        cardContractAddress: item?.contractAddress,
      },
      "post",
    )
    GToast.current.showLoading("寄售上链中")
    if (res.kind === "ok") {
      if (res.data.isApprove) {
        setIsAuthorize(true)
        fetchReonsellTradeMarketOrder()
      } else {
        setIsAuthorize(false)
        const content = await collectStore.environment.api.userPrepareApprove(
          "PayAbleMarket",
          item?.contractAddress,
        )
        fetchReonsellTradeMarketOrder()
        setMsgId(content.data.msgId)
        setIsAsyncFnc(true)
      }
    } else {
      hideLoad()
      throw res?.msg
    }
  }

  useEffect(() => {
    let time = null
    if (isAsyncFnc) {
      time = setInterval(() => {
        getAsyncResult(msgId)
      }, 1000)
    }
    return () => {
      clearInterval(time)
    }
  }, [isAsyncFnc])

  const [saleType, SetSaleType] = useState(1)

  return (
    <FlexView>
      <Sheader rightImage={""} title={"发布商品"} />
      <View
        style={{
          width: "100%",
          minHeight: fitSize(174),
          marginTop: fitSize(5),
          backgroundColor: "#fff",
        }}
      >
        <View
          style={{
            minHeight: fitSize(120),
            width: fitSize(351),
            alignSelf: "center",
            borderBottomColor: "#E8E8E8",
            borderBottomWidth: fitSize(1),
            flexDirection: "row",
          }}
        >
          <SizeImage
            source={{ uri: item?.Image }}
            style={{
              width: fitSize(90),
              height: fitSize(90),
              borderRadius: fitSize(10),
              overflow: "hidden",
              backgroundColor: "#000",
              alignSelf: "center",
            }}
          />
          <View style={{ marginLeft: fitSize(10), flex: 1, paddingTop: fitSize(15) }}>
            <Text style={{ fontSize: fitSize(16), fontWeight: "500", color: color.text }}>
              {item?.Name}
            </Text>
            <Text style={{ fontSize: fitSize(14), color: color?.dim, marginTop: fitSize(2) }}>
              {item?.Series}
            </Text>
            <Text
              numberOfLines={2}
              style={{
                fontSize: fitSize(16),
                fontWeight: "500",
                color: color.primary,
                marginTop: fitSize(10),
                flexShrink: 1,
                // width: fitSize(150),
              }}
            >
              {item?.CardShowId}
            </Text>
          </View>
        </View>
        <Touchable
          onPress={() => {
            setisUseRmb((c) => !c)
          }}
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: fitSize(12),
            marginTop: fitSize(8),
          }}
        >
          <SizeImage
            source={isUseRmb ? Images.components.choose : Images.components.not_choose}
            style={{ width: fitSize(20), height: fitSize(20) }}
          />
          <Text
            style={{
              color: color.text,
              fontSize: fitSize(16),
              fontWeight: "500",
              marginLeft: fitSize(12),
              marginTop: fitSize(8),
            }}
          >
            人民币交易
          </Text>
        </Touchable>
        <View style={{ paddingLeft: fitSize(10) }}>
          {platePrice != "0" && (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                paddingHorizontal: fitSize(12),
                alignItems: "center",
                // flex: 1,
                height: fitSize(54),
              }}
            >
              <Touchable
                onPress={() => {
                  SetSaleType(0)
                  onChangePrice(platePrice)
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <View
                  style={{
                    width: fitSize(16),
                    height: fitSize(16),
                    borderRadius: fitSize(8),
                    backgroundColor: "rgba(0, 175, 181, 0.2)",
                    borderWidth: fitSize(1.5),
                    borderColor: "rgba(0, 175, 181, 0.6)",
                    marginRight: fitSize(10),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {saleType === 0 && (
                    <View
                      style={{
                        position: "absolute",
                        width: fitSize(10),
                        height: fitSize(10),
                        borderRadius: fitSize(5),
                        backgroundColor: "#00AFB5",
                      }}
                    />
                  )}
                </View>
                <Text style={{ color: color.text, fontSize: fitSize(14), fontWeight: "500" }}>
                  平价转让
                </Text>
              </Touchable>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontSize: fitSize(16), fontWeight: "500", color: color.primary }}>
                  ￥{platePrice}
                </Text>
              </View>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              paddingHorizontal: fitSize(12),
              alignItems: "center",
              height: fitSize(54),
              backgroundColor: "#fff",
            }}
          >
            <Touchable
              onPress={() => {
                SetSaleType(1)
              }}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <View
                style={{
                  width: fitSize(16),
                  height: fitSize(16),
                  borderRadius: fitSize(8),
                  backgroundColor: "rgba(0, 175, 181, 0.2)",
                  borderWidth: fitSize(1.5),
                  borderColor: "rgba(0, 175, 181, 0.6)",
                  marginRight: fitSize(10),
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {saleType === 1 && (
                  <View
                    style={{
                      position: "absolute",
                      width: fitSize(10),
                      height: fitSize(10),
                      borderRadius: fitSize(5),
                      backgroundColor: "#00AFB5",
                    }}
                  />
                )}
              </View>
              <Text style={{ color: color.text, fontSize: fitSize(14), fontWeight: "500" }}>
                设置发布价格
              </Text>
            </Touchable>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontSize: fitSize(16), fontWeight: "500", color: color.primary }}>
                ￥
              </Text>
              <TextInput
                style={styles.inputText}
                value={consignSale.price}
                maxLength={10}
                onChangeText={(text) => onChangePrice(text)}
              />
            </View>
          </View>
        </View>
        <Touchable
          onPress={() => {
            setIsUseTicket((c) => !c)
          }}
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: fitSize(12),
            marginTop: fitSize(8),
          }}
        >
          <SizeImage
            source={isUseTicket ? Images.components.choose : Images.components.not_choose}
            style={{ width: fitSize(20), height: fitSize(20) }}
          />
          <Text
            style={{
              color: color.text,
              fontSize: fitSize(16),
              fontWeight: "500",
              marginLeft: fitSize(12),
              // marginTop: fitSize(8),
            }}
          >
            数玩券交易
          </Text>
        </Touchable>

        <View style={{ paddingLeft: fitSize(10), height: fitSize(54) * 2 }}>
          <View
            style={{
              flexDirection: "row",
              width: fitSize(375),
              justifyContent: "space-between",
              paddingHorizontal: fitSize(12),
              alignItems: "center",
              height: fitSize(54),
              backgroundColor: "#fff",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  width: fitSize(16),
                  height: fitSize(16),
                  borderRadius: fitSize(8),
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></View>
              <Text style={{ color: color.text, fontSize: fitSize(14), fontWeight: "500" }}>
                数玩券种类
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DropDownPicker
                placeholder="选择数玩券"
                style={{ width: fitSize(150) }}
                open={open}
                value={value}
                items={items}
                setOpen={setOpen}
                setValue={setValue}
                onChangeValue={(value) => {
                  console.log("choose", value)
                  if (value) {
                    const item = collectStore.nftForExchange.get(value)
                    console.log("find", item)
                    setConsignSale((c) => ({
                      ...c,
                      nftAsset: {
                        ...c.nftAsset,
                        assetId: item?.assetId,
                        nftAddress: item?.nftAddress,
                      },
                    }))
                  }
                }}
                setItems={setItems}
              />
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              width: fitSize(375),
              justifyContent: "space-between",
              paddingHorizontal: fitSize(12),
              paddingRight: fitSize(12 + 10),
              alignItems: "center",
              height: fitSize(54),
              backgroundColor: "#fff",
              position: "absolute",
              bottom: 0,
              zIndex: -1,
            }}
          >
            <View
              // onPress={() => {
              //   setIsUseTicket((c) => !c)
              // }}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              {/* <View
                style={{
                  width: fitSize(16),
                  height: fitSize(16),
                  borderRadius: fitSize(8),
                  backgroundColor: "rgba(0, 175, 181, 0.2)",
                  borderWidth: fitSize(1.5),
                  borderColor: "rgba(0, 175, 181, 0.6)",
                  marginRight: fitSize(10),
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isUseTicket && (
                  <View
                    style={{
                      position: "absolute",
                      width: fitSize(10),
                      height: fitSize(10),
                      borderRadius: fitSize(5),
                      backgroundColor: "#00AFB5",
                    }}
                  />
                )}
              </View> */}
              <View style={{ width: fitSize(16) }} />
              <Text style={{ color: color.text, fontSize: fitSize(14), fontWeight: "500" }}>
                数玩券数量
              </Text>
            </View>
            <TextInput
              style={styles.inputText}
              value={shuwanTicketNum}
              maxLength={10}
              onChangeText={(text) => {
                if (text === "") {
                  setShuwanTicketNum("")
                  setConsignSale((c) => ({
                    ...c,
                    nftAsset: { ...c.nftAsset, num: 0 },
                  }))
                  return
                }
                if (/^\d+$/.test(text)) {
                  setShuwanTicketNum(text)
                  setConsignSale((c) => ({ ...c, nftAsset: { ...c.nftAsset, num: Number(text) } }))
                }
              }}
            />
          </View>
        </View>
      </View>
      <View
        style={{
          alignSelf: "center",
          position: "absolute",
          zIndex: -1,
          width: fitSize(345),
          height: fitSize(44),
          top: fitSize(500),
        }}
      >
        <Touchable
          style={styles.button}
          onPress={isEdit ? goSaveEdit : isReOnSell ? onReOnSell : onPurchase}
        >
          <Text style={styles.buttonLabel}>{isEdit ? "保存" : "发布"}</Text>
        </Touchable>
      </View>
    </FlexView>
  )
}

const styles = StyleSheet.create({
  nameBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: fitSize(10),
    marginBottom: fitSize(15),
  },
  infoBox: {
    flexDirection: "row",
    paddingHorizontal: fitSize(13),
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: fitSize(15),
  },
  infoText: {
    fontSize: fitSize(13),
    color: "#06FDE8",
    fontWeight: "400",
  },
  nameText: {
    fontSize: fitSize(17),
    color: "#06FDE8",
    fontWeight: "500",
  },
  img: {
    width: fitSize(375),
    height: fitSize(230),
  },
  button: {
    width: fitSize(345),
    height: fitSize(44),
    backgroundColor: color.primary,
    borderRadius: fitSize(22),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: fitSize(40),
  },
  buttonLabel: {
    fontSize: fitSize(16),
    fontWeight: "600",
    color: "#fff",
  },
  inputBox: {
    width: "92%",
    height: fitSize(120),
    alignSelf: "center",
    backgroundColor: "#999999",
    borderRadius: fitSize(10),
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: fitSize(10),
    marginBottom: fitSize(20),
  },
  inputTopBox: {
    flexDirection: "row",
    width: "100%",
    height: fitSize(26),
    backgroundColor: "#333333",
    alignItems: "center",
    paddingLeft: fitSize(10),
  },
  inputTopText: {
    fontSize: fitSize(15),
    color: "#ffffff",
  },
  inputContent: {
    alignSelf: "center",
    width: "80%",
    height: fitSize(40),
  },
  inputContentBox: {
    flexDirection: "row",
    width: "100%",
    height: "100%",
    backgroundColor: "#333333",
    paddingLeft: fitSize(10),
    borderRadius: fitSize(5),
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: fitSize(10),
  },
  inputBottomText: {
    fontSize: fitSize(12),
    color: "#ffffff",
    marginBottom: fitSize(5),
  },
  inputBottomBox: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingHorizontal: fitSize(10),
  },
  inputIcon: {
    height: fitSize(20),
    width: fitSize(20),
    tintColor: "#fafafa",
  },
  inputText: {
    fontSize: fitSize(15),
    color: color.primary,
    textAlign: "left",
    backgroundColor: "rgba(0, 175, 181, 0.2)",
    width: fitSize(80),
    height: fitSize(30),
    borderColor: color.primary,
    borderRadius: fitSize(2),
    borderWidth: fitSize(1),
    marginLeft: fitSize(2),
  },
})
