import {
  Animated,
  FlatList,
  Image,
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native"
import { FULL, Images, color, deviceWidth, fitSize } from "../../theme"
import { HookFlatList, Screen, Text, TouchableDeep } from "../../components"
import React, { useCallback, useState } from "react"
import { SceneRendererProps, TabBar, TabView } from "react-native-tab-view"

import { BoughtView } from "./components/BoughtView"
import { MyTabBar } from "./components/MyTabBar"
import { RootStackNavProps } from "../../type/RouteType"
import { Sheader } from "../../components/header/Sheader"
import { useAutoList } from "../../hooks/useAutoList"
import { useStores } from "../../models"
import { themeData } from "../../navigators"
import { useTheme } from "styled-components/native"
import { ThemeScreen } from "../../components/screen/ThemeScreen"
import { useFocusEffect } from "@react-navigation/native"
import { TradeOrderStatusEnum } from "./define"

interface OrderMainProps extends RootStackNavProps<"OrderBuy"> {}
export const routes = [
  { key: "all", title: "全部" },
  { key: "unpay", title: "待付款" },
  { key: "sending", title: "发送中" },
  { key: "success", title: "已完成" },
  { key: "cancel", title: "已取消" },
]
type routeProp = {
  key: string
  title: string
}

export const OrderBuy: React.FC<OrderMainProps> = ({ navigation, route }) => {
  const [navState, setNavState] = useState({
    index: 0,
    routes: routes,
  })
  const [change, setChange] = useState(0)
  const theme = useTheme() as themeData
  const _renderScene = ({
    route,
  }: SceneRendererProps & {
    route: routeProp
  }) => {
    switch (route.key) {
      case "all":
        return <BoughtView change={change} setChange={setChange} type={0} router={route} />
      case "unpay":
        return <BoughtView change={change} setChange={setChange} type={TradeOrderStatusEnum.Unpay} router={route} />
      case "sending":
        return <BoughtView change={change} setChange={setChange} type={TradeOrderStatusEnum.Sending} router={route} />
      case "success":
        return <BoughtView change={change} setChange={setChange} type={TradeOrderStatusEnum.SendSuccess} router={route} />
      case "cancel":
        return <BoughtView change={change} setChange={setChange} type={TradeOrderStatusEnum.Cancel} router={route} />
      default:
        return null
    }
  }
  const _handleIndexChange = (index: number) => {
    setNavState({ ...navState, index })
  }
  const { height, width } = useWindowDimensions()

  return (
    <View
      testID="PackageDetail"
      style={{
        flexGrow: 1,
        height: Platform.OS === "web" ? height : undefined,
        backgroundColor: color.background,
      }}
    >
      <Sheader
        rightImage={""}
        title={"购买记录"}
        textStyle={{ color: color.text, fontSize: fitSize(17), fontWeight: "600" }}
        leftImage={""}
        style={{ backgroundColor: color.secBackground }}
      />
      <TabView
        initialLayout={{ width: width }}
        lazy={true}
        navigationState={navState}
        renderScene={_renderScene}
        onIndexChange={_handleIndexChange}
        renderTabBar={(props) => (
          // indicatorLeft={fitSize(37)
          <MyTabBar
            split={3}
            bgColor={color.secBackground}
            indicatorLeft={fitSize(33)}
            width={fitSize(70)}
            leftSpace={fitSize(13)}
            textColor="#888888"
            textTintColor="#00AFB5"
            textSize={fitSize(15)}
            tintTextSize={fitSize(15)}
            {...props}
          />
        )}
      />
    </View>
  )
}
const styles = StyleSheet.create({})
