import { Image, ScrollView, StyleSheet, TextInput, View } from "react-native"
import { Images, color, deviceWidth, fitSize, gStyle } from "../../theme"
import React, { useEffect, useRef, useState } from "react"
import { Text, Touchable } from "../../components"
import { load, remove, save } from "../../utils/storage"

import { FlexView } from "../../components/screen/FlexView"
import { GToast } from "../../navigators"
import { useFocusEffect } from "@react-navigation/native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useStores } from "../../models"
import { useTypeNavigation } from "../../navigators/useTypeNavigation"

// import { myStorage, nStorage } from "../../utils/storage/mmkv-storage"

interface MainSearchProps {}

const SEARCH_STORE = "SEARCH_ACTIVITY_STORE"
const swapArrayElements = function (arr: any[], indexA: number, indexB: number) {
  var temp = arr[indexA]
  arr[indexA] = arr[indexB]
  arr[indexB] = temp
  return arr
}
export const MarketSearchPage: React.FC<MainSearchProps> = ({}) => {
  const { top } = useSafeAreaInsets()
  const [test, setTest] = useState<string[]>([])
  useEffect(() => {
    const fetchInit = async () => {
      const res = (JSON.parse(await load(SEARCH_STORE)) || []) as string[]
      console.log("resres", res, res[1])
      setTest(res)
    }
    fetchInit()
  }, [])
  const [inputText, setInputText] = useState("")
  const { goBack, push } = useTypeNavigation()
  // const [searchslist, setSearchList] = useState([])
  const timeRef = useRef<number>(0)
  //添加到历史记录
  const searchItem = async (key: string) => {
    const index = test.findIndex((item) => item === key)
    if (index === -1) {
      setTest([key, ...test])
      save(SEARCH_STORE, JSON.stringify([key, ...test]))
    } else {
      const swapArray = [...swapArrayElements(test, index, 0)]
      setTest(swapArray)
      save(SEARCH_STORE, JSON.stringify(swapArray))
    }
    push("MarketSearchResultList", { searchWord: key, setInputText })
  }

  const directToResult = (cacheRes: string) => {
    setInputText(cacheRes)
    push("MarketSearchResultList", { searchWord: cacheRes, setInputText })
  }
  // useEffect(() => {
  //   clearTimeout(timeRef.current)
  //   if (inputText) {
  //     //@ts-ignore
  //     timeRef.current = setTimeout(() => {
  //       doAssociateSearch()
  //     }, 500)
  //   } else {
  //     setSearchList([])
  //   }
  //   return () => clearTimeout(timeRef.current)
  // }, [inputText])
  //联想搜索
  // const doAssociateSearch = async () => {
  //   const res = await accountStore.environment.api.normalRequest(
  //     `/user/market/search/keywordComplete/${inputText}`,
  //   )
  //   if (res.kind === "ok") {
  //     setSearchList(res.data?.keywords || [])
  //   }
  // }
  const showDelete = () => {
    GToast.current.showAlert(
      "是否清除历史搜索记录",
      () => {},
      () => {
        setTest([])
        remove(SEARCH_STORE)
      },
    )
  }
  useFocusEffect(
    React.useCallback(() => {
      setTimeout(() => {
        textRef?.current?.focus()
      }, 300)
      return () => textRef?.current?.blur()
    }, []),
  )
  const textRef = useRef<TextInput>()

  return (
    <FlexView>
      <View style={styles.searchWarp}>
        <Touchable
          onPress={() => goBack()}
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: fitSize(32),
            height: fitSize(32),
            marginLeft: fitSize(8),
          }}
        >
          <Image
            source={Images.common.black_back}
            style={{ width: fitSize(32), height: fitSize(32) }}
          />
        </Touchable>
        <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
          <TextInput
            ref={textRef}
            value={inputText}
            spellCheck={false}
            enablesReturnKeyAutomatically
            onSubmitEditing={() => searchItem(inputText)}
            returnKeyType={"search"}
            autoFocus={true}
            autoCorrect={false}
            autoCapitalize={"none"}
            placeholder={"输入搜索关键字"}
            placeholderTextColor={color.dim}
            style={styles.inputWrap}
            underlineColorAndroid={"transparent"}
            onChangeText={setInputText}
          />
          <Image
            source={Images.home.search_gray}
            style={{
              width: fitSize(18),
              height: fitSize(18),
              position: "absolute",
              left: fitSize(12),
            }}
          />
          {!!inputText && (
            <Touchable
              onPress={() => {
                setInputText("")
                // setIsTyping(false)
              }}
              style={{
                position: "absolute",
                right: fitSize(20),
                width: fitSize(30),
                height: fitSize(30),
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={Images.market.delete}
                style={{ width: fitSize(16), height: fitSize(16) }}
              />
            </Touchable>
          )}
        </View>
      </View>
      {/* <View style={{ flex: 1 }}>
        <View>
          {test?.length > 0 && (
            <View
              style={{
                marginTop: fitSize(0),
                paddingHorizontal: fitSize(16),
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ color: "#333", fontSize: fitSize(16), fontWeight: "bold" }}>
                搜索历史
              </Text>
              <Touchable
                onPress={showDelete}
                style={{ width: fitSize(13), height: fitSize(13) }}
                hitSlop={{ bottom: 5, top: 5, left: 5, right: 5 }}
              >
                <Image
                  source={Images.market.delete}
                  style={{ width: fitSize(13), height: fitSize(13) }}
                />
              </Touchable>
            </View>
          )}
          <View
            style={{
              flexWrap: "wrap",
              flexDirection: "row",
              paddingHorizontal: fitSize(11),
              marginTop: fitSize(6),
            }}
          >
            {test?.length > 0 &&
              test?.map((item, index) => {
                return (
                  <Touchable
                    key={index}
                    onPress={() => {
                      directToResult(item)
                    }}
                    style={{
                      padding: fitSize(10),
                      borderRadius: fitSize(15),
                      backgroundColor: "#F5F5F5",
                      marginHorizontal: fitSize(5),
                      marginVertical: fitSize(6),
                    }}
                  >
                    <Text style={{ color: "#666", fontSize: fitSize(12) }}>{item}</Text>
                  </Touchable>
                )
              })}
          </View>
        </View>
      </View> */}
    </FlexView>
  )
}

const styles = StyleSheet.create({
  searchWarp: {
    width: deviceWidth,
    height: fitSize(48),
    flexDirection: "row",
    alignItems: "center",
    // paddingLeft: fitSize(10),
  },
  inputWrap: {
    width: fitSize(318),
    height: fitSize(34),
    backgroundColor: color.secBackground,
    borderRadius: fitSize(17),
    paddingLeft: fitSize(38),
    fontSize: fitSize(14),
    color: color.text,
    paddingVertical: 0,
  },
  iconStyle: {
    width: fitSize(32),
    height: fitSize(32),
    marginLeft: fitSize(30),
  },
  monsterimg: {
    width: fitSize(90),
    height: fitSize(100),
    resizeMode: "contain",
  },
  fullView: {
    flexGrow: 1,
    marginLeft: fitSize(19),
  },
})
