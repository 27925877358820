import { Image, StyleSheet, Text } from "react-native"
import React from "react"
import { fitSize, Images } from "../../../theme"
import { Touchable } from "../../../components"
import { modalType } from "../AssetScreen"

interface Props {
  select: string
  onSelect: (text: string, type: modalType) => void
  selectState: boolean
  sheetTitle: string
  defaultValue: string
  type: modalType
}
//Images.knapsack.select_gray_activity
const SelectText: React.FC<Props> = ({
  select = "",
  onSelect = () => {},
  selectState = false,
  sheetTitle = "",
  defaultValue,
  type,
}) => {
  console.log("ssr", select, defaultValue)
  return (
    <Touchable style={styles.container} onPress={() => onSelect(sheetTitle, type)}>
      {type !== "time" ? (
        <React.Fragment>
          <Text
            style={select && select !== defaultValue ? styles.selectText : styles.notSelectText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {select || defaultValue}
          </Text>
          <Image
            source={
              select && select !== defaultValue
                ? Images.components[
                    selectState ? "select_green_activity" : "select_green_unactivity"
                  ]
                : Images.components[selectState ? "select_gray_activity" : "select_gray_unactivity"]
            }
            style={styles.selectIcon}
            resizeMode="cover"
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text
            style={selectState ? styles.selectText : styles.notSelectText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {select || defaultValue}
          </Text>
          <Image
            source={Images.components.filter}
            style={[
              styles.selectIcon,
              {
                tintColor: selectState ? "#06FDE8" : "#A2A3A3",
                transform: [{ rotateX: selectState ? "180deg" : "0deg" }],
              },
            ]}
            resizeMode="cover"
          />
        </React.Fragment>
      )}
    </Touchable>
  )
}

export default SelectText

const styles = StyleSheet.create({
  container: {
    minWidth: fitSize(75),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectText: {
    fontSize: fitSize(15),
    fontWeight: "400",
    lineHeight: fitSize(21),
    color: "#06FDE8",
  },
  notSelectText: {
    fontSize: fitSize(15),
    fontWeight: "400",
    lineHeight: fitSize(21),
    color: "#A2A3A3",
  },
  selectIcon: {
    width: fitSize(12),
    height: fitSize(12),
    marginLeft: fitSize(5),
  },
})
